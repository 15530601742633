import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

function UncheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H14C17.0376 0.5 19.5 2.96243 19.5 6V14C19.5 17.0376 17.0376 19.5 14 19.5H6C2.96243 19.5 0.5 17.0376 0.5 14V6Z"
          stroke="#D0D5DD"
        />
      </svg>
    </SvgIcon>
  );
}

export default UncheckedIcon;
