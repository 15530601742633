import { Typography } from "@mui/material";
import React from "react";

function PageHeadingText({ text }: { text: string }) {
  return (
    <Typography
      variant="h4"
      component="h1"
      color="#101828"
      fontSize={18}
      fontFamily={"Inter"}
      fontWeight={600}
      gutterBottom
    >
      {text}
    </Typography>
  );
}

export default PageHeadingText;
