import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";
import TextField from "../../../../components/TextField";
import PageActionsBar from "../../PageActionsBar";
import SectionHeader from "../../SectionHeader";
import Select from "../../../../components/Select";
import { useFormContext, useWatch } from "react-hook-form";
import { useAppLoader } from "../../../../../../_common/hooks/common/appLoaderHook";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { TStudentProfileDetailsResponse } from "../../../../types/TStudentProfileDetailsResponse";
import { EXECUTE_REQUEST_PATH, TERM_TYPE_OPTIONS } from "../../../../constants";
import { IApiResponse } from "../../../../types/IApiResponse";
import { API } from "../../../../services/API";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../components/Notify";
import { TermType } from "../../../../types/shared";
import CircleLoader from "../../../../components/CircleLoader";

type TPageForm = {
  Institution: string;
  Campus: string;
  TermTypeId: TermType;
};

function AccommodationInformation() {
  const { register, handleSubmit, reset, control } =
    useFormContext<TPageForm>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showLoader, hideLoader } = useAppLoader();
  const currentUser = useCurrentUser();

  // Store initial form values
  const [initialValues, setInitialValues] = useState<TPageForm | null>(null);
  const [isEdited, setIsEdited] = useState(false); // Track if form is edited
  const [termTypeEdit, settermTypeEdit] = useState(false);

  // Watch form values for changes
  const formValues = useWatch({ control });

  const getUserDetails = async () => {
    if (!currentUser) {
      return;
    }
    try {
      showLoader();
      const response: IApiResponse<TStudentProfileDetailsResponse> =
        await API.post(EXECUTE_REQUEST_PATH, {
          entityName: "Employee",
          requestName: "RetrieveProfileDetailsReq",
          inputParamters: {
            RecordId: currentUser.recordId,
          },
        });

      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }

      const profileDetails = response.outputParameters.ProfileDetails;

      // Set initial form values
      const initialFormValues: TPageForm = {
        Institution: profileDetails.facilityInstitution.institutionIdName,
        Campus: profileDetails.facilityInstitution.campusIdName,
        TermTypeId: profileDetails.studentDetails.termTypeId,
      };

      setInitialValues(initialFormValues); // Save initial values
      reset(initialFormValues); // Reset the form with the fetched values

      if (profileDetails.studentDetails.termTypeId !== null) {
        settermTypeEdit(true);
      }
    } catch (e) {
      showErrorAlert("An error occurred while fetching your details");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // Check if the form has been edited
  useEffect(() => {
    if (initialValues) {
      const isFormEdited =
        JSON.stringify(formValues) !== JSON.stringify(initialValues);
      setIsEdited(isFormEdited); // Set form as edited if values have changed
    }
  }, [formValues, initialValues]);

  const onSubmit = async (data: TPageForm) => {
    try {
      if (!currentUser) {
        return;
      }
      setIsSubmitting(true);
      const response = await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "Employee",
        recordId: currentUser.recordId,
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: data,
        },
      });
      if (!response.isSuccess) {
        throw new Error("An error occurred while updating your details");
      }

      showSuccessAlert("Profile updated successfully");
      setInitialValues(data); // Reset initial values after successful save
      setIsEdited(false); // Mark form as not edited
    } catch (e) {
      showErrorAlert("An error occurred while updating your details");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOnSaveClick = () => handleSubmit(onSubmit)();

  const handleOnCancelClick = () => {
    if (initialValues) {
      reset(initialValues); // Reset form to the initial values
      setIsEdited(false); // Mark form as not edited
    }
  };

  return (
    <>
      <PageActionsBar
        title="Academic information"
        subtitle="View your accommodation details here."
        disabled={isSubmitting}
        onSaveClick={handleOnSaveClick}
      />
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <SectionHeader
            title="School details"
            subtitle="This will not be displayed on your profile."
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <TextField
            label="Institution"
            size="small"
            placeholder="University of KZN"
            {...register("Institution", { disabled: true })}
          />
          <TextField
            label="Campus"
            size="small"
            placeholder="Select your campus"
            {...register("Campus", { disabled: true })}
          />
          <Select
            label="Term Type"
            options={TERM_TYPE_OPTIONS}
            size="small"
            placeholder="Select an option"
            {...register("TermTypeId", {
              disabled: termTypeEdit,
              required: true,
            })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid textAlign="right" item xs={12}>
        <Box display="inline-flex" gap={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={!isEdited || isSubmitting} // Enable Cancel only if the form is edited
            onClick={handleOnCancelClick}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            type="button"
            color="primary"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircleLoader /> : undefined}
            onClick={handleOnSaveClick}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default AccommodationInformation;
