import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Container, Row, Col, Button } from "react-bootstrap";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import { CheckNumber } from "../_common/functions";
import Header from "./student/components/header";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useResidenceApi } from "../_common/hooks/api/residenceApiHook";
import { access } from "fs/promises";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import { info } from "console";
import { compileFunction } from "vm";
import LeftPanel from "../components/leftPanel";

export default function MoreAboutProperty() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const commonApi = useCommonApi();
  const residenceApi = useResidenceApi();

  const residenceID = sessionStorage.getItem("residenceID");
  const [amenities, setAmenities] = useState<any>(null);
  const [prevData, setPrevData] = useState<any>(null);
  const [prevDataChecked, setPrevDataChecked] = useState<boolean>(false);

  const name = sessionStorage.getItem("residenceName");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    watch,
    reset,
  } = useForm({ mode: "onChange" });
  const options: any = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];
  const getResidenceAdditionalInfo = () => {
    residenceApi.getResidenceAdditionalInfo(
      {
        ViewName: "Nice_To_Haves",
      },
      (message: string, resp: any) => {
        const _amenities: any = [];

        resp.map((amenity: any) => {
          if (amenity.recordId.search("000000") !== 0) {
            _amenities.push(amenity);
          }
        });
        setAmenities(_amenities);
      },
      (message: string) => {
        // console.log(message);
      }
    );
  };

  const onSubmit = (data: any) => {
    const _amenities: any = [];
    Object.keys(data).map((amenityID: any) => {
      const param: any = {
        AmenityId: amenityID,
        FacilityId: residenceID,
        Quantity: data[amenityID], // Adding quantity as either '1' or '0'
      };

      if (prevData) {
        if (prevData[amenityID]) {
          param.recordId = prevData[amenityID].CapacityId;
        }
      }
      _amenities.push(param);
    });

    const params: any = {
      entityName: "Capacity",
      requestName: "AddMultipleReq",
      inputParamters: { Amenities: _amenities },
    };

    showLoader();
    if (_amenities && _amenities.length > 0) {
      commonApi.post(
        params,
        (message: string, resp: any) => {
          hideLoader();
          navigate("/upload-property-images");
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    } else {
      hideLoader();
      navigate("/upload-property-images");
    }
  };

  const getPrevData = () => {
    if (residenceID) {
      showLoader();
      // Call API and reset form values via reset()
      commonApi.retreive(
        {
          Entity: "Capacity",
          Top: 100,
          ReturnCols: ["CapacityId", "Quantity", "AmenityId", "AmenityIdName"],
          FilterOn: {
            Type: "And",
            Where: [
              {
                filterCol: "FacilityId",
                FilterCondition: "Equals",
                FilterValue: residenceID,
              },
              {
                filterCol: "AmenityTypeId",
                FilterCondition: "Equals",
                FilterValue: "881",
              },
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          const prevData = JSON.parse(resp);
          if (prevData.length) {
            const resetData: any = {};
            prevData.forEach((data: any) => {
              resetData[data.AmenityId] = {
                CapacityId: data.CapacityId,
                Quantity: data.Quantity,
              };
            });
            setPrevData(resetData);
          } else {
            console.log("More about property has no previous data");
          }
          setPrevDataChecked(true);
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    } else {
    }
  };

  useEffect(() => {
    if (residenceID == null) {
      navigate("/add-residence");
    } else {
      getResidenceAdditionalInfo();
    }
  }, []);

  useEffect(() => {
    if (amenities) {
      getPrevData();
    }
  }, [amenities]);

  const getRadioStatus = (value: any, amenityId: string) => {
    if (prevData && prevData[amenityId]) {
      return prevData[amenityId].Quantity === value;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <section className="container">
          <div className="custome-container-inner d-flex">
            <LeftPanel page={3} />
            <div className="white-block p-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col">
                    <h1>{name && `${name} - `}Additional Offerings</h1>
                  </div>
                </div>
                <hr />
                {prevDataChecked ? (
                  <div className="tab_scrollcontent">
                    {amenities?.map((amenity: any, i: any) => (
                      <div className="mb-2" key={i}>
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label"
                        >
                          {amenity.mainText}
                        </label>
                        <div className="row">
                          {options.map((option: any, j: any) => (
                            <div className="col" key={i + "-" + j}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  value={option.value}
                                  defaultChecked={getRadioStatus(
                                    option.value,
                                    amenity.recordId
                                  )}
                                  type="radio"
                                  id={i + "-" + j + "-" + amenity.recordId}
                                  {...register(amenity.recordId, {
                                    required: true,
                                  })}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={i + "-" + j + "-" + amenity.recordId}
                                >
                                  {option.label}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
                <div className="mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-2"
                    disabled={!isValid}
                    data-bs-toggle="button"
                  >
                    Next
                  </button>
                  <Link to={URLS.DESCRIBE_ROOMS}>
                    <button
                      type="button"
                      className="btn btn-outline-secondary mt-2 w-100 back-btn-add-prop"
                      data-bs-toggle="button"
                      style={{
                        // display:'flex',
                        // justifyContent:'center',
                        // alignItems:'center',
                        // height:'35px',
                        backgroundColor: "#fff",
                      }}
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
