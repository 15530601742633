import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../components/header";
import userCheckIcon from "../images/user-check-01.svg";
import { useNavigate } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { STORAGE } from "../_config";
import Breadcrumbs from "./student/components/breadcrumbs";

interface StudentList {
  Id: string;
  CampusIdName: string;
  Name: string;
  Gender: string;
  Status: string;
  Mobile: string;
}
interface Data {
  institutionId: string;
}

const StudentList = () => {
  const navigate = useNavigate();
  const commonApi = useCommonApi();
  const user: any = useUserSelector();
  const [show, setShow] = useState<boolean>(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState<
    string | null
  >(null);
  const [studentList, setStudentList] = useState<StudentList[]>([]);

  const [roomTypes, setRoomTypes] = useState<any[]>([]);
  const { showLoader, hideLoader } = useAppLoader();
  const [seshData, setSeshData] = useState<any>();
  const [id, setId] = useState<any>();
  const [myId, setMyId] = useState<any>();

  const getStudentList = (institutionId: any) => {
    commonApi.getInstitutionStudents(
      {
        InstitutionId: institutionId,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setStudentList(resp);
        }
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    setId(user.institutionId);
  }, []);

  useEffect(() => {
    getStudentList(user.institutionId);
  }, []);

  return (
    <div>
      <Header />
      <Box sx={{ padding: "2rem" }}>
        <Box sx={{ marginTop: "4rem" }}>
          <Breadcrumbs
            data={[{ label: "Home", path: "/" }, { label: "Student List" }]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            marginTop: "5rem",
          }}
        >
          <Typography
            sx={{
              // marginTop: "7rem",
              color: "#101828",
              fontFamily: "Inter",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "38px",
              marginBottom: "20px",
            }}
          >
            List Of Students
          </Typography>
          {/* <Button>Add New</Button> */}
          <Button
            onClick={() => {
              navigate("/add-student");
            }}
            // href='/campus-details'
            sx={{
              border: "1px solid grey",
              cursor: "pointer",
              height: "10px",
            }}
          >
            Add New
          </Button>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{}}>
            <Table sx={{ minWidth: 1050 }} aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#F9FAFB",
                }}
              >
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Campus Name</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Mobile No.</TableCell>
                  <TableCell>Application Status</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentList.map((studentDetail: StudentList) => (
                  <TableRow
                    // key={studentDetail.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {studentDetail.Name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {studentDetail.CampusIdName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {studentDetail.Gender}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {studentDetail.Mobile}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {studentDetail.Status}
                    </TableCell>

                    <TableCell
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        justifyContent: "right",
                      }}
                    >
                      <Button
                        // onClick={()=>{navigate('/student-action')}}
                        onClick={() => {
                          navigate(`/student-action/${studentDetail.Id}`);
                        }}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "8px",
                          border: "1px solid #D0D5DD",
                          color: "#F88A0B",
                          gap: "2rem",
                        }}
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};

export default StudentList;
