import {
  Box,
  FormControl,
  Typography,
  Button,
  FormHelperText,
  TextFieldProps,
} from "@mui/material";
import React, { useEffect } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { UTILS } from "../../../../utils";
import {
  ALLOWED_FILE_TYPES,
  MAX_FILE_SIZE,
  UploadDocumentType,
} from "../../../../ui/v2/constants";
import { showErrorAlert } from "../../../../ui/v2/components/Notify";

const fallbackId = `fileInput${UTILS.getId()}`;

type TUploadValueProps = {
  FileName?: string;
  DocumentTypeId?: string;
  FileExtention?: string;
  FileContent?: string;
  RecordId?: string; // Add RecordId to preserve it
};

type Props = {
  label?: TextFieldProps["label"];
  helperText?: TextFieldProps["helperText"];
  placeholder?: TextFieldProps["placeholder"];
  onChange?: any;
  value?: TUploadValueProps;
  documentTypeId?: UploadDocumentType;
  name?: string;
  id?: string;
  size?: TextFieldProps["size"];
  selectFileText?: string;
  disabled?: boolean;
};

const FileUploadInput = React.forwardRef((props: Props, ref: any) => {
  const {
    label,
    helperText,
    placeholder,
    onChange,
    value: valueObjectString,
    documentTypeId,
    name = fallbackId,
    id = fallbackId,
    size,
    disabled,
    selectFileText = "Attach file",
    ...rest
  } = props;

  // Include RecordId to preserve it when updating
  const [
    { FileName, DocumentTypeId, FileContent, FileExtention, RecordId },
    setValue,
  ] = React.useState<TUploadValueProps>(valueObjectString ?? {});

  const isInitializedRef = React.useRef(false);

  useEffect(() => {
    if (isInitializedRef.current || !valueObjectString) {
      return;
    }
    setValue(valueObjectString);
    setFileLabel(valueObjectString.FileName || "");
    isInitializedRef.current = true;
  }, [valueObjectString]);

  const [fileLabel, setFileLabel] = React.useState<string>(
    FileName ? `${FileName}.${FileExtention}` : placeholder || "upload file"
  );

  const simulateChange = async (e: any) => {
    const file = e.target.files[0];

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      showErrorAlert("File size exceeds the 2MB limit.");
      e.target.value = null;
      return;
    }

    // Check file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      showErrorAlert(
        "Invalid file type. Please upload a PDF, Word document, or an image."
      );
      e.target.value = null;
      return;
    }

    const fileContent = await UTILS.convertFileToBase64(file);

    onChange?.({
      target: {
        name,
        id,
        value: {
          FileName: file.name.split(".").slice(0, -1).join("."),
          DocumentTypeId: DocumentTypeId || documentTypeId,
          FileExtention: file.name.split(".").pop(),
          FileContent: fileContent,
          RecordId, // Include the RecordId when updating the document
        } as TUploadValueProps,
      },
    });
    setFileLabel(file.name);
  };

  return (
    <FormControl fullWidth size={size} disabled={disabled}>
      <Box>
        <Typography fontSize={14} mb={0.5}>
          {label}
        </Typography>
      </Box>
      <Box
        sx={{
          pl: 2,
          border: "1px solid #D0D5DD",
          display: "flex",
          borderRadius: 2,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "#667085" }} noWrap>
          {fileLabel}
        </Typography>
        <Button
          startIcon={<AttachFileIcon />}
          component="label"
          sx={{
            whiteSpace: "nowrap",
            borderLeft: "1px solid #D0D5DD",
            borderRadius: 0,
            textTransform: "none",
            fontWeight: "bold",
            px: 2,
            color: "#344054",
          }}
          disabled={disabled}
        >
          {selectFileText}
          <input
            onChange={simulateChange}
            ref={ref}
            type="file"
            hidden
            disabled={disabled}
          />
          <input hidden type="text" {...rest} disabled={disabled} />
        </Button>
      </Box>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});

export default FileUploadInput;
