import { FieldErrorsImpl, FieldValues, UseFormRegister } from "react-hook-form";
import { RequiredAsterisk } from "./blocks/requiredAsterisk";
import { INPUT_MAX_LENGTHS } from "../_config";

interface InputFieldProps {
  label: string;
  id: string;
  errors: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  required?: boolean;
  register: UseFormRegister<FieldValues>;
  registerParams?: Partial<UseFormRegister<FieldValues>>;
  placeholder?: string;
  type?: "text" | "email" | "tel" | "number" | "date";
  disabled?: boolean;
  step?: number | string;
  prefix?: string;
}

export const InputField = ({
  errors,
  required = true,
  id,
  register,
  label,
  registerParams,
  placeholder,
  type = "text",
  disabled,
  step,
  prefix,
}: InputFieldProps) => {
  const input = (
    <input
      disabled={disabled}
      type={type}
      className="form-control"
      id={`formControlInput_${id}`}
      placeholder={placeholder}
      {...register(id, {
        required,
        maxLength: INPUT_MAX_LENGTHS.default,
        ...registerParams,
      })}
      step={step}
    />
  );

  return (
    <>
      <label
        htmlFor={`formControlInput_${id}`}
        className={"form-label " + errors[id] ? "is-invalid" : ""}
      >
        {label}
        {required && <RequiredAsterisk />}
      </label>

      {prefix ? (
        <div className="input-group mb-3">
          <span
            className="input-group-text"
            id={`formControlInput_${id}_prefix`}
          >
            {prefix}
          </span>
          {input}
        </div>
      ) : (
        input
      )}
      {(errors?.[id]?.message || errors?.[id]?.type === "required") && (
        <div className="invalid-feedback">
          {errors?.[id]?.type === "required"
            ? "Required"
            : (errors?.[id]?.message as string)}
        </div>
      )}
    </>
  );
};
