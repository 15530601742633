import React from "react";
import { Button } from "react-bootstrap";
import IntroSlider from "../components/introSlider";
import { useNavigate } from "react-router-dom";
import { color } from "@amcharts/amcharts5";

const StudentRegistrationSuccessful = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="register_page login_page">
        <div className="register_left">
          <div className="mob_logo">
            <a href="#" className="logo_left">
              <img src="/assets/img/logo.png" />
            </a>
          </div>
          <div className="logo_txt">Registration successful.</div>
          <p className="text-center p-4">
            Your profile has been successfully activated.
            <br />
            <br />
            NEXT STEPS: Please proceed to login, search for residence and apply.{" "}
            <br></br>
            <p className="text-center p-4" style={{ color: "red" }}>
              <b>
                {" "}
                PLEASE NOTE: If you are already residing in an accredited
                residence, please make sure to apply in the same residence
              </b>
            </p>
          </p>
          <div
            className="mb-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="primary"
              className="w-50 mt-2"
              type="button"
              onClick={() => navigate("/login?type=s")}
            >
              Login
            </Button>
          </div>
        </div>
        <IntroSlider />
      </div>
    </div>
  );
};

export default StudentRegistrationSuccessful;
