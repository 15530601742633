import React, { useEffect, useState } from "react";
import {
  Bell,
  LogOut,
  Copyright,
  ArrowRight,
  CircleCheck,
  MapPinCheckInside,
  Zap,
  Home,
} from "lucide-react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import LegacyPageLayout from "@/components/LegacyPageLayout";
import useCurrentUser from "@/ui/v2/hooks/useCurrentUser";
import { useAppLoader } from "@/_common/hooks/common/appLoaderHook";
import { showErrorAlert } from "@/components/Notify";
import { DEFAULT_PAGE_SIZE, EXECUTE_REQUEST_PATH } from "@/ui/v2/constants";
import { CheckInType } from "@/ui/v2/types/shared";
import { IApiResponse } from "@/ui/v2/types/IApiResponse";
import { URLS } from "@/_config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API } from "@/ui/v2/services/API";
import EmptyListingComponent from "./EmptyListingComponent";

interface Property {
  id: string;
  name: string;
  imageUrl: string;
}

type CheckInListingResponseType = {
  Summary: {
    totalCases: number;
    openCases: number;
    closedCases: number;
    onHoldCases: number;
  };
  PageSize: number;
  RecordCount: number;
  Pages: number;
  Listing: {
    address: string;
    checkInId: string;
    checkInStatus: "Pending" | "Draft" | "Complete";
    mobile: string;
    month:
      | "January"
      | "February"
      | "March"
      | "April"
      | "May"
      | "June"
      | "July"
      | "August"
      | "September"
      | "October"
      | "November"
      | "December";
    studentResidence: string;
  }[];
};
type TUpsertResponse = {
  UpsertResponse: {
    recordId: string;
    record: string | null;
    success: boolean;
    errorMesssage: string | null;
    upsertAction: number;
  };
};

const properties: Property[] = [
  {
    id: "1",
    name: "Rink's",
    imageUrl: "/api/placeholder/48/48",
  },
  {
    id: "2",
    name: "Pro Property Maintenance",
    imageUrl: "/api/placeholder/48/48",
  },
];

const PropertyCheckInListing = () => {
  const currentUser = useCurrentUser();
  const { showLoader, hideLoader } = useAppLoader();
  const [details, setDetails] = useState<CheckInListingResponseType>();
  const [selectedCheckIn, setSelectedCheckIn] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : 1;
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedText, setSearchedText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(
    initialPage > 0 ? initialPage : 1
  );

  const getApiData = async (_currentPage: number) => {
    if (!currentUser) {
      return;
    }
    try {
      showLoader();
      const caseListingRequest: IApiResponse<CheckInListingResponseType> =
        await API.post(EXECUTE_REQUEST_PATH, {
          entityName: "CheckIn",
          requestName: "RetrieveCheckInListing",
          inputParamters: {
            UserId: currentUser.supplierId
              ? currentUser.supplierId
              : currentUser.recordId,
            SearchText: searchedText,
            PageNumber: currentPage,
            PageSize: DEFAULT_PAGE_SIZE,
          },
        });
      console.log(caseListingRequest.outputParameters);
      setDetails(caseListingRequest.outputParameters);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getApiData(currentPage);
  }, [currentPage, searchedText]);

  const navigate = useNavigate();
  const isCreatingCheckingRef = React.useRef(false);
  const createNewCheckin = async () => {
    try {
      if (!currentUser) {
        return;
      }
      if (isCreatingCheckingRef.current) {
        return;
      }
      isCreatingCheckingRef.current = true;
      const response: IApiResponse<TUpsertResponse> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "CheckIn",
          requestName: "UpsertRecordReq",
          inputParamters: {
            Entity: {
              studentId: currentUser.recordId,
              checkInTypeId: CheckInType.Adhoc,
            },
          },
        }
      );
      if (!response.isSuccess) {
        throw new Error("An error creating the checkin");
      }
      navigate(
        `${URLS.STUDENT.CHECK_IN_LISTING}/${response.outputParameters.UpsertResponse.recordId}`
      );
    } catch (e) {
      showErrorAlert("An error creating the checkin");
    } finally {
      isCreatingCheckingRef.current = false;
    }
  };
  console.log("deta", details);

  if (details?.Listing.length == 0) {
    return <EmptyListingComponent />;
  }
  return (
    <LegacyPageLayout paddingTop={0}>
      {/* Header */}

      {/* Main Content */}
      <main className="py-5 px-3 max-w-lg mx-auto border border-gray-500 rounded-md mb-3">
        {/* How it works section */}
        <div className="mb-8">
          <div className="bg-[#ffe6d5] rounded-full w-12 h-12 flex items-center justify-center mb-6">
            <div className="text-2xl">
              <Zap className="text-orange-500" />{" "}
            </div>
          </div>

          <h1 className="text-2xl font-bold mb-4 ">How it works</h1>
          <p className="text-gray-600 mb-6 font-normal text-lg">
            As a student you can now view a property before committing to it.
          </p>

          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <CircleCheck className="text-orange-500" />
              <span className="text-gray-600 font-normal text-lg">
                Apply online.
              </span>
            </div>
            <div className="flex items-center gap-3">
              <CircleCheck className="text-orange-500" />{" "}
              <span className="text-gray-600 font-normal text-lg">
                View the property.
              </span>
            </div>
            <div className="flex items-center gap-3">
              <CircleCheck className="text-orange-500" />{" "}
              <span className="text-gray-600 font-normal text-lg">
                Stay or keep looking.
              </span>
            </div>
          </div>
        </div>

        {/* Check-ins section */}
        <Card className="rounded-none shadow-none">
          <CardHeader className="flex flex-row items-center justify-between border-b ">
            <h2 className="text-xl font-bold">Check-ins</h2>
            <button className="text-gray-400">⋮</button>
          </CardHeader>
          <CardContent className="p-0">
            <RadioGroup defaultValue="option-1">
              <div className="space-y-4">
                <div className="flex items-center justify-between py-1 bg-[#EAECF0] px-6 h-11 border-b ">
                  <div className="text-gray-600 font-semibold flex gap-3">
                    {" "}
                    <RadioGroupItem
                      disabled
                      value=""
                      className="w-5 h-5 rounded-md"
                    />
                    Property
                  </div>
                  <div className="text-gray-600 flex items-center gap-1 font-semibold">
                    Address
                  </div>
                </div>

                {details?.Listing.map(property => (
                  <div
                    key={property.checkInId}
                    className="flex items-center justify-between px-6 py-1 h-16 border-b"
                  >
                    <div className="flex items-center gap-3">
                      <RadioGroupItem
                        disabled={property.checkInStatus !== "Pending"}
                        value={property.checkInId}
                        id={property.checkInId}
                        className="w-5 h-5"
                        onClick={() => setSelectedCheckIn(property.checkInId)}
                      />
                      {/* <img
                        src={"https://placehold.co/400"}
                        alt={property.studentResidence}
                        className="w-12 h-12 rounded-full object-cover"
                      /> */}
                      <Home className="w-8 h-6 rounded-full text-gray-400" />
                      <label
                        htmlFor={property.checkInId}
                        className="text-gray-700 font-normal flex-wrap"
                      >
                        {property.studentResidence}
                      </label>
                    </div>
                    <button
                      disabled={property.checkInStatus !== "Pending"}
                      className={` ${property.checkInStatus !== "Pending" ? "text-gray-500" : "text-orange-500 "} flex items-center font-semibold min-w-[90px]`}
                    >
                      <MapPinCheckInside
                        className={`${property.checkInStatus !== "Pending" ? "text-gray-500" : "text-orange-500 "}`}
                      />{" "}
                      Check-in
                    </button>
                  </div>
                ))}
              </div>
            </RadioGroup>
          </CardContent>
        </Card>

        {/* Get Started Button */}
        <button
          disabled={selectedCheckIn === null}
          onClick={() =>
            navigate(`${URLS.STUDENT.CHECK_IN_LISTING}/${selectedCheckIn}`)
          }
          className={`w-full mb-3 ${selectedCheckIn === null ? "bg-gray-500" : "bg-orange-500"} text-white py-3 px-6 rounded-lg font-semibold mt-6 flex items-center justify-center gap-2`}
        >
          Get Started
          <ArrowRight className="w-5 h-5" />
        </button>
      </main>
    </LegacyPageLayout>
  );
};
export default PropertyCheckInListing;
