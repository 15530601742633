/* eslint-disable no-unused-vars */
import { API_KEY } from "@/ui/v2/constants";
import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://nsfas-dev.sandbox.api.ezra360.com/api/v1.0/entities",
  //   baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: headers => {
    headers.set("Content-Type", "application/json");
    headers.set("api-key", API_KEY);
    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["phaseById"],
  endpoints: builder => ({}),
});
