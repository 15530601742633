import { css } from "@emotion/css";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "@mui/material/TextareaAutosize";
import React from "react";

const TextArea = React.forwardRef((props: TextareaAutosizeProps, ref: any) => {
  return (
    <TextareaAutosize
      ref={ref}
      minRows={8}
      {...props}
      className={css({
        width: "100%",
        borderColor: "#a9acaf",
        borderRadius: 4,
      })}
      style={{ resize: "none", border: "1px solid #D0D5DD" }}
    />
  );
});

TextArea.displayName = "TextArea";

export default TextArea;
