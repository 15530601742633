import { takeLatest, all, takeEvery } from "redux-saga/effects"; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from "../../_config";

import * as userSaga from "./userSaga";
import * as commonSaga from "./commonSaga";
import * as residenceSaga from "./residenceSaga";
import * as caseSaga from "./caseSaga";

export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeEvery(SAGA_ACTIONS.USER.REGISTER_ENTITY, userSaga.post),
    takeEvery(SAGA_ACTIONS.USER.REGISTER_INDIVIDUAL, userSaga.post),
    takeEvery(SAGA_ACTIONS.USER.REGISTER_STUDENT, userSaga.post),
    //
    takeEvery(SAGA_ACTIONS.USER.STUDENT_SECURITY_CHECK, userSaga.post),

    takeEvery(SAGA_ACTIONS.USER.ACCEPT_TERMS, userSaga.acceptTerms),
    //
    takeEvery(SAGA_ACTIONS.USER.ACCEPT_TERMS, userSaga.acceptTerms),
    takeEvery(SAGA_ACTIONS.USER.GENERATE_OTP, userSaga.post),
    takeEvery(SAGA_ACTIONS.USER.CONFIRM_OTP, userSaga.post),

    takeEvery(SAGA_ACTIONS.COMMON.POST, commonSaga.post),
    takeEvery(SAGA_ACTIONS.COMMON.GET, commonSaga.get),
    takeEvery(SAGA_ACTIONS.COMMON.GET_INSTITUTIONS, commonSaga.getInstitutions),
    takeEvery(SAGA_ACTIONS.COMMON.GET_CAMPUS, commonSaga.getCumpas),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_CAMPUS_INSTITUTION,
      commonSaga.getCampusInstitution
    ),

    //
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_NOTIFICATIONS,
      commonSaga.getNotifications
    ),

    takeEvery(SAGA_ACTIONS.COMMON.GET_CAPACITIES, commonSaga.getCapacities),
    takeEvery(SAGA_ACTIONS.COMMON.RETREIVE, commonSaga.retreive),
    takeEvery(
      SAGA_ACTIONS.COMMON.RETREIVE_INSTITUTION,
      commonSaga.retreiveInstitution
    ),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ROOMS, commonSaga.getRooms),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_STUDENT_CASES_CLASSIFICATION,
      commonSaga.getStudentCasesClassification
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.RETREIVE_CASE_DETAILS,
      commonSaga.retreiveCaseDetails
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.RETREIVE_STUDENT_CASE_REGARDINGID,
      commonSaga.retrieveStudentCaseRegardingId
    ),
    takeEvery(SAGA_ACTIONS.COMMON.GET_IMPORT_TYPE, commonSaga.getImportType),

    takeEvery(
      SAGA_ACTIONS.RESIDENCE.GET_RESIDENCE_ROOMS,
      residenceSaga.getResidenceRooms
    ),
    takeEvery(
      SAGA_ACTIONS.RESIDENCE.GET_RESIDENCIES,
      residenceSaga.getResidencies
    ),
    takeEvery(
      SAGA_ACTIONS.RESIDENCE.GET_RESIDENCE_AMENITIES,
      residenceSaga.getResidenceAmenities
    ),
    takeEvery(
      SAGA_ACTIONS.RESIDENCE.GET_RESIDENCE_ADDITIONAL_INFO,
      residenceSaga.getResidenceAdditionalInfo
    ),
    takeEvery(
      SAGA_ACTIONS.RESIDENCE.GET_CASE_QUERY_TYPES,
      residenceSaga.getCaseQueryTypes
    ),
    takeEvery(
      SAGA_ACTIONS.RESIDENCE.GET_PAYMENT_VALUE,
      residenceSaga.getPaymentValue
    ),

    takeEvery(SAGA_ACTIONS.CASES.DETAILS, caseSaga.getCaseDetails),
    // takeEvery(SAGA_ACTIONS.COMMON.GET_FACILITY_PRICING, caseSaga.getFacilityPricing),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_FACILITY_PRICING,
      residenceSaga.getFacilityPricing
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_FACILITY_APPLICATION_DETAILS,
      commonSaga.getFacilityApplicationDetails
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_FACILITY_APPLICATIONS,
      commonSaga.getFacilityApplications
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_APPLICATION_ROOM_TYPES,
      commonSaga.getApplicationRoomTypes
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_AP_OFFER_LETTERS,
      commonSaga.getApOfferLetters
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_INSTITUTION_STUDENTS,
      commonSaga.getInstitutionStudents
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_STUDENT_DETAILS,
      commonSaga.getStudentDetails
    ),
    takeEvery(SAGA_ACTIONS.COMMON.GET_CAMPUS_STATS, commonSaga.getCampusStats),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_INSTITUTION_TOTALS,
      commonSaga.getInstituionTotals
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_CAMPUS_DETAILS,
      commonSaga.getCampusDetails
    ),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_CAMPUS_PROPERTIES,
      commonSaga.getCampusProperties
    ),
    takeEvery(SAGA_ACTIONS.COMMON.GET_AP_USERS, commonSaga.getAPUsers),
    takeEvery(
      SAGA_ACTIONS.COMMON.GET_INDIVIDUAL_AP_USERS,
      commonSaga.getIndividualAPUsers
    ),
    takeEvery(SAGA_ACTIONS.COMMON.GET_ROOM_TYPES, commonSaga.getRoomTypes),
    takeEvery(
      SAGA_ACTIONS.COMMON.EXISTING_STUDENT_CHECK,
      commonSaga.getExistingStudentCheck
    ),
  ]);
}
