import { Outlet, Navigate } from "react-router-dom";
import {
  useAuthStatus,
  useChangePasswordHook,
} from "../_common/hooks/authHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
const OpenRoutes = () => {
  const isAuth = useAuthStatus();
  const user: any = useUserSelector();
  const changePassword = useChangePasswordHook();

  return (
    // isAuth?changePassword?<Navigate to="/security-check" />:<Navigate to={user.supplierId?"/":'/student/'} />:<Outlet/>
    isAuth ? (
      changePassword ? (
        <Navigate
          to={
            user.rerelatedObjectIdObjectTypeCode === "Supplier"
              ? "/reset-password"
              : user.relatedObjectIdObjectTypeCode === "Employee"
                ? "/security-check"
                : "/reset-password"
          }
        />
      ) : (
        <Navigate to={user.supplierId ? "/" : "/student/"} />
      )
    ) : (
      <Outlet />
    )
  );
};

export default OpenRoutes;
