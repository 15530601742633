import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { Col, Row } from "react-bootstrap";
import TenantsRoomModal from "../components/tenantsRoomModal";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate, useParams } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import Breadcrumbs from "./student/components/breadcrumbs";

const TenantsDetails = () => {
  const navigate = useNavigate();
  const commonApi = useCommonApi();
  const { id } = useParams();
  const { showLoader, hideLoader } = useAppLoader();

  const [show, setShow] = useState<boolean>(false);
  const [tenantDetails, setTenantDetails] = useState<any>(null);
  const [levels, setLevels] = useState<any>(null);
  const [facilityId, setFacilityId] = useState<string>("");
  const [genders, setGenders] = useState<any>(null);

  const handleClose = () => {
    setShow(false);
  };

  const getTenants = () => {
    showLoader();
    commonApi.post(
      {
        recordId: id,
        requestName: "RetrieveTenantDetailsReq",
      },
      (message: string, resp: any) => {
        hideLoader();
        // console.log({ resp })
        if (resp.outputParameters) {
          setTenantDetails(resp.outputParameters);
        }
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getLevelList = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "YearOfStudy",
          },
        },
      },
      (message: string, resp: any) => {
        // console.log({ resp })
        if (resp.outputParameters.Count > 0) {
          setLevels(resp.outputParameters.data);
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  const getGender = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "Gender",
          },
        },
      },
      (message: string, resp: any) => {
        if (resp.outputParameters.Count > 0) {
          setGenders(resp.outputParameters.data);
        }
      },
      (message: string) => {
        // console.log(message);
      }
    );
  };

  useEffect(() => {
    if (id && !show) {
      // console.log("called")
      getTenants();
      setFacilityId(id);
    }
  }, [id, show]);

  useEffect(() => {
    getLevelList();
    getGender();
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        {/* <div onClick={() => setShow(true)}>TenantsDetails</div> */}
        <div className="tenats-details">
          <div className="container">
            <Breadcrumbs
              data={[
                { label: "Home", path: "/" },
                { label: "Student Place", path: "/dashboard" },
                { label: "Tenants" },
              ]}
            />
            <div className="">
              <div className="tenats-header">
                <h2>{tenantDetails?.StudentDetails?.name}</h2>
                <ul className="tenentdetails-link">
                  <li>
                    <a href="#">
                      <i className="fa fa-phone"></i> call
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-comments"></i> chat
                    </a>
                  </li>
                </ul>
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                      </svg>
                    </span>
                    Student details
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={6}>
                        <div className="tenetDeatail-Table">
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>Full name</td>
                                  <td className="text-right">
                                    <h4>
                                      {tenantDetails?.StudentDetails?.name}
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Student no.</td>
                                  <td className="text-right">
                                    <h4>
                                      {
                                        tenantDetails?.StudentDetails
                                          ?.studentNumber
                                      }
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>ID number</td>
                                  <td className="text-right">
                                    <h4>
                                      {tenantDetails?.StudentDetails?.idNumber}
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Nationality</td>
                                  <td className="text-right">
                                    <h4>
                                      {
                                        tenantDetails?.StudentDetails
                                          ?.nationality
                                      }
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Gender</td>
                                  <td className="text-right">
                                    <h4>
                                      {genders && genders.length > 0
                                        ? genders.map((item: any) =>
                                            item.optionValue ==
                                            tenantDetails?.StudentDetails
                                              ?.genderId
                                              ? item.optionText
                                              : ""
                                          )
                                        : null}
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Level</td>
                                  <td className="text-right">
                                    <h4>
                                      {levels && levels.length > 0
                                        ? levels.map((item: any) =>
                                            item.optionValue ==
                                            tenantDetails?.StudentDetails?.level
                                              ? item.optionText
                                              : ""
                                          )
                                        : null}
                                    </h4>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="tenetDeatail-Table">
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>Acceptance date</td>
                                  <td className="text-right">
                                    <h4>
                                      {
                                        tenantDetails?.StudentDetails
                                          ?.acceptanceDate
                                      }
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Education status</td>
                                  <td className="text-right">
                                    <h4>
                                      {
                                        tenantDetails?.StudentDetails
                                          ?.educationStatus
                                      }
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Institution</td>
                                  <td className="text-right">
                                    <h4>
                                      {
                                        tenantDetails?.StudentDetails
                                          ?.companyIdName
                                      }
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Campus</td>
                                  <td className="text-right">
                                    <h4>
                                      {
                                        tenantDetails?.StudentDetails
                                          ?.campusIdName
                                      }
                                    </h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Educational term</td>
                                  <td className="text-right">
                                    <h4>
                                      {
                                        tenantDetails?.StudentDetails
                                          ?.educationalTerm
                                      }
                                    </h4>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                      </svg>
                    </span>
                    Rental information
                  </Accordion.Header>
                  <Accordion.Body>
                    {tenantDetails && tenantDetails["Rental information"] ? (
                      <Row>
                        <Col md={6}>
                          <div className="tenetDeatail-Table">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>Status</td>
                                    <td className="text-right">
                                      <h4 className="font-12">Active</h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Residence</td>
                                    <td className="text-right">
                                      <h4>
                                        {
                                          tenantDetails["Rental information"][
                                            "facilityIdName"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Room</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        {
                                          tenantDetails["Rental information"][
                                            "roomIdName"
                                          ]
                                        }{" "}
                                        <span
                                          onClick={() => setShow(true)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          edit
                                        </span>
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Bed</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        {
                                          tenantDetails["Rental information"][
                                            "bed"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Room type</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        {
                                          tenantDetails["Rental information"][
                                            "roomType"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Monthly rental</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        R
                                        {
                                          tenantDetails["Rental information"][
                                            "monthlyRental"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="tenetDeatail-Table">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <td>Duration (months)</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        {
                                          tenantDetails["Rental information"][
                                            "duration"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Educations status</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        {
                                          tenantDetails["Rental information"][
                                            "educationStatus"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Institution</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        {
                                          tenantDetails["Rental information"][
                                            "companyIdName"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Campus</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        {
                                          tenantDetails["Rental information"][
                                            "campusIdName"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Educational term</td>
                                    <td className="text-right">
                                      <h4 className="font-12">
                                        {
                                          tenantDetails["Rental information"][
                                            "educationalTerm"
                                          ]
                                        }
                                      </h4>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : null}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path>
                      </svg>
                    </span>
                    Bursary information
                  </Accordion.Header>
                  <Accordion.Body>
                    {tenantDetails && tenantDetails["Bursary Information"] ? (
                      <div className="tenetDeatail-Table">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <td>Bursary provider</td>
                                <td className="text-right">
                                  <h4 className="font-12">
                                    {
                                      tenantDetails["Bursary Information"][
                                        "bursaryProvider"
                                      ]
                                    }
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td>Sponsor amount</td>
                                <td className="text-right">
                                  <h4 className="font-12">
                                    R
                                    {
                                      tenantDetails["Bursary Information"][
                                        "sponsoredAmount"
                                      ]
                                    }
                                  </h4>
                                </td>
                              </tr>
                              <tr>
                                <td>Status</td>
                                <td className="text-right">
                                  <h4 className="font-12">
                                    {
                                      tenantDetails["Bursary Information"][
                                        "status"
                                      ]
                                    }
                                  </h4>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <TenantsRoomModal
        shouldShow={show}
        handleClose={handleClose}
        facilityId={facilityId}
      />
    </React.Fragment>
  );
};

export default TenantsDetails;
