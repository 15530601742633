import React, { useEffect, useState } from "react";
import { TBulkListingFilters } from "../../../types/TBulkListingFilters";
import { IApiResponse } from "../../../types/IApiResponse";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { API } from "../../../services/API";
import { EXECUTE_REQUEST_PATH } from "../../../constants";
import { useAppLoader } from "../../../../../_common/hooks/common/appLoaderHook";

function useFetchListingFilters({
  pageName,
  pageRoute,
}: {
  pageRoute: string;
  pageName: string;
}) {
  const currentUser = useCurrentUser();
  const [filtersResponse, setFiltersResponse] = useState<TBulkListingFilters>();

  const { showLoader, hideLoader } = useAppLoader();
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        showLoader();
        const response: IApiResponse<TBulkListingFilters> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: "PortalListing",
            requestName: "GetListingActions",
            inputParamters: {
              UserId: currentUser?.supplierId || currentUser?.recordId,
              UserType: currentUser?.relatedObjectIdObjectTypeCode,
              PageRoute: pageRoute,
              PageName: pageName,
            },
          }
        );
        setFiltersResponse(response.outputParameters);
      } catch (e) {
        console.error(e);
      } finally {
        hideLoader();
      }
    };
    fetchFilters();
  }, []);
  return filtersResponse;
}

export default useFetchListingFilters;
