import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { ThemeColors } from "../../styles/theme";

const CircleLoader = ({ mode = "dark" }: { mode?: "light" | "dark" }) => {
  return (
    <CircularProgress
      size={24}
      sx={{
        color: mode === "light" ? ThemeColors.white : ThemeColors.dircoYellow,
      }}
    />
  );
};

export default CircleLoader;
