import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import { URLS } from "../_config";
import { MdAddCircle } from "react-icons/md";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";

const InstitutionUploadStatus = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <div onClick={() => navigate(URLS.INSTITUTION_UPLOAD_LIST)}>
          InstitutionUploadStatus
        </div>
        <div className="container dashboard-view">
          <div className="white-block p-3 white-box-wide">
            <Row>
              <Col>
                <h5>Upload Residences</h5>
              </Col>
              <Col className="text-end">
                <span>
                  <Button className="icon-btn">
                    <MdAddCircle />
                  </Button>
                </span>
              </Col>
            </Row>
            <hr></hr>
            <Row className="pt-4">
              <Col className="col-sm-4">
                <div className="student-import-block">
                  <div className="student-import-color-bar orange"></div>
                  <div className="student-import-icon orange">
                    <IoMdCheckmarkCircle></IoMdCheckmarkCircle>
                  </div>
                  <div className="student-import-content">
                    <h5 className="mb-3">Student import</h5>
                    <p className="mb-3">TVET students in Nkangala</p>
                    <p className="mb-0">Date: 20/09/2022</p>
                  </div>
                </div>
              </Col>
              <Col className="col-sm-4">
                <div className="student-import-block">
                  <div className="student-import-color-bar red"></div>
                  <div className="student-import-icon red">
                    <AiFillCloseCircle></AiFillCloseCircle>
                  </div>
                  <div className="student-import-content">
                    <h5 className="mb-3">Student import</h5>
                    <p className="mb-3">UNI students in University of JHB</p>
                    <p className="mb-0">Date: 23/08/2022</p>
                  </div>
                </div>
              </Col>
              <Col className="col-sm-4">
                <div className="student-import-block">
                  <div className="student-import-color-bar orange"></div>
                  <div className="student-import-icon orange">
                    <IoMdCheckmarkCircle></IoMdCheckmarkCircle>
                  </div>
                  <div className="student-import-content">
                    <h5 className="mb-3">Student import</h5>
                    <p className="mb-3">TVET students in EEC Kwa Thema</p>
                    <p className="mb-0">Date: 23/08/2022</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InstitutionUploadStatus;
