import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistPartial } from "redux-persist/es/persistReducer";
import rootReducer from "./reducers";
import { sagas } from "./sagas";

const middlewares = [];

const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

const middleware = applyMiddleware(...middlewares);

// Enable Redux DevTools in development environment only
const isDevelopment = process.env.REACT_APP_STAGE === "development";
const composeEnhancers =
  (isDevelopment && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["form", "user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore<any & PersistPartial, any, any, any>(
  persistedReducer,
  composeEnhancers(middleware)
);
const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };
