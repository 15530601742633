import {
  Box,
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../components/header";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "./student/components/breadcrumbs";

interface UserDetails {
  APUserId: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Mobile: string;
}

const UserList = () => {
  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();
  const user: any = useUserSelector();
  const [userDetails, setUserDetails] = useState<UserDetails[]>([]);
  const navigate: any = useNavigate();

  const getUsers = () => {
    // showLoader();
    commonApi.getAPUsers(
      {
        InstitutionId: user.institutionId,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setUserDetails(resp);
        }
        hideLoader();
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  useEffect(() => {
    getUsers();
    // getCampusDetails();
  }, []);

  return (
    <div>
      <Header />

      <Box sx={{ padding: "2rem" }}>
        <Box sx={{ marginTop: "4rem" }}>
          <Breadcrumbs
            data={[{ label: "Home", path: "/" }, { label: "User List" }]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            marginTop: "7rem",
            marginBottom: "1rem",
          }}
        >
          <Typography
            sx={{
              // marginTop: "7rem",
              color: "#101828",
              fontFamily: "Inter",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "38px",
              marginBottom: "20px",
            }}
          >
            List Of Users
          </Typography>
          <Button
            // onClick={()=>{navigate('/add-campus')}}
            href="/create-user"
            sx={{
              border: "1px solid grey",
              cursor: "pointer",
              height: "10px",
            }}
          >
            Add New
          </Button>
        </Box>
        <Paper sx={{ width: "100%", overflow: "auto" }}>
          <TableContainer sx={{}}>
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#F9FAFB",
                }}
              >
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Contact No.</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userDetails.map((details: UserDetails) => {
                  return (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        textAlign: "center",
                      }}
                    >
                      <TableCell className="table-data">
                        {details.FirstName}
                      </TableCell>
                      <TableCell className="table-data" align="left">
                        {details.LastName}
                      </TableCell>
                      <TableCell className="table-data" align="left">
                        {details.Mobile}
                      </TableCell>
                      <TableCell className="table-data" align="left">
                        {details.Email}
                      </TableCell>
                      <TableCell className="table-data" align="right">
                        <Button
                          onClick={() => {
                            navigate(`/user-details/${details.APUserId}`);
                          }}
                          sx={{
                            border: "1px solid #D0D5DD",
                            cursor: "pointer",
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};

export default UserList;
