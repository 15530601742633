import { useCallback, useEffect, useState } from "react";
import DashboardPageLayout from "../../../../components/DashboardPageLayout";
import ContentPage from "../../../../components/DashboardPageLayout/ContentPage";
import { BreadcrumbData } from "../../../../components/Breadcrumbs/types";
import { URLS } from "../../../../../../_config";
import Grid from "@mui/material/Grid";
import ResponsiveGridSpacer from "../../../../components/ResponsiveGridSpacer";
import TextField from "../../../../components/TextField";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  EXECUTE_REQUEST_PATH,
  PHONE_NUMBER_REGEX,
} from "../../../../constants";
import { IApiResponse } from "../../../../types/IApiResponse";
import { API } from "../../../../services/API";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { ThemeColors } from "../../../../styles/theme";
import useFormFieldErrorProps from "../../../../hooks/useFormFieldErrorProps";
import CircleLoader from "../../../../components/CircleLoader";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../components/Notify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

type TInviteStudentForm = {
  idNumber: string;
  mobile: string;
};

const BREADCRUMB_DATA: BreadcrumbData = [
  {
    label: "Home",
    path: URLS.HOME,
  },
  {
    label: "Student Search",
    path: URLS.AP.STUDENT_SEARCH,
  },
];

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        py: 8,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress size={48} />
      <Typography sx={{ pt: 2 }}>
        Please wait while we fetch your data.
      </Typography>
    </Box>
  );
};

function InviteStudent() {
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { isValid, errors, isSubmitting },
  } = useForm<TInviteStudentForm>({
    mode: "all",
    defaultValues: {
      idNumber: "",
      mobile: "",
    },
  });

  const { getFieldErrorProps } =
    useFormFieldErrorProps<TInviteStudentForm>(errors);
  const currentUser = useCurrentUser();

  const onSubmit = async (data: TInviteStudentForm) => {
    try {
      const response: IApiResponse<unknown> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Employee",
          requestName: "InviteStudents",
          inputParamters: {
            accomodationProviderId: currentUser?.supplierId,
            idNumber: data.idNumber,
            mobile: data.mobile,
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }
      showSuccessAlert(response.clientMessage);
      navigate("/ap/students/search");
    } catch (e) {
      showErrorAlert("An error occurred while inviting the student");
      console.error(e);
    }
  };

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { id: studentId } = useParams();
  const studentNumber = searchParams.get("numbers");

  useEffect(() => {
    if (studentNumber && studentId) {
      setValue("idNumber", studentId);
      setValue("mobile", studentNumber);
    }
  }, [studentNumber, studentId]);

  return (
    <DashboardPageLayout background="primary">
      <ContentPage
        title="Student Search"
        subtitle="Find a student by ID number"
        padding="lg"
        breadcrumbData={BREADCRUMB_DATA}
      >
        <Grid container>
          <ResponsiveGridSpacer width={1} />
          <Grid item xs={12} md={10}>
            <Paper component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                <ResponsiveGridSpacer width={4} />
                <Grid item md={4} mb={6}>
                  <Typography
                    sx={{
                      pt: 2,
                      fontWeight: 600,
                      fontSize: 30,
                      my: 1,
                      textAlign: "center",
                    }}
                  >
                    Invite Student to Apply
                  </Typography>
                  <Typography
                    sx={{
                      pt: 2,
                      fontSize: 14,
                      color: ThemeColors.textTertiary,
                      textAlign: "center",
                    }}
                    component="div"
                  >
                    This student does not have a lease history, you can proceed
                    to invite them to apply to your property below
                  </Typography>
                  <Box display="flex" flexDirection="column" rowGap={2} my={2}>
                    <TextField
                      label="SA ID Number"
                      size="small"
                      {...getFieldErrorProps("idNumber")}
                      {...register("idNumber", {})}
                      disabled
                    />
                    <TextField
                      label="Cellphone"
                      size="small"
                      {...getFieldErrorProps("mobile")}
                      {...register("mobile", {
                        // required: true,
                        // pattern: PHONE_NUMBER_REGEX,
                      })}
                      disabled
                    />
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    color="primary"
                    endIcon={isSubmitting ? <CircleLoader /> : undefined}
                  >
                    Invite to apply
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </ContentPage>
    </DashboardPageLayout>
  );
}

export default InviteStudent;
