import { SAGA_ACTIONS } from "../../../../_config";
import { useApiCall } from "../../common/appApiCallHook";
import {
  ContentReq,
  DetailsReq,
  BlankReq,
  BannerReq,
} from "../../../interfaces/ApiReqRes";

export function useCommonApi() {
  const callApi = useApiCall();
  const post = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.POST, data, onSuccess, onError);
  };

  const get = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GET, data, onSuccess, onError);
  };
  const getInstitutions = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_INSTITUTIONS, data, onSuccess, onError);
  };

  const getCampus = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_CAMPUS, data, onSuccess, onError);
  };
  const getCapacities = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_CAPACITIES, data, onSuccess, onError);
  };

  const retreive = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.RETREIVE, data, onSuccess, onError);
  };

  const retreiveInstitution = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.RETREIVE_INSTITUTION, data, onSuccess, onError);
  };

  const getRooms = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GET_ROOMS, data, onSuccess, onError);
  };

  const getStudentCasesClassification = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_STUDENT_CASES_CLASSIFICATION,
      data,
      onSuccess,
      onError
    );
  };

  const retreiveCaseDetails = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.RETREIVE_CASE_DETAILS,
      data,
      onSuccess,
      onError
    );
  };

  const retrieveStudentCaseRegardingId = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.RETREIVE_STUDENT_CASE_REGARDINGID,
      data,
      onSuccess,
      onError
    );
  };

  const getImportType = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_IMPORT_TYPE, data, onSuccess, onError);
  };

  //
  const getCampusInstitution = (
    data: BlankReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_CAMPUS_INSTITUTION,
      data,
      onSuccess,
      onError
    );
  };
  const getNotifications = (
    externalLogonId: string,
    onSuccess?: Function,
    onError?: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_NOTIFICATIONS,
      {
        recordId: externalLogonId,
        entityName: "ExternalLogOn",
        requestName: "GetAlertNotificationsReq",
      },
      onSuccess,
      onError
    );
  };
  // const getNotifications= (externalLogonId: string, onSuccess?: Function, onError?: Function) => {
  //     callApi(SAGA_getDoc, {recordId: externalLogonId, "entityName": "ExternalLogOn", "requestName": "GetAlertNotificationsReq"}, onSuccess, onError);
  // }
  // const getFacilityApplicationDetails= (externalLogonId: string, onSuccess?: Function, onError?: Function) => {
  //     callApi(SAGA_ACTIONS.COMMON.GET_FACILITY_APPLICATION_DETAILS, {recordId: externalLogonId, "entityName": "ExternalLogOn", "requestName": "GetAlertNotificationsReq"}, onSuccess, onError);
  // }
  const getFacilityApplicationDetails = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_FACILITY_APPLICATION_DETAILS,
      data,
      onSuccess,
      onError
    );
  };
  const getFacilityApplication = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_FACILITY_APPLICATIONS,
      data,
      onSuccess,
      onError
    );
  };
  const getApplicationRoomTypes = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_APPLICATION_ROOM_TYPES,
      data,
      onSuccess,
      onError
    );
  };
  const getApplicationDates = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_APPLICATION_DATES,
      data,
      onSuccess,
      onError
    );
  };
  const getApOfferLetter = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_AP_OFFER_LETTERS, data, onSuccess, onError);
  };
  const getInstitutionStudents = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_INSTITUTION_STUDENTS,
      data,
      onSuccess,
      onError
    );
  };
  const getStudentDetails = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_STUDENT_DETAILS, data, onSuccess, onError);
  };
  const getCampusStats = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_CAMPUS_STATS, data, onSuccess, onError);
  };
  const getInstituionTotals = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_INSTITUTION_TOTALS,
      data,
      onSuccess,
      onError
    );
  };
  const getCampusDetails = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_CAMPUS_DETAILS, data, onSuccess, onError);
  };
  const getCampusProperties = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_CAMPUS_PROPERTIES,
      data,
      onSuccess,
      onError
    );
  };
  const getAPUsers = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GET_AP_USERS, data, onSuccess, onError);
  };
  const getIndividualAPUser = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.GET_INDIVIDUAL_AP_USERS,
      data,
      onSuccess,
      onError
    );
  };
  const getRoomTypes = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GET_ROOM_TYPES, data, onSuccess, onError);
  };
  const getExistingStudentCheck = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(
      SAGA_ACTIONS.COMMON.EXISTING_STUDENT_CHECK,
      data,
      onSuccess,
      onError
    );
  };

  return {
    post: post,
    get: get,
    getInstitutions: getInstitutions,
    getCampus: getCampus,
    getCapacities: getCapacities,
    retreive: retreive,
    retreiveInstitution: retreiveInstitution,
    getRooms: getRooms,
    getStudentCasesClassification: getStudentCasesClassification,
    retreiveCaseDetails: retreiveCaseDetails,
    retrieveStudentCaseRegardingId: retrieveStudentCaseRegardingId,
    getImportType: getImportType,
    //
    getCampusInstitution,
    getNotifications,
    getFacilityApplicationDetails: getFacilityApplicationDetails,
    getFacilityApplication: getFacilityApplication,
    getApplicationRoomTypes: getApplicationRoomTypes,
    getApplicationDates: getApplicationDates,
    getApOfferLetter: getApOfferLetter,
    getInstitutionStudents: getInstitutionStudents,
    getStudentDetails: getStudentDetails,
    getCampusStats: getCampusStats,
    getInstituionTotals: getInstituionTotals,
    getCampusDetails: getCampusDetails,
    getCampusProperties: getCampusProperties,
    getAPUsers: getAPUsers,
    getIndividualAPUser: getIndividualAPUser,
    getRoomTypes: getRoomTypes,
    getExistingStudentCheck: getExistingStudentCheck,
  };
}
