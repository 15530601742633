import { Box, SxProps, Tooltip, Typography } from "@mui/material";
import React from "react";
import { ThemeColors } from "../../styles/theme";

type Variant = "error" | "success";
const baseStyles: SxProps = {
  borderWidth: "1px",
  borderStyle: "solid",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 11,
  height: 22,
  maxWidth: 140,
  px: 1,
};

const styles: Record<Variant, SxProps> = {
  error: {
    ...baseStyles,
    borderColor: ThemeColors.utilityErrorBorder,
    backgroundColor: ThemeColors.utilityErrorBackground,
    color: ThemeColors.utilityErrorText,
  },
  success: {
    ...baseStyles,
    borderColor: ThemeColors.utilitySuccessBorder,
    backgroundColor: ThemeColors.utilitySuccessBackground,
    color: ThemeColors.utilitySuccessText,
  },
};

function StatusPill({
  variant,
  children,
}: {
  children: string;
  variant: Variant;
}) {
  if (!children) {
    return null;
  }
  return (
    <Tooltip arrow title={children}>
      <Box sx={styles[variant]}>
        <Typography
          noWrap
          sx={{
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {children}
        </Typography>
      </Box>
    </Tooltip>
  );
}

export default StatusPill;
