import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";

export function useCasesApi() {
  const callApi = useApiCall();

  const getCaseDetails = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.CASES.DETAILS, data, onSuccess, onError);
  };
  const getFacilityPricing = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.COMMON.GET_FACILITY_PRICING, data, onSuccess, onError);
  };

  return {
    getCaseDetails: getCaseDetails,
    getFacilityPricing: getFacilityPricing,
  };
}
