import {
  Cancel,
  Close,
  CloseRounded,
  Delete,
  MoreVert,
  Star,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  colors,
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

/**@deprecated Avoid using. This only a stop gap for legacy pages */
function LegacyImageThumbnail({
  src,
  onDelete,
  onSetCover,
  isCover,
}: {
  src: string;
  onDelete: () => void;
  onSetCover: () => void;
  isCover: boolean;
}) {
  return (
    <Card
      elevation={1}
      sx={{
        width: 100,
        height: 100,
        background: `url("${src}")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100px 100px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          "& .MuiButton-contained": {
            visibility: "hidden",
          },
          "&:hover .MuiButton-contained": {
            visibility: "visible",
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Box>
            <Typography
              sx={{
                background: colors.deepPurple[800],
                fontSize: 10,
                fontWeight: 700,
                color: "#fff",
                pr: 1,
              }}
            >
              {isCover ? (
                <>
                  <Star color="inherit" sx={{ fontSize: 9 }} /> Cover
                </>
              ) : (
                " "
              )}
            </Typography>
          </Box>
          <IconButton
            color="error"
            sx={{ position: "absolute", top: -4, right: -4 }}
            size="small"
            onClick={onDelete}
          >
            <Cancel />
          </IconButton>
        </Box>
        <Box pb={1} px={1}>
          <Button
            size="small"
            fullWidth
            disabled={isCover}
            variant="contained"
            sx={{ fontSize: 9, minHeight: 0, height: 24, p: 0 }}
            onClick={onSetCover}
          >
            <Star sx={{ fontSize: 9 }} /> Set as Cover
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

export default LegacyImageThumbnail;
