import React, { useEffect, useState } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Header from "../components/header";
import OpenCaseCloseModal from "../components/openCaseCloseModal";
import { useCasesApi } from "../_common/hooks/api/casesApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import parse from "html-react-parser";
import Breadcrumbs from "./student/components/breadcrumbs";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";

const OpenCaseDetails = () => {
  const { id } = useParams();
  const casesApi = useCasesApi();
  const { showLoader, hideLoader } = useAppLoader();
  const user: any = useUserSelector();

  const [show, setShow] = useState<boolean>(false);
  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [images, setImages] = useState<any>(null);

  const detailsImages: any = sessionStorage.getItem("detailsImage");
  const type: any = sessionStorage.getItem("caseType");

  const handleClose = () => {
    setShow(false);
  };

  const getCaseDetails = () => {
    showLoader();
    casesApi.getCaseDetails(
      {
        CaseId: id,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setCaseDetails(resp[0]);
        }
        hideLoader();
      },
      (message: string) => {
        hideLoader();
      }
    );
  };

  const checkImageBase64Code = (url: any) => {
    if (url.search("data:image/") == 0) {
      return url;
    } else {
      return "data:image/jpeg;base64," + url;
    }
  };

  useEffect(() => {
    if (id && images) {
      getCaseDetails();
    }
  }, [id, images]);

  useEffect(() => {
    if (detailsImages) {
      setImages(JSON.parse(detailsImages));
    }
  }, [detailsImages]);

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        {/* <div >OpenCaseDetails</div> */}
        <div className="casedetails">
          <div className="container">
            {type == "Supplier" ? (
              <Breadcrumbs
                data={[
                  { label: "Home", path: "/" },
                  {
                    label: "Cases",
                    path: `/open-cases/${user?.supplierId}/Supplier`,
                  },
                  { label: "Case Details" },
                ]}
              />
            ) : (
              <Breadcrumbs
                data={[
                  { label: "Home", path: "/" },
                  { label: "Residences", path: "/dashboard" },
                  {
                    label: "Residence Details",
                    path: `/residence-details/${caseDetails?.FacilityId}`,
                  },
                  {
                    label: "Cases",
                    path: `/open-cases/${caseDetails?.FacilityId}/Facility`,
                  },
                  { label: "Case Details" },
                ]}
              />
            )}
            <div className="casedetail-bg">
              <h2>Case details</h2>
              <Row>
                <Col md={5}>
                  <div className="casedetails-image">
                    {images && images.length > 0 ? (
                      <Carousel>
                        {images.map((item: any, i: number) => (
                          <Carousel.Item key={i}>
                            <img
                              src={checkImageBase64Code(item?.fileContent)}
                              alt="img"
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    ) : (
                      <img src="/assets/img/no-image-available.jpg" alt="img" />
                    )}
                  </div>
                </Col>
                <Col md={7}>
                  <div className="casedetails-content">
                    <h4>Student details</h4>
                    <h6>Logged by</h6>
                    <h5>{caseDetails?.LoggedBy}</h5>
                    <h6>Student No</h6>
                    <h5>{caseDetails?.StudentNumber}</h5>
                    <h6>ID Number</h6>
                    <h5>{caseDetails?.IdNumber}</h5>
                    <h6>Room</h6>
                    <h5>{caseDetails?.Room}</h5>
                    <h6>Description</h6>
                    {caseDetails?.Description != null ? (
                      parse(caseDetails?.Description)
                    ) : (
                      <h5></h5>
                    )}
                    <h6>Level</h6>
                    <h5>{caseDetails?.Level}</h5>
                    <h6>Educational term</h6>
                    <h5>{caseDetails?.EducationalTerm}</h5>
                    <button
                      className="full-width-yellow-btn"
                      onClick={() => setShow(true)}
                    >
                      Close case
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <OpenCaseCloseModal
        shouldShow={show}
        handleClose={handleClose}
        id={id}
        path={
          type == "Supplier"
            ? `/open-cases/${user?.supplierId}/Supplier`
            : `/open-cases/${caseDetails?.FacilityId}/Facility`
        }
      />
    </React.Fragment>
  );
};

export default OpenCaseDetails;
