import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "./components/header";
import Breadcrumbs from "./components/breadcrumbs";
import FindAccomodation from "./components/findAccomodation";
import AccomodationCard from "./components/accomdationCard";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useUserSelector } from "../../_common/hooks/selectors/userSelector";
import { Button, Grid, Pagination, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import emptyImage from "../../images/empty-2.jpg";
import { DEFAULT_PAGE_SIZE } from "../../constants";
import { useSearchParams } from "react-router-dom";
import { Close } from "@mui/icons-material";

export default function SearchResidence() {
  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();
  const [residencies, setResidencies] = useState<any>(null);
  const [searchKey, setSearchKey] = useState<string>("");
  const user: any = useUserSelector();
  const [isEmpty, setIsEmpty] = useState<boolean>();

  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : 1;

  const [searchedText, setSearchedText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(
    initialPage > 0 ? initialPage : 1
  );
  const [totalPages, setTotalPages] = useState<number>(10);

  const getResidencies = (_currentPage: number) => {
    showLoader();
    setIsEmpty(false);
    commonApi.post(
      {
        recordId: user.recordId,
        requestName: "RetrieveStudentsResidencies",
        InputParamters: {
          PageSize: DEFAULT_PAGE_SIZE,
          PageNumber: _currentPage,
          SearchText: searchedText,
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        setResidencies(resp.outputParameters.Facilities);
        const totalPages = parseInt(resp.outputParameters.Pages, 10);
        setTotalPages(totalPages);
        if (resp.outputParameters.Facilities.length === 0) {
          // toast("Empty")
          setIsEmpty(true);
        }
      },
      (message: string) => {
        // toast.error(message);
        hideLoader();
        setIsEmpty(false);
      }
    );
  };

  const handleSearch = (txt: string) => {
    setCurrentPage(1);
    setSearchParams({ page: "1" });
    setSearchedText(txt);
  };

  useEffect(() => {
    getResidencies(currentPage);
  }, [currentPage, searchedText]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <React.Fragment>
      <div className="dashboard" style={{ paddingTop: "0px" }}>
        <Header />
        <div className="container">
          <FindAccomodation
            key={searchedText || 1}
            handleSearch={handleSearch}
          />
          {searchedText && (
            <Box sx={{ px: 3 }}>
              <Typography>
                Showing search results for <strong>{searchedText}</strong>.{" "}
                <Button
                  size="small"
                  sx={{ py: "0 !important" }}
                  color="error"
                  startIcon={<Close />}
                  onClick={() => setSearchedText("")}
                >
                  Close Search
                </Button>
              </Typography>
            </Box>
          )}
          <div className="residence-filter" style={{ marginTop: "1rem" }}>
            {/* <div className='my-4 ' >Residence</div> */}
          </div>
          {isEmpty === true ? (
            <div className="is-empty">
              <img width={"500px"} src={emptyImage} alt="" />
            </div>
          ) : (
            <Row style={{ width: "100%" }}>
              {residencies?.map((residence: any, i: any) => {
                if (searchKey !== "") {
                  if (
                    residence.name
                      .toLocaleLowerCase()
                      .search(searchKey.toLocaleLowerCase()) >= 0
                  ) {
                    return (
                      <Col md={6} key={i}>
                        <AccomodationCard
                          data={residence}
                          route={"accomodation-details"}
                        />
                      </Col>
                    );
                  }
                } else {
                  return (
                    <Col sm={12} md={6} lg={4} key={i}>
                      <AccomodationCard
                        data={residence}
                        route={"accomodation-details"}
                      />
                    </Col>
                    // <Grid container spacing={2} sx={{display:'flex',flexDirection:'row'}}>
                    //     <Grid item xs={6}>
                    //          <AccomodationCard data={residence} route={"accomodation-details"} />
                    //     </Grid>
                    // </Grid>
                  );
                }
              })}
            </Row>
          )}
          <Row>
            <Box pb={6} display="flex" justifyContent="center">
              <Pagination
                count={totalPages}
                page={currentPage}
                showFirstButton
                showLastButton
                shape="rounded"
                variant="outlined"
                onChange={(e, newPage) => {
                  setCurrentPage(newPage);
                  setSearchParams({ page: `${newPage}` });
                }}
              />{" "}
            </Box>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
