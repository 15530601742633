import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { ReactNode } from "react";
import PersonIcon from "../../../icons/PersonIcon";

type Props = {
  caseContent: ReactNode;
  date?: string;
  name?: string;
};
function CasePost({ caseContent, date, name }: Props) {
  return (
    <Box>
      <Grid
        container
        sx={{
          minHeight: 76,
          background: "#E2E8F0",
          border: "1px solid #94A3B8",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        p={2}
      >
        <Grid xs={12} md={7}>
          <Box display="inline-flex" alignItems="center" columnGap={2}>
            <Box>
              <PersonIcon fontSize="large" />
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography fontSize={18} fontWeight={500}>
                {name || " -- "}
              </Typography>
              {/* <Typography fontSize={14}>Staff</Typography> */}
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={5}>
          <Typography color="#64748B" textAlign="right" fontSize={14}>
            {date}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          border: "1px solid #94A3B8",
          borderTopWidth: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 6,
          borderBottomRightRadius: 6,
        }}
      >
        <Grid xs={12} md={8} p={2}>
          {caseContent}
        </Grid>
      </Grid>
    </Box>
  );
}

export default CasePost;
