import React from "react";
import { FieldErrors } from "react-hook-form";

type ErrorProps<T> = FieldErrors<{} & T>;

const ERROR_MESSAGES_MAP: Record<string, string> = {
  required: "This field is required",
  pattern: "Invalid format",
  maxLength: "Maximum length exceeded",
  minLength: "Minimum length not met",
  min: "Minimum value not met",
  max: "Maximum value exceeded",
  validate: "Invalid value",
};

function useFormFieldErrorProps<T>(errors: ErrorProps<T>) {
  const getFieldErrorProps = <K extends keyof T>(name: K) => {
    const error = (errors as any)[name];
    if (error) {
      return {
        error: true,
        helperText: error?.message || ERROR_MESSAGES_MAP[error.type] || "",
      };
    }
    return {};
  };
  return { getFieldErrorProps };
}

export default useFormFieldErrorProps;
