import React, { useEffect } from "react";
import {
  Home,
  FolderClosed,
  User,
  ChevronRight,
  ChevronDown,
  X,
} from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import WizardCheckListModal from "@/ui/v2/pages/StudentCheckInDetailsPage/WizardCheckListModal";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import { IApiResponse } from "@/ui/v2/types/IApiResponse";
import { API } from "@/ui/v2/services/API";
import { EXECUTE_REQUEST_PATH } from "@/ui/v2/constants";

const RoomInformationComponent = ({ onNextStep }) => {
  const { id: checkInId } = useParams<{ id: string }>();
  const roomTypes = ["Single", "Double", "Suite", "Studio"];
  const floorNumbers = Array.from({ length: 20 }, (_, i) => i + 1);
  const [options, setOptions] = React.useState<
    { label: string; value: string }[]
  >([]);
  const navigate = useNavigate();
  // const { register, handleSubmit } = useFormContext();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useFormContext();

  const showStatusModal = () => {
    NiceModal.show(WizardCheckListModal, {
      onConfirm: () => {
        onNextStep();
      },
      onCancel: () => {
        console.log("cancel");
      },
    });
  };

  useEffect(() => {
    (async () => {
      try {
        // setIsLoading(true);
        const response: IApiResponse<{
          RoomTypes: { capacityId: string; name: string }[];
        }> = await API.post(EXECUTE_REQUEST_PATH, {
          entityName: "CheckIn",
          requestName: "GetCheckInRoomTypes",
          recordId: checkInId,
        });

        if (response.isSuccess) {
          setOptions(
            response.outputParameters.RoomTypes.map(roomType => ({
              label: roomType.name,
              value: roomType.capacityId,
            }))
          );
        } else {
          setOptions([]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        // setIsLoading(false);
      }
    })();
  }, []);

  const onSubmit = handleSubmit(data => {
    console.log(data);
    showStatusModal();
  });

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Main Content */}
      <main className="py-5 px-3 max-w-lg mx-auto border border-gray-500 rounded-md">
        <h2 className="text-gray-500 text-xl mb-8">ROOM INFORMATION</h2>

        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          {/* Room Name/Number */}
          <div className="space-y-2">
            <label className="block text-gray-600">Room name/ number</label>
            <Input
              type="text"
              placeholder="eg: 001"
              className={`w-full border rounded-lg p-6 ${
                errors.roomName ? "border-red-500" : ""
              }`}
              {...register("roomName", { required: "This field is required" })}
            />
            {errors.roomName && (
              <p className="text-red-500 mt-2">{errors?.roomName?.message}</p>
            )}
          </div>

          {/* Room Type */}
          <div className="space-y-2">
            <label className="block text-gray-600">Room type</label>
            <Select
              {...register("roomType", { required: "This field is required" })}
              onValueChange={value => setValue("roomType", value)} // Update form value when selection changes
            >
              <SelectTrigger
                className={`w-full p-6 ${errors.roomType ? "border-red-500" : ""}`}
              >
                <SelectValue placeholder="Select room type" />
              </SelectTrigger>
              <SelectContent>
                {options.map((type, index) => (
                  <SelectItem key={index} value={type.value}>
                    {type.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            {errors.roomType && (
              <p className="text-red-500 mt-2">{errors?.roomType?.message}</p>
            )}
          </div>

          {/* Room Size */}
          <div className="space-y-2">
            <label className="block text-gray-600">Number of beds</label>
            <Input
              type="text"
              placeholder="eg: 12"
              className={`w-full border rounded-lg p-6 ${
                errors.NumberOfBeds ? "border-red-500" : ""
              }`}
              {...register("NumberOfBeds", {
                required: "This field is required",
              })}
            />
            {errors.roomName && (
              <p className="text-red-500 mt-2">
                {errors?.NumberOfBeds?.message}
              </p>
            )}
          </div>

          {/* Floor Number */}
          {/* <div className="space-y-2">
            <label className="block text-gray-600">Floor number</label>
            <Select>
              <SelectTrigger className="w-full p-6">
                <SelectValue placeholder="10" />
              </SelectTrigger>
              <SelectContent>
                {floorNumbers.map(number => (
                  <SelectItem key={number} value={number.toString()}>
                    {number}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div> */}

          {/* Get Started Button */}
          <button className="w-full py-3 px-6 bg-orange-500 hover:bg-orange-600 text-white rounded-lg mt-8 flex items-center justify-center gap-2">
            Lets go
            <ChevronRight className="w-5 h-5" />
          </button>

          {/* Cancel Button */}
        </form>
        <button
          onClick={() => navigate("/student/checkin/")}
          className="w-full mt-4 py-4 flex items-center justify-center gap-2 text-gray-600"
        >
          <X className="w-5 h-5" />
          Cancel
        </button>
      </main>
    </div>
  );
};

export default RoomInformationComponent;
