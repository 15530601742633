import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Grid from "@mui/material/Grid";
import LoadingPage from "../LoadingPage";
import { IApiResponse } from "../../types/IApiResponse";
import { API } from "../../services/API";

import { DASHBOARD_LAYOUT_MODE, EXECUTE_REQUEST_PATH } from "../../constants";
import Alert from "../Alert";
import { UTILS } from "../../../../utils";
import { useNavigate, useNavigation } from "react-router-dom";
import useCurrentUser from "../../hooks/useCurrentUser";
import LegacyPageLayout from "../../../../components/LegacyPageLayout";
import Header from "../../../../pages/student/components/header";
import { ThemeColors } from "../../styles/theme";

type DashboardPageLayoutProps = {
  bannerComponent?: React.ReactNode;
  sidebarComponent?: React.ReactNode;
  children: React.ReactNode;
  sidebarSize?: 1 | 2 | 3 | 4;
  showLoader?: boolean;
  background?: "default" | "primary";
};

type ResponseType = {
  Alerts: {
    message: string;
    actionLink: string;
  }[];
};

function DashboardPageLayout({
  children,
  sidebarComponent,
  bannerComponent,
  sidebarSize = 2,
  showLoader,
  background = "default",
}: DashboardPageLayoutProps) {
  const hasSidebar = !!sidebarComponent;
  const _sidebarSize = hasSidebar ? sidebarSize : 0;
  const [alerts, setAlerts] = useState<ResponseType["Alerts"]>([]);

  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const alertsComponent = alerts?.map(alert => {
    return (
      <>
        <Alert
          key={UTILS.getId()}
          severity="error"
          message={<>{alert.message}</>}
          actionButton={{
            label: "Learn more",
            onClick: () => {
              navigate(`/${alert.actionLink}`);
            },
          }}
        />
        <Box sx={{ height: 4 }} />
      </>
    );
  });

  const backgroundProps =
    background === "primary"
      ? { background: ThemeColors.bgPrimary, minHeight: "100vh" }
      : {};

  return (
    <Box sx={backgroundProps}>
      {DASHBOARD_LAYOUT_MODE === "legacy" ? <Header hideAlerts /> : <Navbar />}
      <Box sx={{ width: "100%", pt: "1px" }}>
        {bannerComponent || alertsComponent}
        <Grid container>
          {showLoader ? (
            <LoadingPage />
          ) : (
            <>
              {hasSidebar && (
                <Grid item md={_sidebarSize}>
                  {sidebarComponent}
                </Grid>
              )}
              <Grid item md={12 - _sidebarSize}>
                {children}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default DashboardPageLayout;
