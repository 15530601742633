import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./student/components/header";
import {
  useNavigationSelector,
  useUserSelector,
} from "../_common/hooks/selectors/userSelector";
import { NAVIGATIONS, URLS } from "../_config";
import { useUserApi } from "../_common/hooks/api/UserApiHook";

const MainDashboard = () => {
  const user: any = useUserSelector();
  const navigation: any = useNavigationSelector();
  const navigate = useNavigate();
  const userApi = useUserApi();

  const [navigations, setNavigations] = useState<any>(null);
  const logout = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    userApi.logout(
      {},
      (message: string, resp: any) => {},
      (message: string) => {}
    );
  };

  useEffect(() => {
    if (navigation == undefined) {
      let storedNavigation = sessionStorage.getItem(NAVIGATIONS) || "[]";
      storedNavigation = JSON.parse(storedNavigation);
      setNavigations(storedNavigation);
    } else {
      setNavigations(navigation);
    }
  }, [navigation]);

  useEffect(() => {
    if (user.isExpired === true) {
      logout();
    }
    if (
      user.relatedObjectIdObjectTypeCode == "Employee" &&
      user?.userUpdate == true
    ) {
      navigate(URLS.STUDENT.MY_PROFILE);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <div className="dashboard-wrapper">
          <div className="dashboard-wrapper-inner">
            <h3>Welcome</h3>
            <h2 style={{ width: "100%" }}>{user?.name}</h2>
            <div className="dashboard-block-wrapper">
              {navigations && navigations.length > 0
                ? navigations.map((items: any, index: number) => (
                    <div
                      className="dashboard-block"
                      onClick={() => navigate(items?.navigate)}
                      key={index}
                    >
                      <div className="dash-notification-icon">
                        {items?.notification != 0 &&
                        items?.notification != null ? (
                          <span>{items?.notification}</span>
                        ) : null}
                        <i className={items?.icon} aria-hidden="true"></i>
                      </div>
                      <h4>{items?.title}</h4>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <a
            href="https://wa.me/0647468669"
            className="float"
            target="_blank"
            style={{ width: "" }}
            rel="noreferrer"
          >
            <i className="fa fa-whatsapp my-float"></i>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainDashboard;
