import {
  Box,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import React from "react";
import { SharedStyles, ThemeColors } from "../../styles/theme";

type Props = TextFieldProps & {
  userSx?: any;
  startText?: string;
  endButton?: React.ReactNode;
  helperText?: string;
  color?: "primary" | "secondary";
};

const styles = ({ fullWidth, userSx }: Props) => {
  const userStyles = userSx ?? {};
  return {
    ...userStyles,
    display: "inline-flex",
    flexDirection: "column",
    width: fullWidth ? "100%" : null,
  };
};

const EMPTY_OBJECT = {};

const TextField = React.forwardRef(
  (
    {
      disabled,
      label,
      fullWidth = true,
      startText,
      endButton,
      helperText,
      color = "secondary",
      ...rest
    }: Props,
    ref: any
  ) => {
    const startTextProps = startText
      ? {
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                {startText}&nbsp;<span style={{ color: "#D0D5DD" }}> |</span>
              </InputAdornment>
            ),
          },
        }
      : EMPTY_OBJECT;

    const endButtonProps = endButton
      ? { InputProps: { ...startTextProps, endAdornment: endButton } }
      : startTextProps;
    const borderStyles =
      color === "primary" && !rest.error
        ? SharedStyles.primaryBorderStyles
        : EMPTY_OBJECT;
    return (
      <Box sx={styles({ fullWidth, userSx: rest.sx })}>
        {label && <label>{label}</label>}
        <MuiTextField
          helperText={helperText}
          ref={ref}
          {...rest}
          {...endButtonProps}
          disabled={disabled}
          sx={{
            backgroundColor: disabled
              ? ThemeColors.disabled
              : ThemeColors.white,
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            ...borderStyles,
          }}
        />
      </Box>
    );
  }
);

TextField.displayName = "TextField";

export default TextField;
