import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { BellIcon, Menu } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  useAlertsSelector,
  useNavigationSelector,
  useUserSelector,
} from "@/_common/hooks/selectors/userSelector";
import { ACTIONS, NAVIGATIONS, URLS } from "@/_config";
import { useDispatch } from "react-redux";
import { useUserApi } from "@/_common/hooks/api/UserApiHook";
import { useCommonApi } from "@/_common/hooks/api/common/commonApiHook";
import { IApiResponse } from "@/ui/v2/types/IApiResponse";
import toast from "react-hot-toast";
import { EXECUTE_REQUEST_PATH } from "@/ui/v2/constants";

import { DropdownMenuCheckboxItemProps } from "@radix-ui/react-dropdown-menu";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface NavItem {
  label: string;
  path: string;
}

interface NavbarProps {
  notificationCount?: number;
  userInitials?: string;
}

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const navigation: any = useNavigationSelector();
  const navigate = useNavigate();
  const [navigations, setNavigations] = useState<any>(null);
  const [forcedActions, setForcedActions] = useState<any>([]);

  const [showStatusBar, setShowStatusBar] = React.useState<Checked>(true);
  const [showActivityBar, setShowActivityBar] = React.useState<Checked>(false);
  const [showPanel, setShowPanel] = React.useState<Checked>(false);

  console.log("forcedActions", forcedActions);
  console.log("navigations", navigations);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (navigation == undefined) {
      let storedNavigation = sessionStorage.getItem(NAVIGATIONS) || "[]";
      storedNavigation = JSON.parse(storedNavigation);
      setNavigations(storedNavigation);
    } else {
      setNavigations(navigation);
    }
  }, [navigation]);

  const dispatch = useDispatch();
  const userApi = useUserApi();
  const commonApi = useCommonApi();
  const alerts = useAlertsSelector();
  const user: any = useUserSelector();

  const [showTermsModal, setShowTermsModal] = useState(false);

  const [userInitials, setUserInitials] = useState<any>();
  const logout = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    userApi.logout(
      {},
      (message: string, resp: any) => {},
      (message: string) => {}
    );
  };

  const updateProfile = () => {
    if (user.relatedObjectIdObjectTypeCode == "Employee") {
      navigate(`${URLS.STUDENT.BASE}/edit-profile`);
    }
    if (user.relatedObjectIdObjectTypeCode == "Supplier") {
      navigate(`${URLS.AP.BASE}/edit-profile`);
    }
  };

  const userTermsAccepted = user?.tsAndCsAccepted ?? null;

  const getForcedActions = async () => {
    try {
      const responseFrocedAction: IApiResponse<any> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "ExternalLogon",
          requestName: "RetrieveForcedActions",
          inputParamters: {
            UserId: user?.supplierId || user?.recordId,
            UserType: user.relatedObjectIdObjectTypeCode,
          },
        }
      );

      setForcedActions(responseFrocedAction.outputParameters.forcedActions);
    } catch (e) {
      console.error(e);
    }
  };

  const getNotifications = () => {
    if (!user.externalLogonId) {
      return;
    }
    commonApi.getNotifications(user.externalLogonId);
  };

  useEffect(() => {
    if (userTermsAccepted === false) {
      setShowTermsModal(true);
    }
  }, [userTermsAccepted]);

  useEffect(() => {
    if (!user) {
      return;
    }
    getForcedActions();

    getNotifications();

    const initials = user?.name;
    if (initials.split(" ").length > 1) {
      const firstInitials = initials.split(" ")[0];
      const secondInitials = initials.split(" ")[1];
      setUserInitials(firstInitials[0] + secondInitials[0]);
    } else {
      setUserInitials(initials[0]);
    }
  }, []);

  const acceptTerms = () => {
    if (!user.externalLogonId) {
      toast.error("An error occurred");
      return;
    }
    commonApi.post(
      {
        entityName: "ExternalLogOn",
        recordId: user.externalLogonId,
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: {
            TsAndCsAccepted: "true",
          },
        },
      },
      (message: string, resp: any) => {
        userApi.acceptTerms();
      },
      (message: string) => {
        toast.error(message);
      }
    );

    setShowTermsModal(false);
  };

  const unreadAlerts = alerts?.filter(alert => alert.read !== true);

  const notificationCount = unreadAlerts?.length || 0;

  // dispatch({
  //   type: ACTIONS.USER.READ_NOTIFICATION,
  //   payload: actionLink,
  // });
  // navigate(`/${actionLink}`);

  return (
    <>
      {/* Desktop Navigation */}
      <nav className="hidden md:block bg-[#D86018] text-white">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="flex-shrink-0">
              <img
                src="/assets/img/logo-white.png"
                alt="NSFAS"
                className="h-8"
              />
            </Link>
            <div className="flex items-center space-x-8">
              {navigations?.map(item => (
                <Link
                  key={item.navigate}
                  to={item.navigate}
                  className="text-white hover:text-orange-100 text-sm font-medium"
                >
                  {item.title}
                </Link>
              ))}
            </div>
            <div className="flex items-center space-x-4">
              <div className="relative">
                <BellIcon className="h-5 w-5 text-white" />
                {notificationCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-white text-orange-500 rounded-full text-xs px-1">
                    ({notificationCount})
                  </span>
                )}
              </div>
              <div className="flex items-center">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    {/* <Button variant="outline">Open</Button> */}
                    <div className="h-8 w-8 cursor-pointer rounded-full bg-gray-300 flex items-center justify-center text-gray-700">
                      {userInitials}
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56">
                    <DropdownMenuCheckboxItem
                      onClick={updateProfile}
                      onCheckedChange={setShowStatusBar}
                    >
                      Profile
                    </DropdownMenuCheckboxItem>
                    <DropdownMenuCheckboxItem
                      onClick={logout}
                      onCheckedChange={setShowActivityBar}
                    >
                      Log out
                    </DropdownMenuCheckboxItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navigation */}
      <nav className="md:hidden bg-[#D86018] text-white">
        <div className="flex items-center justify-between px-4 h-16">
          <Sheet open={isOpen} onOpenChange={setIsOpen}>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="text-white">
                <Menu className="h-6 w-6" />
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="w-[300px] p-0">
              <div className="flex flex-col">
                <div className="p-4 border-b">
                  <img src="/assets/img/logo.png" alt="NSFAS" className="h-8" />
                </div>
                <div className="flex flex-col py-2">
                  {navigations?.map(item => (
                    <Link
                      key={item.navigate}
                      to={item.navigate}
                      className="px-4 py-3 text-gray-900 hover:bg-gray-100"
                      onClick={() => setIsOpen(false)}
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>
              </div>
            </SheetContent>
          </Sheet>

          <div className="flex items-center space-x-4">
            <div className="relative">
              <BellIcon className="h-5 w-5 text-white" />
              {notificationCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-white text-orange-500 rounded-full text-xs px-1">
                  ({notificationCount})
                </span>
              )}
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                {/* <Button variant="outline">Open</Button> */}
                <div className="h-8 w-8 cursor-pointer rounded-full bg-gray-300 flex items-center justify-center text-gray-700">
                  {userInitials}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                <DropdownMenuCheckboxItem
                  onClick={updateProfile}
                  onCheckedChange={setShowStatusBar}
                >
                  Profile
                </DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem
                  onClick={logout}
                  onCheckedChange={setShowActivityBar}
                >
                  Log out
                </DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </nav>
    </>
  );
}
