import { Checkbox, CheckboxProps } from "@mui/material";
import React from "react";
import UncheckedIcon from "./UncheckedIcon";
import CheckedIcon from "./CheckedIcon";
import IndeterminateIcon from "./IndeterminateIcon";

const TableCheckbox = React.forwardRef((props: CheckboxProps, ref: any) => {
  return (
    <Checkbox
      ref={ref}
      {...props}
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      indeterminateIcon={<IndeterminateIcon />}
    />
  );
});

export default TableCheckbox;
