import {
  Box,
  Button,
  IconButton,
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableContainer,
  TableProps,
  Typography,
} from "@mui/material";
import React from "react";
import { ThemeColors } from "../../../styles/theme";
import { ArrowBack, ArrowForward, MoreVert } from "@mui/icons-material";

const NextButton = () => (
  <>
    Next <ArrowForward />{" "}
  </>
);

const PrevButton = () => (
  <>
    <ArrowBack /> Previous{" "}
  </>
);

function ListingTable({
  title,
  totalPages,
  currentPage,
  onPageChange,
  actionButtons = [],
  ...restOfProps
}: TableProps & {
  title: string;
  totalPages: number;
  currentPage: number;
  actionButtons?: {
    label: string;
    onClick: () => void;
  }[];
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
}) {
  return (
    <Paper
      sx={{ width: "100%", overflow: "hidden", p: 2, mb: 3, borderRadius: 2 }}
    >
      <Box display="flex">
        <Box flex={1}>
          {title && (
            <Typography
              color={ThemeColors.textPrimary}
              fontSize={18}
              fontWeight={600}
              gutterBottom
            >
              {title}
            </Typography>
          )}
        </Box>
        <Box sx={{ pb: 1 }}>
          {actionButtons.map(button => (
            <Button
              key={button.label}
              variant="outlined"
              color="primary"
              onClick={button.onClick}
              sx={{ ml: 1 }}
              size="small"
            >
              {button.label}
            </Button>
          ))}
          <IconButton>
            <MoreVert />
          </IconButton>
        </Box>
      </Box>
      <TableContainer sx={{}}>
        <Table {...restOfProps} />
      </TableContainer>
      <Box py={3} display="flex" justifyContent="stretch">
        <Pagination
          count={totalPages}
          page={currentPage}
          size="large"
          shape="rounded"
          variant="outlined"
          sx={{
            width: "100%",
            "& ul": {
              width: "100%",
              display: "flex",
              justifyContent: "center",
            },
            "& li:first-of-type ": {
              flex: 1,
            },
            "  & li:last-of-type": {
              flex: 1,
              textAlign: "right",
            },
          }}
          onChange={onPageChange}
          renderItem={item => {
            return (
              <PaginationItem
                slots={{ previous: PrevButton, next: NextButton }}
                {...item}
                size="medium"
              />
            );
          }}
        />
      </Box>
    </Paper>
  );
}

export default ListingTable;
