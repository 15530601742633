import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Header from "../components/header";
import { Button } from "react-bootstrap";
import { DevTool } from "@hookform/devtools";
import { useParams } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import Breadcrumbs from "./student/components/breadcrumbs";

interface AP_Details {
  // APUserId: "960433af-9cd0-41e7-9a07-f676fa358588"
  Email: string;
  FirstName: string;
  LastName: string;
  Mobile: string;
}

const UserDetails = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const id = useParams();
  const commonApi = useCommonApi();
  const [userDetail, setUserDetail] = useState<AP_Details[]>([]);
  const { showLoader, hideLoader } = useAppLoader();

  const getUserDetails = () => {
    // showLoader();
    console.log("fred");
    commonApi.getAPUsers(
      {
        InstitutionId: "b97bc08f-fb93-458f-95cb-4d0a58f08523",
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setUserDetail(resp);
          console.log(resp);
        }
        hideLoader();
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };
  const getIndividaulAPUser = () => {
    // showLoader();
    console.log("Indi User");
    commonApi.getIndividualAPUser(
      {
        APUserId: id.id,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setUserDetail(resp);
          console.log(resp);
        }
        hideLoader();
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  useEffect(() => {
    // console.log("user-id",id);
    getUserDetails();
    getIndividaulAPUser();
  }, []);

  return (
    <div>
      <Box>
        <Header />
      </Box>
      <Box sx={{ padding: "2rem" }}>
        <Box sx={{ marginTop: "4rem", marginBottom: "2rem" }}>
          <Breadcrumbs
            data={[
              { label: "Home", path: "/" },
              { label: "User List", path: "/user-list" },
              { label: "User Details" },
            ]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "7rem",
          }}
        >
          <Typography
            sx={{
              color: "#101828",
              fontFamily: "Inter",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "38px",
              marginBottom: "20px",
            }}
          >
            User Details
          </Typography>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden", padding: "3rem" }}>
          {userDetail.map((details: AP_Details) => {
            return (
              <form action="">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                    flexDirection: { xs: "column", md: "row" },
                    width: { xs: "50%", md: "100%" },
                    marginLeft: { xs: "auto" },
                    marginRight: { xs: "auto" },
                  }}
                >
                  <div
                    className="mb-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput1a"
                      className={"form-label "}
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1a"
                      placeholder="E.g Barberton Tvet"
                      style={{ width: "60%" }}
                      {...register("firstName")}
                      // defaultValue={studentDetail.Name}
                      value={details.FirstName}
                      disabled={true}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput1a"
                      className={"form-label "}
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1a"
                      placeholder="E.g Barberton Tvet"
                      style={{ width: "60%" }}
                      {...register("lastName")}
                      // defaultValue={studentDetail.Name}
                      value={details.LastName}
                      disabled={true}
                    />
                  </div>
                </Box>
                {/*  */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                    flexDirection: { xs: "column", md: "row" },
                    width: { xs: "50%", md: "100%" },
                    marginLeft: { xs: "auto" },
                    marginRight: { xs: "auto" },
                  }}
                >
                  <div
                    className="mb-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput1a"
                      className={"form-label "}
                    >
                      Contact No.
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1a"
                      placeholder="E.g Barberton Tvet"
                      style={{ width: "60%" }}
                      {...register("contact")}
                      // defaultValue={studentDetail.Name}
                      value={details.Mobile}
                      disabled={true}
                    />
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      gap: "1rem",
                      marginTop: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      htmlFor="exampleFormControlInput1a"
                      className={"form-label "}
                      style={{ textAlign: "left" }}
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1a"
                      placeholder="E.g 12 Hellen Road"
                      style={{ width: "60%", textAlign: "left" }}
                      {...register("address")}
                      value={details.Email}
                      disabled={true}
                    />
                  </div>
                </Box>
                {/* ******************** */}

                <Box
                  sx={{
                    marginTop: "5rem",
                    display: "flex",
                    justifyContent: "end",
                    gap: "2rem",
                  }}
                >
                  {/* <Button
                        variant="primary"
                        style={{ width: "10%" }}
                        type="submit"
                      >
                        Create
                      </Button>
                      <Button variant="danger" style={{ width: "10%" }}>
                        Back
                      </Button> */}
                </Box>
              </form>
            );
          })}
        </Paper>

        <DevTool control={control} />
      </Box>
    </div>
  );
};

export default UserDetails;
