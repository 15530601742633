import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL, STORAGE } from "../../_config";
import { CallApi } from "./api/callApi";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* post(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_BASE_URL + "/ExecuteRequest",
      data
    );
    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.isSuccess
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* get(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_BASE_URL + "/ExecuteRequest",
      data
    );
    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.isSuccess &&
      resp.data.outputParameters
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getInstitutions(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_INSTITUTIONS, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getCumpas(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_CAMPUS, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getCapacities(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_CAPACITIES, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* retreive(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.RETREIVE, data);
    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.success
    ) {
      // console.log(resp)
      action &&
        action.callbackSuccess &&
        action.callbackSuccess(resp.data.results);
    } else {
      action &&
        action.callbackError &&
        action.callbackError(resp.data.errorMessage);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* retreiveInstitution(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.RETREIVE_INSTITUTION,
      data
    );
    // console.log(resp)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action &&
        action.callbackError &&
        action.callbackError(resp.data.errorMessage);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getRooms(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ROOMS, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getStudentCasesClassification(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_STUDENT_CASES_CLASSIFICATION,
      data
    );
    // console.log(resp)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action &&
        action.callbackError &&
        action.callbackError(resp.data.errorMessage);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* retreiveCaseDetails(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.RETREIVE_CASE_DETAILS,
      data
    );
    // console.log(resp)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action &&
        action.callbackError &&
        action.callbackError(resp.data.errorMessage);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* retrieveStudentCaseRegardingId(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.RETREIVE_STUDENT_CASE_REGARDINGID,
      data
    );
    // console.log(resp)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action &&
        action.callbackError &&
        action.callbackError(resp.data.errorMessage);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getImportType(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_IMPORT_TYPE, data);
    // console.log(resp)
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action &&
        action.callbackError &&
        action.callbackError(resp.data.errorMessage);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getCampusInstitution(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_CAMPUS_INSTITUTION,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

//
export function* getNotifications(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_BASE_URL + "/ExecuteRequest",
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      yield put({
        type: ACTIONS.USER.GET_NOTIFICATIONS,
        payload: {
          alerts: resp.data.outputParameters.Alerts,
        },
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    console.log({ e });
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// facility details application
export function* getFacilityApplicationDetails(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_FACILITY_APPLICATION_DETAILS,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
//  facility applications
export function* getFacilityApplications(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_FACILITY_APPLICATIONS,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// ROOM TYPES
export function* getApplicationRoomTypes(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_APPLICATION_ROOM_TYPES,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// OFFER LETTER
export function* getApOfferLetters(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_AP_OFFER_LETTERS,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// STUDENT LIST

export function* getInstitutionStudents(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_INSTITUTION_STUDENTS,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// STUDENT DETAILS

export function* getStudentDetails(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_STUDENT_DETAILS,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// CAMPUS STATS

export function* getCampusStats(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_CAMPUS_STATS, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// INSTITUTION TOTAL STATS

export function* getInstituionTotals(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_INSTITUTION_TOTALS,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// cAMPUS DETAILS
export function* getCampusDetails(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_CAMPUS_DETAILS,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// CAMPUS PROPERTIES
export function* getCampusProperties(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_CAMPUS_PROPERTIES,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// AP USERS
export function* getAPUsers(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_AP_USERS, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
// INDIVIUAL AP USERS
export function* getIndividualAPUsers(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_INDIVIDUAL_AP_USERS,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
// ROOM TYPES
export function* getRoomTypes(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_ROOM_TYPES, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

// EXISTING STUDENT
export function* getExistingStudentCheck(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.EXISTING_STUDENT_CHECK,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
