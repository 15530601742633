import Swal from "sweetalert2";

export const showErrorAlert = (message: any) => {
  return Swal.fire({
    icon: "error",
    confirmButtonColor: "#F88A0B",
    html: message.replace(/\n/g, "<br />"),
  });
};
export const showSuccessAlert = (message: any) => {
  return Swal.fire({
    icon: "success",
    html: message.replace(/\n/g, "<br />"),
  });
};

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const signedInAlert = () => {
  Toast.fire({
    icon: "success",
    title: "Signed in successfully",
  });
};
