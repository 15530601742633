import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

type GoogleAutocompleteProps = {
  onPlaceSelected: (place: google.maps.places.PlaceResult | null) => void;
};

const GoogleAutocomplete: React.FC<GoogleAutocompleteProps> = ({
  onPlaceSelected,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const apiKey = process.env.REACT_APP_GOOGLE_KEY;

  useEffect(() => {
    const loadScript = () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.onload = () => initAutocomplete();
        document.body.appendChild(script);
      } else {
        initAutocomplete();
      }
    };

    const initAutocomplete = () => {
      if (window.google) {
        const autocompleteService =
          new window.google.maps.places.AutocompleteService();

        if (inputValue) {
          setLoading(true);
          autocompleteService.getPlacePredictions(
            { input: inputValue },
            predictions => {
              setLoading(false);
              setOptions(predictions || []);
            }
          );
        } else {
          setOptions([]);
        }
      }
    };

    loadScript();
  }, [inputValue, apiKey]);

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
  };

  const handleOptionSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: google.maps.places.AutocompletePrediction | null
  ) => {
    if (value) {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      placesService.getDetails({ placeId: value.place_id }, placeDetails => {
        onPlaceSelected(placeDetails);
      });
    } else {
      onPlaceSelected(null);
    }
  };

  return (
    <Autocomplete
      freeSolo
      value={inputValue}
      onInputChange={handleInputChange}
      onChange={(event, value) =>
        handleOptionSelect(
          event,
          value as google.maps.places.AutocompletePrediction | null
        )
      }
      options={options}
      getOptionLabel={option =>
        typeof option === "string" ? option : option.description
      }
      renderInput={params => (
        <TextField
          {...params}
          label="Search Places"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default GoogleAutocomplete;
