import { DevTool } from "@hookform/devtools";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FaCheckCircle } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useCommonApi } from "../../../_common/hooks/api/common/commonApiHook";
import { useUserApi } from "../../../_common/hooks/api/UserApiHook";
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import { PASSWORD_VALIDATION_REGEX } from "../../../_config";
import { RequiredAsterisk } from "../../../components/blocks/requiredAsterisk";
import { useDocumentTypeModal } from "../../../hooks/useDocumentTypeModal";
import ScrollableContainer from "../../../components/global/scrollableContainer";

interface InstitutionType {
  InstitutionId: string;
  InstitutionIdName: string;
}

const REQUIRED_DOCS = [886, 950, 951];

interface SelectOption {
  value: number;
  label: string;
}

interface incomingProps {
  onSubmitStudentDetailsForm: any;
}

export default function StudentRegForm() {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    updatePasswordCheck(value);
  };

  const updatePasswordCheck = (value: string) => {
    const letterRegex = /[a-z]/;
    const upperCaseLetterRegex = /[A-Z]/;
    const digitRegex = /\d/;
    const specialCharRegex = /[^\w\s]/;
    const minLength = value.length >= 8;

    const containsLetter = letterRegex.test(value);
    const containsUpperCaseLetter = upperCaseLetterRegex.test(value);
    const containsDigit = digitRegex.test(value);
    const containsSpecialChar = specialCharRegex.test(value);

    const letterParagraph = document.getElementById("letter");
    const upperCaseLetterParagraph = document.getElementById("upperCaseLetter");
    const digitParagraph = document.getElementById("numDigit");
    const charParagraph = document.getElementById("character");
    const lengthParagraph = document.getElementById("length");

    if (letterParagraph) {
      letterParagraph.className = containsLetter
        ? "passwordChecked"
        : "passwordCheckLetter";
    }

    if (upperCaseLetterParagraph) {
      upperCaseLetterParagraph.className = containsUpperCaseLetter
        ? "passwordChecked"
        : "passwordCheckUpperCaseLetter";
    }

    if (digitParagraph) {
      digitParagraph.className = containsDigit
        ? "passwordChecked"
        : "passwordCheckDigit";
    }

    if (charParagraph) {
      charParagraph.className = containsSpecialChar
        ? "passwordChecked"
        : "passwordCheckChar";
    }

    if (lengthParagraph) {
      lengthParagraph.className = minLength
        ? "passwordChecked"
        : "passwordCheckLength";
    }
  };

  const userApi = useUserApi();
  const logout = () => {
    userApi.logout(
      {},
      (message: string, resp: any) => {},
      (message: string) => {}
    );
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
    reset,
  } = useForm();
  const institution = watch("institution");
  const campus = watch("campus");
  const province = watch("Province");

  const navigate = useNavigate();

  const commonApi = useCommonApi();

  const [institutions, setInstitutions] = useState<any>(null);
  const [campuses, setCampuses] = useState<any>(null);
  const [yearOfStudy, setYearOfStudy] = useState<any>(null);
  const [provinces, setProvinces] = useState<any>(null);
  const [files, setFiles] = useState<any>([]);
  const [setPrevCampus, set_setPrevCampus] = useState<any>(null);
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);
  const getAttachment = (code: number) =>
    files.find((file: Record<string, unknown>) => file.DocumentTypeId === code);
  const {
    modalComponent,
    setOnClickContinue,
    closeModal,
    showModal,
    setSelectedDocumentType,
  } = useDocumentTypeModal({ documentOptions, getAttachment });
  const requiredDocumentCount = 3;
  const documents: any = watch("Documents");
  const hasEveryRequiredDocument = REQUIRED_DOCS.every(documentCode =>
    getAttachment(documentCode)
  );
  const hasAnyRequiredDocument = () =>
    REQUIRED_DOCS.some(documentCode => getAttachment(documentCode));
  const { showLoader, hideLoader } = useAppLoader();

  const onSubmit = (data: any) => {
    // console.log("called", data );

    const documentsData: any[] = [];
    if (files.length > 0) {
      files.forEach((file: any) => {
        documentsData.push({
          FileName: file.FileName,
          FileExtention: file.FileExtention,
          DocumentTypeId: file.DocumentTypeId,
          FileContent: file.FileContent,
          IDTypeId: "862",
        });
      });
    }

    showLoader();

    commonApi.post(
      {
        entityName: "Employee",
        requestName: "RegisterUnverifiedStudent",
        //   "recordId": id,
        inputParamters: {
          UnverifiedStudent: {
            IdNumber: data.IdNumber,
            StudentNumber: data.StudentNumber,
            InstitutionId: data.institution.InstitutionId,
            CampusId: data.campus.value,
            Gender: data.Gender.value,
            DisabilityStatus: data.disability.value,
            Email: data.Email,
            FirstName: data.Name,
            LastName: data.Surname,
            // IdNumber:data.IdNumber,
            Password: data.Password,
            ConfirmPassword: data.ConfrimPassword,
            Mobile: data.MobileNumber,
            Documents: documentsData,
            TermTypeId: data.YearOfStudyId.optionValue,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();

        toast.success(message);

        setTimeout(() => {
          navigate("/successful-student-registration");
        }, 2000);

        // hideLoader()
      },
      (message: string) => {
        toast.error(message);
        hideLoader();
      }
    );
  };

  const genderOptions = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];
  const disabilityOptions = [
    { value: "636", label: "Yes" },
    { value: "637", label: "No" },
  ];

  const getInstitutions = () => {
    commonApi.retreiveInstitution(
      {},
      (message: string, resp: any) => {
        const options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search("000000") !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        });
        setInstitutions(options);
      },
      (message: string) => {}
    );
  };

  const getProvinces = () => {
    //Filter Fix
    const provincesToExclude = [
      "Limpopo",
      "Gauteng",
      "Free State",
      "Western Cape",
      "Northern Cape",
      "North West",
      "Eastern Cape ",
    ];

    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "Province",
          },
        },
      },
      (message: string, resp: any) => {
        if (resp.outputParameters.Count > 0) {
          // Filter provinces here to exclude 'Limpopo'
          const filteredProvinces = resp.outputParameters.data.filter(
            (data: any) => !provincesToExclude.includes(data.optionText)
          );

          setProvinces(
            filteredProvinces.map((data: any) => ({
              label: data.optionText,
              value: data.optionValue,
            }))
          );
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  const getCampus = (id: any = province?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCampus(
        {
          filters: "ProvinceId eq '" + id + "'",
          ViewName: "LookUpView",
        },
        (message: string, resp: any) => {
          const options: any = [];
          resp.map((ins: any) => {
            if (ins.recordId.search("000000") !== 0) {
              ins.value = ins.recordId;
              ins.label = ins.mainText;
              options.push(ins);
            }
          });
          setCampuses(options);
          setTimeout(() => {
            resolve();
          }, 300);
        },
        (message: string) => {
          reject();
        }
      );
    });
  };

  const getPrevData = () => {
    const residenceId = sessionStorage.getItem("residenceID");
    if (residenceId) {
      showLoader();
      // console.log("residenceID: " + residenceId);
      //call api reset form values via reset()
      commonApi.retreive(
        {
          Entity: "Facility",
          Top: 100,
          ReturnCols: [
            "FacilityId",
            "Name",
            "ProvinceId",
            "InstitutionId",
            "CampusId",
            "KMToCampus",
            "Address",
          ],
          FilterOn: {
            Type: "And",
            Where: [
              {
                filterCol: "FacilityId",
                FilterCondition: "Equals",
                FilterValue: residenceId,
              },
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          const prevData = JSON.parse(resp)[0];
          set_setPrevCampus(prevData.CampusId);
          reset({
            Name: prevData.Name,
            Province: getSelectDataById(prevData.ProvinceId, provinces),
            institution: getSelectDataById(
              prevData.InstitutionId,
              institutions
            ),
            // campus: getSelectDataById(prevData.CampusId, campuses),
            KMToCampus: prevData.KMToCampus,
            Address: prevData.Address,
          });
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    } else {
      console.log("Start A New Residence");
    }
  };

  const getSelectDataById = (id: any, data: any) => {
    let option = null;
    data?.map((_option: any) => {
      if (_option.value == id) {
        option = _option;
      }
    });
    return option;
  };

  useEffect(() => {
    if (campus) {
      console.log("Fired");

      if (!campus.recordId) {
        return;
      }
      commonApi.getCampusInstitution(
        { CampusId: campus.recordId },
        (message: string, resp: any) => {
          console.log({ resp });

          const _institutions = resp.map((institution: InstitutionType) => ({
            label: institution.InstitutionIdName,
            value: institution.InstitutionId,
          }));

          // setInstitutions(_institutions);

          setValue("institution", resp?.[0] || {});
        },
        (message: string) => {
          // console.log(message);
        }
      );
    }
  }, [campus]);

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    if (provinces && institutions) {
      getPrevData();
    }
  }, [provinces, institutions]);

  useEffect(() => {
    if (province) {
      setValue("institution", "");
      getCampus();
    }
  }, [province]);

  useEffect(() => {
    if (campuses) {
      if (setPrevCampus) {
        const opt: any = getSelectDataById(setPrevCampus, campuses);
        set_setPrevCampus(null);
        // console.log(opt)
        // console.log({...getValues(), ...{campus: opt}})
        reset({
          ...getValues(),
          campus: opt,
        });
      }
    }
  }, [campuses]);

  //  DOCS
  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "DocTypes",
          },
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
          return console.log(doc.optionText); // Returns array object with doc 'options'
        });
        setDocumentOptions(
          options.filter((option: SelectOption) =>
            REQUIRED_DOCS.includes(option.value)
          )
        );
        console.log(documentOptions);

        // const requiredDocumentOptions = options.filter((option: SelectOption) =>
        //     REQUIRED_DOCS.slice(0, requiredDocumentCount).includes(option.value)
        // );

        const requiredDocumentOptions = options.filter((option: SelectOption) =>
          REQUIRED_DOCS.slice(0, requiredDocumentCount).includes(option.value)
        );

        setDocumentOptions(requiredDocumentOptions);
      },
      (message: string) => {}
    );
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      resolve();
    });
  };

  useEffect(() => {
    const _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        const file: any = documents[i];
        checkIsSelected(file.name)
          .then(() => {
            if (file.size <= 8 * 1024 * 1024) {
              const fileType = file.type;

              setOnClickContinue(() => (docTypeCode: number) => {
                if (!docTypeCode) {
                  toast.error("Select document type");
                  return;
                }
                toBase64(file).then((b64: any) => {
                  _files.push({
                    FileName: file.name,
                    FileExtention: file.name.split(".").pop(),
                    DocumentTypeId: docTypeCode,
                    FileContent: b64.split(",")[1],
                  });
                  setFiles(_files);
                  setOnClickContinue(undefined);
                  closeModal();
                  setSelectedDocumentType(undefined);
                });
              });
              showModal();
            } else {
              toast.error("File must be 8mb or less");
            }
          })
          .catch(() => {});
      }
    } else {
      setFiles(_files);
    }
  }, [documents]);

  const isValidRSAID = (idNumber: any) => {
    if (idNumber.length !== 13) {
      return false;
    }

    for (let i = 0; i < idNumber.length; i++) {
      if (isNaN(idNumber)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    getDocTypes();
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [idNumber, setIdNumber] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setIdNumber(inputValue);
    console.log(idNumber);
    if (inputValue.length < 13) {
      setErrorMessage("Input must be at least 13 characters long.");
    } else {
      setErrorMessage("");
    }
  };

  const getYearOfStudy = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "TermTypes",
          },
        },
      },
      (message: string, resp: any) => {
        const data = resp.outputParameters.data;
        const options: any = [];
        data.map((ins: any) => {
          ins.value = ins.optionValue;
          ins.label = ins.optionText;
          options.push(ins);
        });
        setYearOfStudy(options);
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    getYearOfStudy();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ScrollableContainer>
          <div className="mb-2 ">
            <label
              htmlFor="exampleFormControlInput4"
              className={"form-label " + errors.Name ? "is-invalid" : ""}
            >
              Name
            </label>
            <RequiredAsterisk />
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput4"
              {...register("Name", { required: true })}
            />
            {errors?.Name ? (
              <div className="invalid-feedback">
                {errors.Name.type === "required" ? "Required" : null}
                {/* {errors.Surname.type === "pattern" ? 'Invalid email address' : null} */}
              </div>
            ) : null}
          </div>
          <div className="mb-2 ">
            <label
              htmlFor="exampleFormControlInput4"
              className={"form-label " + errors.Surname ? "is-invalid" : ""}
            >
              Surname
            </label>
            <RequiredAsterisk />
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput4"
              {...register("Surname", { required: true })}
            />
            {errors?.Surname ? (
              <div className="invalid-feedback">
                {errors.Surname.type === "required" ? "Required" : null}
                {/* {errors.Surname.type === "pattern" ? 'Invalid email address' : null} */}
              </div>
            ) : null}
          </div>
          <div className="mb-2 ">
            <label
              htmlFor="exampleFormControlInput4"
              className={"form-label " + errors.IdNumber ? "is-invalid" : ""}
            >
              ID Number
            </label>
            <RequiredAsterisk />
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput4"
              maxLength={13}
              minLength={13}
              {...register("IdNumber", { required: true, maxLength: 13 })}
            />
            <p style={{ color: "#CF1010", fontSize: "12px", margin: "0" }}>
              {errorMessage}
            </p>

            {errors?.IdNumber ? (
              <div className="invalid-feedback">
                {errors?.IdNumber.type === "required" ? "Required" : null}
                {errors.IdNumber.type === "pattern" ? "Invalid ID" : null}
              </div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput9"
              className={"form-label " + errors.Email ? "is-invalid" : ""}
            >
              Email
            </label>

            <input
              type="text"
              className="form-control security-input"
              id="exampleFormControlInput9"
              {...register("Email", {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors?.Email ? (
              <div className="invalid-feedback">
                {errors.Email.type === "required" ? "Required" : null}
                {errors.Email.type === "pattern"
                  ? "Invalid email address"
                  : null}
              </div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput9"
              className={
                "form-label " + errors.MobileNumber ? "is-invalid" : ""
              }
            >
              Mobile Number
            </label>
            <RequiredAsterisk />
            <input
              type="number"
              className="form-control security-input"
              id="exampleFormControlInput9"
              maxLength={10}
              {...register("MobileNumber", { required: true })}
            />
            {errors?.MobileNumber ? (
              <div className="invalid-feedback">
                {errors.MobileNumber.type === "required" ? "Required" : null}
                {errors.MobileNumber.type === "pattern"
                  ? "Mobile number"
                  : null}
              </div>
            ) : null}
          </div>
          <div className="mb-2 ">
            <label
              htmlFor="exampleFormControlInput4"
              className={
                "form-label " + errors.StudentNumber ? "is-invalid" : ""
              }
            >
              Student number
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput4"
              {...register("StudentNumber", { required: false, maxLength: 80 })}
            />
          </div>
          {/* Academic Term  ****************************************************/}
          <div className="mb-2">
            <label className="form-label">Academic Term</label>
            <RequiredAsterisk />

            <Controller
              name={"YearOfStudyId"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <Select
                    className={
                      "form-control p-0" +
                      (errors?.YearOfStudyId ? " is-invalid" : "")
                    }
                    options={yearOfStudy}
                    placeholder={""}
                    onChange={onChange}
                    value={value}
                    defaultValue={value}
                  />
                );
              }}
            />

            {errors?.Gender ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>

          {/* Gender Dropdown*/}

          <div className="mb-2">
            <label className="form-label">Gender</label>
            <RequiredAsterisk />

            <Controller
              name={"Gender"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <Select
                    className={
                      "form-control p-0" + (errors?.Gender ? " is-invalid" : "")
                    }
                    options={genderOptions}
                    placeholder={"Gender"}
                    onChange={onChange}
                    value={value}
                    defaultValue={value}
                  />
                );
              }}
            />

            {errors?.Gender ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>

          {/* End Gender Dropdown */}
          {/* Disable Dropdown*/}
          <div className="mb-2 required">
            <label className="form-label">Disabled Status</label>
            <Controller
              name={"disability"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <Select
                    className={
                      "form-control p-0" +
                      (errors?.disability ? " is-invalid" : "")
                    }
                    options={disabilityOptions}
                    placeholder={""}
                    value={value}
                    defaultValue={value}
                    // {...register('DisabilityStatus', { required: true } )}
                    onChange={onChange}
                  />
                );
              }}
            />
            {errors?.disability ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          {/* End Disabled Dropdown */}
          <div className="mb-2 required">
            <label className="form-label">Institution Province</label>
            <Controller
              name={"Province"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <Select
                    className={
                      "form-control p-0" +
                      (errors?.Province ? " is-invalid" : "")
                    }
                    options={provinces}
                    placeholder={""}
                    onChange={onChange}
                    value={value}
                    defaultValue={value}
                  />
                );
              }}
            />
          </div>

          <div className="mb-2 required">
            <label className="form-label">
              Campus
              {!province && (
                <>
                  <br />
                  <sub>Select a province before you can select a campus</sub>
                </>
              )}
            </label>
            <Controller
              name={"campus"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <Select
                    className={
                      "form-control p-0" + (errors?.campus ? " is-invalid" : "")
                    }
                    isDisabled={!province}
                    options={campuses}
                    placeholder={""}
                    onChange={onChange}
                    value={value}
                    defaultValue={value}
                  />
                );
              }}
            />
            {errors?.campus ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>

          <div className="mb-2">
            <label className="form-label">Target Institution</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              readOnly
              value={institution?.InstitutionIdName}
              {...register("institution")}
            />
            {errors?.institution ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>

          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput7"
              className={"form-label " + (errors?.Password ? "is-invalid" : "")}
            >
              Create new Password
              <RequiredAsterisk />
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="exampleFormControlInput7"
              placeholder="********"
              {...register("Password", {
                required: true,
                minLength: 6,
                maxLength: 80,
                pattern: {
                  value: PASSWORD_VALIDATION_REGEX,
                  message:
                    "Password must contain at least 1 alphanumeric and 1 special character",
                },
              })}
              onChange={handleInputChange}
              value={inputValue}
            />

            <div>
              <p className="passwordHeader">Password should :</p>
              <p id="letter" className="passwordCheckLetter">
                * Contain at least one lowercase letter
              </p>
              <p id="upperCaseLetter" className="passwordCheckUpperCaseLetter">
                * Contain at least one uppercase letter
              </p>
              <p id="numDigit" className="passwordCheckDigit">
                * Contain at least one digit
              </p>
              <p id="character" className="passwordCheckChar">
                * Contain at least one special character
              </p>
              <p id="length" className="passwordCheckLength">
                * Be at least 8 characters long
              </p>
            </div>

            {errors?.Password ? (
              <div className="invalid-feedback">
                {errors.Password.type === "required" ? "Required" : null}
                {errors.Password.type === "minLength"
                  ? "Password must be at least 6 characters"
                  : null}
              </div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1"
              className={
                "form-label" + (errors?.confPassword ? " is-invalid" : "")
              }
            >
              Confirm Password
              <RequiredAsterisk />
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="********"
              {...register("confPassword", {
                required: true,
                minLength: 6,
                maxLength: 80,
                validate: text =>
                  getValues("Password") === text || "Passwords do not match",
              })}
            />
            {errors?.confPassword ? (
              <div className="invalid-feedback">
                {errors.confPassword.type === "required" ? "Required" : null}
                {errors.confPassword.type === "minLength"
                  ? "Password must be at least 6 characters"
                  : null}
                {errors.confPassword?.message && (
                  <span>{errors.confPassword?.message as string}</span>
                )}
              </div>
            ) : null}
          </div>
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="showPasswordCheckbox"
              onChange={togglePasswordVisibility}
              checked={showPassword}
            />
            <label className="form-check-label" htmlFor="showPasswordCheckbox">
              Show password
            </label>
          </div>
        </ScrollableContainer>

        {/*  */}
        <div className="upload-instruction mt-3">
          <h5>Please add the following documents below:</h5>
          <ul className="no-padding">
            {documentOptions.map(option => (
              <li className="no-bullet" key={option.label}>
                <FaCheckCircle
                  color={getAttachment(option.value) ? "#0acc7e" : "#aaa"}
                />
                &nbsp;&nbsp;{option.label}
                <RequiredAsterisk />
              </li>
            ))}
          </ul>
        </div>
        <div
          className={`upload-documents mt-4 ${
            hasEveryRequiredDocument ? "disabled" : ""
          }`}
        >
          <label>
            <MdAddCircle className="icon" />
            &nbsp; Add Documents
            {/* <input type="file" id="general_documents" disabled={hasEveryRequiredDocument} multiple={true} accept=".pdf, .doc, .docx" {...register("Documents", { required: true })} /> */}
            <input
              type="file"
              id="general_documents"
              disabled={hasEveryRequiredDocument}
              multiple={true}
              accept=".pdf, .doc, .docx, .png, .jpeg, .jpg" // Update accept attribute
              {...register("Documents", { required: true })}
            />
          </label>
        </div>

        {/* </div> */}
        <Row className="align-items-center">
          <Col md={6}>
            <div
              className=""
              style={{ display: "flex", gap: "10px", marginBottom: "0" }}
            >
              <button
                className="btn btn-primary w-50 mt-2"
                type="submit"
                disabled={
                  !hasAnyRequiredDocument() ||
                  files.length < requiredDocumentCount
                }
              >
                Register
              </button>
              <button
                className="btn btn-danger w-50 mt-2"
                onClick={() => navigate("/entry-yype")}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </form>

      {modalComponent}

      <DevTool control={control} />
    </>
  );
}
