import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { MdAddCircle } from "react-icons/md";
import Header from "../components/header";

export default function Payment() {
  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <section className="container">
          <div className="white-block p-3">
            <div className="mobbottom_fixbtn">
              <Link to="/">
                <button
                  type="button"
                  className="btn btn-primary w-100 mt-2"
                  data-bs-toggle="button"
                >
                  Next
                </button>
              </Link>

              <button
                type="button"
                className="btn btn-outline-secondary mt-2 w-100"
                data-bs-toggle="button"
              >
                Back
              </button>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
