import Typography from "@mui/material/Typography";
import React from "react";
import { ThemeColors } from "../../../styles/theme";
import Grid from "@mui/material/Grid";

function PageHeaderTexts({
  heading,
  subHeading,
  actions,
}: {
  heading: string;
  subHeading?: string;
  actions?: React.ReactNode;
}) {
  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
            fontColor: "#101828",
            fontFamily: "Inter",
            fontSize: 30,
          }}
        >
          {heading}
        </Typography>
        {subHeading && (
          <Typography variant="body1" sx={{ color: ThemeColors.textTertiary }}>
            {subHeading}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={5} sx={{ display: "flex", justifyContent: "end" }}>
        {actions}
      </Grid>
    </Grid>
  );
}

export default PageHeaderTexts;
