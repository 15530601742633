import React from "react";
import DashboardPageLayout from "../../components/DashboardPageLayout";
import ContentPage from "../../components/DashboardPageLayout/ContentPage";

import { URLS } from "../../../../_config";
import { BreadcrumbData } from "../../components/Breadcrumbs/types";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MyDetails from "./Student/MyDetails";

import AccommodationInformation from "./Student/AccommodationInformation";
import BursaryInformation from "./Student/BursaryInformation";
import { FormProvider, useForm } from "react-hook-form";

import useCurrentUser, { TCurrentUser } from "../../hooks/useCurrentUser";
import useMobileResponsiveness from "../../hooks/useMobileResponsiveness";
import ProfileDetails from "./AP/ProfileDetails";
import Addresses from "./AP/Addresses";
import BankingDetails from "./AP/BankingDetails";
import Documents from "./AP/Documents";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import StudentDocuments from "./Student/Documents";

const BREADCRUMB_DATA: BreadcrumbData = [
  {
    label: "Dashboard",
    path: URLS.DASHBOARD,
  },
  {
    label: "Profile",
    path: URLS.STUDENT.MY_PROFILE,
  },
  {
    label: "My Details",
    path: URLS.STUDENT.MY_PROFILE,
  },
];

type TTab = {
  label: string;
  component: React.ReactNode;
  disabled?: boolean;
  path: string;
};

const STUDENT_TABS: TTab[] = [
  {
    label: "My details",
    component: <MyDetails />,
    path: "my-details",
  },
  {
    label: "Academic Information",
    component: <AccommodationInformation />,
    path: "accommodation-information",
  },
  {
    label: "Documents",
    component: <StudentDocuments />,
    path: "documents",
  },
];

const AP_TABS: TTab[] = [
  {
    label: "Profile details",
    component: <ProfileDetails />,
    path: "profile-details",
  },
  {
    label: "Addresses",
    component: <Addresses />,
    path: "addresses",
  },
  {
    label: "Banking Details",
    component: <BankingDetails />,
    path: "banking-details",
  },
  {
    label: "Documents",
    component: <Documents />,
    path: "documents",
  },
];

const TABS = {
  student: STUDENT_TABS,
  ap: AP_TABS,
};

const getDefaultTabIndex = (role: "student" | "ap", section?: string) => {
  const index = TABS[role].findIndex(tab => tab.path === section);
  if (index === -1) {
    return 0;
  }
  return index;
};

const getSectionByTabIndex = (role: "student" | "ap", tabIndex: number) => {
  return TABS[role][tabIndex].path;
};

function ProfileContent({
  currentUser,
  mode,
  defaultTabIndex,
}: {
  mode: "student" | "ap";
  currentUser: TCurrentUser;
  defaultTabIndex: number;
}) {
  const [activeTab, setActiveTab] = React.useState(defaultTabIndex);
  const { isMobileView } = useMobileResponsiveness();

  const methods = useForm({
    defaultValues: {
      FirstName: currentUser?.firstName,
      LastName: currentUser?.lastName,
      Email: currentUser?.email,
      Mobile: currentUser?.mobile,
      IDNumber: currentUser?.idNumber,
      Gender: currentUser.gender,
    },
  });

  const navigate = useNavigate();

  return (
    <DashboardPageLayout>
      <ContentPage
        title="Profile"
        padding="lg"
        breadcrumbData={BREADCRUMB_DATA}
      >
        <Grid container pt={2}>
          <Grid item xs={12} md={3}>
            <Tabs
              orientation={isMobileView ? "horizontal" : "vertical"}
              value={activeTab}
              sx={
                isMobileView
                  ? { mb: 3 }
                  : {
                      mb: 3,
                      whiteSpace: "nowrap",
                      borderRight: 0,
                      borderColor: "divider",
                      "& .MuiTab-root": { alignItems: "flex-start" },
                      "& .MuiTabs-indicator": { display: "none" },
                    }
              }
              onChange={(event, newValue) => {
                navigate(
                  `/${mode}/edit-profile/${getSectionByTabIndex(
                    mode,
                    newValue
                  )}`
                );
              }}
            >
              {TABS[mode].map((tab, index) => (
                <Tab
                  disabled={tab.disabled}
                  key={index}
                  label={tab.label}
                  sx={
                    isMobileView
                      ? undefined
                      : {
                          borderRight: 0,
                          borderLeft: index === activeTab ? 2 : 2,
                          borderColor:
                            index === activeTab
                              ? "primary.main"
                              : "transparent",
                        }
                  }
                />
              ))}
            </Tabs>
          </Grid>
          <FormProvider {...methods}>
            <Grid container item xs={12} md={9}>
              {TABS[mode][activeTab].component}
            </Grid>
          </FormProvider>
        </Grid>
      </ContentPage>
    </DashboardPageLayout>
  );
}
const supportedRoles = ["student", "ap"];
const EditProfile = () => {
  const currentUser = useCurrentUser();
  const params = useParams<{ role: "student" | "ap"; section: string }>();
  const role = params.role?.toLowerCase();

  if (!role || !supportedRoles.includes(role)) {
    return <Navigate to="/" />;
  }

  return (
    currentUser && (
      <ProfileContent
        key={params.section}
        currentUser={currentUser}
        mode={role as any}
        defaultTabIndex={getDefaultTabIndex(
          role as "student" | "ap",
          params.section?.toLowerCase()
        )}
      />
    )
  );
};

export default EditProfile;
