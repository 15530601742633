import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col, Button } from "react-bootstrap";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import Select from "react-select";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import toast from "react-hot-toast";
import { RequiredAsterisk } from "../blocks/requiredAsterisk";
import { INPUT_MAX_LENGTHS } from "../../_config/index";
import { useDocumentTypeModal } from "../../hooks/useDocumentTypeModal";
import { array } from "yup";
import { Upload } from "@progress/kendo-react-upload";
import { useNavigate } from "react-router-dom";

interface SelectOption {
  value: number;
  label: string;
}

// interface incomingProps {
//     onSubmitAddressForm: any;
//     tab: any;
//   }

//****************************************************** */

const REQUIRED_DOCS = [886, 887];

interface incomingProps {
  onSubmitAddressForm: any;
  tab: any;
  userType: "individual" | "enterprise"; // Add userType prop
}

export default function AddressForm({
  onSubmitAddressForm,
  tab,
  userType,
}: incomingProps) {
  const commonApi = useCommonApi();

  const requiredDocumentCount = userType === "individual" ? 1 : 2;
  const navigate = useNavigate();

  const [province, setProvince] = useState<any>(null);
  const [provinces, setProvinces] = useState<any>(null); // state to hold provinces

  //new state for testing provinces
  const [myProvinces, setMyProvinces] = useState<[]>(); // my new state

  const [files, setFiles] = useState<any>([]);
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);
  const getAttachment = (code: number) =>
    files.find((file: Record<string, unknown>) => file.DocumentTypeId === code);
  const {
    modalComponent,
    setOnClickContinue,
    closeModal,
    showModal,
    setSelectedDocumentType,
  } = useDocumentTypeModal({ documentOptions, getAttachment });
  const provincesToExclude = [
    "Limpopo",
    "Gauteng",
    "Free State",
    "Western Cape",
    "Northern Cape",
    "North West",
    "Eastern Cape ",
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const documents: any = watch("Documents");

  useEffect(() => {
    const _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        const file: any = documents[i];
        checkIsSelected(file.name)
          .then(() => {
            if (file.size <= 2 * 1024 * 1024) {
              const fileType = file.type;
              if (
                fileType === "application/pdf" ||
                fileType === "application/msword" ||
                fileType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                setOnClickContinue(() => (docTypeCode: number) => {
                  if (!docTypeCode) {
                    toast.error("Select document type");
                    return;
                  }
                  toBase64(file).then((b64: any) => {
                    _files.push({
                      FileName: file.name,
                      FileExtention: file.name.split(".").pop(),
                      DocumentTypeId: docTypeCode,
                      FileContent: b64.split(",")[1],
                    });
                    setFiles(_files);
                    setOnClickContinue(undefined);
                    closeModal();
                    setSelectedDocumentType(undefined);
                  });
                });
                showModal();
              } else {
                toast.error(
                  "Invalid file type. Please upload a valid PDF or Word document."
                );
              }
            } else {
              toast.error("File size should be less than 2MB");
            }
          })
          .catch(() => {});
      }
    } else {
      setFiles(_files);
    }
  }, [documents]);

  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };

  const deleteLocal = (file: any) => {
    const _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file);
      }
    });

    setFiles(_files);
  };

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const onSubmit = (data: any) => {
    const addressData: any = {
      StreetNumber: data.StreetNumber,
      StreetName: data.StreetName,
      Suburb: data.Surburb,
      City: data.City,
      Province: data.Province.value,
      PostalCode: data.PostalCode,
      APTypeId: "865",
      IDTypeId: "862",
    };

    const documentsData: any = files;
    if (data.Documents?.length) {
      for (let i = 0; i < data.Documents?.length; i++) {
        const file: any = data.Documents[i];
        if (file.size <= 2 * 1024 * 1024) {
          onSubmitAddressForm(addressData, files);
        }
      }
    } else {
      onSubmitAddressForm(addressData, documentsData);
    }
  };

  const getProvinces = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "Province",
          },
        },
      },
      (message: string, resp: any) => {
        // if (resp.outputParameters.Count > 0) {
        //   // Filter provinces here to exclude 'Limpopo'
        //   const filteredProvinces = resp.outputParameters.data.filter(
        //     (data: any) => data.optionText !== ""
        //   );

        //   setProvinces(
        //     filteredProvinces.map((data: any) => ({
        //       label: data.optionText,
        //       value: data.optionValue,
        //     }))
        //   );
        // }
        if (resp.outputParameters.Count > 0) {
          // Filter provinces here to exclude 'Limpopo'
          const filteredProvinces = resp.outputParameters.data.filter(
            (data: any) => !provincesToExclude.includes(data.optionText)
          );

          setProvinces(
            filteredProvinces.map((data: any) => ({
              label: data.optionText,
              value: data.optionValue,
            }))
          );
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  const checkFileSize = (data: any) => {
    let count: number = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].size > 2 * 1024 * 1024) {
        count += 1;
      }
    }
    if (count > 0) {
      reset({
        Documents: null,
      });
      toast.error("File size should be less 2MB");
    }
  };

  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "DocTypes",
          },
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
          return console.log(doc.optionText); // Returns array object with doc 'options'
        });
        setDocumentOptions(
          options.filter((option: SelectOption) =>
            REQUIRED_DOCS.includes(option.value)
          )
        );

        const requiredDocumentOptions = options.filter((option: SelectOption) =>
          REQUIRED_DOCS.slice(0, requiredDocumentCount).includes(option.value)
        );

        setDocumentOptions(requiredDocumentOptions);
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    getProvinces();
    getDocTypes();
  }, []);

  const hasEveryRequiredDocument = REQUIRED_DOCS.every(documentCode =>
    getAttachment(documentCode)
  );
  const hasAnyRequiredDocument = () =>
    REQUIRED_DOCS.some(documentCode => getAttachment(documentCode));
  const [selectedFile, setSelectedFile] = useState(null);

  const [fileTypeError, setFileTypeError] = useState(false);

  // const handleFileChange = (event:any) => {
  //     const file = event.target.files[0];

  //     // Check if a file is selected
  //     if (file) {
  //       // Check the file type
  //       const fileType = file.type;
  //       if (
  //         fileType === "application/pdf" ||
  //         fileType === "application/msword" ||
  //         fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  //       ) {
  //         // File type is PDF or Word document, set the selected file
  //         setSelectedFile(file);
  //         setFileTypeError(false); // Reset the file type error
  //         showModal();
  //       } else {
  //         // File type is not supported, show an error message or take appropriate action
  //         toast.error("Please upload a valid PDF or Word document.");
  //       }
  //     }
  // }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1a"
              className={
                "form-label " + errors.StreetNumber ? "is-invalid" : ""
              }
            >
              Address Line 1<RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1a"
              placeholder="90 Bekker Road"
              {...register("StreetNumber", {
                required: true,
                maxLength: INPUT_MAX_LENGTHS.streetNumber,
              })}
            />
            {errors?.StreetNumber ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1_addressLine2"
              className={"form-label " + errors.StreetName ? "is-invalid" : ""}
            >
              Address Line 2<RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1_addressLine2"
              placeholder="Hertford Office Park"
              {...register("StreetName", {
                required: true,
                maxLength: INPUT_MAX_LENGTHS.streetAddress,
              })}
            />
            {errors?.StreetName ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1_city"
              className={"form-label " + errors.City ? "is-invalid" : ""}
            >
              City
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1_city"
              placeholder="Johannesburg"
              {...register("City", {
                required: true,
                maxLength: INPUT_MAX_LENGTHS.city,
              })}
            />
            {errors?.City ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-9">
              <div className="mb-2">
                <label
                  htmlFor="exampleFormControlInput2a"
                  className={"form-label " + errors.Surburb ? "is-invalid" : ""}
                >
                  Surburb
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput2a"
                  placeholder="Waterfall"
                  {...register("Surburb", {
                    required: true,
                    maxLength: INPUT_MAX_LENGTHS.suburb,
                  })}
                />
                {errors?.Surburb ? (
                  <div className="invalid-feedback">Required</div>
                ) : null}
              </div>
            </div>
            <div className="col-3">
              <div className="mb-2">
                <label
                  htmlFor="exampleFormControlInput3a"
                  className={
                    "form-label " + errors.PostalCode ? "is-invalid" : ""
                  }
                >
                  Postal&nbsp;Code
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput3a"
                  placeholder="0129"
                  {...register("PostalCode", {
                    required: true,
                    maxLength: INPUT_MAX_LENGTHS.postalCode,
                  })}
                />
                {errors?.PostalCode ? (
                  <div className="invalid-feedback">Required</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mb-2">
            <label className="form-label">Province</label>

            <Controller
              name={"Province"}
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => {
                return (
                  <Select
                    className={
                      "form-control p-0" +
                      (errors?.Province ? " is-invalid" : "")
                    }
                    options={provinces}
                    placeholder={"Select a Province"}
                    onChange={onChange}
                    value={value}
                    defaultValue={value}
                  />
                );
              }}
            />

            {errors?.Province ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
        </div>

        {/* <div className="upload-instruction mt-3">
                    <h5>Please add the following documents below:</h5>
                    <ul className="no-padding">
                        <li>ID Copy of the director</li>
                        {isEntityMode ? <li>Company registration as per CIPC</li> : null}
                        
                        {documentOptions.map(option =>  <li className="no-bullet" key={option.label}><FaCheckCircle color={getAttachment(option.value) ? '#0acc7e' : '#aaa'} />&nbsp;&nbsp;{option.label}<RequiredAsterisk/></li> )}
                    </ul>
                    
                </div>

                <div className={`upload-documents mt-4 ${hasEveryRequiredDocument ? 'disabled' : ''}`}>
                <label>
                      <MdAddCircle className="icon" />&nbsp;
                      Add Documents
                      <input type="file" id="general_documents" disabled={hasEveryRequiredDocument}  multiple={true} accept=".png,.jpg,.jpeg,application/pdf" {...register("Documents", { required: true })} />
                      </label>
                </div> */}

        <div className="upload-instruction mt-3">
          <h5>Please add the following documents below:</h5>
          <ul className="no-padding">
            {documentOptions.map(option => (
              <li className="no-bullet" key={option.label}>
                <FaCheckCircle
                  color={getAttachment(option.value) ? "#0acc7e" : "#aaa"}
                />
                &nbsp;&nbsp;{option.label}
                <RequiredAsterisk />
              </li>
            ))}
          </ul>
        </div>

        <div
          className={`upload-documents mt-4 ${hasEveryRequiredDocument ? "disabled" : ""}`}
        >
          <label>
            <MdAddCircle className="icon" />
            &nbsp; Add Documents
            {/* <input type="file" id="general_documents" disabled={hasEveryRequiredDocument} multiple={true} accept=".pdf, .doc, .docx" {...register("Documents", { required: true })} /> */}
            <input
              type="file"
              id="general_documents"
              disabled={hasEveryRequiredDocument}
              multiple={true}
              accept=".pdf, .doc, .docx"
              // onChange={handleFileChange} // Add this line
              {...register("Documents", { required: true })}
            />
          </label>
        </div>

        {/* new code above */}

        <div className="mt-2">
          {files.map((file: any, i: any) => (
            <div className="doc-file mb-2" key={i}>
              <FaTimes
                className="delete-me"
                onClick={() => {
                  deleteLocal(file);
                }}
              />
              <input
                type="text"
                className="form-control"
                disabled
                id="exampleFormControlInput3"
                placeholder={file.FileName}
              />
            </div>
          ))}
        </div>

        <Row className="align-items-center">
          <Col md={6}>
            <div className="" style={{ display: "flex", gap: "1rem" }}>
              <Button
                variant="primary"
                className="w-50 "
                type="submit"
                disabled={
                  !hasAnyRequiredDocument() ||
                  files.length < requiredDocumentCount
                }
              >
                Next
              </Button>
              <Button
                onClick={() => {
                  navigate("/");
                }}
                variant="danger"
                className="w-50 "
                type="button"
              >
                Cancel
              </Button>
            </div>
          </Col>
          {/* <Col md={6} className="text-end">
                        <a href="/">Sign in</a>
                    </Col> */}
        </Row>
      </form>
      {modalComponent}
    </React.Fragment>
  );
}
