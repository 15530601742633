import Box from "@mui/material/Box";
import React from "react";
import LoadingPage from "../LoadingPage";

function AsyncContent({
  loading,
  children,
}: {
  loading: boolean;
  children: React.ReactNode;
}) {
  return (
    <>
      {loading ? (
        <Box>
          <LoadingPage />
        </Box>
      ) : (
        children
      )}
    </>
  );
}

export default AsyncContent;
