import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select, { GroupBase, OptionsOrGroups } from "react-select";

interface SelectOption {
  value: number;
  label: string;
}

export const useDocumentTypeModal = ({
  documentOptions,
  getAttachment,
}: {
  documentOptions: OptionsOrGroups<SelectOption, GroupBase<SelectOption>>;
  getAttachment: (code: number) => Record<string, unknown>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<SelectOption>();
  const [onClickContinue, setOnClickContinue] = useState<Function>();

  const modalComponent = (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Document Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please specify the type of document you are attaching
        <Select
          className={"form-control p-0"}
          options={documentOptions}
          getOptionLabel={option => {
            const attachment = getAttachment(option.value);
            if (attachment) {
              return `${option.label} [${attachment.FileName}]`;
            }
            return option.label;
          }}
          isOptionDisabled={option => Boolean(getAttachment(option.value))}
          placeholder={""}
          onChange={_ => setSelectedDocumentType(_ as SelectOption)}
          value={selectedDocumentType}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onClickContinue?.(selectedDocumentType?.value);
          }}
        >
          Add Document
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return {
    modalComponent,
    setOnClickContinue,
    setSelectedDocumentType,
    closeModal: () => setShowModal(false),
    showModal: () => setShowModal(true),
  };
};
