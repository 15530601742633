import Grid, { GridProps } from "@mui/material/Grid";
import React from "react";

type Props = GridProps & {
  children: React.ReactNode;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};

const FormGridItem = ({ children, md = 6, ...restOfProps }: Props) => (
  <Grid {...restOfProps} sx={{ pr: 0, pb: 2 }} item xs={12} md={md}>
    {children}
  </Grid>
);

export default FormGridItem;
