import React from "react";
import Header from "./components/header";

const PageNotFound = () => {
  return (
    <React.Fragment>
      <div className="dashboard" style={{ paddingTop: "0px" }}>
        <Header />
        <div className="dashboard-wrapper">
          <div className="dashboard-wrapper-inner">
            <h1
              className="alert alert-danger text-center"
              role="alert"
              style={{ fontSize: "50px" }}
            >
              404 Page Not Found
            </h1>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;
