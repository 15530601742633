import { GenderType, TermType } from "../types/shared";

export const API_PATH = `${
  process.env.REACT_APP_API_DOMAIN || "https://nsfas.api.ezra360.com"
}/api/v1/`;
export const EXECUTE_REQUEST_PATH = `${API_PATH}entities/ExecuteRequest`;
export const GET_AP_SIGNING_URL_PATH = `${API_PATH}Entities/Data/GetAPSigningURL`;
export const API_KEY = process.env.REACT_APP_API_KEY || "";
export const CASE_CLASSIFICATION_REQUEST_PATH = `${API_PATH}entities/CaseClassification/lookup?ViewName=StudentCaseClassifications`;
export const AP_CASE_CLASSIFICATION_REQUEST_PATH = `${API_PATH}entities/CaseClassification/lookup?ViewName=APCaseClassifications`;
export const REGARDING_OPTIONS_REQUEST_PATH = `${API_PATH}entities/RegardingOptions/lookup?ViewName=StudentRegardingOptions`;
export const GET_SURVEY_RESPONSE_PATH = `${API_PATH}entities/SurveyResponse/lookup?filters=RegardingId eq `;

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const EMPTY_ARRAY = Object.freeze([]);

export const EMPTY_OBJECT = Object.freeze({});

export const CASE_DETAILS_REQUEST_PATH = `${API_PATH}entities/Data/RetrieveCaseDetails`;
/** Used by updated v2 listing screens */
export const PAGE_SIZE = 20;
/** Used by listing screens like student check-in */
export const DEFAULT_PAGE_SIZE = 12;

export const CASE_STATUS_MAP = {
  307: "New",
  308: "Reopened",
  309: "In progress",
  310: "On hold",
  311: "Resolved",
  112: "Cancelled",
  762: "Complete",
};
export const POSITIVE_CASE_STATUSES = [762, 311];
export const NEGATIVE_CASE_STATUSES = [112];

export const EMAIL_VALIDATION_REGEX =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PHONE_NUMBER_REGEX = /^(\+?27|0)[6-8][0-9]{8}$/;
export const NUMBERS_ONLY_REGEX = /^[0-9]*$/;

export const COVER_IMAGE_CODE = 889;
export const NON_COVER_IMAGE_CODE = 893;
export const PROOF_OF_BANKING_DOCUMENT_CODE = 905;

export enum UploadDocumentType {
  CoverImage = 889,
  NonCoverImage = 893,
  ProofOfBanking = 905,
  ProofOfAddress = 904,
  IDDocument = 886,
  CIPCDocument = 887,
  ProofOfRegistration = 950,
  Selfie = 951,
}

// TODO: Confirm file types for file drop
export const UPLOADS_ACCEPTED_FILE_TYPES = [
  ".svg",
  ".png",
  ".jpg",
  ".jpeg",
  ".gif",
  ".pdf",
];

/** To determine which mode to render the desktop.  A v2 ui already exists, but while transitioning we can use this constants to keep items in legacy ui mode */
export const DASHBOARD_LAYOUT_MODE: "legacy" | "v2" = "legacy";

export const PROVINCES_CODE_MAP = {
  175: "Eastern Cape",
  176: "Gauteng",
  177: "Free State",
  178: "KwaZulu Natal",
  179: "Limpopo",
  180: "Mpumalanga",
  181: "Northen Cape",
  182: "Western Cape",
  183: "North West",
};

export const PROVINCE_OPTIONS = Object.entries(PROVINCES_CODE_MAP).map(
  ([value, label]) => ({ value, label })
);

export const BANKING_ACCOUNT_TYPES_CODE_MAP = {
  317: "Savings",
  316: "Checking",
  315: "Current",
};

export const BANKING_ACCOUNT_TYPE_OPTIONS = Object.entries(
  BANKING_ACCOUNT_TYPES_CODE_MAP
).map(([value, label]) => ({ value, label }));

export const GENDER_OPTIONS = Object.entries(GenderType).map(
  ([label, value]) => ({ value, label })
);

export const TERM_TYPE_OPTIONS = Object.entries(TermType).map(
  ([label, value]) => ({ value, label })
);

export const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

export const ALLOWED_FILE_TYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "image/svg+xml", // .svg
  "image/png", // .png, .PNG
  "image/jpeg", // .jpg, .jpeg, .JPEG 2000
  "image/webp", // .WebP
  "image/bmp", // .BMP
];
