import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../../_common/hooks/selectors/userSelector";
import AccomodationCard from "./components/accomdationCard";
import Breadcrumbs from "./components/breadcrumbs";
import Header from "./components/header";

export default function MyResidences() {
  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();
  const [residencies, setResidencies] = useState<any>(null);
  const user: any = useUserSelector();

  const getMyResidencies = () => {
    showLoader();
    commonApi.post(
      {
        recordId: user.recordId,
        requestName: "StudentMyResidence",
      },
      (message: string, resp: any) => {
        hideLoader();
        setResidencies(resp.outputParameters.Residence);
      },
      (message: string) => {
        toast.error(message);
        hideLoader();
      }
    );
  };

  useEffect(() => {
    getMyResidencies();
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard" style={{ paddingTop: "0px" }}>
        <Header />
        <div className="container">
          <Breadcrumbs
            data={[
              { label: "Home", path: "/student" },
              { label: "My Residences" },
            ]}
          />
          <div className="my-4">My Residences</div>
          <Row>
            {residencies?.map((residence: any, i: any) => (
              <Col md={6} key={i}>
                <AccomodationCard
                  data={residence}
                  route={"my-residence-details"}
                  getMyResidencies={getMyResidencies}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
