import { useRef, useState, useEffect } from "react";
import Header from "../components/header";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DashboardCard from "../components/dashboardCard";
import bedIcon from "../images/bed.png";
import accreditedIcon from "../images/accredit.png";
import toBeAccreditedIcon from "../images/tobeaccredited.png";
import propertiesAccredited from "../images/properties-accredited.png";
import totalProperties from "../images/property.png";
import totalPropertiesAccredited from "../images/compliant.png";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import Breadcrumbs from "./student/components/breadcrumbs";

interface CampusStats {
  CampusId: string;
  InstitutionId: string;
  InstitutionIdName: string;
  Name: string;
  PropertiesAccredited: number;
  TotalBedToBeAccredited: number;
  TotalBeds: number;
  TotalBedsAccredited: number;
  TotalProperties: number;
  TotalPropertiesStillToBeAccredited: number;
}

interface InstitutionTotals {
  Name: string;
  PropertiesAccredited: number;
  TotalBedToBeAccredited: number;
  TotalBeds: number;
  TotalBedsAccredited: number;
  TotalProperties: number;
  TotalPropertiesStillToBeAccredited: number;
}

const InstitutionDashboard = () => {
  const user: any = useUserSelector();
  const chartRef = useRef(null);
  const commonApi = useCommonApi();
  const [campusStats, setCampusStats] = useState<CampusStats[]>([]);
  const [institutionTotal, setInstitutionTotals] = useState<
    InstitutionTotals[]
  >([]);
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();

  const data = [
    { id: 0, value: 10, label: "Active Applications" },
    { id: 1, value: 15, label: "No. of Rooms" },
    { id: 2, value: 20, label: "No. of Beds" },
  ];

  // Getting campus stats
  const getCampusStats = () => {
    commonApi.getCampusStats(
      {
        InstitutionId: user.institutionId,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setCampusStats(resp);
        }
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };
  // Getting Instition Stats Overall Total
  const getInstitutionTotals = () => {
    commonApi.getInstituionTotals(
      {
        InstitutionId: user.institutionId,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setInstitutionTotals(resp);
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  useEffect(() => {
    getCampusStats();
    getInstitutionTotals();
  }, []);

  return (
    <Box sx={{ padding: "2rem" }}>
      <Box sx={{ marginBottom: "5rem" }}>
        <Header />
      </Box>
      {/* <Box sx={{marginTop:'4rem', marginBottom:'2rem'}}>
            <Breadcrumbs
                data={[
                  { label: 'Home', path: '/' },
                  { label: 'Dashbaord' },
                ]}
            />
          </Box> */}
      <Typography variant="h4" fontWeight={500} sx={{ marginBottom: "2rem" }}>
        Dashboard{" "}
      </Typography>
      {institutionTotal.map(totals => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "3rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <DashboardCard
                  cardIcon={bedIcon}
                  cardDesc="Total Beds"
                  statDesc={totals.TotalBeds}
                />
                <DashboardCard
                  cardIcon={accreditedIcon}
                  cardDesc="Total Beds Accredited"
                  statDesc={totals.TotalBedsAccredited}
                />
                <DashboardCard
                  cardIcon={toBeAccreditedIcon}
                  cardDesc="Total Beds To Be Accredited"
                  statDesc={totals.TotalBedToBeAccredited}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <DashboardCard
                  cardIcon={totalProperties}
                  cardDesc="Total Properties"
                  statDesc={totals.TotalProperties}
                />
                <DashboardCard
                  cardIcon={propertiesAccredited}
                  cardDesc="Total Properties Accredited"
                  statDesc={totals.PropertiesAccredited}
                />
                <DashboardCard
                  cardIcon={totalPropertiesAccredited}
                  cardDesc="Total Properties To Be Accreditted"
                  statDesc={totals.TotalPropertiesStillToBeAccredited}
                />
              </Box>
            </Box>
          </>
        );
      })}
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            marginTop: "7rem",
          }}
        >
          <Typography
            sx={{
              // marginTop: "7rem",
              color: "#101828",
              fontFamily: "Inter",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "38px",
              marginBottom: "20px",
            }}
          >
            Campus Stats Overview
          </Typography>
          {/* <Button>Add New</Button> */}
          <Button
            onClick={() => {
              navigate("/add-campus");
            }}
            // href='/campus-details'
            sx={{
              border: "1px solid grey",
              cursor: "pointer",
              height: "10px",
            }}
          >
            Add New
          </Button>
        </Box>
        <Paper sx={{ width: "100%", overflow: "auto", height: 470 }}>
          <TableContainer sx={{}}>
            <Table sx={{ minWidth: 1050 }} aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#F9FAFB",
                }}
              >
                <TableRow>
                  <TableCell>Institution Name</TableCell>
                  <TableCell>Campus Name</TableCell>
                  <TableCell>Total Properties</TableCell>
                  <TableCell>Total Beds</TableCell>
                  <TableCell>Properties Accredited</TableCell>
                  <TableCell>Total Properties To Be Accredited</TableCell>
                  <TableCell>Total Beds Accredited</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campusStats.map((stats: CampusStats) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      textAlign: "center",
                    }}
                  >
                    <TableCell className="table-data">
                      {stats.InstitutionIdName}
                    </TableCell>
                    <TableCell className="table-data">{stats.Name}</TableCell>
                    <TableCell className="table-data" align="center">
                      {stats.TotalProperties}
                    </TableCell>
                    <TableCell className="table-data" align="center">
                      {stats.TotalBeds}
                    </TableCell>
                    <TableCell className="table-data" align="center">
                      {stats.PropertiesAccredited}
                    </TableCell>
                    <TableCell className="table-data" align="center">
                      {stats.TotalPropertiesStillToBeAccredited}
                    </TableCell>
                    <TableCell className="table-data" align="center">
                      {stats.TotalBedsAccredited}
                    </TableCell>
                    <TableCell className="table-data">
                      <Button
                        // onClick={()=>{navigate('/campus-details/')}}
                        onClick={() => {
                          navigate(`/campus-details/${stats.CampusId}`);
                        }}
                        sx={{
                          border: "1px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Box>
  );
};

export default InstitutionDashboard;
