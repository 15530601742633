import React, { useEffect, useRef, useState } from "react";
import Header from "./components/header";
import Breadcrumb from "./components/breadcrumbs";
import "../../styles/student/mycases.scss";
import { Button, Row, Col, Carousel } from "react-bootstrap";
import { useCommonApi } from "../../_common/hooks/api/common/commonApiHook";
import { useParams } from "react-router-dom";
import { useAppLoader } from "../../_common/hooks/common/appLoaderHook";
import parse from "html-react-parser";
import toast from "react-hot-toast";

const MyCasesDetails = () => {
  const commonApi = useCommonApi();
  const { id } = useParams();
  const { showLoader, hideLoader } = useAppLoader();
  const comments: any = useRef();

  const [caseDetails, setCaseDetails] = useState<any>(null);
  const [caseStatus, setCaseStatus] = useState<any>(null);
  const [slaStatus, setSlaStatus] = useState<any>(null);
  const [images, setImages] = useState<any>(null);

  const detailsImages: any = sessionStorage.getItem("detailsImage");

  const retreiveCaseDetails = () => {
    showLoader();
    commonApi.retreiveCaseDetails(
      {
        CaseId: id,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setCaseDetails(resp);
        }
        hideLoader();
      },
      (message: string) => {
        hideLoader();
        // console.log(message);
      }
    );
  };

  const getCasesStatus = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "CaseStatus",
          },
        },
      },
      (message: string, resp: any) => {
        if (resp.outputParameters.Count > 0) {
          setCaseStatus(resp.outputParameters.data);
        }
      },
      (message: string) => {
        //toast.error(message);
      }
    );
  };

  const getSlaStatus = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "SLAStatus",
          },
        },
      },
      (message: string, resp: any) => {
        // console.log({ slaStatus: resp.outputParameters })
        if (resp.outputParameters.Count > 0) {
          setSlaStatus(
            resp.outputParameters.data.map((data: any) => {
              data.label = data.optionText;
              data.value = data.optionValue;
              return data;
            })
          );
        }
      },
      (message: string) => {
        console.log(message);
        //toast.error(message);
      }
    );
  };

  const getFilteredCaseStatus = (facilityStatusId: any) => {
    const filteredData = caseStatus.filter(
      (item: any) => item.optionValue == facilityStatusId
    );
    // console.log(filteredData[0])
    return (
      <div
        className="status"
        style={{
          backgroundColor: filteredData[0]?.colorCode
            ? filteredData[0]?.colorCode
            : "#F3981E",
        }}
      >
        <>{filteredData[0]?.optionText ? filteredData[0]?.optionText : "NA"}</>
      </div>
    );
  };

  const handleEscalate = (data: any) => {
    showLoader();
    return new Promise((resolve: any, reject: any) => {
      commonApi.post(
        {
          requestName: "EscalateCaseReq",
          recordId: data,
        },
        (message: string, resp: any) => {
          // toast.success(message)
          resolve(message);
          hideLoader();
        },
        (message: string) => {
          // toast.error(message);
          reject(message);
          hideLoader();
        }
      );
    });
  };

  const handleWithdraw = (data: any, cmnt: any) => {
    showLoader();
    return new Promise((resolve: any, reject: any) => {
      commonApi.post(
        {
          entityName: "Cases",
          requestName: "UpsertRecordReq",
          recordId: data,
          inputParamters: {
            Entity: {
              ResolutionStatusId: "460",
              Comments: cmnt,
              CasesStatusId: "312",
            },
          },
        },
        (message: string, resp: any) => {
          // toast.success(message)
          resolve(message);
          hideLoader();
        },
        (message: string) => {
          // toast.error(message);
          reject(message);
          hideLoader();
        }
      );
    });
  };

  const escalateNotification = (data: any) => {
    toast(
      t => (
        <span>
          Are you sure you want to escalate your case?
          <br />
          <hr />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger btn-sm m-1"
              onClick={() => {
                toast.dismiss(t.id);
                toast.promise(
                  handleEscalate(data),
                  {
                    loading: "working on request...",
                    success: (msg: any) => {
                      return <b> {msg}</b>;
                    },
                    error: msg => {
                      return <b>{msg}</b>;
                    },
                  },
                  { duration: 10000 }
                );
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-secondary btn-sm m-1"
              onClick={() => toast.dismiss(t.id)}
            >
              No
            </button>
          </div>
        </span>
      ),
      { duration: 5000, className: "withdraw-toast" }
    );
  };

  const withdrawNotification = (data: any) => {
    toast(
      t => (
        <span>
          Are you sure you want to withdraw your case?
          <br />
          <div className="">
            <label htmlFor="comments">Comment:</label>
            <textarea
              name="comments"
              id="comments"
              ref={comments}
              className="w-100"
            />
          </div>
          <hr />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger btn-sm m-1"
              onClick={() => {
                toast.dismiss(t.id);
                toast.promise(
                  handleWithdraw(data, comments.current.value),
                  {
                    loading: "working on request...",
                    success: (msg: any) => {
                      return <b> {msg}</b>;
                    },
                    error: msg => {
                      return <b>{msg}</b>;
                    },
                  },
                  { duration: 10000 }
                );
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-secondary btn-sm m-1"
              onClick={() => toast.dismiss(t.id)}
            >
              No
            </button>
          </div>
        </span>
      ),
      { duration: 5000, className: "withdraw-toast" }
    );
  };

  const checkImageBase64Code = (url: any) => {
    if (url.search("data:image/") == 0) {
      return url;
    } else {
      return "data:image/jpeg;base64," + url;
    }
  };

  useEffect(() => {
    if (id && images) {
      retreiveCaseDetails();
    }
  }, [id, images]);

  useEffect(() => {
    getCasesStatus();
    getSlaStatus();
  }, []);

  useEffect(() => {
    if (detailsImages) {
      setImages(JSON.parse(detailsImages));
    }
  }, [detailsImages]);

  return (
    <div
      className="dashboard mycasedetails-wrapper"
      style={{ paddingTop: "0px" }}
    >
      <Header />
      <div className="container">
        <Breadcrumb
          data={[
            { label: "Home", path: "/student" },
            { label: "My Cases", path: "/student/my-cases" },
            { label: "Case Details" },
          ]}
        />
        <div className="card p-3">
          <h1>Case details</h1>
          <hr></hr>
          {caseDetails &&
          caseDetails.length > 0 &&
          caseStatus &&
          caseStatus.length > 0
            ? caseDetails.map((item: any, index: number) => (
                <div className="case-details-row" key={index}>
                  <Row className="mb-4">
                    <Col sm={5}>
                      <div className="mycasedetails-pic">
                        {images && images.length > 0 ? (
                          <Carousel>
                            {images.map((item: any, i: number) => (
                              <Carousel.Item key={i}>
                                <img
                                  src={checkImageBase64Code(item?.fileContent)}
                                  alt="img"
                                />
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        ) : (
                          <img
                            src="/assets/img/no-image-available.jpg"
                            alt="img"
                          />
                        )}
                        {getFilteredCaseStatus(item?.CasesStatusId)}
                      </div>
                      {item?.SLAStatus == 755 || item?.SLAStatus == null ? (
                        <Button
                          className="btn btn-primary mt-3 w-100"
                          onClick={() => withdrawNotification(item?.CasesId)}
                        >
                          Withdraw
                        </Button>
                      ) : item?.SLAStatus == 756 ? (
                        <Button
                          className="btn btn-primary mt-3 w-100"
                          onClick={() => escalateNotification(item?.CasesId)}
                        >
                          Escalate
                        </Button>
                      ) : null}
                    </Col>
                    <Col sm={7}>
                      <h3>Student details</h3>
                      <div className="details-feature-block-wrapper">
                        <div className="details-feature-block">
                          <h5>Case No:</h5>
                          <h6>{item?.Name}</h6>
                        </div>
                        <div className="details-feature-block">
                          <h5>SLA Status:</h5>
                          <h6>
                            {slaStatus && slaStatus.length > 0
                              ? slaStatus.map((status: any) =>
                                  status.optionValue == item?.SLAStatus
                                    ? status.optionText
                                    : ""
                                )
                              : null}
                          </h6>
                        </div>
                        <div className="details-feature-block">
                          <h5>Case Status:</h5>
                          <h6>
                            {caseStatus && caseStatus.length > 0
                              ? caseStatus.map((status: any) =>
                                  status.optionValue == item?.CasesStatusId
                                    ? status.optionText
                                    : ""
                                )
                              : null}
                          </h6>
                        </div>
                        <div className="details-feature-block">
                          <h5>Resolved by:</h5>
                          <h6>{item?.ResolvedByIdName}</h6>
                        </div>
                      </div>
                      <h5>Logged by</h5>
                      <h6>{item?.LoggedBy}</h6>
                      <h5>Student No</h5>
                      <h6>{item?.StudentNumber}</h6>
                      <h5>ID Number</h5>
                      <h6>{item?.IdNumber}</h6>
                      <h5>Room</h5>
                      <h6>{item?.room}</h6>
                      <h5>Description</h5>
                      {item?.Description != null ? (
                        parse(item?.Description)
                      ) : (
                        <h6></h6>
                      )}
                      <h5>Level</h5>
                      <h6>{item?.Level}</h6>
                      <h5>Educational term</h5>
                      <h6>{item?.EducationalTerm}</h6>
                    </Col>
                  </Row>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default MyCasesDetails;
