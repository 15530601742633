import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { useEffect, useState } from "react";
import TextField from "../../../../components/TextField";
import Select from "../../../../components/Select";
import PageActionsBar from "../../PageActionsBar";
import SectionHeader from "../../SectionHeader";

import { useFormContext, useWatch } from "react-hook-form";
import {
  EXECUTE_REQUEST_PATH,
  GENDER_OPTIONS,
  NUMBERS_ONLY_REGEX,
  PHONE_NUMBER_REGEX,
} from "../../../../constants";
import CircleLoader from "../../../../components/CircleLoader";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../components/Notify";
import { API } from "../../../../services/API";
import { useAppLoader } from "../../../../../../_common/hooks/common/appLoaderHook";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { IApiResponse } from "../../../../types/IApiResponse";
import { GenderType } from "../../../../types/shared";
import { TStudentProfileDetailsResponse } from "../../../../types/TStudentProfileDetailsResponse";

type TPersonalDetailsForm = {
  FirstName: string;
  LastName: string;
  Email: string;
  Mobile: string;
  IDNumber: string; // readonly UI
  GenderId?: GenderType;
  NextOfKinFullName: string;
  NextOfKinRelationship: string;
  NextOfKinMobile: string;
  NextOfKinEmail: string;
};

function MyDetails() {
  const { register, handleSubmit, reset, control } =
    useFormContext<TPersonalDetailsForm>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showLoader, hideLoader } = useAppLoader();
  const currentUser = useCurrentUser();

  // Store initial form values
  const [initialValues, setInitialValues] =
    useState<TPersonalDetailsForm | null>(null);
  const [isEdited, setIsEdited] = useState(false); // Track if form is edited
  const [termTypeEdit, settermTypeEdit] = useState(false);

  // Watch form values for changes
  const formValues = useWatch({ control });

  const getUserDetails = async () => {
    if (!currentUser) {
      return;
    }
    try {
      showLoader();
      const response: IApiResponse<TStudentProfileDetailsResponse> =
        await API.post(EXECUTE_REQUEST_PATH, {
          entityName: "Employee",
          requestName: "RetrieveProfileDetailsReq",
          inputParamters: {
            RecordId: currentUser.recordId,
          },
        });
      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }

      const profileDetails = response.outputParameters.ProfileDetails;

      // Set initial form values
      const initialFormValues: TPersonalDetailsForm = {
        FirstName: profileDetails.studentDetails.firstName,
        LastName: profileDetails.studentDetails.lastName,
        Mobile: profileDetails.studentDetails.mobile,
        Email: profileDetails.studentDetails.email,
        NextOfKinFullName:
          profileDetails.nextOfKinDetails.nextOfKinFullName || "",
        NextOfKinRelationship:
          profileDetails.nextOfKinDetails.nextOfKinRelationship || "",
        NextOfKinMobile: profileDetails.nextOfKinDetails.nextOfKinMobile || "",
        NextOfKinEmail: profileDetails.nextOfKinDetails.nextOfKinEmail || "",
        IDNumber: profileDetails.studentDetails.idNumber,
        GenderId: profileDetails.studentDetails.gender,
      };

      setInitialValues(initialFormValues); // Save initial values
      reset(initialFormValues); // Reset the form with the fetched values
    } catch (e) {
      showErrorAlert("An error occurred while fetching your details");
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  // Check if the form has been edited
  useEffect(() => {
    if (initialValues) {
      const isFormEdited =
        JSON.stringify(formValues) !== JSON.stringify(initialValues);
      setIsEdited(isFormEdited); // Set form as edited if values have changed
    }
  }, [formValues, initialValues]);

  const onSubmit = async (data: TPersonalDetailsForm) => {
    try {
      if (!currentUser) {
        return;
      }
      setIsSubmitting(true);
      const response = await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "Employee",
        recordId: currentUser.recordId,
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: data,
        },
      });
      if (!response.isSuccess) {
        throw new Error("An error occurred while updating your details");
      }

      showSuccessAlert("Profile updated successfully");
      setInitialValues(data); // Reset initial values after successful save
      setIsEdited(false); // Mark form as not edited
    } catch (e) {
      showErrorAlert("An error occurred while updating your details");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleOnSaveClick = () => handleSubmit(onSubmit)();

  const handleOnCancelClick = () => {
    if (initialValues) {
      reset(initialValues); // Reset form to the initial values
      setIsEdited(false); // Mark form as not edited
    }
  };
  return (
    <>
      <PageActionsBar
        title="Personal profile"
        subtitle="Update your details here."
        onSaveClick={handleOnSaveClick}
        disabled={isSubmitting}
      />
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <SectionHeader
            title="Personal detail"
            subtitle="This will be displayed on your profile."
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <TextField
            label="First name"
            size="small"
            placeholder="Alicia"
            {...register("FirstName", { required: true })}
          />
          <TextField
            label="Last name"
            size="small"
            placeholder="Mkhwanazi"
            {...register("LastName", { required: true })}
          />
          <TextField
            label="Email"
            type="email"
            size="small"
            placeholder="alicia.mkhwanazi@gmail.com"
            {...register("Email", { required: true })}
          />
          <TextField
            label="Mobile Number"
            type="tel"
            size="small"
            placeholder="81 454 0865"
            {...register("Mobile", {
              required: true,
              pattern: PHONE_NUMBER_REGEX,
            })}
          />
          <TextField
            label="ID Number"
            type="text"
            size="small"
            placeholder="0302025566088"
            {...register("IDNumber", {
              required: true,
              disabled: true,
              pattern: NUMBERS_ONLY_REGEX,
            })}
          />
          <Select
            label="Gender"
            options={GENDER_OPTIONS}
            size="small"
            placeholder="Select your gender"
            {...register("GenderId", { required: true })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <SectionHeader
            title="Next of Kin Detail"
            subtitle="Add and manage your next of kin information"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <TextField
            label="Full Name"
            size="small"
            type="tel"
            placeholder=""
            {...register("NextOfKinFullName", { required: true })}
          />
          <TextField
            label="Relationship"
            size="small"
            placeholder="Spouse"
            {...register("NextOfKinRelationship", { required: true })}
          />
          <TextField
            label="Mobile Number"
            size="small"
            type="tel"
            placeholder="81 454 0865"
            {...register("NextOfKinMobile", {
              required: true,
              pattern: PHONE_NUMBER_REGEX,
            })}
          />
          <TextField
            label="Email"
            size="small"
            type="email"
            placeholder="email@person.com"
            {...register("NextOfKinEmail", { required: true })}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      {/* Hide for now  */}
      {false && (
        <>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <SectionHeader
                title="Password & Security"
                subtitle="All your security settings"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              display="flex"
              flexDirection="column"
              rowGap={2}
            >
              <TextField
                label="Password"
                type="password"
                size="small"
                placeholder="*********"
                endButton={
                  <Button
                    size="small"
                    sx={{ color: "#000", whiteSpace: "nowrap", pr: 2 }}
                  >
                    Reset Password
                  </Button>
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} py={2}>
            <Divider />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12} md={4}>
              <SectionHeader title="Social profiles" />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              display="flex"
              flexDirection="column"
              rowGap={2}
            >
              <TextField label="" size="small" startText="twitter.com/" />
              <TextField label="" size="small" startText="facebook.com/" />
              <TextField
                startText="linkedin.com/company/"
                label=""
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} py={2}>
            <Divider />
          </Grid>
        </>
      )}
      <Grid textAlign="right" item xs={12}>
        <Box display="inline-flex" gap={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={!isEdited || isSubmitting} // Enable Cancel only if the form is edited
            onClick={handleOnCancelClick}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            type="button"
            color="primary"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircleLoader /> : undefined}
            onClick={handleOnSaveClick}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default MyDetails;
