import axios from "axios";
import { API_PATH, API_KEY } from "../constants";

const pathify = (path: string) => {
  if (path === "/") {
    return API_PATH;
  }
  const url = new URL(path, API_PATH);
  return url.href;
};

const handleApiError = (e: Error) => {
  // const statusCode = e?.response?.status;
  // TODO: custom error handling here, like if user is not logged or token expired
  throw e;
};

const baseHeaders = {
  // TODO: add common headers here as needed. Such as API key
  "api-key": API_KEY,
};

const validateHeaders = (options: Record<string, unknown>) => {
  if (options.headers && Object.keys(options.headers).length === 0) {
    const modifiedOptions = { ...options };
    modifiedOptions.headers = { ...baseHeaders };
    return modifiedOptions;
  }
  return options;
};

// TODO: add mechanism to get token
const getToken = () => {
  return "";
};

class API {
  static get = async (path: string, options = {}) => {
    const token = getToken();
    const authHeaders = token
      ? {
          ...baseHeaders,
          Authorization: `Bearer ${token}`,
        }
      : baseHeaders;

    try {
      const response = await axios.get(
        pathify(path),
        validateHeaders({
          headers: authHeaders,
          ...options,
        })
      );
      return response.data;
    } catch (e: any) {
      handleApiError(e);
    }
  };

  static async post<T>(path: string, data: T & Record<string, any>, options = {}) {
    const token = getToken();
    const authHeaders = token
      ? {
          ...baseHeaders,
          Authorization: `Bearer ${token}`,
        }
      : baseHeaders;
    try {
      const response = await axios.post(
        pathify(path),
        data,
        validateHeaders({
          headers: authHeaders,
          ...options,
        })
      );
      return response.data;
    } catch (e: any) {
      handleApiError(e);
    }
  }

  static patch = async (path: string, data: Record<string, any>, options = {}) => {
    const token = getToken();
    const authHeaders = token
      ? {
          ...baseHeaders,
          Authorization: `Bearer ${token}`,
        }
      : baseHeaders;
    try {
      const response = await axios.patch(
        pathify(path),
        data,
        validateHeaders({
          headers: authHeaders,
          ...options,
        })
      );
      return response.data;
    } catch (e: any) {
      handleApiError(e);
    }
  };

  static put = async (path: string, data: Record<string, any>, options = {}) => {
    const token = getToken();
    const authHeaders = token
      ? {
          ...baseHeaders,
          Authorization: `Bearer ${token}`,
        }
      : baseHeaders;
    try {
      const response = await axios.put(
        pathify(path),
        data,
        validateHeaders({
          headers: authHeaders,
          ...options,
        })
      );
      return response.data;
    } catch (e: any) {
      handleApiError(e);
    }
  };

  static delete = async (path: string, options = {}) => {
    const token = getToken();
    const authHeaders = token
      ? {
          ...baseHeaders,
          Authorization: `Bearer ${token}`,
        }
      : baseHeaders;
    try {
      const response = await axios.delete(
        pathify(path),
        validateHeaders({
          headers: authHeaders,
          ...options,
        })
      );
      return response.data;
    } catch (e: any) {
      handleApiError(e);
    }
  };

  static download = async (path: string, options = {}) => {
    const token = getToken();
    const authHeaders = token
      ? {
          ...baseHeaders,
          Authorization: `Bearer ${token}`,
        }
      : baseHeaders;
    try {
      const response = await axios.get(pathify(path), {
        headers: authHeaders,
        responseType: "blob",
        ...options,
      });
      return response.data;
    } catch (e: any) {
      handleApiError(e);
    }
  };
}

export { API };
