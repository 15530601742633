import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardPageLayout from "../../../../components/DashboardPageLayout";
import ContentPage from "../../../../components/DashboardPageLayout/ContentPage";
import { BreadcrumbData } from "../../../../components/Breadcrumbs/types";
import { URLS } from "../../../../../../_config";
import {
  Box,
  Button,
  CircularProgress,
  SvgIcon,
  Typography,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../components/Notify";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { IApiResponse } from "../../../../types/IApiResponse";
import { API } from "../../../../services/API";
import { EXECUTE_REQUEST_PATH } from "../../../../constants";
import Swal from "sweetalert2";

type Props = {};

type ResponseType = {
  randomQuestions: {
    securityQuestionId: string;
    response: {
      questionId: number;
      question: string;
      possibleAnswers: string[];
    }[];
  };
};

const BREADCRUMB_DATA: BreadcrumbData = [
  {
    label: "Student Search",
    path: URLS.AP.STUDENT_SEARCH,
  },
  {
    label: "Security Questions",
    path: URLS.AP.SECURITY_QUESTIONS,
  },
];

export default function SecurityQuestions({}: Props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { id: studentId } = useParams();
  const studentEmail = searchParams.get("email");
  const studentNumber = searchParams.get("number");
  const employeeId = searchParams.get("employee-id");

  const [isAnswersCorrect, setIsAnswersCorrect] = useState<boolean | null>(
    null
  );
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [securityId, setSecurityId] = useState("");
  const [questions, setQuestions] = useState<
    {
      questionId: number;
      question: string;
      possibleAnswers: string[];
    }[]
  >([]);
  const [questionAnswers, setQuestionAnswers] = useState<
    {
      questionId: number;
      answer: string;
    }[]
  >([]);
  const [questionStep, setQuestionStep] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const IncorrectAnwers = () => {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            background: "#fef3f2",
            border: "1px solid #d0d5dd",
            borderRadius: 4,
            padding: 2,
          }}
        >
          <Box sx={{ width: "40px", mr: 1 }}>
            <InfoOutlined color="primary" fontSize="large" />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              The questions were answerd incorrectly
            </Typography>
            <Typography>
              please tell the student to check thier answers
            </Typography>
            {/* <Box sx={{ display: "flex", mt: 2 }}>
              <Typography sx={{ fontWeight: "bold", mr: 2, cursor: "pointer" }}>
                Dismiss
              </Typography>
              <Typography
                sx={{ color: "purple", fontWeight: "bold", cursor: "pointer" }}
              >
                Learn more
              </Typography>
            </Box> */}
          </Box>
        </Box>
        {/* <Typography sx={{ mt: 2 }}>Error3</Typography>
        <Typography sx={{ mt: 2 }}>There has been an error</Typography> */}
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => navigate("/ap/students/search")}
        >
          Search another student
        </Button>
      </Box>
    );
  };

  const currentUser = useCurrentUser();
  const { id } = useParams();

  const fetchSecurityQuestions = async () => {
    setIsLoading(true);
    try {
      const response: IApiResponse<ResponseType> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "NSFASAPIconfig",
          requestName: "RetrieveAnswers",
          inputParamters: {
            IdNumber: studentId,
          },
        }
      );

      setIsLoading(false);
      if (!response.isSuccess) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: "#F88A0B",
          text: response.clientMessage,
        }).then(result => {
          if (result.isConfirmed) {
            navigate("/ap/students/search");
          }
        });
        return;
      }

      // showSuccessAlert("Student invited successfully");
      setQuestions(response.outputParameters.randomQuestions.response);
      const filteredAnswers =
        response.outputParameters.randomQuestions.response.map(question => {
          return {
            questionId: question.questionId,
            answer: "",
          };
        });
      setQuestionAnswers(filteredAnswers);
      setSecurityId(
        response.outputParameters.randomQuestions.securityQuestionId
      );
    } catch (e) {
      setIsLoading(false);

      Swal.fire({
        icon: "error",
        confirmButtonColor: "#F88A0B",
        text: "An error occurred while retriving security questions",
      }).then(result => {
        if (result.isConfirmed) {
          navigate("/ap/students/search");
        }
      });
    }
  };

  const confirmAnswers = async () => {
    setIsLoading(true);

    try {
      const response: IApiResponse<{ status: boolean }> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "SecurityRequest",
          requestName: "ConfirmAnswers",
          inputParamters: {
            confirm: {
              securityQuestionId: securityId,
              answers: questionAnswers,
            },
          },
        }
      );

      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }

      setIsLoading(false);
      if (response.outputParameters.status) {
        setIsAnswersCorrect(true);
      }
      if (!response.outputParameters.status) {
        setIsAnswersCorrect(false);
      }
    } catch (e) {
      setIsLoading(false);

      showErrorAlert("An error occurred while confirming asnwers");
      console.error(e);
    }
  };

  useEffect(() => {
    setIsAnswersCorrect(null);

    fetchSecurityQuestions();
  }, []);

  const updateStudentAnswers = (questionId: number, answer: string) => {
    setQuestionAnswers(
      questionAnswers.map(question =>
        question.questionId === questionId ? { ...question, answer } : question
      )
    );
  };

  useEffect(() => {
    if (isAnswersCorrect) {
      navigate(
        `/ap/students/${studentId}/otp?number=${studentNumber}&email=${studentEmail}&employee-id=${employeeId}`
      );
    }
  }, [isAnswersCorrect]);

  return (
    <DashboardPageLayout background="primary">
      <ContentPage
        title="Security Questions"
        subtitle="Please ask the student these questions, this is to ensure that they are the correct person."
        padding="lg"
        breadcrumbData={BREADCRUMB_DATA}
      >
        {isLoading && (
          <Box
            sx={{
              p: 4,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {isAnswersCorrect === false && <IncorrectAnwers />}

        {questions.length > 0 &&
          questionStep < 3 &&
          isAnswersCorrect === null && (
            <Box>
              <Box>
                {/* <Typography sx={{ fontSize: "30px", mb: 1 }}>
                Name of home town
              </Typography> */}
                <Typography sx={{ mb: 6, fontSize: "25px" }}>
                  {questions[questionStep].question}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "wrap",
                  gap: 3,
                  mb: 5,
                }}
              >
                {questions[questionStep].possibleAnswers.map(
                  (answer: string, index: number) => {
                    const chosenAnswer = questionAnswers.find(
                      qa => qa.questionId === questions[questionStep].questionId
                    )?.answer;

                    return (
                      <Box
                        key={index}
                        sx={{
                          border: "2px solid #ebe4e4",
                          paddingY: 1,
                          paddingX: 2.5,
                          borderRadius: 2,
                          bgcolor: chosenAnswer === answer ? "#F88A0B" : "#fff",
                          color: chosenAnswer === answer ? "#fff" : "#000",
                          cursor: "pointer",
                          fontWeight: "bold",
                          "&:hover": {
                            bgcolor: "#F88A0B",
                            color: "#fff",
                          },
                        }}
                        onClick={() => {
                          updateStudentAnswers(
                            questions[questionStep].questionId,
                            answer
                          );
                        }}
                      >
                        {answer}
                      </Box>
                    );
                  }
                )}
              </Box>
              {questionAnswers[questionStep].answer != "" && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setQuestionStep(prev => prev + 1);
                    if (questionStep === 2) {
                      //confirm answers
                      confirmAnswers();
                    }
                  }}
                >
                  Proceed
                </Button>
              )}
            </Box>
          )}
      </ContentPage>
    </DashboardPageLayout>
  );
}
