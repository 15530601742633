import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { StateExtended } from "../interfaces/StateExtended";

export function useAuthStatus() {
  const user = useSelector((state: StateExtended) => state.user);
  const authChecker = () => {
    return user && user.isAuthenticated && user.user ? true : false;
  };
  const [isAuthenticated, setAuthenticated] = useState(authChecker());

  useEffect(() => {
    setAuthenticated(authChecker());
  }, [user]);

  return isAuthenticated;
}

export function useChangePasswordHook() {
  const user = useSelector((state: StateExtended) => state.user);

  const authChecker = () => {
    return user && user.isExpired == true && user.user ? true : false;
  };
  const [isAuthenticated, setAuthenticated] = useState(authChecker());

  useEffect(() => {
    setAuthenticated(authChecker());
  }, [user]);

  return isAuthenticated;
}
