import Paper from "@mui/material/Paper";
import React from "react";
import { ThemeColors } from "../../../styles/theme";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import noApplicationImg from "../../../../../images/empty-2.jpg";

function NoItemsPaper() {
  return (
    <Paper
      sx={{
        background: ThemeColors.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 6,
        py: 2,
      }}
    >
      <Typography sx={{ fontWeight: "600" }}>No items</Typography>
      <Box sx={{ width: { xs: 240, md: 480 } }}>
        <img
          src={noApplicationImg}
          alt="no-applications-image"
          width={"100%"}
        />
      </Box>
    </Paper>
  );
}

export default NoItemsPaper;
