import React, { useState } from "react";
import SearchAndFiltersBar, {
  TListingFilter,
  TSelectedFilters,
} from "./SearchAndFiltersBar";
import SearchedTextBanner from "./SearchedTextBanner";

const getInitialFilters = (filters: TListingFilter[]): TSelectedFilters => {
  return filters.map(filter => ({
    filterNameValue: filter.value,
    value: filter.initialValue,
  }));
};

function useSearchAndFiltersBar({
  onSearch,
  filters = [],
}: {
  filters?: TListingFilter[];
  onSearch: (searchInput: string) => void;
}) {
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchedText, setSearchedText] = useState<string>("");
  const [activeFilters, setActiveFilters] = useState<TSelectedFilters>(
    getInitialFilters(filters)
  );

  const searchAndFiltersBarComponent = (
    <SearchAndFiltersBar
      activeFilters={getInitialFilters(filters)}
      value={searchInput}
      setSearchInput={setSearchInput}
      filters={filters}
      onFiltersChange={_ => setActiveFilters(_)}
      onSearchClick={() => {
        onSearch(searchInput);
        setSearchedText(searchInput);
      }}
    />
  );
  const searchedTextBannerComponent = searchedText && (
    <SearchedTextBanner
      onClearSearch={() => {
        setSearchedText("");
        setSearchInput("");
        onSearch("");
      }}
      searchedText={searchedText}
    />
  );

  return {
    searchAndFiltersBarComponent,
    searchedTextBannerComponent,
    searchedText,
    activeFilters,
  };
}

export default useSearchAndFiltersBar;
