import React, { useState, useEffect } from "react";
import Header from "../components/header";
import { Box, Paper, Typography } from "@mui/material";
import { RequiredAsterisk } from "../components/blocks/requiredAsterisk";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  GENDER_OPTIONS,
  STUDENT_STATUS_OPTIONS,
  ID_TYPE,
  ETHNICITY_OPTIONS,
  NATIONALITY_OPTIONS,
  genderOptions,
  DISABLED_STATUS_OPTIONS,
} from "../constants";
import { Button, Form } from "react-bootstrap";
import { DevTool } from "@hookform/devtools";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import StudentDetailsForm from "./student/components/registration/studentDetailsForm";
import Breadcrumbs from "./student/components/breadcrumbs";

interface InstitutionType {
  InstitutionId: string;
  InstitutionIdName: string;
}
interface StudentDetails {
  Address: string;
  CampusId: string;
  CampusIdName: string;
  CampusIdName1: string;
  DateOfBirth: string;
  DisabilityText: string;
  DisabilityValue: number;
  Email: string;
  EmployeeId: string;
  EthnicityText: null;
  EthnicityValue: null;
  FirstName: string;
  GenderText: string;
  GenderValue: number;
  IdNumber: string;
  IdTypeText: string;
  IdTypeValue: number;
  LastName: string;
  Mobile: string;
  Name: string;
  NationalityId: string;
  NationalityIdName: string;
  StudentNumber: string;
  StudentStatusText: string;
  StudentStatusValue: any;
  Telephone: string;
  sendInvite: boolean;
}

const StudentViewAction = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const [institutions, setInstitutions] = useState<any>(null);
  const [campuses, setCampuses] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const [studentDetails, setStudentDetails] = useState<StudentDetails[]>([]);

  const institution = watch("institution");
  const campus = watch("campus");

  const commonApi = useCommonApi();

  const [myDate, setMyDate] = useState<any>();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const { showLoader, hideLoader } = useAppLoader();
  const [enabled, setEnabled] = useState(false);

  const formatDate = () => {
    studentDetails.map(details => {
      const date = details.DateOfBirth;
      // console.log("😴",date);
      const dateObj = new Date(date);
      // console.log("🧒",moment().add(10, 'days').calendar());
      // console.log("🧒",moment(date).format('YYYY/MM/DD'));
      setMyDate(moment(date).format("MM/DD/YYYY"));

      // Assuming studentDetail.DateOfBirth is a string like "2000-02-11T00:00:00"
      setDateOfBirth(moment(details.DateOfBirth).format("YYYY-MM-DD"));
    });
  };

  const handleDateChange = (e: any) => {
    setDateOfBirth(e.target.value);
  };

  const getStudentDetails = () => {
    commonApi.getStudentDetails(
      {
        StudentId: id,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setStudentDetails(resp);

          // setStudentList(resp);
          // console.log("my students list",studentList);
        }
      },
      (message: string) => {}
    );
  };

  const getInstitutions = () => {
    commonApi.retreiveInstitution(
      {},
      (message: string, resp: any) => {
        const options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search("000000") !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        });
        setInstitutions(options);
      },
      (message: string) => {
        // console.log(message);
      }
    );
  };

  const getCampus = (id: any = institution?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCampus(
        {
          filters: "InstitutionId eq '" + id + "'",
          ViewName: "LookUpView",
        },
        (message: string, resp: any) => {
          const options: any = [];
          resp.map((ins: any) => {
            if (ins.recordId.search("000000") !== 0) {
              ins.value = ins.recordId;
              ins.label = ins.mainText;
              options.push(ins);
            }
          });
          setCampuses(options);
          setTimeout(() => {
            resolve();
          }, 300);
        },
        (message: string) => {
          // console.log(message);
          reject();
        }
      );
    });
  };

  useEffect(() => {
    formatDate();
  }, [studentDetails]);

  useEffect(() => {
    getInstitutions();
    getStudentDetails();
  }, []);
  useEffect(() => {
    if (institution) {
      getCampus();
    }
  }, [institution]);

  //
  const [details, setDetails] = useState<any>();

  const onSubmit = (data: any) => {
    commonApi.post(
      {
        entityName: "Employee",
        requestName: "UpsertRecordReq",
        recordId: id,
        inputParamters: {
          Entity: {
            Address: data.Address,
            FirstName: data.firstName,
            LastName: data.LastName,
            Email: data.Email,
            Mobile: data.Mobile,
            Name: data.FullName,
            StudentStatus: data.studentStatus.value,
            CampusId: data.Campus.value,
            NationalId: data.nationalId,
            DateOfBirth: data.dateOfBirth,
            sendInvite: data.invite,
            CompanyId: data.institution.value,
            GenderId: data.gender.value,
            DisabledId: data.disability.value,
            EthnicityId: data.ethnicity.value,
            EmployeeNo: data.StudentNumber,
            IDType: data.idType.value,
            NationalityId: data.nationality.value,
            Telephone: data.Telephone,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        const payload = {
          entityName: "Employee",
          requestName: "UpsertRecordReq",
          recordId: id, // Replace with the actual recordId
          inputParamters: {
            Entity: data, // Include the form data in the Entity object
          },
        };

        // Log the final payload before sending the API request

        toast.success(message);
      },
      (message: string) => {
        toast.error(message);
        hideLoader();
      }
    );
  };

  const handleRegistration = (data: any, event: any) => {
    event.preventDefault();
  };
  const renderErrorMessage = (name: string) => {
    const { message } = errors[name] || {};
    return (message as string) || null;
  };

  return (
    <div className="">
      <Header />
      <Box sx={{ marginTop: "6rem", paddingLeft: "2rem" }}>
        <Breadcrumbs
          data={[
            { label: "Home", path: "/" },
            { label: "Student List", path: "/student-list" },
            { label: "Student Action" },
          ]}
        />
      </Box>
      {studentDetails.map((studentDetail: StudentDetails) => {
        return (
          <>
            <form
              onSubmit={handleSubmit(onSubmit, () => {
                console.log("invalid");
              })}
            >
              <Box
                sx={{
                  marginTop: "4rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "50px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    {studentDetail.Name}
                  </Typography>
                </Box>
                <Paper sx={{ padding: "2rem" }}>
                  <Box sx={{ marginBottom: "3rem" }}>
                    <Typography>Student Details</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Full Name
                        <RequiredAsterisk />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1a"
                        placeholder="Full Name"
                        style={{ width: "60%" }}
                        {...register("FullName")}
                        defaultValue={studentDetail.Name}
                        disabled={enabled ? false : true}
                      />
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                        style={{ textAlign: "left" }}
                      >
                        Student Number
                        <RequiredAsterisk />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1a"
                        placeholder="Student Number"
                        style={{ width: "60%", textAlign: "left" }}
                        {...register("StudentNumber")}
                        // defaultValue={studentDetail.StudentNumber}
                        disabled={enabled ? false : true}
                        value={watch("StudentNumber") || ""}
                        onChange={e =>
                          setValue("StudentNumber", e.target.value)
                        }
                        // disabled={!enabled}
                      />
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                        style={{ textAlign: "left" }}
                      >
                        First Name
                        <RequiredAsterisk />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1a"
                        placeholder="First Name"
                        style={{ width: "60%" }}
                        {...register("firstName")}
                        defaultValue={studentDetail.FirstName}
                        disabled={enabled ? false : true}
                      />
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                        style={{ textAlign: "left" }}
                      >
                        Student Status
                        <RequiredAsterisk />
                      </label>
                      <div className="" style={{ width: "60%" }}>
                        <Controller
                          name={"studentStatus"}
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              // <Select
                              //   className={
                              //     "form-control p-0" +
                              //     (errors?.institution ? " is-invalid" : "")
                              //   }
                              //   options={STUDENT_STATUS_OPTIONS}
                              //   placeholder={"Choose Student Status"}
                              //   value={value}
                              //   defaultValue={value}
                              //   onChange={onChange}
                              //   defaultInputValue={studentDetail.StudentStatusText}
                              //   // {...register('studentStatus')}
                              //   // name={"StudentStatusText"}
                              //   isDisabled={ enabled ? false : true}

                              // />
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.institution ? " is-invalid" : "")
                                }
                                options={STUDENT_STATUS_OPTIONS}
                                placeholder={"Choose Student Status"}
                                value={STUDENT_STATUS_OPTIONS.find(
                                  option => option.value === value
                                )}
                                onChange={selectedOption =>
                                  setValue(
                                    "studentStatus",
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                                isDisabled={!enabled}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Last Name
                        <RequiredAsterisk />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1a"
                        placeholder="Last Name"
                        style={{ width: "60%" }}
                        {...register("LastName")}
                        defaultValue={studentDetail.LastName}
                        disabled={enabled ? false : true}
                      />
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label"}
                      >
                        Nationality
                        <RequiredAsterisk />
                      </label>
                      <div className="" style={{ width: "60%" }}>
                        {/* <Select
                                        options={NATIONALITY_OPTIONS}
                                        placeholder={"Choose Option"}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={value}
                                    /> */}
                        <Controller
                          name={"nationality"}
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.institution ? " is-invalid" : "")
                                }
                                options={NATIONALITY_OPTIONS}
                                placeholder={"choose Option"}
                                value={value}
                                // defaultValue={studentDetails.n}
                                onChange={onChange}
                                defaultInputValue={
                                  studentDetail.NationalityIdName
                                }
                                name={"Nationality"}
                                isDisabled={enabled ? false : true}

                                // {...register('institution', { required: true } )}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Ethinicity
                        <RequiredAsterisk />
                      </label>
                      <div className="" style={{ width: "60%" }}>
                        <Controller
                          name={"ethnicity"}
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.institution ? " is-invalid" : "")
                                }
                                options={ETHNICITY_OPTIONS}
                                placeholder={"Choose Option"}
                                value={value}
                                defaultValue={value}
                                onChange={onChange}
                                name={"ethinicity"}
                                // defaultInputValue={studentDetail?.EthnicityValue}
                                isDisabled={enabled ? false : true}

                                // {...register('institution', { required: true } )}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        ID Type
                        <RequiredAsterisk />
                      </label>
                      {/* <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="90 Bekker Road" style={{width:'60%'}}  /> */}
                      <div className="" style={{ width: "60%" }}>
                        <Controller
                          name={"idType"}
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.institution ? " is-invalid" : "")
                                }
                                options={ID_TYPE}
                                placeholder={"choose Option"}
                                value={value}
                                defaultValue={value}
                                onChange={onChange}
                                // {...register('studentStatus')}
                                defaultInputValue={studentDetail.IdTypeText}
                                isDisabled={enabled ? false : true}

                                // {...register('institution', { required: true } )}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Disabled
                        <RequiredAsterisk />
                      </label>
                      <div className="" style={{ width: "60%" }}>
                        <Controller
                          name={"disability"}
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.institution ? " is-invalid" : "")
                                }
                                options={DISABLED_STATUS_OPTIONS}
                                placeholder={"choose Option"}
                                // value={value}
                                defaultValue={value}
                                onChange={onChange}
                                // {...register('disability')}
                                // defaultValue={studentDetails.n}
                                defaultInputValue={studentDetail.DisabilityText}
                                isDisabled={enabled ? false : true}

                                // {...register('institution', { required: true } )}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        National ID
                        <RequiredAsterisk />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1a"
                        placeholder="National ID"
                        style={{ width: "60%" }}
                        {...register("nationalId")}
                        defaultValue={studentDetail.IdNumber}
                        disabled={enabled ? false : true}
                      />
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Gender
                        <RequiredAsterisk />
                      </label>
                      <div className="" style={{ width: "60%" }}>
                        <Controller
                          name={"gender"}
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.institution ? " is-invalid" : "")
                                }
                                options={genderOptions}
                                placeholder={"choose Option"}
                                value={value}
                                defaultValue={value}
                                onChange={onChange}
                                // {...register('studentStatus')}
                                defaultInputValue={studentDetail.GenderText}
                                isDisabled={enabled ? false : true}

                                // {...register('institution', { required: true } )}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Date Of Birth
                      </label>
                      {/* <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="90 Bekker Road" style={{width:'60%'}}  /> */}
                      <div className="" style={{ width: "60%" }}>
                        <Form.Control
                          type="date"
                          id="inputPassword5"
                          aria-describedby="passwordHelpBlock"
                          // onChange={handleDateChange}

                          {...register("dateOfBirth")}
                          // defaultValue={moment(studentDetail.DateOfBirth).format('YYYY/MM/DD')}
                          // defaultValue={myDate}

                          defaultValue={dateOfBirth}
                          disabled={enabled ? false : true}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box sx={{}}>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Send Invite
                      </label>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        // label="Send Invite"
                        style={{ paddingLeft: "5.5em" }}
                        {...register("invite")}
                      />
                    </div>
                  </Box>
                </Paper>

                {/* **************************** Contact Information ********************************** */}

                <Paper sx={{ padding: "2rem", marginTop: "2rem" }}>
                  <Box sx={{ marginBottom: "3rem" }}>
                    <Typography>Contact Information</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Email
                        <RequiredAsterisk />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1a"
                        placeholder="Email"
                        style={{ width: "60%" }}
                        {...register("Email")}
                        defaultValue={studentDetail.Email}
                        disabled={enabled ? false : true}
                      />
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                        style={{ textAlign: "left" }}
                      >
                        Mobile
                        <RequiredAsterisk />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1a"
                        placeholder="Mobile No."
                        style={{ width: "60%", textAlign: "left" }}
                        {...register("Mobile")}
                        defaultValue={studentDetail.Mobile}
                        disabled={enabled ? false : true}
                      />
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                        style={{ textAlign: "left" }}
                      >
                        Telephone
                        <RequiredAsterisk />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1a"
                        placeholder="Tel No."
                        style={{ width: "60%" }}
                        {...register("Telephone")}
                        defaultValue={studentDetail.Telephone}
                        disabled={enabled ? false : true}
                      />
                    </div>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                        style={{ textAlign: "left" }}
                      >
                        Home Address
                        <RequiredAsterisk />
                      </label>
                      {/* <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="90 Bekker Road" style={{width:'60%'}}  /> */}
                      <div className="" style={{ width: "60%" }}>
                        <textarea
                          placeholder={"Home Address"}
                          style={{ width: "410px", height: "80px" }}
                          {...register("Address")}
                          defaultValue={studentDetail.Address}
                          disabled={enabled ? false : true}
                        />
                      </div>
                    </div>
                  </Box>
                </Paper>

                {/* ************************************ Course Information ********************************/}

                <Paper sx={{ padding: "2rem", marginTop: "2rem" }}>
                  <Box sx={{ marginBottom: "3rem" }}>
                    <Typography>Course Information</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Institution
                        <RequiredAsterisk />
                      </label>
                      <div className="" style={{ width: "80%" }}>
                        <Controller
                          name={"institution"}
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.institution ? " is-invalid" : "")
                                }
                                options={institutions}
                                placeholder={"Choose Institution"}
                                value={value}
                                defaultValue={value}
                                onChange={onChange}
                                // {...register('institution', { required: true } )}
                                defaultInputValue={studentDetail.CampusIdName1}
                                isDisabled={enabled ? false : true}
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                      flexDirection: { xs: "column", md: "row" },
                      width: { xs: "50%", md: "100%" },
                      marginLeft: { xs: "auto" },
                      marginRight: { xs: "auto" },
                    }}
                  >
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Campus
                      </label>
                      <div className="" style={{ width: "80%" }}>
                        <Controller
                          name={"Campus"}
                          control={control}
                          // rules={{ required: 'Campus Required'}}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.campus ? " is-invalid" : "")
                                }
                                options={campuses}
                                placeholder={"Choose Campus"}
                                onChange={onChange}
                                value={value}
                                // defaultValue={studentDetail.CampusIdName1}
                                defaultInputValue={studentDetail.CampusIdName1}
                                isDisabled={enabled ? false : true}

                                // {...register('Campus')}
                              />
                            );
                          }}
                        />
                        {renderErrorMessage("campus")}
                      </div>
                    </div>
                  </Box>
                </Paper>

                <Box
                  sx={{
                    marginTop: "2rem",
                    marginBottom: "2rem",
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="primary"
                    style={{ width: "10%" }}
                    type="submit"
                    disabled={enabled ? false : true}
                  >
                    Save
                  </Button>
                  <Button
                    variant="danger"
                    style={{ width: "10%" }}
                    onClick={() => {
                      setEnabled(!enabled);
                    }}
                  >
                    {enabled ? "Stop Editing" : "Edit"}
                  </Button>
                </Box>
              </Box>
            </form>
          </>
        );
      })}

      <DevTool control={control} />
    </div>
  );
};

export default StudentViewAction;
