import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { RequiredAsterisk } from "../blocks/requiredAsterisk";
import {
  DIGITS_ONLY_PATTERN,
  INPUT_MAX_LENGTHS,
  PASSWORD_VALIDATION_REGEX,
} from "../../_config";
import { InputField } from "../InputField";
import { useNavigate } from "react-router-dom";

interface incomingProps {
  mobileNumber: string;
  email: string;
  onSubmitEntityProviderForm: any;
}

export default function EntityProviderForm({
  onSubmitEntityProviderForm,
  email,
  mobileNumber,
}: incomingProps) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    if (data.Password === data.confPassword) {
      const params: any = {
        ...data,
        ...{
          APTypeId: "865",
          IDTypeId: "862",
        },
        Email: email,
        Mobile: mobileNumber,
      };
      onSubmitEntityProviderForm(params);
    } else {
      toast.error("password and confirm password didnot match.");
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput5"
              aria-disabled
              className={"form-label" + (errors?.Mobile ? " is-invalid" : "")}
            >
              Mobile
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              value={mobileNumber}
              className="form-control"
              id="exampleFormControlInput5"
              placeholder="0780001111"
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput_email"
              className={"form-label" + (errors?.Email ? " is-invalid" : "")}
            >
              Email
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput_email"
              placeholder="support@xiquelgroup.com"
              value={email}
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1"
              className={
                "form-label" + (errors?.TradingName ? " is-invalid" : "")
              }
            >
              Trading Name
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Mbombela Properties"
              {...register("TradingName", {
                required: true,
                maxLength: INPUT_MAX_LENGTHS.tradingName,
              })}
            />
            {errors?.TradingName ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput2"
              className={
                "form-label" + (errors?.RegistrationName ? " is-invalid" : "")
              }
            >
              Registered Name
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput2"
              placeholder="Mbombela Properties (Pty) Ltd"
              {...register("RegistrationName", {
                required: true,
                maxLength: INPUT_MAX_LENGTHS.registeredName,
              })}
            />
            {errors?.RegistrationName ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          {/* <div className="mb-2">
                        <label htmlFor="exampleFormControlInput3" className={"form-label" + (errors?.RegistrationNumber ? ' is-invalid' : '')}>Registred number</label>
                        <input type="text" className="form-control" id="exampleFormControlInput3" placeholder="" {...register("RegistrationNumber", { required: true, maxLength: 80 })} />
                        {errors?.RegistrationNumber ? <div className="invalid-feedback">Required</div> : null}
                    </div> */}

          <div className="mb-2">
            <InputField
              label="Registration Number"
              id="IDNumber"
              errors={errors}
              register={register}
              required
              placeholder="00/00000/00"
              registerParams={{
                maxLength: {
                  value: INPUT_MAX_LENGTHS.registrationNumber,
                  message: `Must be at most ${INPUT_MAX_LENGTHS.registrationNumber} characters`,
                },
                /*pattern: {
                                value: DIGITS_ONLY_PATTERN,
                                message: 'Numbers only',
                                },*/
              }}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="exampleFormControlInput2" className={"form-label"}>
              VAT Number
            </label>
            <input
              type="text"
              className="form-control"
              id="VATNumber"
              placeholder="412345678"
              {...register("VATNumber", {
                required: false,
                maxLength: INPUT_MAX_LENGTHS.vatNumber,
              })}
            />
            {errors?.vatNumber ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          {/*<div className="mb-2">
                        <InputField
                            label="VAT Number"
                            id="VATNumber"
                            errors={errors}
                            register={register} 
                            placeholder="412345678"
                            registerParams={{
                                maxLength: {
                                value: INPUT_MAX_LENGTHS.vatNumber,
                                message: `Must be at most ${INPUT_MAX_LENGTHS.vatNumber} characters`,
                                },
                                pattern: {
                                value: DIGITS_ONLY_PATTERN,
                                message: 'Numbers only',
                                },
                            }}
                            />
                    </div>*/}
          {/*<div className="mb-2">
                        <InputField
                            type="tel"
                            label="Telephone"
                            id="Telephone"
                            errors={errors}
                            register={register} 
                            placeholder="0104472222"
                            registerParams={{
                                maxLength: {
                                value: INPUT_MAX_LENGTHS.telephone,
                                message: `Must be at most ${INPUT_MAX_LENGTHS.telephone} characters`,
                                },
                                pattern: {
                                value: DIGITS_ONLY_PATTERN,
                                message: 'Numbers only',
                                },
                            }}
                            />
                        </div>*/}

          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput7"
              className={"form-label " + (errors?.Password ? "is-invalid" : "")}
            >
              Password
              <RequiredAsterisk />
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="exampleFormControlInput7"
              placeholder="********"
              {...register("Password", {
                required: true,
                minLength: 6,
                maxLength: 80,
                pattern: {
                  value: PASSWORD_VALIDATION_REGEX,
                  message:
                    "Password must contain at least 1 alphanumeric and 1 special character",
                },
              })}
            />
            {errors?.Password ? (
              <div className="invalid-feedback">
                {errors.Password.type === "required" ? "Required" : null}
                {errors.Password.type === "minLength"
                  ? "Password must be at least 6 characters"
                  : null}
              </div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1"
              className={
                "form-label" + (errors?.confPassword ? " is-invalid" : "")
              }
            >
              Confirm Password
              <RequiredAsterisk />
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="********"
              {...register("confPassword", {
                required: true,
                minLength: 6,
                maxLength: 80,
                validate: text =>
                  getValues("Password") === text || "Passwords do not match",
              })}
            />
            {errors?.confPassword ? (
              <div className="invalid-feedback">
                {errors.confPassword.type === "required" ? "Required" : null}
                {errors.confPassword.type === "minLength"
                  ? "Password must be at least 6 characters"
                  : null}
                {errors.confPassword?.message && (
                  <span>{errors.confPassword?.message as string}</span>
                )}
              </div>
            ) : null}
          </div>
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="showPasswordCheckbox"
              onChange={togglePasswordVisibility}
              checked={showPassword}
            />
            <label className="form-check-label" htmlFor="showPasswordCheckbox">
              Show password
            </label>
          </div>
        </div>

        <Row className="align-items-center">
          {" "}
          <Col xs={12}>
            <small>
              <sup>Fields marked with asterik (*) are required</sup>
            </small>
          </Col>
          <Col md={5}>
            <div className="" style={{ display: "flex", gap: "1rem" }}>
              <button
                className="btn btn-primary w-50  mt-2"
                type="submit"
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Next
              </button>
              <button
                onClick={() => {
                  navigate("/entry-type");
                }}
                className="btn btn-danger w-50  mt-2"
                type="submit"
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Cancel
              </button>
            </div>
          </Col>
          {/* <Col md={6} className="text-end">
                        <a href="/">Sign in</a>
                    </Col> */}
        </Row>
      </form>
    </React.Fragment>
  );
}
