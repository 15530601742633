import { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import "../../../styles/student/components/accomodationSearch.scss";

interface incomingProps {
  paramName?: any;
  handleSearch: any;
}

export default function FindAccomodation({
  paramName,
  handleSearch,
}: incomingProps) {
  const search: any = useRef();
  useEffect(() => {
    search.current.addEventListener("search", () => {
      handleSearch(search.current.value);
    });
  }, []);
  return (
    <div className="accomodation-search">
      <p className="find-accomodation mb-4" style={{ width: "100%" }}>
        Find accommodation nearby
      </p>
      <div className="search-container mt-5" style={{ marginTop: "2rem" }}>
        <input
          type=""
          ref={search}
          className="search-label"
          onKeyDown={e => {
            if (e.key === "Enter") {
              handleSearch(search.current.value);
            }
          }}
        />
        <Button
          onClick={() => {
            handleSearch(search.current.value);
          }}
          variant="dark"
          className="search-btn"
        >
          Search
        </Button>
      </div>
    </div>
  );
}
