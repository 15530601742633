import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { RequiredAsterisk } from "../blocks/requiredAsterisk";
import {
  DIGITS_ONLY_PATTERN,
  INPUT_MAX_LENGTHS,
  PASSWORD_VALIDATION_REGEX,
} from "../../_config";
import { InputField } from "../InputField";
import { useNavigate } from "react-router-dom";
interface incomingProps {
  mobileNumber: string;
  email: string;
  onSubmitIndividualProviderForm: any;
}

export default function IndividualProviderForm({
  onSubmitIndividualProviderForm,
  email,
  mobileNumber,
}: incomingProps) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    const params: any = {
      ...data,
      ...{
        APTypeId: "864",
        IDTypeId: "862",
      },
      Email: email,
      Mobile: mobileNumber,
    };

    onSubmitIndividualProviderForm(params);
    // } else {
    //   toast.error('password and confirm password didnot match.');
    // }
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const [idNumber, setIdNumber] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setIdNumber(inputValue);

    if (inputValue.length < 13) {
      setErrorMessage("Input must be at least 13 characters long.");
    } else {
      setErrorMessage("");
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1"
              className={"form-label " + errors.Mobile ? "is-invalid" : ""}
            >
              Mobile
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput5"
              placeholder="0780001111"
              defaultValue={mobileNumber}
              {...register("Mobile")}
            />
            {errors?.Mobile ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>

          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput7"
              className={"form-label " + errors.Email ? "is-invalid" : ""}
            >
              Email
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput7"
              placeholder="youremail@yourdomain.com"
              value={email}
              {...register("Email")}
            />
          </div>

          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1"
              className={"form-label " + errors.FirstName ? "is-invalid" : ""}
            >
              First name
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="John"
              {...register("FirstName", { required: true, maxLength: 80 })}
            />
            {errors?.FirstName ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput2"
              className={"form-label " + errors.LastName ? "is-invalid" : ""}
            >
              Last name
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput2"
              placeholder="Doe"
              {...register("LastName", { required: true, maxLength: 80 })}
            />
            {errors?.LastName ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput3"
              className={"form-label " + errors.IDNumber ? "is-invalid" : ""}
            >
              ID number
              <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput3"
              placeholder="123456789012"
              maxLength={13}
              minLength={13}
              {...register("IDNumber", { required: true, maxLength: 13 })}
              onChange={handleChange}
            />
            <p style={{ color: "#CF1010", fontSize: "12px", margin: "0" }}>
              {errorMessage}
            </p>

            {errors?.IDNumber ? (
              <div className="invalid-feedback">Required</div>
            ) : null}
          </div>

          {/*<div className="mb-2">
            <InputField
              type="tel"
              label="Telephone"
              id="Telephone"
              errors={errors}
              register={register}
              placeholder="0104472222"
              registerParams={{
                maxLength: {
                  value: INPUT_MAX_LENGTHS.telephone,
                  message: `Must be at most ${INPUT_MAX_LENGTHS.telephone} characters`,
                },
                pattern: {
                  value: DIGITS_ONLY_PATTERN,
                  message: 'Numbers only',
                },
              }}
            />
            </div>*/}
          {/* <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput7"
              className={'form-label ' + errors.Password ? 'is-invalid' : ''}
            >
              Password
              <RequiredAsterisk />
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleFormControlInput7"
              placeholder="********"
              {...register('Password', {
                required: true,
                minLength: 6,
                maxLength: 80,
                pattern: {
                  value: PASSWORD_VALIDATION_REGEX,
                  message:
                    'Password must contain at least 1 alphanumeric and 1 special character',
                },
              })}
            />
            {errors?.Password ? (
              <div className="invalid-feedback">
                {errors.Password.type === 'required' ? 'Required' : null}
                {errors.Password.type === 'minLength'
                  ? 'Password must be at least 6 characters'
                  : null}
              </div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1"
              className={
                'form-label' + (errors?.confPassword ? ' is-invalid' : '')
              }
            >
              Confirm Password
              <RequiredAsterisk />
            </label>
            <input
              type="password"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="********"
              {...register('confPassword', {
                required: true,
                minLength: 6,
                maxLength: 80,
                validate: (text) =>
                  getValues('Password') === text || 'Passwords do not match',
              })}
            />
            {errors?.confPassword ? (
              <div className="invalid-feedback">
                {errors.confPassword.type === 'required' ? 'Required' : null}
                {errors.confPassword.type === 'minLength'
                  ? 'Password must be at least 6 characters'
                  : null}
                {errors.confPassword?.message && (
                  <span>{errors.confPassword?.message as string}</span>
                )}
              </div>
            ) : null}
          </div> */}
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput7"
              className={"form-label " + (errors?.Password ? "is-invalid" : "")}
            >
              Password
              <RequiredAsterisk />
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="exampleFormControlInput7"
              placeholder="********"
              {...register("Password", {
                required: true,
                minLength: 6,
                maxLength: 80,
                pattern: {
                  value: PASSWORD_VALIDATION_REGEX,
                  message:
                    "Password must contain at least 1 alphanumeric and 1 special character",
                },
              })}
            />
            {errors?.Password ? (
              <div className="invalid-feedback">
                {errors.Password.type === "required" ? "Required" : null}
                {errors.Password.type === "minLength"
                  ? "Password must be at least 6 characters"
                  : null}
              </div>
            ) : null}
          </div>
          <div className="mb-2">
            <label
              htmlFor="exampleFormControlInput1"
              className={
                "form-label" + (errors?.confPassword ? " is-invalid" : "")
              }
            >
              Confirm Password
              <RequiredAsterisk />
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="********"
              {...register("confPassword", {
                required: true,
                minLength: 6,
                maxLength: 80,
                validate: text =>
                  getValues("Password") === text || "Passwords do not match",
              })}
            />
            {errors?.confPassword ? (
              <div className="invalid-feedback">
                {errors.confPassword.type === "required" ? "Required" : null}
                {errors.confPassword.type === "minLength"
                  ? "Password must be at least 6 characters"
                  : null}
                {errors.confPassword?.message && (
                  <span>{errors.confPassword?.message as string}</span>
                )}
              </div>
            ) : null}
          </div>
          <div className="form-check mb-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="showPasswordCheckbox"
              onChange={togglePasswordVisibility}
              checked={showPassword}
            />
            <label className="form-check-label" htmlFor="showPasswordCheckbox">
              Show password
            </label>
          </div>
        </div>
        <Row className="align-items-center">
          <Col md={6}>
            <div className="" style={{ display: "flex", gap: "1rem" }}>
              <button
                className="btn btn-primary w-50 mt-2"
                type="submit"
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Next
              </button>
              <button
                onClick={() => {
                  navigate("/entry-type");
                }}
                className="btn btn-danger w-50  mt-2"
                type="submit"
                style={{
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Cancel
              </button>
            </div>
          </Col>
          {/* <Col md={6} className="text-end">
                      <a href="/">Sign in</a>
                    </Col> */}
        </Row>
      </form>
    </React.Fragment>
  );
}
