import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../components/header";
import "../styles/residence-details.scss";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { URLS } from "../_config";
import parse from "html-react-parser";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";
import toast from "react-hot-toast";
import Slider from "react-slick";
import Breadcrumbs from "./student/components/breadcrumbs";

const ResidenceDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const commonApi = useCommonApi();

  const [residenceDetails, setResidenceDetails] = useState<any>(null);

  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const thumbSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);

  const getResidenceDetails = () => {
    commonApi.post(
      {
        recordId: id,
        requestName: "RetrieveFacilityDetails",
      },
      (message: string, resp: any) => {
        if (
          resp.outputParameters &&
          resp.outputParameters.FacilitiyDetails.length > 0
        ) {
          setResidenceDetails(resp.outputParameters.FacilitiyDetails[0]);
        }
      },
      (message: string) => {}
    );
  };

  const editMe = (residence: any) => {
    if (
      residence?.facilityStatusId == 872 ||
      residence?.facilityStatusId == 834
    ) {
      sessionStorage.setItem("residenceID", residence.facilityId);
      sessionStorage.setItem("residenceName", residence?.Name);

      sessionStorage.setItem("regrade", "true");
      navigate("/add-residence");
    }
  };

  const checkImageBase64Code = (url: any) => {
    if (url.search("data:image/") == 0) {
      return url;
    } else {
      return "data:image/jpeg;base64," + url;
    }
  };

  const getRating = (rating: any, totalStar: any, outOf: any) => {
    if (rating) {
      const finalRating = (rating / outOf) * totalStar;
      const checkFraction = finalRating % 1 != 0;
      if (checkFraction == false) {
        const blankStar = totalStar - finalRating;
        const span: any = [];
        for (let i = 0; i < finalRating; i++) {
          span.push(
            <BsStarFill className="text-primary" key={Math.random()} />
          );
        }
        for (let i = 0; i < blankStar; i++) {
          span.push(<BsStar key={Math.random()} />);
        }
        return span;
      } else {
        let fullStar = Math.round(finalRating * 10) / 10;
        const halfStar = Number(fullStar.toString().split(".")[1]);
        fullStar = Number(finalRating.toString().split(".")[0]);
        const span: any = [];
        if (halfStar >= 5) {
          const blankStar = totalStar - fullStar - 1;
          for (let i = 0; i < fullStar; i++) {
            span.push(
              <BsStarFill className="text-primary" key={Math.random()} />
            );
          }
          span.push(<BsStarHalf className="text-primary" />);
          for (let i = 0; i < blankStar; i++) {
            span.push(<BsStar key={Math.random()} />);
          }
          // console.log(fullStar,halfStar, blankStar)
          return span;
        } else {
          const blankStar = totalStar - fullStar + 1;
          for (let i = 0; i < fullStar; i++) {
            span.push(
              <BsStarFill className="text-primary" key={Math.random()} />
            );
          }
          for (let i = 0; i < blankStar; i++) {
            span.push(<BsStar key={Math.random()} />);
          }
          // console.log(fullStar, blankStar)
          return span;
        }
      }
    }
  };

  const withdrawal = (residence: any) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.post(
        {
          entityName: "Facility",
          recordId: residence.facilityId,
          requestName: "UpsertRecordReq",
          inputParamters: {
            Entity: {
              FacilityStatusId: "872",
            },
          },
        },
        (message: string, resp: any) => {
          resolve(message);
        },
        (message: string) => {
          reject(message);
        }
      );
    });
  };

  const deleteResidence = (residence: any) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.post(
        {
          entityName: "Facility",
          recordId: residence.facilityId,
          requestName: "RemoveRecordReq",
        },
        (message: string, resp: any) => {
          resolve(message);
        },
        (message: string) => {
          reject(message);
        }
      );
    });
  };

  const withdrawalNotification = (residence: any) => {
    toast(
      t => (
        <span>
          <b>Please note!</b>
          <br />
          should you withdraw your property, your payment will not be refunded.
          <br />
          <hr />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger btn-sm m-1"
              onClick={() => {
                toast.dismiss(t.id);
                toast.promise(
                  withdrawal(residence),
                  {
                    loading: "working on request...",
                    success: (msg: any) => {
                      navigate(URLS.DASHBOARD);
                      return <b>{residence.name} withdrawal Successfully!</b>;
                    },
                    error: msg => {
                      return <b>{msg}</b>;
                    },
                  },
                  { duration: 6000 }
                );
              }}
            >
              Withdraw
            </button>

            <button
              className="btn btn-secondary btn-sm m-1"
              onClick={() => toast.dismiss(t.id)}
            >
              Cancel
            </button>
          </div>
        </span>
      ),
      { duration: 5000, className: "withdraw-toast" }
    );
  };

  const deleteNotification = (residence: any) => {
    toast(
      t => (
        <span>
          Are you sure you want to delete your property.
          <br />
          <hr />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger btn-sm m-1"
              onClick={() => {
                toast.dismiss(t.id);
                toast.promise(
                  deleteResidence(residence),
                  {
                    loading: "working on request...",
                    success: (msg: any) => {
                      navigate(URLS.DASHBOARD);
                      return (
                        <b>{residence.name} Property deleted successfully</b>
                      );
                    },
                    error: msg => {
                      return <b>{msg}</b>;
                    },
                  },
                  { duration: 6000 }
                );
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-secondary btn-sm m-1"
              onClick={() => toast.dismiss(t.id)}
            >
              No
            </button>
          </div>
        </span>
      ),
      { duration: 5000, className: "withdraw-toast" }
    );
  };

  useEffect(() => {
    if (id) {
      getResidenceDetails();
    }
  }, [id]);

  useEffect(() => {}, [residenceDetails]);

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <div className="container dashboard-view">
          <Breadcrumbs
            data={[
              { label: "Home", path: "/" },
              { label: "Residences", path: "/dashboard" },
              { label: "Residence Details" },
            ]}
          />
          <div className="white-block p-3 white-box-wide">
            <div className="open-case-main-wrapper my-residences-wrapper my-residences-details-wrapper">
              <div className="row">
                <div className="col">
                  <h5>{residenceDetails?.name}</h5>
                </div>
                <div className="col text-end">
                  <i
                    className="fa fa-chevron-circle-right yellow-arrow-right"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="open-case-block-wrapper d-flex">
                <div className="open-case-block">
                  <div className="open-case-pic my-residences-pic">
                    {residenceDetails?.facilityStatusId == 872 ? (
                      <a
                        onClick={() => {
                          editMe(residenceDetails);
                        }}
                        className="my-residences-edit"
                      >
                        Edit <i className="fa fa-pencil" aria-hidden="true"></i>
                      </a>
                    ) : null}
                    <div className="slider">
                      <Slider
                        {...settings}
                        asNavFor={nav2}
                        ref={(slider1: any) => setNav1(slider1)}
                      >
                        {residenceDetails?.images?.length > 0 ? (
                          residenceDetails?.images.map((image: any, i: any) => (
                            <div className="slide-item" key={i}>
                              <img
                                src={checkImageBase64Code(image.fileContent)}
                                alt=""
                              />
                            </div>
                          ))
                        ) : (
                          <div className="slide-item">
                            <img
                              src={"/assets/img/no-image-available.jpg"}
                              alt=""
                            />
                          </div>
                        )}
                      </Slider>

                      <Slider
                        {...thumbSettings}
                        className="thumb-slider"
                        asNavFor={nav1}
                        ref={slider2 => setNav2(slider2)}
                        focusOnSelect={true}
                      >
                        {residenceDetails?.images?.length > 0 ? (
                          residenceDetails?.images.map((image: any, i: any) => (
                            <div className="slide-item" key={i}>
                              <img
                                src={checkImageBase64Code(image.fileContent)}
                                alt=""
                              />
                            </div>
                          ))
                        ) : (
                          <div className="slide-item">
                            <img
                              src={"/assets/img/no-image-available.jpg"}
                              alt=""
                            />
                          </div>
                        )}
                      </Slider>
                    </div>
                  </div>

                  <div className="my-residences-rating pt-3 pb-3">
                    {residenceDetails?.facilityStatusId == 834 ? (
                      <div>
                        {getRating(
                          residenceDetails?.rating != null
                            ? residenceDetails?.rating
                            : 2.5,
                          5,
                          5
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex my-residences-student-panel">
                    <div className="col">
                      <h3>{residenceDetails?.name}</h3>
                    </div>
                    <div className="col">
                      <p>{residenceDetails?.distance}</p>
                    </div>
                    <div className="col">
                      <p>{residenceDetails?.address}</p>
                    </div>
                  </div>
                  <div className="open-case-feature-block-wrapper my-residences-feature-block-wrapper">
                    {residenceDetails?.amenities.length > 0 ? (
                      residenceDetails?.amenities.map(
                        (item: any, k: number) => (
                          <div className="open-case-feature-block" key={k}>
                            {item?.icon ? parse(item?.icon) : null}
                            <h3>{item?.iconName}</h3>
                          </div>
                        )
                      )
                    ) : (
                      <div className="open-case-feature-block d-flex error-msg-text">
                        <i
                          className="fa fa-refresh me-5"
                          style={{ color: "red" }}
                          aria-hidden="true"
                        />
                        <p style={{ color: "red" }}>
                          For some reason, we couldn't load this amenities,
                          please try reload or try again later
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="my-residences-btns">
                    {/* <button className="full-width-outline-btn"><i className="fa-solid fa-trash"></i> Remove</button>
                                        <button className="full-width-yellow-btn">Withdraw</button> */}

                    {residenceDetails?.facilityStatusId == 834 ? (
                      <div className="btn-full-width">
                        <button
                          onClick={() => {
                            editMe(residenceDetails);
                          }}
                          className="full-width-yellow-btn"
                        >
                          Regrade
                        </button>
                      </div>
                    ) : null}
                    {residenceDetails?.facilityStatusId == 835 ||
                    residenceDetails?.facilityStatusId == 836 ? (
                      <div className="btn-full-width">
                        <Link
                          to={
                            "/create-case/" +
                            residenceDetails?.facilityId +
                            "?name=" +
                            residenceDetails.name +
                            "&type=Appeal"
                          }
                          className="full-width-yellow-btn"
                        >
                          Appeal
                        </Link>
                      </div>
                    ) : null}
                    {residenceDetails?.facilityStatusId == 872 ? (
                      <div className="d-flex w-100 justify-content-between">
                        <Link
                          to={"/make-payment/" + residenceDetails?.facilityId}
                          className="full-width-yellow-btn"
                        >
                          Pay Now
                        </Link>
                        <button
                          onClick={() => {
                            deleteNotification(residenceDetails);
                          }}
                          className="full-width-outline-btn"
                        >
                          <i className="fa-solid fa-trash me-2"></i>Delete
                        </button>
                      </div>
                    ) : null}
                    {residenceDetails?.facilityStatusId == 833 ||
                    residenceDetails?.facilityStatusId == 840 ||
                    residenceDetails?.facilityStatusId == 0 ? (
                      <div className="btn-full-width">
                        <button
                          className="full-width-yellow-btn"
                          onClick={() => {
                            withdrawalNotification(residenceDetails);
                          }}
                        >
                          Withdrawal
                        </button>
                      </div>
                    ) : null}
                  </div>
                  <hr />
                  <div className="quick-links-panel">
                    <h3>Quick Links</h3>
                    <div className="row">
                      <div className="col-sm-4">
                        <div
                          className="custom-card d-flex justify-content-between align-items-center"
                          onClick={() =>
                            navigate(`/tenants/${residenceDetails?.facilityId}`)
                          }
                        >
                          <div>
                            <h5>Tenants</h5>
                            <p className="mb-0">All your resident tenants</p>
                          </div>
                          <i
                            className="fa fa-chevron-circle-right yellow-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div
                          className="custom-card d-flex justify-content-between align-items-center"
                          onClick={() =>
                            navigate(
                              `/leases/${residenceDetails?.facilityId}/Facility`
                            )
                          }
                        >
                          <div>
                            <h5>Lease Agreements</h5>
                            <p className="mb-0">Pending Signature</p>
                          </div>
                          <i
                            className="fa fa-chevron-circle-right yellow-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div
                          className="custom-card d-flex justify-content-between align-items-center"
                          onClick={() =>
                            navigate(
                              `/open-cases/${residenceDetails?.facilityId}/Facility`
                            )
                          }
                        >
                          <div>
                            <h5>Cases Lodged</h5>
                            <p className="mb-0">All your resident tenants</p>
                          </div>
                          <i
                            className="fa fa-chevron-circle-right yellow-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResidenceDetails;
