import React, { useEffect, useState } from "react";
import Navbar from "../ui/v2/components/Navbar";
import toast from "react-hot-toast";
import { useDocumentTypeModal } from "../hooks/useDocumentTypeModal";
import { SelectOption } from "../_common/interfaces";
import { useForm } from "react-hook-form";
import { MdAddCircle } from "react-icons/md";
import { Upload } from "@progress/kendo-react-upload";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { FaTimes } from "react-icons/fa";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const WorkSpace = () => {
  const commonApi = useCommonApi();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const [file, setFile] = useState();

  const requiredDocumentCount = 1;

  const [files, setFiles] = useState<any>([]);
  const REQUIRED_DOCS = [886];
  const getAttachment = (code: number) =>
    files.find((file: Record<string, unknown>) => file.DocumentTypeId === code);
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);

  const hasEveryRequiredDocument = REQUIRED_DOCS.every(documentCode =>
    getAttachment(documentCode)
  );
  // const {modalComponent, setOnClickContinue, closeModal, showModal, setSelectedDocumentType } = useDocumentTypeModal({ getAttachment})

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const documents: any = watch("Documents");

  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "DocTypes",
          },
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
          return console.log(doc.optionText); // Returns array object with doc 'options'
        });
        setDocumentOptions(
          options.filter((option: SelectOption) =>
            REQUIRED_DOCS.includes(option.value)
          )
        );

        // const requiredDocumentOptions = options.filter((option: SelectOption) =>
        //     REQUIRED_DOCS.slice(0, requiredDocumentCount).includes(option.value)
        // );

        const requiredDocumentOptions = options.filter((option: SelectOption) =>
          REQUIRED_DOCS.slice(0, requiredDocumentCount).includes(option.value)
        );

        setDocumentOptions(requiredDocumentOptions);
      },
      (message: string) => {}
    );
  };

  useEffect(() => {
    const _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        const file: any = documents[i];
        checkIsSelected(file.name)
          .then(() => {
            if (file.size <= 2 * 1024 * 1024) {
              const fileType = file.type;
              if (
                fileType === "application/pdf" ||
                fileType === "application/msword" ||
                fileType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                // setOnClickContinue(() => (docTypeCode: number) => {
                // if (!docTypeCode) {
                //   toast.error("Select document type");
                //   return;
                // }
                toBase64(file).then((b64: any) => {
                  _files.push({
                    FileName: file.name,
                    FileExtention: file.name.split(".").pop(),
                    // "DocumentTypeId": docTypeCode,
                    FileContent: b64.split(",")[1],
                  });
                  setFiles(_files);
                  // setOnClickContinue(undefined);
                  // closeModal();
                  // setSelectedDocumentType(undefined);
                });
                // });
                // showModal();
              } else {
                toast.error(
                  "Invalid file type. Please upload a valid PDF or Word document."
                );
              }
            } else {
              toast.error("File size should be less than 2MB");
            }
          })
          .catch(() => {});
      }
    } else {
      setFiles(_files);
    }
  }, [documents]);

  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };

  const deleteLocal = (file: any) => {
    const _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file);
      }
    });

    setFiles(_files);
  };

  // ************************************************

  // useEffect(()=>{
  //   getDocTypes();
  // },[])

  const onSubmit = () => {
    // console.log("called", { data, files });
    // showLoader()
    commonApi.post(
      {
        entityName: "PaymentReceipt",
        requestName: "UpsertRecordReq",

        inputParamters: {
          Entity: {
            InvoiceId: "9a5e08f9-3f45-417e-a5ee-3010448b5961",
          },
          Documents: files,
        },
      },
      (message: string, resp: any) => {
        toast.success(message);

        // console.log({ resp })
      },
      (message: string) => {
        toast.error(message);
      }
    );
  };

  return (
    <div>
      <Navbar />

      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput
            type="file"
            {...register("picture", { required: true })}
            name="picture"
          />
        </Button>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          onClick={onSubmit}
        >
          Submit
        </Button>

        <div className="mt-2">
          {files.map((file: any, i: any) => (
            <div className="doc-file mb-2" key={i}>
              <FaTimes
                className="delete-me"
                onClick={() => {
                  deleteLocal(file);
                }}
              />
              <input
                type="text"
                className="form-control"
                disabled
                id="exampleFormControlInput3"
                placeholder={file.FileName}
              />
            </div>
          ))}
        </div>

        <div
          className={`upload-documents mt-4 ${hasEveryRequiredDocument ? "disabled" : ""}`}
        >
          <label>
            <MdAddCircle className="icon" />
            &nbsp; Add Documents
            {/* <input type="file" id="general_documents" disabled={hasEveryRequiredDocument} multiple={true} accept=".pdf, .doc, .docx" {...register("Documents", { required: true })} /> */}
            <input
              type="file"
              id="general_documents"
              disabled={hasEveryRequiredDocument}
              multiple={true}
              accept=".pdf, .doc, .docx"
              // onChange={handleFileChange} // Add this line
              {...register("Documents", { required: true })}
            />
          </label>
        </div>
      </form>
      {/* {modalComponent} */}
    </div>
  );
};

export default WorkSpace;
