import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL, STORAGE } from "../../_config";
import { CallApi } from "./api/callApi";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* post(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_BASE_URL + "/ExecuteRequest",
      data
    );
    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.isSuccess &&
      resp.data.outputParameters
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* get(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_BASE_URL + "/ExecuteRequest",
      data
    );
    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.isSuccess &&
      resp.data.outputParameters
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getResidences(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GET_INSTITUTIONS, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getResidenceRooms(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.RESIDENCE.ODATA, data);
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getResidenceAmenities(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.RESIDENCE.GET_RESIDENCE_AMENITIES,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getResidenceAdditionalInfo(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.RESIDENCE.GET_RESIDENCE_ADDITIONAL_INFO,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getResidencies(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.RESIDENCE.GET_RESIDENCIES,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getCaseQueryTypes(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.RESIDENCE.GET_CASE_QUERY_TYPES,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
export function* getPaymentValue(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.RESIDENCE.GET_PAYMENT_VALUE,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
// ---
export function* getFacilityPricing(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.GET,
      API_URL.COMMON.GET_FACILITY_PRICING,
      data
    );
    if (resp.status >= 200 && resp.status < 300 && resp.data) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
