import { Typography } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";

interface Props {
  children: string;
  sx?: SxProps; // Optional sx prop for custom styles
}

const defaultStyles = {
  fontSize: "1.5rem",
  paddingTop: "2.5rem",
  fontWeight: 600,
  lineHeight: "2rem",
};

function HeaderText({ children, sx }: Props) {
  return (
    <Typography variant="h3" sx={{ ...defaultStyles, ...sx }}>
      {children}
    </Typography>
  );
}

export default HeaderText;
