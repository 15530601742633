import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import Header from "../components/header";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { URLS } from "../_config";
import LeftPanel from "../components/leftPanel";
import { Button } from "@mui/material";
import { LegacyCompatOverrides } from "../ui/v2/styles/theme";
import LegacyPageLayout from "../components/LegacyPageLayout";

interface SelectOption {
  value: number;
  label: string;
}

const REQUIRED_DOCS = [888, 904, 906, 1018, 1019, 1020];

type TLocationState = {
  residence?: unknown;
  isEditUploadsOnly?: boolean;
};

export default function UploadPropertyDocuments() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const [showModal, setShowModal] = useState(false);
  const commonApi = useCommonApi();
  const location = useLocation();
  const locationState = (location.state as TLocationState) || {
    isEditUploadsOnly: false,
  };
  const residenceID = sessionStorage.getItem("residenceID");
  const [documentOptions, setDocumentOptions] = useState<SelectOption[]>([]);
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<SelectOption>();
  const [docType, setDocType] = useState<any>(null);
  const [files, setFiles] = useState<any>([]);
  const [prevFiles, setPrevFiles] = useState<any>([]);
  const [onClickContinue, setOnClickContinue] = useState<Function>();

  const name = sessionStorage.getItem("residenceName");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    watch,
    reset,
  } = useForm({ mode: "onChange" });

  const documents = watch("documents");

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onload = () => resolve(reader.result);
      reader.onload = () =>
        resolve({ data: reader.result, extension: file.name.split(".").pop() });
      reader.onerror = error => reject(error);
    });

  useEffect(() => {
    const _files: any = [...files];
    if (documents?.length) {
      for (let i = 0; i < documents?.length; i++) {
        const file: any = documents[i];
        checkIsSelected(file.name)
          .then(() => {
            if (file.size <= 2 * 1024 * 1024) {
              // setOnClickContinue(()=>(docTypeCode: number)=>{
              //     if(!docTypeCode){
              //         toast.error("Select document type");
              //         return;
              //     }
              //     toBase64(file).then((b64: any) => {
              //         _files.push({
              //             "FileName": file.name,
              //             "FileExtention": file.name.split('.').pop(),
              //             "DocumentTypeId": docTypeCode,
              //             "FileContent": b64.split(',')[1]
              //         });
              //         // if (_files.length == documents?.length) {
              //         console.log('files=>', _files);
              //         setFiles(_files)
              //         // }
              //         setOnClickContinue(undefined);
              //         setShowModal(false);
              //         setSelectedDocumentType(undefined);
              //     });
              // })
              setOnClickContinue(() => (docTypeCode: number) => {
                if (!docTypeCode) {
                  toast.error("Select document type");
                  return;
                }
                toBase64(file).then((result: any) => {
                  const { data, extension } = result;
                  if (extension === "pdf" || extension === "docx") {
                    _files.push({
                      FileName: file.name,
                      FileExtention: extension,
                      DocumentTypeId: docTypeCode,
                      FileContent: data.split(",")[1],
                    });
                    setFiles(_files);
                    setOnClickContinue(undefined);
                    setShowModal(false);
                    setSelectedDocumentType(undefined);
                  } else {
                    toast.error("Only PDF and Word documents are allowed");
                  }
                });
              });

              setShowModal(true);
            } else {
              toast.error("File size should be less 2MB");
            }
          })
          .catch(() => {
            console.log("already selected");
          });
      }
    } else {
      setFiles(_files);
    }
    reset({
      documents: null,
    });
  }, [documents]);

  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "DocTypes",
          },
        },
      },
      (message: string, resp: any) => {
        const options = resp.outputParameters.data.map((doc: any) => {
          return {
            value: doc.optionValue,
            label: doc.optionText,
          };
        });
        setDocumentOptions(
          options.filter((option: SelectOption) =>
            REQUIRED_DOCS.includes(option.value)
          )
        );
        resp.outputParameters.data.map((type: any) => {
          if (type.optionText == "AP Documents") {
            setDocType(type.optionValue);
          }
        });
      },
      (message: string) => {
        //oast.error(message);
      }
    );
  };

  const onSubmit = (data: any) => {
    console.log("add", (files?.length || 0) + (prevFiles?.length || 0));
    const params: any = {
      entityName: "Facility",
      requestName: "CreateDocumentExecuteRequest",
      recordId: residenceID,
      inputParamters: { Documents: files },
    };

    showLoader();
    commonApi.post(
      params,
      (message: string, resp: any) => {
        hideLoader();
        navigate(
          locationState.isEditUploadsOnly ? URLS.DASHBOARD : "/payment-info"
        );
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };

  const getPrevData = () => {
    if (residenceID) {
      showLoader();
      //call api reset form values via reset()
      commonApi.retreive(
        {
          Entity: "FacilityDocLib",
          Top: 100,
          ReturnCols: [
            "FacilityDocLibId",
            "Name",
            "DocumentTypeId",
            "FileExtention",
            "FileContent",
          ],
          FilterOn: {
            Type: "And",
            Where: [
              {
                filterCol: "FacilityId",
                FilterCondition: "Equals",
                FilterValue: residenceID,
              },
              //   TODO: add proper filter after feedback from @SiyandaSitsha
              // , {
              //     "filterCol": "DocumentTypeId", "FilterCondition": "Equals",
              //     "FilterValue": ["892"]
              // }
            ],
          },
        },
        (message: string, resp: any) => {
          hideLoader();
          const prevData = JSON.parse(resp);
          const relevantDocs = [...REQUIRED_DOCS, 892];
          setPrevFiles(
            prevData.filter((doc: Record<string, number>) =>
              relevantDocs.includes(doc.DocumentTypeId)
            )
          );
        },
        (message: string) => {
          hideLoader();
          toast.error(message);
        }
      );
    } else {
    }
  };

  useEffect(() => {
    if (residenceID == null) {
      navigate("/add-residence");
    } else {
      getDocTypes();
    }
  }, []);

  useEffect(() => {
    getPrevData();
  }, []);

  const deleteLocal = (file: any) => {
    const _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file);
      }
    });

    setFiles(_files);
  };
  const deleteFromServer = (file: any) => {
    showLoader();
    commonApi.post(
      {
        entityName: "FacilityDocLib",
        recordId: file.FacilityDocLibId,
        requestName: "RemoveRecordReq",
      },
      (message: string, resp: any) => {
        hideLoader();
        getPrevData();
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getAttachment = (code: number) => {
    const localFileMatch = files.find(
      (file: Record<string, unknown>) => file.DocumentTypeId === code
    );
    if (localFileMatch) {
      return localFileMatch;
    }
    return prevFiles.find(
      (file: Record<string, unknown>) => file.DocumentTypeId === code
    );
  };

  const hasEveryRequiredDocument = REQUIRED_DOCS.every(documentCode =>
    getAttachment(documentCode)
  );

  return (
    <LegacyPageLayout>
      <section className="container">
        <div className="custome-container-inner d-flex">
          <LeftPanel page={4} lockToPage={locationState.isEditUploadsOnly} />
          <div className="white-block p-3" style={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col">
                  <h1>{name && `${name} - `}Documents</h1>
                </div>
                {/* <div className="col text-end">
                                    <img src="/assets/img/Add-button.png" alt="Add butten" />
                                </div> */}
              </div>
              <hr />
              <div className="upload-instruction mt-3">
                {/* <h5>If you're an entity please upload the following:</h5> */}
                <h5>Please upload the following:</h5>
                <ul className="no-padding">
                  {documentOptions.map(option => (
                    <li className="no-bullet">
                      <FaCheckCircle
                        color={getAttachment(option.value) ? "#0acc7e" : "#aaa"}
                      />
                      &nbsp;&nbsp;{option.label}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="upload-btn-wrapper">
                    <button className="btn" disabled={hasEveryRequiredDocument}>
                      <img src="/assets/img/plus.png" alt="upload" />
                      <br />
                      Upload your documents
                    </button>
                    {/* <input type="file" multiple  disabled={hasEveryRequiredDocument}  accept="application/pdf" {...register('documents', { required: false })} /> */}
                    <input
                      type="file"
                      multiple
                      disabled={hasEveryRequiredDocument}
                      accept=".pdf,.docx"
                      {...register("documents", { required: false })}
                    />
                  </div>
                </div>
              </div>
              <div className="documents-fld-panel">
                {prevFiles && prevFiles.length > 0
                  ? prevFiles.map((file: any, i: any) => (
                      <div className="doc-file mb-2" key={i}>
                        <FaTimes
                          className="delete-me"
                          onClick={() => {
                            deleteFromServer(file);
                          }}
                        />
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="exampleFormControlInput3"
                          placeholder={file.Name}
                        />
                      </div>
                    ))
                  : null}
                {files.map((file: any, i: any) => (
                  <div className="doc-file mb-2" key={i}>
                    <FaTimes
                      className="delete-me"
                      onClick={() => {
                        deleteLocal(file);
                      }}
                    />
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      id="exampleFormControlInput3"
                      placeholder={file.FileName}
                    />
                  </div>
                ))}
              </div>

              <div className="">
                <Button
                  {...LegacyCompatOverrides.button}
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={(files?.length || 0) + (prevFiles?.length || 0) < 3}
                >
                  Next
                </Button>
                {/* <div className="add-room-room-back-btn"> */}

                <button
                  onClick={() =>
                    navigate(URLS.UPLOAD_PROPERTY_IMAGES, {
                      state: {
                        isEditUploadsOnly: locationState.isEditUploadsOnly,
                      },
                    })
                  }
                  type="button"
                  className="btn btn-outline-secondary mt-2 w-100 back-btn-add-prop"
                  data-bs-toggle="button"
                  style={{
                    backgroundColor: "#fff",
                    // display:'flex',0
                    // justifyContent:'center',
                    // alignItems:'center',
                    // height:'30px',
                  }}
                >
                  Back
                </button>
                {/* </div> */}
              </div>
            </form>
          </div>
        </div>
      </section>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Document Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please specify the type of document you are attaching
          <Select
            className={
              "form-control p-0" + (errors?.campus ? " is-invalid" : "")
            }
            options={documentOptions}
            getOptionLabel={option => {
              const attachment = getAttachment(option.value);
              if (attachment) {
                return `${option.label} [${attachment.FileName}]`;
              }
              return option.label;
            }}
            isOptionDisabled={option => getAttachment(option.value)}
            placeholder={""}
            onChange={_ => setSelectedDocumentType(_ as SelectOption)}
            value={selectedDocumentType}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <div></div>
          <Button
            variant="contained"
            onClick={() => {
              onClickContinue?.(selectedDocumentType?.value);
            }}
          >
            Add Document
          </Button>
        </Modal.Footer>
      </Modal>
    </LegacyPageLayout>
  );
}
