import react, { useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row } from "react-bootstrap";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import parse from "html-react-parser";
import { useCommonApi } from "../../../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import viewImage from "../../../images/view-image-one.png";
import emptyImage from "../../../images/empty-set.png";
import Tooltip from "@mui/material/Tooltip";
import { IApiResponse } from "../../../ui/v2/types/IApiResponse";
import { API } from "../../../ui/v2/services/API";
import { EXECUTE_REQUEST_PATH } from "../../../ui/v2/constants";

interface incomingProps {
  data?: any;
  revoke?: boolean;
  getApplications?: any;
  route?: any;
  getMyResidencies?: any;
}
type signwellRetrieveUrl = {
  url: string;
};

export default function AccomodationCard({
  data,
  revoke = false,
  getApplications,
  route,
  getMyResidencies,
}: incomingProps) {
  const settings = {
    dots: false,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const thumbSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const navigate = useNavigate();
  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();

  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);

  const [grade, setGrade] = useState("");
  const [rating, setRating] = useState<any>();
  const [residenceStatus, setResidenceStatus] = useState<any>([]);

  const getRating = (rating: any, totalStar: any, outOf: any) => {
    if (rating) {
      const finalRating = (rating / outOf) * totalStar;
      const checkFraction = finalRating % 1 != 0;
      if (checkFraction == false) {
        const blankStar = totalStar - finalRating;
        const span: any = [];
        for (let i = 0; i < finalRating; i++) {
          span.push(
            <BsStarFill className="text-primary" key={Math.random()} />
          );
        }
        for (let i = 0; i < blankStar; i++) {
          span.push(
            <BsStarFill style={{ color: "#d4d4d4" }} key={Math.random()} />
          );
        }
        return span;
      } else {
        let fullStar = Math.round(finalRating * 10) / 10;
        const halfStar = Number(fullStar.toString().split(".")[1]);
        fullStar = Number(finalRating.toString().split(".")[0]);
        const span: any = [];
        if (halfStar >= 5) {
          const blankStar = totalStar - fullStar - 1;
          for (let i = 0; i < fullStar; i++) {
            span.push(
              <BsStarFill className="text-primary" key={Math.random()} />
            );
          }
          span.push(<BsStarHalf className="text-primary" />);
          for (let i = 0; i < blankStar; i++) {
            span.push(
              <BsStarFill key={Math.random()} style={{ color: "#d4d4d4" }} />
            );
          }
          return span;
        } else {
          const blankStar = totalStar - fullStar + 1;
          for (let i = 0; i < fullStar; i++) {
            span.push(
              <BsStarFill className="text-primary" key={Math.random()} />
            );
          }
          for (let i = 0; i < blankStar; i++) {
            span.push(
              <BsStarFill key={Math.random()} style={{ color: "#d4d4d4" }} />
            );
          }
          return span;
        }
      }
    }
  };

  const getLinkOnClick = async (applicationId: any) => {
    try {
      showLoader();
      const response: IApiResponse<signwellRetrieveUrl> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "AccomodationApplications",
          requestName: "RetrieveSignwellReq",
          recordId: applicationId,
          inputParamters: {
            UserType: "Student",
          },
        }
      );
      window.open(response.outputParameters.url);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  const checkImageBase64Code = (url: any) => {
    if (url.search("data:image/") == 0) {
      return url;
    } else {
      return "data:image/jpeg;base64," + url;
    }
  };

  const imageSource = (docId: any, folder: any) => {
    return (
      process.env.REACT_APP_API_DOMAIN +
      "/api/v1/entities/imageview?id=" +
      docId +
      "&folder=" +
      folder +
      "&apikey=" +
      process.env.REACT_APP_API_KEY
    );
  };

  const handleRevoke = (data: any) => {
    showLoader();
    return new Promise((resolve: any, reject: any) => {
      commonApi.post(
        {
          entityName: "AccomodationApplications",
          recordId: data,
          requestName: "RemoveRecordReq",
        },
        (message: string, resp: any) => {
          // toast.success(message)
          resolve(message, { duration: 1000 });
          // toast.success(message ,{ duration:2000})

          hideLoader();
          navigate(`/student/my-applications/home`);
          getApplications();
          getMyResidencies();
        },
        (message: string) => {
          // toast.error(message);
          reject("🤢", message);
          hideLoader();
          // getApplications()
        }
      );
    });
  };

  const revokeNotification = (data: any) => {
    toast(
      t => (
        <span>
          Are you sure you want to revoke your application ?
          <br />
          <hr />
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-danger btn-sm m-1"
              onClick={() => {
                toast.dismiss(t.id);

                toast.promise(
                  handleRevoke(data),
                  {
                    loading: "working on request...",
                    success: (msg: any) => {
                      // getResidencies();
                      return <b> {msg}</b>;
                    },
                    error: msg => {
                      return <b>{msg}</b>;
                    },
                  },
                  { duration: 6000 }
                );
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-secondary btn-sm m-1"
              onClick={() => toast.dismiss(t.id)}
            >
              No
            </button>
          </div>
        </span>
      ),
      { duration: 5000, className: "withdraw-toast" }
    );
  };

  const navigateFunction = () => {
    if (!revoke) {
      navigate(`../${route}/${data.facilityId}`);
    }
  };

  const getResidenceStatus = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "FacilityStatus",
          },
        },
      },
      (message: string, resp: any) => {
        if (resp.outputParameters.Count > 0) {
          setResidenceStatus(resp.outputParameters.data);
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  const getFilteredResidenceStatus = (facilityStatusId: any) => {
    const filteredData = residenceStatus.filter(
      (item: any) => item.optionValue == facilityStatusId
    );

    return (
      <div className="tag-status">
        {" "}
        <span
          className="badge"
          style={{
            backgroundColor: filteredData[0]?.colorCode
              ? filteredData[0]?.colorCode
              : "#F3981E",
          }}
        >
          <>
            {filteredData[0]?.optionText ? filteredData[0]?.optionText : "NA"}
          </>
        </span>
      </div>
    );
  };

  useEffect(() => {
    getResidenceStatus();
  }, []);

  return (
    <div
      className="accomodation-card"
      style={{ height: "auto", marginBottom: "2rem", overflow: "hidden" }}
    >
      <div className="card-content">
        {route ? (
          <div className="card-container">
            {/* <img src="" alt="" /> */}
            {data.images?.length > 0 ? (
              <img
                className="residence-image"
                src={imageSource(
                  data.images[0].facilityDocLibId,
                  "FacilityDocLib"
                )}
                alt=""
              />
            ) : (
              <img src={"/assets/img/no-image-available.jpg"} alt="" />
            )}
            {data.faciltyStatusId == 69 ? (
              <span className="badge">Approved</span>
            ) : null}
            <div className="tag-status status-container">
              {data.applicationStatusId == 70 ? (
                <span
                  className="badge badge-status"
                  style={{ backgroundColor: "#A71F23" }}
                >
                  Declined
                </span>
              ) : null}
            </div>
            <div className="tag-status status-container">
              {data.applicationStatusId == 69 ? (
                <span
                  className="badge badge-status"
                  style={{ backgroundColor: " #275303" }}
                >
                  Approved
                </span>
              ) : null}
            </div>
            <div className="tag-status status-container">
              {data.applicationStatusId == 602 ? (
                <span
                  className="badge badge-status"
                  style={{ backgroundColor: " #F3981E" }}
                >
                  Pending Approval
                </span>
              ) : null}
            </div>

            {/* <div style={{cursor:'pointer'}} >
                            {getFilteredResidenceStatus(data.facilityStatusId)}
                        </div>   */}
            {/* <img className='residence-image'  src={checkImageBase64Code(data.images[2].fileContent)} alt="" /> */}
            <div className="my-card-info">
              <div
                className="price-and-rating"
                style={{ alignItems: "center" }}
              >
                <div
                  className="residence-price"
                  style={{ width: "100%", fontSize: "14px", lineHeight: "1" }}
                >
                  {data.name}
                </div>
                <div className="residence-rating" style={{ fontSize: "12px" }}>
                  GRADE {data.rating}
                </div>
              </div>
              <Row className="name-and-distance">
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    marginTop: "1rem",
                  }}
                >
                  <p className="residence-distance">{data.distance}</p>
                </div>
                {/* <p className='text2'>{data.distance}</p> */}
              </Row>
              <Row>
                <div className="my-amenities">
                  <Tooltip title={data.totalRooms + " Rooms"} placement="top">
                    <div>
                      <i className="fa fa-bed" aria-hidden="true"></i>
                    </div>
                  </Tooltip>

                  {data.amenities.length > 0 ? (
                    data.amenities?.map((amenity: any, i: any) => (
                      <div className="">
                        <div className="" key={i}>
                          <Tooltip title={amenity.iconName} placement="top">
                            <div>
                              <div style={{ cursor: "pointer" }}>
                                {amenity.icon ? parse(amenity.icon) : null}
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="amenity d-flex error-msg-text">
                      {/* <img src={emptyImage} alt="empty-icon"  style={{width:'1rem',paddingBottom:'1px'}}/> */}
                    </div>
                  )}
                </div>
                <div className="line"></div>
                {data.applicationStatusId == 69 ? (
                  <a
                    className="view-btn text-center"
                    // onClick={navigateFunction}
                    href={`/my-property/${data.facilityId}`}
                    style={{ textDecoration: "none" }}
                  >
                    View
                  </a>
                ) : data.applicationStatusId == 70 ? (
                  <a
                    className="view-btn text-center"
                    // onClick={navigateFunction}
                    href={`/my-property/${data.facilityId}`}
                    style={{ textDecoration: "none" }}
                  >
                    View
                  </a>
                ) : data.applicationStatusId == 602 ? (
                  <a
                    className="view-btn text-center"
                    // onClick={navigateFunction}
                    href={`/my-property/${data.facilityId}`}
                    style={{ textDecoration: "none" }}
                  >
                    View
                  </a>
                ) : (
                  <a
                    className="view-btn text-center"
                    // onClick={navigateFunction}
                    href={`/student/accomodation-details/${data.facilityId}`}
                    style={{ textDecoration: "none" }}
                  >
                    View
                  </a>
                )}
              </Row>
              <div className="info pb-2">
                {data.message ? (
                  <div className="note">
                    <p>{data.message}</p>
                  </div>
                ) : null}
                {data.applicationStatusId == 69 ? (
                  <div className="btn-full-width mt-2 pb-2 ">
                    <button
                      // h={data.studentSigningURL}
                      // target='_blank'
                      onClick={() => {
                        getLinkOnClick(data.accomodationApplicationsId);
                      }}
                      // ()=>window.open('/someURL','_blank', 'rel=noopener noreferrer')
                      className="full-width-outline-btn find-accommodation-btn"
                      style={{ width: "100%" }}
                    >
                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "1rem",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                        <div className="">View Lease</div>
                      </div>
                    </button>
                  </div>
                ) : null}
                {data.applicationStatusId == 70 ? (
                  <div className="btn-full-width  mt-2 pb-2">
                    <button
                      className="full-width-outline-btn find-accommodation-btn"
                      onClick={() => {
                        navigate("/student/search-residence");
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i className="fa fa-home" aria-hidden="true"></i>

                        <div>Find Accommodation</div>
                      </div>
                    </button>
                  </div>
                ) : null}
                {data.applicationStatusId == 602
                  ? null
                  : // <div className="btn-full-width mt-2 pb-2" style={{display:'flex',alignItems:'end'}}>
                    //     <button
                    //         onClick={() => revokeNotification(data.accomodationApplicationsId)}
                    //         className="full-width-outline-btn find-accommodation-btn">
                    //             <div style={{display:'flex',gap:'1rem',justifyContent:'center',alignItems:'center'}}>
                    //                 <i className='fa-solid fa-trash'></i>
                    //                 <div>Revoke Application</div>
                    //             </div>
                    //     </button>
                    // </div>
                    // :
                    null}
              </div>
            </div>
          </div>
        ) : (
          // <div className="slider">
          //     <Slider {...settings} asNavFor={nav2} ref={(slider1: any) => setNav1(slider1)} >
          //         {data.images?.length > 0 ? data.images.map((image: any, i: any) => (
          //             <div className="slide-item" key={i}>
          //                 <img src={checkImageBase64Code(image.fileContent)} alt="" />
          //             </div>
          //         )) : <div className="slide-item">
          //             <img src={"/assets/img/no-image-available.jpg"} alt="" />
          //         </div>}
          //     </Slider>
          //     <Slider {...thumbSettings} className="thumb-slider" asNavFor={nav1} ref={(slider2) => setNav2(slider2)} focusOnSelect={true}>
          //         {data.images?.length > 0 ? data.images.map((image: any, i: any) => (
          //             <div className="slide-item" key={i}>
          //                 <img src={checkImageBase64Code(image.fileContent)} />
          //             </div>
          //         )) : <div className="slide-item">
          //             <img src={"/assets/img/no-image-available.jpg"} alt="" />
          //         </div>}
          //     </Slider>
          // </div>
          <div className="slider">
            <Slider
              {...settings}
              asNavFor={nav2}
              ref={(slider1: any) => setNav1(slider1)}
            >
              {data.images?.length > 0 ? (
                data.images.map((image: any, i: any) => (
                  <div className="slide-item" key={i}>
                    <img
                      src={imageSource(
                        image.facilityDocLibId,
                        "FacilityDocLib"
                      )}
                    />
                  </div>
                ))
              ) : (
                <div className="slide-item">
                  <img src={"/assets/img/no-image-available.jpg"} alt="" />
                </div>
              )}
            </Slider>
            <Slider
              {...thumbSettings}
              className="thumb-slider"
              asNavFor={nav1}
              ref={slider2 => setNav2(slider2)}
              focusOnSelect={true}
            >
              {data.images?.length > 0 ? (
                data.images.map((image: any, i: any) => (
                  <div className="slide-item" key={i}>
                    <img
                      src={imageSource(
                        image.facilityDocLibId,
                        "FacilityDocLib"
                      )}
                      alt=""
                    />
                  </div>
                ))
              ) : (
                <div className="slide-item">
                  <img src={"/assets/img/no-image-available.jpg"} alt="" />
                </div>
              )}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
}
