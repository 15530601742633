import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "./student/components/header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import {
  CheckCircleOutlineRounded,
  Close,
  Download,
  ErrorOutlineOutlined,
  HourglassEmptyOutlined,
  ReceiptOutlined,
} from "@mui/icons-material";
import { IApiResponse } from "../ui/v2/types/IApiResponse";
import { API } from "../ui/v2/services/API";
import { EXECUTE_REQUEST_PATH } from "../ui/v2/constants";
import { DEFAULT_PAGE_SIZE } from "../constants";

import { Viewer } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { format } from "date-fns";
import { URLS } from "../_config";
import LegacyPageLayout from "../components/LegacyPageLayout";
import { ThemeColors } from "../ui/v2/styles/theme";
import SummaryCard from "../ui/v2/components/GenericListingScreen/SummaryCard";
import PageHeaderTexts from "../ui/v2/components/GenericListingScreen/PageHeaderTexts";
import useSearchAndFiltersBar from "../ui/v2/components/GenericListingScreen/useSearchAndFiltersBar";
import ListingTable from "../ui/v2/components/GenericListingScreen/ListingTable";
import NoItemsPaper from "../ui/v2/components/GenericListingScreen/NoItemsPaper";
import { TSelectedFilters } from "../ui/v2/components/GenericListingScreen/useSearchAndFiltersBar/SearchAndFiltersBar";
import { UTILS } from "../utils";
interface InvoiceDetail {
  invoiceId: string;
  invoiceNumber: string;
  referenceNumber: string;
  invoiceTotal: number;
  paymentDate: string;
  invoiceStatus: string;
  propertyName: string;
}

type FacilityResponseType = {
  PageSize: number;
  RecordCount: number;
  Pages: number;
  InvoiceSummary: {
    paid: number;
    pending: number;
    billed: number;
    exceptional: number;
    exceptionalTrend: number;
    paidTrend: number;
    pendingTrend: number;
    billedTrend: number;
  };
  InvoiceDetails: InvoiceDetail[];
};

type YearResponseType = {
  Count: number;
  data: {
    optionValue: number;
    optionText: string;
    colorCode: null;
  }[];
};

function BasicMenu({
  invoiceId,
  invoiceNumber,
}: {
  invoiceId: string;
  invoiceNumber: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [
    { isModalOpen, invoiceFileName, fileContent, fileExtension },
    setModalState,
  ] = useState<{
    isModalOpen: boolean;
    invoiceFileName?: string;
    isLoading?: boolean;
    fileContent?: string;
    fileExtension?: string;
  }>({
    isModalOpen: false,
  });

  const getFile = async (format: "PDF" | "XLSX") => {
    try {
      setModalState(curr => ({ ...curr, isLoading: true }));
      const downloadResult: IApiResponse<{
        Filename: string;
        FileExtension: string;
      }> = await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "Invoice",
        recordId: invoiceId,
        requestName: "DownloadRecordExecuteRequest",
        inputParamters: {
          Format: format,
        },
      });
      return {
        name: downloadResult.outputParameters.Filename,
        extension: downloadResult.outputParameters.FileExtension || "pdf",
        content: downloadResult.results,
      };
    } catch (e) {
      console.error(e);
    } finally {
      setModalState(curr => ({ ...curr, isLoading: false }));
    }
  };

  const downloadFile = async (data: {
    name: string;
    extension: string;
    content: unknown;
  }) => {
    const a = document.createElement("a");
    a.download = data.name + "." + data.extension;
    a.href = `data:application/${data.extension};base64,${data.content}`;
    a.click();
  };

  const handleView = async () => {
    setModalState({
      isModalOpen: true,
      invoiceFileName: "Loading...",
      isLoading: true,
    });
    handleClose();
    const file = await getFile("PDF");
    if (!file) return;
    setModalState({
      isModalOpen: true,
      invoiceFileName: file.name,
      isLoading: false,
      fileContent: file.content as any,
      fileExtension: file.extension,
    });
  };
  const handleDownload = async () => {
    handleClose();
    const file = await getFile("PDF");
    if (!file) return;
    downloadFile(file);
  };

  const handleDownloadReport = async () => {
    handleClose();
    try {
      const fileName = `${invoiceNumber}_InvoiceReport.xlsx`;
      const response = await API.download(
        `Entities/Invoice/ExportExcelSubGridData/688A16F1-6D5F-44BA-9DD0-92D94944FC32/${invoiceId}?&filename=${fileName}`
      );
      const href = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (e) {
      console.error(e);
    }
  };

  const navigate = useNavigate();

  const handleCreateDispute = () => {
    handleClose();
    navigate(URLS.CREATE_CASE_TEMPORARY, {
      state: { invoiceId, type: "Invoice Dispute" },
    });
  };

  return (
    <>
      <div>
        <Button
          variant="outlined"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Actions
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleView}>View Invoice</MenuItem>
          <MenuItem onClick={handleDownload}>Download Invoice</MenuItem>
          <MenuItem onClick={handleDownloadReport}>Download Report</MenuItem>
          <MenuItem onClick={handleCreateDispute}>Create Dispute</MenuItem>
        </Menu>
      </div>
      <Dialog
        fullScreen
        open={isModalOpen}
        onClose={() => {
          setModalState({
            isModalOpen: false,
            invoiceFileName: "",
            isLoading: false,
          });
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalState({
                  isModalOpen: false,
                  invoiceFileName: "",
                  isLoading: false,
                });
              }}
              aria-label="close"
            >
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {invoiceFileName}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() =>
                downloadFile({
                  content: fileContent,
                  extension: fileExtension || "PDF",
                  name: invoiceFileName!,
                })
              }
              startIcon={<Download />}
            >
              Download
            </Button>
          </Toolbar>
        </AppBar>
        <Box>
          {fileContent ? (
            <Viewer
              fileUrl={`data:application/${fileExtension};base64,${fileContent}`}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                p: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />{" "}
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
}

const YEAR_TODAY = new Date().getFullYear().toString();

const Invoices = () => {
  const commonApi = useCommonApi();

  const user: any = useUserSelector();

  const [applications, setApplications] = useState<any[]>([]);
  const { showLoader, hideLoader } = useAppLoader();

  const getFacilityApplicationDetails = () => {
    commonApi.getFacilityApplicationDetails(
      {
        AccomodationProviderId: user.supplierId,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setApplications(resp);
        }
      },
      () => {}
    );
  };

  useEffect(() => {
    getFacilityApplicationDetails();
  }, []);

  const [details, setDetails] = useState<FacilityResponseType>();
  const [yearOptions, setYearOptions] = useState<YearResponseType>();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialPage = searchParams.get("page")
    ? parseInt(searchParams.get("page") as string)
    : 1;

  const [currentPage, setCurrentPage] = useState<number>(
    initialPage > 0 ? initialPage : 1
  );

  const getApiData = async (
    _currentPage: number,
    activeFilters: TSelectedFilters
  ) => {
    try {
      showLoader();
      const facilityPromise: Promise<IApiResponse<FacilityResponseType>> =
        API.post(EXECUTE_REQUEST_PATH, {
          entityName: "Invoice",
          requestName: "GetAPInvoicesExecuteReq",
          inputParamters: {
            APInvoices: {
              UserId: user.supplierId,
              PropertyId: UTILS.extractFilterValue(
                activeFilters,
                "FilteredProperty"
              ),
              Year: parseInt(
                UTILS.extractFilterValue(activeFilters, "FilteredYear") ||
                  "2000",
                10
              ),
              Status: UTILS.extractFilterValue(activeFilters, "Status"),
            },
            SearchText: searchedText,
            PageNumber: currentPage,
            PageSize: DEFAULT_PAGE_SIZE,
          },
        });

      const yearPromise: Promise<IApiResponse<YearResponseType>> = API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Picklist",
          requestName: "RetrieveSelectOptionExecuteRequest",
          inputParamters: {
            SelectOption: {
              OptionText: "Year",
            },
          },
        }
      );

      const [yearResponseData, facilityResponse] = await Promise.all([
        yearPromise,
        facilityPromise,
      ]);

      setDetails(facilityResponse.outputParameters);
      setYearOptions(yearResponseData.outputParameters);
    } catch (e) {
      console.error(e);
    } finally {
      hideLoader();
    }
  };

  const [, setModalState] = useState<{
    show: boolean;
    selectedFacilityId?: string;
    selectedApplicationId?: string;
  }>({
    show: false,
  });

  const {
    searchAndFiltersBarComponent,
    searchedTextBannerComponent,
    searchedText,
    activeFilters,
  } = useSearchAndFiltersBar({
    filters: [
      {
        type: "dropdown",
        value: "Status",
        name: "Status",
        multiple: false,
        options: [
          { label: "All", value: "All" },
          { label: "Paid", value: "Paid" },
          { label: "Pending", value: "Pending" },
          { label: "Exceptional", value: "Exceptional" },
        ],
        initialValue: "All",
      },
      {
        type: "dropdown",
        value: "FilteredProperty",
        name: "My Properties",
        multiple: false,
        options: [
          { label: "All", value: "All" },
          ...applications.map(item => ({
            label: item.Name,
            value: item.FacilityId,
          })),
        ],
        initialValue: "All",
      },
      {
        type: "dropdown",
        value: "FilteredYear",
        name: "Year",
        multiple: false,
        options:
          yearOptions?.data.map(item => ({
            label: item.optionText,
            value: item.optionText,
          })) || [],
        initialValue: YEAR_TODAY,
      },
    ],
    onSearch: searchInput => {
      setCurrentPage(1);
      setSearchParams({ page: "1" });
    },
  });

  useEffect(() => {
    getApiData(currentPage, activeFilters);
  }, [currentPage, searchedText, activeFilters]);

  return (
    <LegacyPageLayout
      bgColor={ThemeColors.bgPrimary}
      padding={3}
      paddingTop={0}
      breadcrumbData={[{ label: "Home", path: "/" }, { label: "Invoices" }]}
    >
      <Box sx={{ mt: 1, pt: 2 }}>{searchAndFiltersBarComponent}</Box>

      <Grid item xs={12} sx={{ my: 6 }}>
        <PageHeaderTexts heading="My Invoices" />
      </Grid>
      <Grid container sx={{ my: 2 }}>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            label="Paid to Date"
            icon={<ReceiptOutlined />}
            value={details?.InvoiceSummary.billed || 0}
            trend={details?.InvoiceSummary.billedTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            label="Paid this month"
            icon={<CheckCircleOutlineRounded />}
            value={details?.InvoiceSummary.paid || 0}
            trend={details?.InvoiceSummary.paidTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            label="Pending Payment"
            icon={<HourglassEmptyOutlined />}
            value={details?.InvoiceSummary.pending || 0}
            trend={details?.InvoiceSummary.pendingTrend || 0}
          />
        </Grid>
        <Grid item xs={12} md={3} container>
          <SummaryCard
            label="Exceptional"
            icon={<ErrorOutlineOutlined />}
            value={details?.InvoiceSummary.exceptional || 0}
            trend={details?.InvoiceSummary.exceptionalTrend || 0}
          />
        </Grid>
      </Grid>

      {searchedTextBannerComponent}

      {applications.length > 0 ? (
        <ListingTable
          title="Invoices"
          totalPages={details?.Pages || 1}
          currentPage={currentPage}
          onPageChange={(e, newPage) => {
            setCurrentPage(newPage);
            setSearchParams({ page: `${newPage}` });
          }}
          sx={{ minWidth: 1050 }}
          aria-label="simple table"
        >
          <TableHead
            sx={{
              backgroundColor: "#F9FAFB",
            }}
          >
            <TableRow>
              <TableCell>Residence</TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Reference</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Payment Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.InvoiceDetails.map((entry: InvoiceDetail) => (
              <TableRow
                key={entry.invoiceId}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {entry.propertyName}
                </TableCell>
                <TableCell className="table-data">
                  <div>{entry.invoiceNumber}</div>
                </TableCell>
                <TableCell className="table-data">
                  <div>{entry.referenceNumber}</div>
                </TableCell>
                <TableCell className="table-data">
                  R {entry.invoiceTotal.toFixed(2)}
                </TableCell>
                <TableCell className="table-data">
                  {format(entry.paymentDate, "yyyy-MM-dd")}
                </TableCell>
                <TableCell className="table-data">
                  {entry.invoiceStatus}
                </TableCell>
                <TableCell align="right">
                  <BasicMenu
                    invoiceId={entry.invoiceId}
                    invoiceNumber={entry.invoiceNumber}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ListingTable>
      ) : (
        <NoItemsPaper />
      )}
    </LegacyPageLayout>
  );
};

export default Invoices;
