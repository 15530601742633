import React from "react";
import { Button } from "react-bootstrap";
import IntroSlider from "../components/introSlider";
import { useNavigate } from "react-router-dom";

export default function RegistrationSuccessful() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="register_page login_page">
        <div className="register_left">
          <div className="mob_logo">
            <a href="#" className="logo_left">
              <img src="/assets/img/logo.png" />
            </a>
          </div>
          <div className="logo_txt">Registration successful.</div>
          <p className="text-center p-4">
            Your profile has been successfully set, see the mail sent to you for
            further details.
            <br />
            <br />
            Read through NSFAS accreditation guidelines before adding a
            property.
            <br />
            <br />
            An inspection fee will be billed to you when you request an
            inspection for you student residence.
          </p>
          <div
            className="mb-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="primary"
              className="w-50 mt-2"
              type="button"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </div>
        </div>
        <IntroSlider />
      </div>
    </React.Fragment>
  );
}
