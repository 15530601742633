import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useForm, useWatch } from "react-hook-form";
import { checkImageBase64Code } from "../_common/functions";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import {
  MAX_IMAGE_RESOLUTION,
  MAX_IMAGE_UPLOADS,
  MIN_IMAGE_UPLOADS,
  URLS,
} from "../_config";
import { UTILS } from "../utils";
import LeftPanel from "../components/leftPanel";
import { showErrorAlert } from "../components/Notify";
import { Button, Grid } from "@mui/material";
import { LegacyCompatOverrides } from "../ui/v2/styles/theme";
import LegacyImageThumbnail from "../ui/v2/components/Legacy/LegacyImageThumbnail";
import {
  TDocumentRetrieve,
  TFacilityDocumentUpload,
} from "../ui/v2/types/TDocumentUpload";
import {
  COVER_IMAGE_CODE,
  EXECUTE_REQUEST_PATH,
  NON_COVER_IMAGE_CODE,
} from "../ui/v2/constants";
import LegacyPageLayout from "../components/LegacyPageLayout";
import { API } from "../ui/v2/services/API";
import { IApiResponse } from "../ui/v2/types/IApiResponse";

type TLocationState = {
  residence?: unknown;
  isEditUploadsOnly?: boolean;
};

export default function UploadPropertyImages() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const commonApi = useCommonApi();
  const location = useLocation();
  const locationState = (location.state as TLocationState) || {
    isEditUploadsOnly: false,
  };

  const residenceID = sessionStorage.getItem("residenceID");
  const [docType, setDocType] = useState<any>(null);
  const [files, setFiles] = useState<TFacilityDocumentUpload[]>([]);

  const [prevFiles, setPrevFiles] = useState<TFacilityDocumentUpload[]>([]);

  const combinedFiles = useMemo(
    () => [...files, ...prevFiles],
    [files, prevFiles]
  );

  const name = sessionStorage.getItem("residenceName");

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    watch,
    reset,
  } = useForm({ mode: "onChange" });

  const images = useWatch({ name: "images", control });

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const checkIsSelected = (name: string) => {
    return new Promise((resolve: any, reject: any) => {
      files.map((file: any) => {
        if (file.FileName == name) {
          reject();
        }
      });
      resolve();
    });
  };
  const validateFileSize = (size: number) => {
    if (size <= 5 * 1024 * 1024) {
      return true;
    }
    throw new Error("File size should be less 5MB");
  };

  const validateExtension = (name: string) => {
    const extension = name.toLowerCase().split(".").pop();
    if (
      extension &&
      ["jpg", "png", "gif", "jpeg", "jfif", "webp", "tiff"].includes(extension)
    ) {
      return extension;
    }
    throw new Error("supported files are .jpeg .jpg, .png and .gif and webp");
  };

  useEffect(() => {
    const _files: any = [...files];
    if (images?.length) {
      (async () => {
        let isCoverAlreadySet =
          combinedFiles.length === 0 ||
          !combinedFiles.find(el => el.DocumentTypeId === COVER_IMAGE_CODE)
            ? false
            : true;
        for (const imageFile of images) {
          console.log("image", imageFile);
          try {
            await checkIsSelected(imageFile.name);
            validateFileSize(imageFile.size);
            const ext = validateExtension(imageFile.name);
            const b64 = await UTILS.resizeFile(imageFile, MAX_IMAGE_RESOLUTION);
            _files.push({
              FileName: imageFile.name,
              FileExtention: ext,
              DocumentTypeId: !isCoverAlreadySet ? COVER_IMAGE_CODE : docType,
              FileContent: (b64 as string).split(",")[1],
            });
            isCoverAlreadySet = true;
          } catch (e: any) {
            showErrorAlert(e.message);
            console.log(e);
          }
        }
        setFiles(_files);
        reset({
          images: null,
        });
      })();
    } else {
      setFiles(_files);
    }
  }, [images]);

  const getDocTypes = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "DocTypes",
          },
        },
      },
      (message: string, resp: any) => {
        resp.outputParameters.data.map((type: any) => {
          if (type.optionText == "Banner Image") {
            setDocType(type.optionValue);
          }
        });
      },
      (message: string) => {
        //oast.error(message);
      }
    );
  };

  const onSubmit = (data: any) => {
    if (combinedFiles.length == 0) {
      showErrorAlert("Please upload minimum of 4 images");
      return;
    }
    if (combinedFiles.length < MIN_IMAGE_UPLOADS) {
      showErrorAlert("Please upload minimum of 4 images");
      return;
    }
    if (combinedFiles.length > MAX_IMAGE_UPLOADS) {
      showErrorAlert("Please upload maximum of 10 images");
      return;
    }
    if (!combinedFiles.find(el => el.DocumentTypeId == COVER_IMAGE_CODE)) {
      showErrorAlert("Please select a cover image");
      return;
    }

    const params: any = {
      entityName: "Facility",
      requestName: "CreateDocumentExecuteRequest",
      recordId: residenceID,
      inputParamters: { Documents: files },
    };
    if ((files?.length || 0) + (prevFiles?.length || 0) < MIN_IMAGE_UPLOADS) {
      showErrorAlert("Please upload minimum of 4 images");
      return;
    }
    showLoader();
    commonApi.post(
      params,
      (message: string, resp: any) => {
        hideLoader();
        navigate(URLS.UPLOAD_PROPERTY_DOCUMENTS, {
          state: { isEditUploadsOnly: locationState.isEditUploadsOnly },
        });
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const getPrevData = async () => {
    if (residenceID) {
      try {
        showLoader();
        const response: IApiResponse<{ Documents: TDocumentRetrieve[] }> =
          await API.post(EXECUTE_REQUEST_PATH, {
            entityName: "FacilityDocLib",
            requestName: "RetrieveDocument",
            inputParamters: {
              RelatedRecordId: residenceID,
              DocumentTypes: [COVER_IMAGE_CODE, NON_COVER_IMAGE_CODE],
            },
          });
        if (!response.isSuccess) {
          showErrorAlert(response.clientMessage || "An Error occurred");
        }
        let hasCover = false;
        const _prevFiles = response.outputParameters.Documents.map(el => {
          const _file: TFacilityDocumentUpload = {
            FacilityDocLibId: el.recordId,
            FileName: el.fileName,
            FileContent: el.fileContent,
            FileExtention: el.fileExtention,
            DocumentTypeId: el.documentTypeId,
          };
          if (!hasCover && el.documentTypeId == COVER_IMAGE_CODE) {
            hasCover = true;
            return _file;
          }
          return {
            ..._file,
            DocumentTypeId: NON_COVER_IMAGE_CODE,
          };
        });
        setPrevFiles(_prevFiles);
      } catch (e) {
        console.log(e);
        showErrorAlert("An error occurred");
      } finally {
        hideLoader();
      }
    } else {
    }
  };

  useEffect(() => {
    if (residenceID == null) {
      navigate("/add-residence");
    } else {
      getDocTypes();
    }
  }, []);

  useEffect(() => {
    getPrevData();
  }, []);

  const deleteLocal = (file: any) => {
    const _files: any = [];
    files.map((_file: any) => {
      if (_file.FileName != file.FileName) {
        _files.push(_file);
      }
    });

    setFiles(_files);
  };
  const setCoverLocal = (file: TFacilityDocumentUpload) => {
    const _files: TFacilityDocumentUpload[] = [...files].map(el => {
      if (el.FileName === file.FileName) {
        el.DocumentTypeId = COVER_IMAGE_CODE;
      } else {
        el.DocumentTypeId = NON_COVER_IMAGE_CODE;
      }
      return el;
    });
    if (prevFiles) {
      const updates = prevFiles.map(el => {
        el.DocumentTypeId = NON_COVER_IMAGE_CODE;
        return el;
      });
      setPrevFiles(updates);
    }
    setFiles(_files);
  };
  const deleteFromServer = (file: any) => {
    showLoader();
    commonApi.post(
      {
        entityName: "FacilityDocLib",
        recordId: file.FacilityDocLibId,
        requestName: "RemoveRecordReq",
      },
      (message: string, resp: any) => {
        hideLoader();
        getPrevData();
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };
  const setCoverServer = (file: TFacilityDocumentUpload) => {
    showLoader();
    commonApi.post(
      {
        entityName: "FacilityDocLib",
        recordId: file.FacilityDocLibId,
        requestName: "UpsertRecordReq",
        InputParamters: {
          Entity: {
            DocumentTypeId: COVER_IMAGE_CODE,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();
        getPrevData();
        if (files) {
          const updates = files.map(el => {
            el.DocumentTypeId = NON_COVER_IMAGE_CODE;
            return el;
          });
          setFiles(updates);
        }
      },
      (message: string) => {
        hideLoader();
        toast.error(message);
      }
    );
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <LegacyPageLayout>
      <section className="container">
        <div className="custome-container-inner d-flex">
          <LeftPanel page={4} lockToPage={locationState.isEditUploadsOnly} />
          <div className="white-block p-3">
            <div className="row">
              <div className="col">
                <h1>{name ? `${name} - ` : ""}Images</h1>
              </div>
            </div>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="tab_scrollcontent">
                <div className="residence-attachment-panel">
                  <h4>Attachments</h4>
                  *Minimum of 4 images
                  <Grid container spacing={2} sx={{ pb: 3 }}>
                    {prevFiles && prevFiles.length > 0
                      ? prevFiles.map((file, i: any) => (
                          <Grid item xs={3}>
                            <LegacyImageThumbnail
                              src={checkImageBase64Code(file.FileContent)}
                              onDelete={() => {
                                deleteFromServer(file);
                              }}
                              onSetCover={() => {
                                setCoverServer(file);
                              }}
                              isCover={file.DocumentTypeId == COVER_IMAGE_CODE}
                            />
                          </Grid>
                        ))
                      : null}
                    {files.map((file: any, i: any) => (
                      <Grid item xs={3}>
                        <LegacyImageThumbnail
                          src={checkImageBase64Code(file.FileContent)}
                          onDelete={() => {
                            deleteLocal(file);
                          }}
                          onSetCover={() => {
                            setCoverLocal(file);
                          }}
                          isCover={file.DocumentTypeId == COVER_IMAGE_CODE}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  <div className="upload-btn-wrapper">
                    <button
                      className="btn"
                      disabled={
                        (files?.length || 0) + (prevFiles?.length || 0) >=
                        MAX_IMAGE_UPLOADS
                      }
                    >
                      <img src="/assets/img/plus.png" alt="upload" />
                      <br />
                      Upload your attachment
                    </button>
                    <input
                      type="file"
                      multiple
                      disabled={
                        (files?.length || 0) + (prevFiles?.length || 0) >=
                        MAX_IMAGE_UPLOADS
                      }
                      accept="image/*"
                      {...register("images", { required: false })}
                    />
                  </div>
                </div>
                <div className="upload-instruction mt-3">
                  <h5>Please upload pictures of facility including:</h5>
                  <ul>
                    <li>Outdoor</li>
                    <li>Room / Bedroom</li>
                    <li>Kitchen / Kitchenette</li>
                    <li>Bathroom</li>
                  </ul>
                </div>
              </div>
              <div className="">
                <Button
                  {...LegacyCompatOverrides.button}
                  fullWidth
                  variant="contained"
                  type="submit"
                  disabled={
                    (files?.length || 0) + (prevFiles?.length || 0) <
                    MIN_IMAGE_UPLOADS
                  }
                >
                  Next
                </Button>
                {!locationState.isEditUploadsOnly && (
                  <Link to={URLS.MORE_ABOUT_PROPERTY}>
                    <button
                      type="button"
                      className="btn btn-outline-secondary mt-2 w-100 back-btn-add-prop"
                      data-bs-toggle="button"
                      style={{
                        // display:'flex',
                        // justifyContent:'center',
                        // alignItems:'center',
                        // height:'35px',
                        backgroundColor: "#fff",
                        color: "#000",
                      }}
                    >
                      Back
                    </button>
                  </Link>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </LegacyPageLayout>
  );
}
