import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { API_ROOT_URL } from "../_config";
import IntroSlider from "../components/introSlider";
import StudentRegForm from "./student/components/studentRegForm";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import ScrollableContainer from "../components/global/scrollableContainer";
import { API_KEY } from "../ui/v2/constants";

const StudentReg = () => {
  const { hideLoader } = useAppLoader();
  const user: any = useUserSelector();

  const [studentData, setStudentData] = useState<any>(null);

  const studentSecurityCheck = () => {
    const myHeaders = new Headers();

    myHeaders.append("api-key", API_KEY);

    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      entityName: "Employee",

      requestName: "StudentSecurityCheck",

      recordId: user?.recordId,

      inputParamters: {
        SecurityCheck: {
          //    studentData
          InstitutionId: studentData.InstitutionId,

          CampusId: studentData.CampusId,
          StudentNumber: studentData.StudentNumber,
          Gender: studentData.Gender,
          DisabilityStatus: studentData.DisabilityStatus,
          Email: studentData.Email,
          Name: studentData.Name,
          Surname: studentData.Surname,
        },
      },
    });

    const requestOptions: RequestInit = {
      method: "POST",

      headers: myHeaders,

      body: raw,

      redirect: "follow",
    };

    fetch(`${API_ROOT_URL}/v1.0/entities/ExecuteRequest`, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Parse the JSON response
      })
      .then(data => {
        // Access the individual fields
        hideLoader();
        const isSuccess = data.isSuccess;
        const clientMessage = data.clientMessage;

        if (isSuccess === true) {
          toast.success(data.clientMessage, {
            duration: 10000,
            position: "top-center",

            // Styling
            style: {},
            className: "",

            // Custom Icon

            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#fff",
              secondary: "green",
            },

            // Aria
            ariaProps: {
              role: "status",
              "aria-live": "polite",
            },
          });
          // toast.success(data.clientMessage)

          // ////////////////////////////////

          setTimeout(() => {
            // toast.success(data.clientMessage);
            // window.location.href = "/reset-password";
            window.location.href = "/proceed-to-login";
          }, 4000);
        } else if (isSuccess === false) {
          hideLoader();
          toast.error(data.clientMessage);
          hideLoader();
        }

        // If there are more fields in the response, you can access them in a similar way
      })
      .catch(error => {
        hideLoader(); // Hide loader in case of error
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    hideLoader();
    if (studentData) {
      studentSecurityCheck();
    }
  }, [studentData]);

  return (
    <React.Fragment>
      <div className="register_page">
        <div className="register_left">
          <ScrollableContainer>
            <div className="mob_logo">
              <a href="#" className="logo_left">
                <img src="/assets/img/logo.png" />
              </a>
            </div>
            <div className="logo_txt">Register Student</div>
            <div className="register_tab">
              <div className="tab-content" id="myTabContent">
                <StudentRegForm />
              </div>
            </div>
          </ScrollableContainer>
        </div>
        <IntroSlider />
      </div>
    </React.Fragment>
  );
};

export default StudentReg;
