import React from "react";
import {
  Bell,
  LogOut,
  Copyright,
  ChevronRight,
  Zap,
  Ban,
  Plus,
} from "lucide-react";
import LegacyPageLayout from "@/components/LegacyPageLayout";
import { useNavigate } from "react-router-dom";

const EmptyListingComponent = () => {
  const navigate = useNavigate();
  return (
    <LegacyPageLayout paddingTop={0}>
      {/* Header */}

      {/* Main Content */}
      <main className="p-4 max-w-md mx-auto border border-gray-500 rounded-md">
        {/* How it works section */}
        <div className="mb-8 flex flex-col items-center">
          <div className="bg-[#ffe6d5] rounded-md text-center w-12 h-12 flex items-center justify-center mb-6">
            <div className="text-2xl text-center flex items-center self-center">
              <Ban className="text-orange-500" />{" "}
            </div>
          </div>

          <h1 className="text-2xl font-bold mb-4 text-center ">
            No upcoming Property Viewing
          </h1>
          <p className="text-gray-600 mb-6 font-normal text-lg text-center">
            You do not yet have any property viewings reserved, apply to a
            property to start seeing upcoming reservations
          </p>
        </div>

        {/* Get Started Button */}
        <button
          onClick={() => navigate(`/student/search-residence`)}
          className="w-full mb-3 bg-orange-500 text-white py-4 px-6 rounded-lg font-semibold mt-6 flex items-center justify-center gap-2"
        >
          <Plus className="w-5 h-5" /> Apply for accommodation
        </button>
      </main>
    </LegacyPageLayout>
  );
};

export default EmptyListingComponent;
