import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/header";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { URLS } from "../_config";
import parse from "html-react-parser";
import Breadcrumbs from "./student/components/breadcrumbs";
import { Carousel } from "react-bootstrap";

const OpenCases = () => {
  const navigate = useNavigate();
  const user: any = useUserSelector();
  const { showLoader, hideLoader } = useAppLoader();
  const commonApi = useCommonApi();
  const { id, type } = useParams();
  // console.log({id, type})

  const [openCases, setOpenCases] = useState<any>(null);
  const [closeCases, setCloseCases] = useState<any>(null);
  const [caseStatus, setCaseStatus] = useState<any>(null);
  const [caseType, setCaseType] = useState<string>("");

  const getCases = () => {
    showLoader();
    commonApi.post(
      {
        recordId: id,
        requestName: "RetrieveCasesByTenantRequest",
        EntityName: type,
      },
      (message: string, resp: any) => {
        if (resp.outputParameters) {
          setOpenCases(resp.outputParameters.OpenCases);
          setCloseCases(resp.outputParameters.ClosedCases);
        }
        hideLoader();
      },
      (message: string) => {
        hideLoader();
      }
    );
  };

  const getCaseStatusList = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "CaseStatus",
          },
        },
      },
      (message: string, resp: any) => {
        if (resp.outputParameters.Count > 0) {
          setCaseStatus(resp.outputParameters.data);
        }
      },
      (message: string) => {}
    );
  };

  const checkImageBase64Code = (url: any) => {
    if (url.search("data:image/") == 0) {
      return url;
    } else {
      return "data:image/jpeg;base64," + url;
    }
  };

  const setImage = (images?: any) => {
    sessionStorage.setItem("detailsImage", JSON.stringify(images));
  };

  useEffect(() => {
    if (id && type) {
      getCases();
      setCaseType(type);
    }
  }, [id, type]);

  useEffect(() => {
    getCaseStatusList();
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <section className="container dashboard-view">
          {type == "Supplier" ? (
            <Breadcrumbs
              data={[{ label: "Home", path: "/" }, { label: "Cases" }]}
            />
          ) : (
            <Breadcrumbs
              data={[
                { label: "Home", path: "/" },
                {
                  label: "Residence Details",
                  path: `/residence-details/${id}`,
                },
                { label: "Cases" },
              ]}
            />
          )}

          <div className="white-block p-3 white-box-wide">
            <div className="row">
              <div className="col">
                <h5>Cases by tenant</h5>
              </div>
            </div>
            <hr></hr>
            <div className="open-case-main-wrapper">
              <h2>Open Cases</h2>
              <div className="row open-case-block-wrapper">
                {openCases && openCases.length > 0 ? (
                  openCases.map((openCase: any, i: number) => (
                    <div className="col-sm-4" key={i}>
                      <div className="open-case-block2">
                        <div className="open-case-pic">
                          {openCase?.images && openCase?.images.length > 0 ? (
                            <Carousel>
                              {openCase?.images.map((item: any, i: number) => (
                                <Carousel.Item key={i}>
                                  <img
                                    src={checkImageBase64Code(
                                      item?.fileContent
                                    )}
                                    alt="img"
                                  />
                                </Carousel.Item>
                              ))}
                            </Carousel>
                          ) : (
                            <img
                              src="/assets/img/no-image-available.jpg"
                              alt="img"
                            />
                          )}
                        </div>
                        <h3>{openCase?.facilityName}</h3>
                        <div className="open-case-feature-block-wrapper">
                          <div className="open-case-feature-block">
                            <h5>Tenant</h5>
                            <p>{openCase?.tenantIdName}</p>
                          </div>
                          <div className="open-case-feature-block">
                            <h5>Description</h5>
                            {openCase.description
                              ? parse(openCase.description)
                              : null}
                          </div>
                          <div className="open-case-feature-block">
                            <h5>Status</h5>
                            <p>
                              {caseStatus && caseStatus.length > 0
                                ? caseStatus.map((item: any) =>
                                    item.optionValue == openCase.casesStatusId
                                      ? item.optionText
                                      : ""
                                  )
                                : null}
                            </p>
                          </div>
                        </div>
                        <button
                          className="full-width-yellow-btn"
                          onClick={() => {
                            navigate(
                              `/open-cases-details/${openCase?.casesId}`
                            );
                            setImage(openCase?.images);
                            sessionStorage.setItem("caseType", caseType);
                          }}
                        >
                          Open Case Detail
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <h3>No Open Cases</h3>
                )}
              </div>
              <h2>Resolved Cases</h2>
              <hr></hr>
              <div className="row">
                {closeCases && closeCases.length > 0 ? (
                  closeCases.map((closeCase: any, i: number) => (
                    <div className="col-sm-4 mb-3" key={i}>
                      <div className="resolved-case-block">
                        <h3>{closeCase?.facilityName}</h3>
                        <div className="open-case-feature-block-wrapper">
                          <div className="open-case-feature-block">
                            <h5>Tenant</h5>
                            <h3>{closeCase?.tenantIdName}</h3>
                          </div>
                          <div className="open-case-feature-block">
                            <h5>Description</h5>
                            {closeCase.description
                              ? parse(closeCase.description)
                              : null}
                          </div>
                          <div className="open-case-feature-block">
                            <h5>Status</h5>
                            <h3>
                              {caseStatus && caseStatus.length > 0
                                ? caseStatus.map((item: any) =>
                                    item.optionValue == closeCase.casesStatusId
                                      ? item.optionText
                                      : ""
                                  )
                                : null}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h6>No Resolved Cases</h6>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default OpenCases;
