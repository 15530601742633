import React from "react";
import { createContext, useState } from "react";
import "./appLoader.scss";

const ApplicationLoaderContext = createContext<
  { isLoading: boolean; show: () => void; hide: () => void } | undefined
>(undefined);

const useApplicationLoaderContext = () => {
  const context = React.useContext(ApplicationLoaderContext);
  if (context === undefined) {
    throw new Error(
      "useApplicationLoaderContext must be used within a ApplicationLoaderProvider"
    );
  }
  return context;
};

function AppLoader() {
  const { isLoading } = ApplicationLoader.useContext();
  return isLoading ? (
    <div className="loader">
      <div className="app-loader-container">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
              margin: "auto",
              background: "transparent",
              display: "block",
            }}
            width="80px"
            height="80px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <defs>
              <clipPath
                id="ldio-y6wjb4v9bum-cp"
                x="0"
                y="0"
                width="100"
                height="100"
              >
                <rect x="0" y="0" width="0" height="100">
                  <animate
                    attributeName="width"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0;100;100"
                    keyTimes="0;0.5;1"
                  ></animate>
                  <animate
                    attributeName="x"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0;0;100"
                    keyTimes="0;0.5;1"
                  ></animate>
                </rect>
              </clipPath>
            </defs>
            <path
              fill="none"
              stroke="#D36E28"
              strokeWidth="2.7928"
              d="M82 63H18c-7.2 0-13-5.8-13-13v0c0-7.2 5.8-13 13-13h64c7.2 0 13 5.8 13 13v0C95 57.2 89.2 63 82 63z"
            ></path>
            <path
              fill="#D36E28"
              clipPath="url(#ldio-y6wjb4v9bum-cp)"
              d="M81.3 58.7H18.7c-4.8 0-8.7-3.9-8.7-8.7v0c0-4.8 3.9-8.7 8.7-8.7h62.7c4.8 0 8.7 3.9 8.7 8.7v0C90 54.8 86.1 58.7 81.3 58.7z"
            ></path>
          </svg>
          {/* <span>Please Wait</span> */}
        </div>
      </div>
    </div>
  ) : null;
}

const ApplicationLoaderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const show = () => setIsLoading(true);
  const hide = () => setIsLoading(false);

  return (
    <ApplicationLoaderContext.Provider value={{ isLoading, show, hide }}>
      <AppLoader />
      {children}
    </ApplicationLoaderContext.Provider>
  );
};

export const ApplicationLoader = {
  Provider: ApplicationLoaderProvider,
  useContext: useApplicationLoaderContext,
};
