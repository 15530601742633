import Box from "@mui/material/Box";
import React from "react";
import Illustration from "./Illustration";
import { Typography } from "@mui/material";
import { ThemeColors } from "../../../../styles/theme";

function EmptyState() {
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          maxWidth: 352,
          pt: 8,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Illustration />
        <Typography
          sx={{ fontSize: 20, fontWeight: 600, pt: 2, textAlign: "center" }}
        >
          No students available at the moment.
        </Typography>
        <Typography
          sx={{ color: ThemeColors.textTertiary, textAlign: "center", pt: 1 }}
        >
          After completing your search, student profiles will be shown here.
        </Typography>
      </Box>
    </Box>
  );
}

export default EmptyState;
