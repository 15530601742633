import React, { useMemo } from "react";
import { Box, Link, Typography } from "@mui/material";

import Dropzone, { DropzoneProps } from "react-dropzone";

import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";

function FileDropzone({
  acceptedFiles,
  onDrop,
  maxSize = 4,
  disabled,
}: {
  disabled?: boolean;
  acceptedFiles: DropzoneProps["accept"];
  onDrop: DropzoneProps["onDrop"];
  maxSize?: number;
}) {
  const acceptedFilesString = useMemo(() => {
    let _ = "";
    Object.entries(acceptedFiles as any).map(([, types]) => {
      _ += (types as string[]).join(", ").toLocaleUpperCase();
    });
    return _;
  }, [acceptedFiles]);

  return (
    <Dropzone
      disabled={disabled}
      onDrop={onDrop}
      accept={acceptedFiles}
      maxSize={maxSize * 1024 * 1024}
      maxFiles={1}
    >
      {({ getRootProps, getInputProps }) => (
        <Box
          sx={{
            border: "1px solid #EAECF0",
            px: 4,
            py: 2,
            cursor: disabled ? "no-drop" : "pointer",
            background: disabled ? "#F9FAFB" : undefined,
            borderRadius: 3,
            ":hover": {
              border: `1px solid ${disabled ? "#EAECF0" : "#F88A0B"}`,
            },
          }}
        >
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography textAlign="center">
              <Box
                sx={{
                  height: 40,
                  width: 40,
                  borderRadius: 2,
                  border: "1px solid #EAECF0",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 1,
                }}
              >
                {/* TODO: Match figma Icon */}
                <BackupOutlinedIcon sx={{ color: "#475467" }} />
              </Box>
            </Typography>

            <Typography
              fontSize={14}
              textAlign="center"
              sx={{ color: "#475467" }}
              mb={1}
            >
              {disabled ? (
                <strong>Click to Upload</strong>
              ) : (
                <Link href="#">
                  <strong>Click to Upload</strong>
                </Link>
              )}
              &nbsp; or drag and drop
            </Typography>
            <Typography
              fontSize={12}
              textAlign="center"
              sx={{ color: "#475467" }}
            >
              {acceptedFilesString} (max. {maxSize}MB)
            </Typography>
          </div>
        </Box>
      )}
    </Dropzone>
  );
}

export default FileDropzone;
