import React, { useState, useEffect } from "react";
import Header from "../components/header";
import { Box, Paper, Typography } from "@mui/material";
import { RequiredAsterisk } from "../components/blocks/requiredAsterisk";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  GENDER_OPTIONS,
  STUDENT_STATUS_OPTIONS,
  ID_TYPE,
  ETHNICITY_OPTIONS,
  NATIONALITY_OPTIONS,
  genderOptions,
  DISABLED_STATUS_OPTIONS,
} from "../constants";
import { Button, Form } from "react-bootstrap";
import { DevTool } from "@hookform/devtools";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import StudentDetailsForm from "./student/components/registration/studentDetailsForm";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "./student/components/breadcrumbs";

interface InstitutionType {
  InstitutionId: string;
  InstitutionIdName: string;
}
interface StudentDetails {
  Address: string;
  CampusId: string;
  CampusIdName: string;
  CampusIdName1: string;
  DateOfBirth: string;
  DisabilityText: string;
  DisabilityValue: number;
  Email: string;
  EmployeeId: string;
  EthnicityText: null;
  EthnicityValue: null;
  FirstName: string;
  GenderText: string;
  GenderValue: number;
  IdNumber: string;
  IdTypeText: string;
  IdTypeValue: number;
  LastName: string;
  Mobile: string;
  Name: string;
  NationalityId: string;
  NationalityIdName: string;
  StudentNumber: string;
  StudentStatusText: string;
  StudentStatusValue: any;
  Telephone: string;
  sendInvite: boolean;
}

const AddStudent = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const [institutions, setInstitutions] = useState<any>(null);
  const [campuses, setCampuses] = useState<any>(null);
  const { id } = useParams<{ id: string }>();
  const [studentDetails, setStudentDetails] = useState<StudentDetails[]>([]);

  const institution = watch("institution");
  const campus = watch("campus");

  const commonApi = useCommonApi();

  const [myDate, setMyDate] = useState<any>();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const { showLoader, hideLoader } = useAppLoader();
  const [enabled, setEnabled] = useState(false);
  const navigate = useNavigate();

  const formatDate = () => {
    studentDetails.map(details => {
      const date = details.DateOfBirth;
      // console.log("😴",date);
      const dateObj = new Date(date);
      // console.log("🧒",moment().add(10, 'days').calendar());
      // console.log("🧒",moment(date).format('YYYY/MM/DD'));
      setMyDate(moment(date).format("MM/DD/YYYY"));

      // Assuming studentDetail.DateOfBirth is a string like "2000-02-11T00:00:00"
      setDateOfBirth(moment(details.DateOfBirth).format("YYYY-MM-DD"));
    });
  };

  const handleDateChange = (e: any) => {
    setDateOfBirth(e.target.value);
  };

  const getInstitutions = () => {
    commonApi.retreiveInstitution(
      {},
      (message: string, resp: any) => {
        const options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search("000000") !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        });
        setInstitutions(options);
      },
      (message: string) => {
        // console.log(message);
      }
    );
  };

  const getCampus = (id: any = institution?.value) => {
    return new Promise((resolve: any, reject: any) => {
      commonApi.getCampus(
        {
          filters: "InstitutionId eq '" + id + "'",
          ViewName: "LookUpView",
        },
        (message: string, resp: any) => {
          const options: any = [];
          resp.map((ins: any) => {
            if (ins.recordId.search("000000") !== 0) {
              ins.value = ins.recordId;
              ins.label = ins.mainText;
              options.push(ins);
            }
          });
          setCampuses(options);
          setTimeout(() => {
            resolve();
          }, 300);
        },
        (message: string) => {
          // console.log(message);
          reject();
        }
      );
    });
  };

  useEffect(() => {
    formatDate();
  }, [studentDetails]);

  useEffect(() => {
    getInstitutions();
    // getStudentDetails();
  }, []);
  useEffect(() => {
    if (institution) {
      getCampus();
    }
  }, [institution]);

  //
  const [details, setDetails] = useState<any>();

  const onSubmit = (data: any) => {
    console.log("called", data);

    commonApi.post(
      {
        entityName: "Employee",
        requestName: "UpsertRecordReq",
        //   "recordId": id,
        inputParamters: {
          Entity: {
            Address: data.Address,
            FirstName: data.firstName,
            LastName: data.LastName,
            Email: data.Email,
            Mobile: data.Mobile,
            Name: data.FullName,
            StudentStatus: data.studentStatus.value,
            CampusId: data.Campus.value,
            NationalId: data.nationalId,
            DateOfBirth: data.dateOfBirth,
            sendInvite: data.invite,
            CompanyId: data.institution.value,
            GenderId: data.gender.value,
            DisabledId: data.disability.value,
            EthnicityId: data.ethnicity.value,
            EmployeeNo: data.StudentNumber,
            IDType: data.idType.value,
            NationalityId: data.nationality.value,
            Telephone: data.Telephone,
          },
        },
      },
      (message: string, resp: any) => {
        hideLoader();

        toast.success(message);

        setTimeout(() => {
          navigate("/");
        }, 2000);

        // hideLoader()
        // console.log({ resp })
      },
      (message: string) => {
        // toast.error(message)
        hideLoader();
        // console.log(message);
      }
    );
  };

  const handleRegistration = (data: any, event: any) => {
    event.preventDefault();
  };
  const renderErrorMessage = (name: string) => {
    const { message } = errors[name] || {};
    return (message as string) || null;
  };

  return (
    <div className="">
      <Header />
      <Box sx={{ marginTop: "6rem", paddingLeft: "2rem" }}>
        <Breadcrumbs
          data={[
            { label: "Home", path: "/" },
            { label: "Student List", path: "/student-list" },
            { label: "Add Student" },
          ]}
        />
      </Box>
      <>
        <form
          onSubmit={handleSubmit(onSubmit, () => {
            console.log("invalid");
          })}
        >
          <Box
            sx={{
              marginTop: "7rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "50px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Create Student
              </Typography>
            </Box>
            <Paper sx={{ padding: "2rem" }}>
              <Box sx={{ marginBottom: "3rem" }}>
                <Typography>Student Details</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Full Name
                    <RequiredAsterisk />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="Full Name"
                    style={{ width: "60%" }}
                    {...register("FullName")}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    Student Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="Student Number"
                    style={{ width: "60%", textAlign: "left" }}
                    {...register("StudentNumber")}
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    First Name
                    <RequiredAsterisk />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="First Name"
                    style={{ width: "60%" }}
                    {...register("firstName")}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    Student Status
                    <RequiredAsterisk />
                  </label>
                  <div className="" style={{ width: "60%" }}>
                    <Controller
                      name={"studentStatus"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.institution ? " is-invalid" : "")
                            }
                            options={STUDENT_STATUS_OPTIONS}
                            placeholder={"Choose Student Status"}
                            value={value}
                            onChange={onChange}
                            // {...register('studentStatus')}
                            // name={"StudentStatusText"}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Last Name
                    <RequiredAsterisk />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="Last Name"
                    style={{ width: "60%" }}
                    {...register("LastName")}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label"}
                  >
                    Nationality
                    <RequiredAsterisk />
                  </label>
                  <div className="" style={{ width: "60%" }}>
                    {/* <Select
                                        options={NATIONALITY_OPTIONS}
                                        placeholder={"Choose Option"}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={value}
                                    /> */}
                    <Controller
                      name={"nationality"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.institution ? " is-invalid" : "")
                            }
                            options={NATIONALITY_OPTIONS}
                            placeholder={"choose Option"}
                            value={value}
                            onChange={onChange}
                            name={"Nationality"}

                            // {...register('institution', { required: true } )}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Ethinicity
                    <RequiredAsterisk />
                  </label>
                  <div className="" style={{ width: "60%" }}>
                    <Controller
                      name={"ethnicity"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.institution ? " is-invalid" : "")
                            }
                            options={ETHNICITY_OPTIONS}
                            placeholder={"Choose Option"}
                            value={value}
                            onChange={onChange}
                            name={"ethinicity"}
                            // defaultInputValue={studentDetail?.EthnicityValue}

                            // {...register('institution', { required: true } )}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    ID Type
                    <RequiredAsterisk />
                  </label>
                  {/* <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="90 Bekker Road" style={{width:'60%'}}  /> */}
                  <div className="" style={{ width: "60%" }}>
                    <Controller
                      name={"idType"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.institution ? " is-invalid" : "")
                            }
                            options={ID_TYPE}
                            placeholder={"choose Option"}
                            value={value}
                            onChange={onChange}
                            // {...register('studentStatus')}

                            // {...register('institution', { required: true } )}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Disabled
                    <RequiredAsterisk />
                  </label>
                  <div className="" style={{ width: "60%" }}>
                    <Controller
                      name={"disability"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.institution ? " is-invalid" : "")
                            }
                            options={DISABLED_STATUS_OPTIONS}
                            placeholder={"choose Option"}
                            // value={value}
                            onChange={onChange}
                            // {...register('disability')}

                            // {...register('institution', { required: true } )}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    National ID
                    <RequiredAsterisk />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="National ID"
                    style={{ width: "60%" }}
                    {...register("nationalId")}
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Gender
                    <RequiredAsterisk />
                  </label>
                  <div className="" style={{ width: "60%" }}>
                    <Controller
                      name={"gender"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.institution ? " is-invalid" : "")
                            }
                            options={genderOptions}
                            placeholder={"choose Option"}
                            value={value}
                            onChange={onChange}
                            // {...register('studentStatus')}

                            // {...register('institution', { required: true } )}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Date Of Birth
                  </label>
                  {/* <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="90 Bekker Road" style={{width:'60%'}}  /> */}
                  <div className="" style={{ width: "60%" }}>
                    <Form.Control
                      type="date"
                      id="inputPassword5"
                      aria-describedby="passwordHelpBlock"
                      // onChange={handleDateChange}

                      {...register("dateOfBirth")}
                    />
                  </div>
                </div>
              </Box>
              {/* <Box sx={{}}>
                    <div
                      className="mb-2"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        marginTop: "1rem",
                        justifyContent: "",
                      }}
                    >
                      <label
                        htmlFor="exampleFormControlInput1a"
                        className={"form-label "}
                      >
                        Send Invite
                      </label>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        // label="Send Invite"
                        style={{paddingLeft:'5.5em'}}
                        {...register('invite')}
                      />
                    </div>
                  </Box> */}
            </Paper>

            {/* **************************** Contact Information ********************************** */}

            <Paper sx={{ padding: "2rem", marginTop: "2rem" }}>
              <Box sx={{ marginBottom: "3rem" }}>
                <Typography>Contact Information</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="Email"
                    style={{ width: "60%" }}
                    {...register("Email")}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    Mobile
                    <RequiredAsterisk />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="Mobile No."
                    style={{ width: "60%", textAlign: "left" }}
                    {...register("Mobile")}
                  />
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    Telephone
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="Tel No."
                    style={{ width: "60%" }}
                    {...register("Telephone")}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    Home Address
                    <RequiredAsterisk />
                  </label>
                  {/* <input type="text" className="form-control" id="exampleFormControlInput1a" placeholder="90 Bekker Road" style={{width:'60%'}}  /> */}
                  <div className="" style={{ width: "60%" }}>
                    <textarea
                      placeholder={"Home Address"}
                      style={{ width: "410px", height: "80px" }}
                      {...register("Address")}
                    />
                  </div>
                </div>
              </Box>
            </Paper>

            {/* ************************************ Course Information ********************************/}

            <Paper sx={{ padding: "2rem", marginTop: "2rem" }}>
              <Box sx={{ marginBottom: "3rem" }}>
                <Typography>Course Information</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Institution
                    <RequiredAsterisk />
                  </label>
                  <div className="" style={{ width: "80%" }}>
                    <Controller
                      name={"institution"}
                      control={control}
                      // rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.institution ? " is-invalid" : "")
                            }
                            options={institutions}
                            placeholder={"Choose Institution"}
                            value={value}
                            onChange={onChange}
                            // {...register('institution', { required: true } )}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Campus
                  </label>
                  <div className="" style={{ width: "80%" }}>
                    <Controller
                      name={"Campus"}
                      control={control}
                      // rules={{ required: 'Campus Required'}}
                      render={({ field: { value, onChange, onBlur } }) => {
                        return (
                          <Select
                            className={
                              "form-control p-0" +
                              (errors?.campus ? " is-invalid" : "")
                            }
                            options={campuses}
                            placeholder={"Choose Campus"}
                            onChange={onChange}
                            value={value}

                            // {...register('Campus')}
                          />
                        );
                      }}
                    />
                    {renderErrorMessage("campus")}
                  </div>
                </div>
              </Box>
            </Paper>

            <Box
              sx={{
                marginTop: "2rem",
                marginBottom: "2rem",
                display: "flex",
                gap: "1rem",
                justifyContent: "end",
              }}
            >
              <Button variant="primary" style={{ width: "10%" }} type="submit">
                Create
              </Button>
              <Button
                variant="danger"
                style={{ width: "10%" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </form>
      </>

      <DevTool control={control} />
    </div>
  );
};

export default AddStudent;
