import React from "react";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import IntroSlider from "../components/introSlider";
import SecurityForm from "./student/components/securityForm";
import { EXECUTE_REQUEST_PATH } from "../ui/v2/constants";
import { IApiResponse } from "../ui/v2/types/IApiResponse";
import { API } from "../ui/v2/services/API";
import { TStudentSecurityForm } from "../ui/v2/types/TStudentSecurityForm";
import useCurrentUser from "../ui/v2/hooks/useCurrentUser";
import { logout } from "../store/sagas/userSaga";
import { useUserApi } from "../_common/hooks/api/UserApiHook";

const StudentRegistration = () => {
  const { showLoader, hideLoader } = useAppLoader();
  const currentUser = useCurrentUser();
  const userApi = useUserApi();
  const navigate = useNavigate();

  const onSubmitStudentDetailsForm = async (data: TStudentSecurityForm) => {
    try {
      showLoader();
      const response: IApiResponse<unknown> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Employee",
          requestName: "StudentSecurityCheck",
          recordId: currentUser?.recordId,
          inputParamters: {
            SecurityCheck: data,
          },
        }
      );
      if (response.isSuccess) {
        toast.success(response.clientMessage);
        setTimeout(() => {
          navigate("/reset-password");
          hideLoader();
        }, 1500);
      } else {
        toast.error(response.clientMessage);
        hideLoader();
      }
    } catch (e) {
      console.error(e);
      hideLoader();
    }
  };

  return (
    <React.Fragment>
      <div className="register_page">
        <div className="register_left">
          <div className="mob_logo">
            <a href="#" className="logo_left">
              <img src="/assets/img/logo.png" />
            </a>
          </div>
          <div className="logo_txt">Update Details</div>
          <div className="register_tab">
            <div className="tab-content" id="myTabContent">
              <SecurityForm
                onSubmitStudentDetailsForm={onSubmitStudentDetailsForm}
              />
            </div>
          </div>
        </div>
        <IntroSlider />
      </div>
    </React.Fragment>
  );
};

export default StudentRegistration;
