import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
type Props = {
  title: string;
  subtitle: string;
  onCancelClick?: () => void;
  onSaveClick?: () => void;
  disabled?: boolean;
};

function PageActionsBar({
  title,
  subtitle,
  onCancelClick,
  onSaveClick,
  disabled,
}: Props) {
  return (
    <>
      <Grid item xs={12} md={9}>
        <Typography fontWeight="bold" fontSize={18}>
          {title}
        </Typography>
        <Typography fontSize={14}>{subtitle}</Typography>
      </Grid>
      <Grid textAlign="right" item xs={12} md={3}>
        {/* <Box display="inline-flex" gap={1}>
          <Button size="small" variant="outlined" color="secondary" disabled={disabled} onClick={onCancelClick}>
            Cancel
          </Button>
          <Button size="small" variant="contained" disabled={disabled} onClick={onSaveClick}>
            Save
          </Button>
        </Box> */}
      </Grid>
    </>
  );
}

export default PageActionsBar;
