import { apiSlice } from "../slices/apiSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getGroupById: builder.query({
      query: ({ authToken, id }) => ({
        url: `/association/api/v1/group/${id}`,
        method: "GET",
        headers: { Authorization: authToken },
      }),
      providesTags: [],
    }),
    forgotPassword: builder.mutation({
      query: ({ body, authToken }) => ({
        url: `/ExecuteRequest`,
        method: "POST",
        body,
        headers: { Authorization: `Bearer ${authToken}` },
      }),
      invalidatesTags: [],
    }),
  }),
});

export const { useForgotPasswordMutation } = authApi;
