import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  AppBar,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Close from "@mui/icons-material/Close";
import { ArrowOutward } from "@mui/icons-material";
import { ThemeColors } from "../../../styles/theme";
import TableCheckbox from "../../../components/GenericListingScreen/ListingGrid/Checkbox";
import { useState } from "react";
import { Check, X } from "lucide-react";

export default NiceModal.create(
  ({
    onCancel,
    onProceed,
    onCancelApplication,
  }: {
    onCancel: () => void;
    onProceed: () => void;
    onCancelApplication: () => void;
  }) => {
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [stay, setStay] = useState<boolean | null>(null);
    // Use a hook to manage the modal state
    const modal = useModal();

    const handleCancel = async (e: unknown, reason?: string) => {
      if (reason && reason === "backdropClick") {
        return;
      }
      onCancel?.();
      await modal.hide();
      modal.remove();
    };
    const handleCancelApplication = async (e: unknown, reason?: string) => {
      if (reason && reason === "backdropClick") {
        return;
      }
      onCancelApplication?.();
      onCancel?.();
      await modal.hide();
      modal.remove();
    };

    const handleProceed = async () => {
      onProceed();
      await modal.hide();
      modal.remove();
    };

    if (!modal.visible) return null;
    return (
      <Dialog maxWidth="sm" open onClose={handleCancel}>
        <AppBar sx={{ position: "relative" }} elevation={0} color="transparent">
          <Toolbar>
            <IconButton edge="start" onClick={handleCancel} aria-label="close">
              <Close />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              &nbsp;
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography
              color="inherit"
              sx={{
                fontSize: 36,
                lineHeight: "44px",
                fontFamily: "Inter",
                fontWeight: 600,
                my: 3,
              }}
            >
              Your check-in is complete.
            </Typography>
            <Typography sx={{ pb: 10 }}>
              We&apos;ll notify you once the check-in is processed. Typically,
              this process can range from 5 minutes to an hour, although in some
              cases, it may take up to 48 hours.
            </Typography>
            <Box
              flex={1}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                pb: 4,
              }}
            >
              <Box sx={{ background: ThemeColors.white, borderRadius: 1 }}>
                {stay === true && (
                  <FormControlLabel
                    control={
                      <TableCheckbox
                        checked={isConfirmed}
                        onChange={() => setIsConfirmed(!isConfirmed)}
                      />
                    }
                    sx={{
                      my: 2,
                      width: "100%",
                      mx: 0,
                      borderRadius: 2,
                      p: 1,
                      border: `2px solid ${ThemeColors.primaryOrange}`,
                    }}
                    label={
                      <Box>
                        <Typography>
                          <strong>I confirm that</strong>
                        </Typography>
                        <Typography>
                          I have inspected the property and consent to make it
                          my new residence.
                        </Typography>
                      </Box>
                    }
                  />
                )}
                {/* <Button
                  endIcon={<ArrowOutward />}
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleProceed}
                  disabled={!isConfirmed}
                >
                  Continue
                </Button> */}
                {/* <div className="min-h-screen  flex items-center justify-center p-4"> */}
                <PropertySearchModal
                  isOpen={showModal}
                  onClose={() => setShowModal(false)}
                  onConfirm={handleCancelApplication}
                />
                {/* </div> */} 
                {!isConfirmed && stay===null && (
                  <Button
                    endIcon={<ArrowOutward />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => setStay(true)}
                    sx={{mb:2}}
                    // disabled={!isConfirmed}
                  >
                    I want to stay
                  </Button>
                )}
                {!isConfirmed && stay===null && (
                  <Button
                    endIcon={<ArrowOutward />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      setShowModal(true);
                      setStay(null);
                    }}
                    // disabled={!isConfirmed}
                  >
                    I&apos;ll keep looking
                  </Button>
                )}

                {isConfirmed && stay === true && (
                  <Button
                    endIcon={<ArrowOutward />}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleProceed}
                    disabled={!isConfirmed}
                  >
                    Continue to Sign lease
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
);

interface PropertySearchModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (e: unknown, reason?: string) => Promise<void>;
}

const PropertySearchModal = ({
  isOpen,
  onClose,
  onConfirm,
}: PropertySearchModalProps) => {
  return (
    <Dialog open={isOpen} onChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <div className="flex flex-col items-center px-4 pt-8 pb-6">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6 rounded-sm" />
          </button>

          {/* Success Icon */}
          <div className="w-16 h-16 bg-green-50 rounded-full flex items-center justify-center mb-6">
            <Check className="h-8 w-8 text-green-500" />
          </div>

          {/* Content */}
          <div className="text-left space-y-4 mb-8">
            <h2 className="text-2xl font-semibold text-gray-900">
              Keep searching for properties?
            </h2>
            <p className="text-gray-600 text-left leading-relaxed">
              This will cause your accommodation application to no longer be
              reserved. You would have to apply to this property again should
              you want it later.
            </p>
          </div>

          {/* Buttons */}
          <div className="w-full space-y-3">
            <button
              onClick={onConfirm}
              className="w-full bg-orange-500 text-white py-3 px-4 rounded-lg hover:bg-orange-600 transition-colors"
            >
              Confirm
            </button>
            <button
              onClick={onClose}
              className="w-full border border-gray-200 text-gray-600 py-3 px-4 rounded-lg hover:bg-gray-50 transition-colors"
            >
              Cancel
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
