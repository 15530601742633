import { Close } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React from "react";

function SearchedTextBanner({
  searchedText,
  onClearSearch,
}: {
  searchedText: string;
  onClearSearch: () => void;
}) {
  return (
    <Box sx={{ py: 3 }}>
      <Alert
        color="info"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClearSearch}
          >
            <Close fontSize="inherit" color="error" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        Showing search results for <strong>{searchedText}</strong>.
      </Alert>
    </Box>
  );
}

export default SearchedTextBanner;
