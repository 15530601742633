import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import { URLS } from "../_config";
import { MdAddCircle } from "react-icons/md";
import Breadcrumbs from "./student/components/breadcrumbs";

const Institution = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <section className="container dashboard-view">
          <Breadcrumbs
            data={[{ label: "Home", path: "/" }, { label: "Upload" }]}
          />

          <div className="container dashboard-view">
            <div className="white-block p-3 white-box-wide institution-wrapper">
              <Row>
                <Col>
                  <h5>Upload Residences</h5>
                </Col>
                <Col className="text-end">
                  <span>
                    <Button className="icon-btn">
                      <MdAddCircle />
                    </Button>
                  </span>
                </Col>
              </Row>
              <hr></hr>
              <div className="upload-card">
                <div
                  className="upload-btn-wrapper"
                  onClick={() => navigate(URLS.ADD_RESIDENCE)}
                >
                  <button className="btn">
                    <img src="/assets/img/plus.png" alt="upload" />
                    <br />
                    Add new documents
                  </button>
                  {/* <input type="file" multiple accept="application/pdf" /> */}
                </div>
                <div
                  className="text-center"
                  onClick={() => navigate(URLS.INSTITUTION_UPLOAD)}
                >
                  <button className="upload-bulk-btn">Bulk upload</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};

export default Institution;
