import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import Header from "../components/header";
import Breadcrumbs from "./student/components/breadcrumbs";
import FindAccomodation from "./student/components/findAccomodation";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import parse from "html-react-parser";
import StudentRoomModal from "./student/components/studentRoomModal";
import AccomodationCardSlider from "./student/components/accomoationCardSlider";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useHistory } from "react-router-use-history";
import { Box } from "@mui/material";
// import emptyIcon from '../../images/empty-set.png';s

export default function ViewProperties() {
  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();
  const [residence, setResidence] = useState<any>(null);
  const [show, setShow] = useState<boolean>(false);

  const { id } = useParams();

  const getResidence = () => {
    showLoader();
    commonApi.post(
      {
        recordId: id,
        requestName: "RetrieveFacilityDetails",
      },
      (message: string, resp: any) => {
        hideLoader();
        setResidence(resp.outputParameters.FacilitiyDetails[0]);
        console.log("Residence =>", resp.outputParameters.FacilitiyDetails[0]);
      },
      (message: string) => {
        toast.error(message);
        hideLoader();
      }
    );
  };

  const checkImageBase64Code = (url: any) => {
    if (url.search("data:image/") == 0) {
      return url;
    } else {
      return "data:image/jpeg;base64," + url;
    }
  };

  const imageSource = (docId: any, folder: any) => {
    return (
      process.env.REACT_APP_API_DOMAIN +
      "/api/v1/entities/imageview?id=" +
      docId +
      "&folder=" +
      folder +
      "&apikey=" +
      process.env.REACT_APP_API_KEY
    );
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    getResidence();
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard" style={{ paddingTop: "0px" }}>
        <Header />
        <Box sx={{ marginTop: "2rem", paddingLeft: "2rem" }}>
          <Breadcrumbs
            data={[
              { label: "Home", path: "/" },
              { label: "Dashbaord", path: "/institution-dashboard" },
              { label: "View Properties" },
            ]}
          />
        </Box>
        <div className="container">
          <div style={{ textAlign: "center" }}>{residence?.name}</div>

          {residence ? (
            <div className="accomodation-details">
              <div className="card">
                {/* <div className="banner">
                                    {residence.images?.length > 0 ?
                                        <img src={checkImageBase64Code(residence.images[0].fileContent)} />
                                        :
                                        <img src="/assets/img/no-image-available.jpg" />}
                                </div> */}
                <AccomodationCardSlider
                  data={residence}
                  route={"accomodation-details"}
                />

                <div style={{ padding: "10px" }}>
                  <div className="name-rating">
                    <div className="">
                      <div className="my-residence-and-grade">
                        <h2>{residence.name}</h2>
                        <div className="residence-rating">
                          GRADE {residence.rating}
                        </div>
                      </div>
                      <p className="res-distance">{residence.distance}</p>
                    </div>
                    {/* <div className='residence-rating'>{getRating(residence.rating, 5, 5)}</div> */}
                  </div>
                  {/* <div className="rating">{getRating(residence.rating, 5, 5)}</div> */}
                  {residence.message ? (
                    <div className="note">{residence.message}</div>
                  ) : null}
                  <Row className="student-place pt-4 pb-4">
                    {/* <Col md={6}>
                                            <h2>{residence.name}</h2>
                                        </Col>
                                        <Col md={6}>
                                            <p>{residence.distance}</p>
                                        </Col> */}
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Row>
                        {residence.amenities.length > 0 ? (
                          residence.amenities?.map((amenity: any, i: any) => (
                            <Col className="text-center" key={i}>
                              {/* {amenity.icon ? parse(amenity.icon) : null} */}
                              {/* <p>{amenity.iconName}</p> */}
                              {/* <img src={emptyIcon} style={{width:'2rem', paddingBottom:'2rem'}}/>   */}
                            </Col>
                          ))
                        ) : (
                          <div className="amenity d-flex error-msg-text">
                            {/* <i className="fa fa-refresh me-5" style={{ color: "red" }} aria-hidden="true" /> */}
                          </div>
                        )}
                      </Row>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={2}>
                      {/* <Button variant="success" className='w-100'>Available</Button>
                                            <Row>
                                                <Col sm={6} className="price">
                                                    <label>Price</label>
                                                    <div>R{residence.price}</div>
                                                </Col>
                                                <Col sm={6} className="capacity">
                                                    <label>Capacity</label>
                                                    <div>{residence.capacity}</div>
                                                </Col>
                                            </Row> */}
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <h4>Address</h4>
                      <p>{residence.address}</p>
                      <h6 style={{ maxWidth: "90%" }}>{residence.distance}</h6>
                    </Col>
                  </Row>
                  {/* <Row className="pt-4">
                                        <Col sm={6} className="address-panel">
                                            <h4>Addresses</h4>
                                            <p>{residence.address}</p>
                                            <h6>{residence.distance}</h6>
                                            <div className='mt-3'>
                                                <img src="/assets/img/map.jpg" />
                                            </div>
                                        </Col>
                                        <Col sm={6} className="address-features">
                                            <h4>Addresses</h4>
                                            <Row>
                                                {residence && residence.rooms.length > 0 ? residence.rooms.map((item: any, i: number) => (
                                                    <Col sm={4} key={i}>
                                                        <div className="address-features-block">
                                                            <div className="icon">{item.icon ? parse(item.icon) : null}</div>
                                                            <h4>{item?.iconName}</h4>
                                                            <div className="address-features-block-inner text-center">
                                                                <h6>Price</h6>
                                                                <h5>R{item?.pricePerBed}</h5>
                                                                <h6>Applications/Capacity</h6>
                                                                <h5>10/{item?.quantity}</h5>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))
                                                    : null}
                                            </Row>
                                        </Col>
                                    </Row> */}

                  <div className="pt-4">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <AiOutlinePlus></AiOutlinePlus> Property Overview
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="student-accordian-content">
                            <Row className="row-alternet">
                              <Col sm={6}>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Listing Number</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {
                                        residence?.propertyOverView
                                          ?.listingNumber
                                      }
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Study Area</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {residence?.propertyOverView?.studyArea}
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Address</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>{residence?.address}</h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Description</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {residence?.propertyOverView?.description}
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Lease Period</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {residence?.propertyOverView?.leasePeriod}
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Disability Friendly</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {
                                        residence?.propertyOverView
                                          ?.disbilityFriendly
                                      }
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={6}>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Self-Catering</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {
                                        residence?.propertyOverView
                                          ?.selfCatering
                                      }
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Room Type</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {residence?.propertyOverView?.roomType}
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Gender</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {residence?.propertyOverView?.gender}
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Shuttle Service</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>
                                      {
                                        residence?.propertyOverView
                                          ?.shuttleService
                                      }
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Wifi</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>{residence?.propertyOverView?.wifi}</h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={6}>
                                    <h5>Gym</h5>
                                  </Col>
                                  <Col sm={6} className="text-end">
                                    <h6>{residence?.propertyOverView?.gym}</h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <AiOutlinePlus></AiOutlinePlus> Amenities
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col md={12}>
                              <Row>
                                {residence.amenities?.map(
                                  (amenity: any, i: any) => (
                                    <>
                                      <Col
                                        md={6}
                                        className="text-center"
                                        key={i}
                                      >
                                        {amenity.icon ? (
                                          parse(amenity.icon)
                                        ) : (
                                          <>
                                            <img
                                              src={""}
                                              alt="empty icon"
                                              style={{ width: "1rem" }}
                                            />
                                          </>
                                        )}{" "}
                                        {/* <p>{amenity.iconName}</p> */}
                                      </Col>
                                      <Col md={6}>
                                        <p>{amenity.iconName}</p>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                  <div className="pt-3">
                    <h3>Gallery</h3>
                    <Row>
                      {residence?.images.map((image: any, i: any) => (
                        <Col sm={4} key={i}>
                          <div className="student-gallery-pic">
                            <img
                              src={imageSource(
                                image.facilityDocLibId,
                                "FacilityDocLib"
                              )}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
                {/* <Col md={12}>
                                    {residence && residence.rooms.length > 0 ?
                                        <Button variant='primary' className='w-100' onClick={() => setShow(true)}>Apply</Button>
                                    : null}
                                </Col> */}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <StudentRoomModal
        shouldShow={show}
        handleClose={handleClose}
        residence={residence}
      />
    </React.Fragment>
  );
}
