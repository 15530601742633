import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

interface incomingProps {
  base64PDF: string;
}

const EftPaymentModalContent = ({ base64PDF }: incomingProps) => {
  const pdfSource = `data:application/pdf;base64,${base64PDF}`;

  return (
    <>
      <Box>
        <Box>
          <iframe src={pdfSource} style={{ width: "100%", height: "100vh" }} />
          {/* <iframe src={"https://assets.ezra.com/images/images/Terms and Conditions.pdf"} style={{width:'100%',height:'100vh'}}/> */}
        </Box>
      </Box>
    </>
  );
};

export default EftPaymentModalContent;
