import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../components/header";
import { URLS } from "../_config";

const InstitutionViewList = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="dashboard">
        <Header />
        <div onClick={() => navigate(URLS.INSTITUTION_UPLOAD_STATUS)}>
          InstitutionViewList
        </div>
        <div className="container dashboard-view">
          <div className="white-block p-3 white-box-wide institution-view-wrapper">
            <h3>Map excel fields to products</h3>
            <div className="pt-4">
              <h3>
                Select fields from your excel/csv file to map against student
                fields, or to ignore during import.
              </h3>
              <div className="nstitution-view-list-panel pt-5">
                <Row className="mb-3">
                  <Col className="col-sm-5">
                    <h4>Column name</h4>
                  </Col>
                  <Col className="col-sm-7">
                    <h4>Map to field</h4>
                  </Col>
                </Row>
                <Row className="align-items-center mb-4">
                  <Col className="col-sm-5">
                    <h5>Column 1</h5>
                  </Col>
                  <Col className="col-sm-7">
                    <h5>First name</h5>
                    <input type="text" />
                  </Col>
                </Row>
                <Row className="align-items-center mb-4">
                  <Col className="col-sm-5">
                    <h5>Column 2</h5>
                  </Col>
                  <Col className="col-sm-7">
                    <h5>First name</h5>
                    <input type="text" />
                  </Col>
                </Row>
                <Row className="align-items-center mb-4">
                  <Col className="col-sm-5">
                    <h5>Last name</h5>
                  </Col>
                  <Col className="col-sm-7">
                    <h5>First name</h5>
                    <input type="text" />
                  </Col>
                </Row>
                <Row className="align-items-center mb-4">
                  <Col className="col-sm-5">
                    <h5>First Name</h5>
                  </Col>
                  <Col className="col-sm-7">
                    <h5>First name</h5>
                    <input type="text" />
                  </Col>
                </Row>
                <Row className="align-items-center mb-4">
                  <Col className="col-sm-5">
                    <h5>Student Number</h5>
                  </Col>
                  <Col className="col-sm-7">
                    <h5>First name</h5>
                    <input type="text" />
                  </Col>
                </Row>
                <Row className="align-items-center mb-4">
                  <Col className="col-sm-5">
                    <h5>Campus</h5>
                  </Col>
                  <Col className="col-sm-7">
                    <h5>First name</h5>
                    <input type="text" />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InstitutionViewList;
