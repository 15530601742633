import { Box, FormHelperText, Select, SelectProps } from "@mui/material";
import React from "react";
import { SharedStyles } from "../../styles/theme";
import { EMPTY_OBJECT } from "../../constants";

type Props = SelectProps & {
  options: { label: string; value: string }[];
  isLoading?: boolean;
  showEmptyOption?: boolean;
  color?: "primary" | "secondary";
  helperText?: string;
};

const styles = ({ fullWidth }: Omit<Props, "options">) => {
  return {
    display: "inline-flex",
    flexDirection: "column",
    width: fullWidth ? "100%" : null,
  };
};

export type TSelectProps = Props;

const CustomSelect = React.forwardRef(
  (
    {
      fullWidth = true,
      options = [],
      isLoading,
      disabled,
      showEmptyOption = true,
      color = "secondary",
      helperText,
      ...restOfProps
    }: Props,
    ref: any
  ) => {
    const label = restOfProps.label ?? "Select";
    const labelId = `app-control-select-${label}`;
    const borderStyles =
      color === "primary" && !restOfProps.error
        ? SharedStyles.primaryBorderStyles
        : EMPTY_OBJECT;
    return (
      <Box sx={styles({ fullWidth })} key={isLoading ? 1 : 0}>
        <label>{label}</label>
        <Select
          ref={ref}
          {...restOfProps}
          defaultValue=""
          disabled={isLoading || disabled}
          native
          labelId={labelId}
          id={labelId}
          label={null}
          sx={{ background: "#ffffff", ...borderStyles }}
        >
          {showEmptyOption ? (
            <option value="">
              {isLoading
                ? "Loading..."
                : restOfProps.placeholder || "Select an option"}
            </option>
          ) : (
            isLoading && <option value="">Loading...</option>
          )}
          {options?.map(option => (
            <option
              key={`${option.value}-${option.label}`}
              value={option.value}
            >
              {isLoading ? "Loading..." : option.label}
            </option>
          ))}
        </Select>
        {helperText && (
          <FormHelperText sx={{ mx: 2 }} error={restOfProps.error}>
            {helperText}
          </FormHelperText>
        )}
      </Box>
    );
  }
);

CustomSelect.displayName = "Select";

export default CustomSelect;
