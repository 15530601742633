import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default NiceModal.create(
  ({
    onConfirm,
    onCancel,
  }: {
    onCancel?: () => void;
    onConfirm: () => void;
  }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const handleConfirm = async () => {
      onConfirm();
      await modal.hide();
      modal.remove();
    };
   

    const handleCancel = async (e: unknown, reason?: string) => {
      if (reason && reason === "backdropClick") {
        return;
      }
      onCancel?.();
      await modal.hide();
      modal.remove();
    };
    if (!modal.visible) return null;
    return (
      <Dialog open={modal.visible} onClose={handleCancel} maxWidth="sm">
        <DialogTitle sx={{ display: "flex", alignItems: "left" }}>
          <IconButton onClick={handleCancel}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
            <Typography sx={{fontWeight:600, }}>          We are currently verifying your account.
            </Typography>
       
            <Typography sx={{ }}>  
          We'll notify you once the check-in is finished. Typically, this process can range from 5 minutes to an hour, although in some cases, it may take up to 48 hours.
          </Typography>
         
          <Button
            onClick={handleConfirm}
            sx={{ flex: 1, my: 3 }}
            fullWidth
            variant="outlined"
          >
            Continue to home
          </Button>
          
          
        </DialogContent>
      </Dialog>
    );
  }
);
