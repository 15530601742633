import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default NiceModal.create(
  ({
    isSelfieDone = false,
    isRoomPictureDone = false,
    onConfirm,
    onCancel,
    onRetake,
    photoUrl
  }: {
    isSelfieDone?: boolean;
    isRoomPictureDone?: boolean;
    onCancel?: () => void;
    onRetake: () => void;
    onConfirm: () => void;
    photoUrl:string;
  }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const handleConfirm = async () => {
      onConfirm();
      await modal.hide();
      modal.remove();
    };
    const handleRetake = async () => {
      onRetake();
      await modal.hide();
      modal.remove();
    };

    const handleCancel = async (e: unknown, reason?: string) => {
      if (reason && reason === "backdropClick") {
        return;
      }
      onCancel?.();
      await modal.hide();
      modal.remove();
    };
    if (!modal.visible) return null;
    return (
      <Dialog open={modal.visible} onClose={handleCancel} maxWidth="sm">
        <DialogTitle sx={{ display: "flex", alignItems: "left" }}>
          <IconButton onClick={handleCancel}>
            <Close />
          </IconButton>
          <Box sx={{ flex: 1, textAlign: "center" }}>Picture Preview</Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" sx={{borderRadius:3}}>
            <img alt="" src={photoUrl} />
          </Box>
         
          <Box sx={{display:'flex', flexDirection:'row', gap:3}} >
          <Button
            onClick={handleRetake}
            sx={{ flex: 1, my: 3 }}
            fullWidth
            variant="outlined"
          >
            Retake
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{ flex: 1, my: 3 }}
            fullWidth
            variant="contained"
          >
            Use photo
          </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
);
