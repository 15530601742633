import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import { useCommonApi } from "../../../_common/hooks/api/common/commonApiHook";
import { useUserApi } from "../../../_common/hooks/api/UserApiHook";
import { TStudentSecurityForm } from "../../../ui/v2/types/TStudentSecurityForm";
import {
  EMAIL_VALIDATION_REGEX,
  GENDER_OPTIONS,
  PHONE_NUMBER_REGEX,
  TERM_TYPE_OPTIONS,
} from "../../../ui/v2/constants";
import TextField from "../../../ui/v2/components/TextField";
import useFormFieldErrorProps from "../../../ui/v2/hooks/useFormFieldErrorProps";
import Select from "../../../ui/v2/components/Select";
import { DISABLED_STATUS_OPTIONS } from "../../../constants";
import useCurrentUser from "../../../ui/v2/hooks/useCurrentUser";

interface incomingProps {
  onSubmitStudentDetailsForm: (data: TStudentSecurityForm) => void;
}

export default function SecurityForm({
  onSubmitStudentDetailsForm,
}: incomingProps) {
  const userApi = useUserApi();
  const currentUser = useCurrentUser();

  const logout = () =>
    userApi.logout(
      {},
      () => {},
      () => {}
    );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<TStudentSecurityForm>();

  useEffect(() => {
    if (currentUser) {
      reset({
        Name: currentUser.firstName,
        Surname: currentUser.lastName,
        Email: currentUser.email,
        Mobile: currentUser.mobile,
        StudentNumber: currentUser.studentNumber || "",
        Gender: currentUser.gender || "",
        DisabilityStatus: currentUser.disabilityStatusId,
        InstitutionId: currentUser.institutionId,
        CampusId: currentUser.campusId || "",
        TermTypeId: currentUser.termTypeId,
      });
    }
  }, [currentUser]);

  const institution = watch("InstitutionId");

  const commonApi = useCommonApi();

  const [institutions, setInstitutions] = useState<any>(null);
  const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(false);
  const [campuses, setCampuses] = useState<any>(null);

  const onSubmit = (data: TStudentSecurityForm) => {
    const params: TStudentSecurityForm = {
      StudentNumber: data.StudentNumber,
      InstitutionId: data.InstitutionId,
      CampusId: data.CampusId,
      Gender: data.Gender,
      DisabilityStatus: data.DisabilityStatus,
      Email: data.Email,
      Name: data.Name,
      Surname: data.Surname,
      Mobile: data.Mobile,
      TermTypeId: data.TermTypeId,
    };

    onSubmitStudentDetailsForm(params);
  };

  const getInstitutions = () => {
    setIsLoadingInstitutions(true);
    commonApi.retreiveInstitution(
      {},
      (message: string, resp: any) => {
        setIsLoadingInstitutions(false);
        const options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search("000000") !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        });
        setInstitutions(options);
      },
      (message: string) => {
        setIsLoadingInstitutions(false);
        console.debug("errorMessage", message);
      }
    );
  };

  const [isLoadingCampus, setIsLoadingCampus] = useState(false);

  const getCampus = (id: string = institution) => {
    setIsLoadingCampus(true);
    commonApi.getCampus(
      {
        filters: "InstitutionId eq '" + id + "'",
        ViewName: "LookUpView",
      },
      (message: string, resp: any) => {
        setIsLoadingCampus(false);
        const options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search("000000") !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        });
        setCampuses(options);
      },
      (message: string) => {
        setIsLoadingCampus(false);
        console.log("errorMessage", message);
      }
    );
  };

  useEffect(() => {
    getInstitutions();
  }, []);

  useEffect(() => {
    if (institution) {
      getCampus();
    }
  }, [institution]);

  const { getFieldErrorProps } =
    useFormFieldErrorProps<TStudentSecurityForm>(errors);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2 ">
          <TextField
            size="small"
            label="Name"
            color="primary"
            {...register("Name", { required: true, maxLength: 80 })}
            {...getFieldErrorProps("Name")}
          />
        </div>
        <div className="mb-2 ">
          <TextField
            size="small"
            label="Surname"
            color="primary"
            {...register("Surname", { required: true, maxLength: 80 })}
            {...getFieldErrorProps("Surname")}
          />
        </div>
        <div className="mb-2 ">
          <TextField
            size="small"
            type="email"
            label="Email"
            color="primary"
            {...register("Email", {
              required: true,
              pattern: EMAIL_VALIDATION_REGEX,
            })}
            {...getFieldErrorProps("Email")}
          />
        </div>
        <div className="mb-2 ">
          <TextField
            size="small"
            type="tel"
            label="Mobile"
            color="primary"
            {...register("Mobile", {
              required: true,
              pattern: PHONE_NUMBER_REGEX,
            })}
            {...getFieldErrorProps("Mobile")}
          />
        </div>
        <div className="mb-2 ">
          <TextField
            size="small"
            type="text"
            label="Student Number"
            color="primary"
            {...register("StudentNumber", { required: true })}
            {...getFieldErrorProps("StudentNumber")}
          />
        </div>
        <div className="mb-2 ">
          <Select
            size="small"
            type="text"
            label="Gender"
            color="primary"
            options={GENDER_OPTIONS}
            {...register("Gender", { required: true })}
            {...getFieldErrorProps("Gender")}
          />
        </div>
        <div className="mb-2 ">
          <Select
            size="small"
            type="text"
            label="Disabled Status"
            color="primary"
            options={DISABLED_STATUS_OPTIONS}
            {...register("DisabilityStatus", { required: true })}
            {...getFieldErrorProps("DisabilityStatus")}
          />
        </div>
        <div className="mb-2 ">
          <Select
            size="small"
            type="text"
            label="Term Type"
            color="primary"
            options={TERM_TYPE_OPTIONS}
            {...register("TermTypeId", { required: true })}
            {...getFieldErrorProps("TermTypeId")}
          />
        </div>
        <div className="mb-2 ">
          <Select
            size="small"
            type="text"
            label="Institution"
            color="primary"
            options={institutions}
            isLoading={isLoadingInstitutions}
            {...register("InstitutionId", {
              required: true,
              disabled: !institutions,
            })}
            {...getFieldErrorProps("InstitutionId")}
          />
        </div>
        <div className="mb-2 ">
          <Select
            size="small"
            type="text"
            label="Campus"
            color="primary"
            options={campuses}
            isLoading={isLoadingCampus}
            {...register("CampusId", {
              required: true,
              disabled: !institution,
            })}
            {...getFieldErrorProps("CampusId")}
          />
        </div>
        <Row className="align-items-center">
          <Col md={6}>
            <div
              className=""
              style={{ display: "flex", gap: "10px", marginBottom: "0" }}
            >
              <button className="btn btn-primary w-50 mt-2" type="submit">
                Next
              </button>
              <button
                className="btn btn-danger w-50 mt-2"
                onClick={() => logout()}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
}
