import Grid from "@mui/material/Grid";
import React from "react";

function ResponsiveGridSpacer({
  width,
}: {
  width: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}) {
  return (
    <Grid
      item
      xs={12}
      md={width}
      sx={{ display: { xs: "none", md: "inherit" } }}
    >
      &nbsp;
    </Grid>
  );
}

export default ResponsiveGridSpacer;
