import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";
import TextField from "../../../../components/TextField";
import PageActionsBar from "../../PageActionsBar";
import SectionHeader from "../../SectionHeader";
import { Controller, useFormContext } from "react-hook-form";
import Select from "../../../../components/Select";
import FileUploadInput from "../../../../../../pages/student/components/FileUploadInput";
import OutlinedBanner from "../../../../components/OutlinedBanner";
import NiceModal from "@ebay/nice-modal-react";
import ConfirmOTPModal from "../../../../components/ConfirmOTPModal";
import { TApProfileDetailsResponse } from "../../../../types/TApProfileDetailsResponse";
import { useAppLoader } from "../../../../../../_common/hooks/common/appLoaderHook";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { IApiResponse } from "../../../../types/IApiResponse";
import {
  BANKING_ACCOUNT_TYPE_OPTIONS,
  EXECUTE_REQUEST_PATH,
  UploadDocumentType,
} from "../../../../constants";
import { API } from "../../../../services/API";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../components/Notify";
import { TDocumentUpload } from "../../../../types/TDocumentUpload";
import CircleLoader from "../../../../components/CircleLoader";
import { Schedule } from "@mui/icons-material";
import { ThemeColors } from "../../../../styles/theme";
import { Typography } from "@mui/material";
import useTimer from "../../../../hooks/usePageTimer";
import { UTILS } from "../../../../../../utils";

type TBankDetailsForm = {
  Name: string;
  BranchName: string;
  BranchNo: string;
  AccountHolder: string;
  AccountNo: string;
  AccountTypeId: string;
  ProofOfBanking: TDocumentUpload;
};

const EDIT_DURATION = 151;

function BankingDetails() {
  const { register, handleSubmit, reset, setValue } =
    useFormContext<TBankDetailsForm>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bankingDetails, setBankingDetails] =
    useState<TApProfileDetailsResponse["ProfileDetails"]["bankDetails"]>();
  const { showLoader, hideLoader } = useAppLoader();
  const currentUser = useCurrentUser();
  const { startTimer, cancelTimer, formattedTimeLeft, timeLeft } =
    useTimer(EDIT_DURATION);

  const getUserDetails = async () => {
    if (!currentUser) {
      return;
    }
    try {
      showLoader();
      const response: IApiResponse<TApProfileDetailsResponse> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Supplier",
          requestName: "RetrieveProfileDetailsReq",
          inputParamters: {
            RecordId: currentUser.supplierId,
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }

      reset({
        Name:
          response.outputParameters.ProfileDetails.bankDetails.bankName || "",
        BranchName:
          response.outputParameters.ProfileDetails.bankDetails.branchName || "",
        BranchNo:
          response.outputParameters.ProfileDetails.bankDetails.branchNo || "",
        AccountHolder:
          response.outputParameters.ProfileDetails.bankDetails.accountHolder ||
          "",
        AccountNo:
          response.outputParameters.ProfileDetails.bankDetails.accountNo || "",
        AccountTypeId:
          response.outputParameters.ProfileDetails.bankDetails.accountType ||
          "",
        ProofOfBanking: response.outputParameters.ProfileDetails.bankDetails
          .proofOfBankingFileName
          ? {
              FileName:
                response.outputParameters.ProfileDetails.bankDetails
                  .proofOfBankingFileName,
              DocumentTypeId: UploadDocumentType.ProofOfBanking,
            }
          : undefined,
      });
      setBankingDetails(response.outputParameters.ProfileDetails.bankDetails);
    } catch (e) {
      showErrorAlert("An error occurred while fetching your details");
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  const onSubmit = async (data: TBankDetailsForm) => {
    try {
      if (!currentUser) {
        return;
      }
      const proofOfBanking =
        data.ProofOfBanking && data.ProofOfBanking.FileContent
          ? [data.ProofOfBanking]
          : [];
      setIsSubmitting(true);
      // We make an API call for each individual page because a user could land on the page/tab
      // directly via URL. In future we will migrate to Redux ToolKit where caching and state would be handled
      const response = await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "BankAccount",
        recordId: bankingDetails?.bankAccountId || undefined,
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: {
            ...data,
            ProofOfBanking: undefined,
            Documents: [...proofOfBanking],
            RelatedObjectId: currentUser.supplierId,
            RelatedObjectIdObjectTypeCode: "Supplier",
          },
        },
      });
      if (!response.isSuccess) {
        throw new Error("An error occurred while updating your details");
      }
      showSuccessAlert("Profile updated successfully");
    } catch (e) {
      console.log(e);
      showErrorAlert("An error occurred while updating your details");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleOnSaveClick = () => handleSubmit(onSubmit)();

  const [isLocked, setIsLocked] = React.useState(true);
  const shouldDisableInputs = isLocked;

  const handleLockClick = () => {
    if (!isLocked) {
      cancelTimer();
      return setIsLocked(true);
    }
    NiceModal.show(ConfirmOTPModal, {
      title: "Confirm your OTP",
      mode: "sms",
      onOtpConfirm: () => {
        setIsLocked(false);
        startTimer(() => setIsLocked(true));
      },
      onCancel: () => setIsLocked(true),
    });
  };

  return (
    <>
      <PageActionsBar
        title="Financial information"
        subtitle="View your finance information here."
        onSaveClick={handleOnSaveClick}
        disabled={isSubmitting}
      />
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid item xs={11}>
        <OutlinedBanner
          message="To make changes to this section you will need to authenticate"
          noBorder
          subMessage="We do this to protect our accommodation partners & platform"
        />
      </Grid>
      <Grid item xs={1}>
        {timeLeft !== EDIT_DURATION && (
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              color: ThemeColors.primaryPurple,
            }}
          >
            <Schedule />
            &nbsp;<Typography>{formattedTimeLeft}</Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <SectionHeader
            title="Banking details"
            subtitle="This will not be displayed on your profile."
            locked={isLocked}
            onLockClick={handleLockClick}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <TextField
            label="Bank Name"
            size="small"
            placeholder="First National Bank"
            {...register("Name", {
              required: true,
              disabled: shouldDisableInputs,
            })}
          />
          <TextField
            label="Branch Name"
            size="small"
            placeholder="Pretoria"
            disabled={shouldDisableInputs}
            {...register("BranchName", {
              required: true,
              disabled: shouldDisableInputs,
            })}
          />
          <TextField
            label="Branch Number"
            size="small"
            placeholder="011 811 0098"
            {...register("BranchNo", {
              required: true,
              disabled: shouldDisableInputs,
            })}
          />
          <TextField
            label="Account Holder"
            size="small"
            placeholder="mark.johansen@acme.io"
            {...register("AccountHolder", {
              required: true,
              disabled: shouldDisableInputs,
            })}
          />
          <TextField
            label="Account Number"
            size="small"
            placeholder="011 811 0098"
            {...register("AccountNo", {
              required: true,
              disabled: shouldDisableInputs,
            })}
          />
          <Select
            label="Account Type"
            options={BANKING_ACCOUNT_TYPE_OPTIONS}
            size="small"
            {...register("AccountTypeId", {
              required: true,
              disabled: shouldDisableInputs,
            })}
          />
          <Controller
            {...register("ProofOfBanking", {
              required: true,
              disabled: shouldDisableInputs,
            })}
            render={({ field: { onChange, ...rest } }) => (
              <FileUploadInput
                label="Proof of Banking"
                selectFileText="Upload"
                documentTypeId={UploadDocumentType.ProofOfBanking}
                {...rest}
                onChange={(e: any) => {
                  onChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid textAlign="right" item xs={12}>
        <Box display="inline-flex" gap={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={!isSubmitting}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            type="button"
            color="primary"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircleLoader /> : undefined}
            onClick={handleOnSaveClick}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default BankingDetails;
