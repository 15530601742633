import React, { useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { SingleValue } from "react-select";
import {
  Controller,
  Control,
  FieldValues,
  UseFormTrigger,
  UseFormSetValue,
} from "react-hook-form";
import { INPUT_MAX_LENGTHS } from "../../../_config";

export interface IAddressData {
  streetNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  province: string;
  postalCode: string;
  formattedAddress?: string;
}

interface IAddressProps {
  onAddressDataChange: (data: IAddressData) => void;
  control: Control<FieldValues, any>;
  trigger: UseFormTrigger<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  required?: boolean;
  initialAdress: string;
  name: string;
}
const Address: React.FC<IAddressProps> = ({
  onAddressDataChange,
  control,
  required = false,
  name,
  initialAdress = "",
  setValue,
  trigger,
}) => {
  const [manuallyAddress, setManualAddress] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(initialAdress);
  const addressRef = useRef<any>(null);

  useEffect(() => {
    if (!loading && (manuallyAddress || inputValue)) {
      setValue(name, manuallyAddress || inputValue);
      setTimeout(() => trigger(name), 1000);
    }
  }, [loading]);

  const handleOnChange = (address: SingleValue<any>) => {
    if (address) {
      geocodeByAddress(address.label)
        .then(results => {
          const fullAddress = results[0].formatted_address;
          const addressDetails = results[0].address_components;
          const addressData = {
            streetNumber: "",
            streetName: "",
            suburb: "",
            city: "",
            province: "",
            postalCode: "",
            formattedAddress: fullAddress,
          };
          addressDetails.forEach(detail => {
            if (detail.types.includes("street_number")) {
              addressData.streetNumber = detail.long_name;
            } else if (detail.types.includes("route")) {
              addressData.streetName = detail.long_name;
            } else if (
              ["sublocality", "political"].every(area =>
                detail?.types.includes(area)
              )
            ) {
              addressData.suburb = detail.long_name;
            } else if (
              ["locality", "political"].every(area =>
                detail?.types.includes(area)
              )
            ) {
              addressData.city = detail.long_name;
            } else if (detail.types.includes("administrative_area_level_1")) {
              addressData.province = detail.long_name;
            } else if (detail.types.includes("postal_code")) {
              addressData.postalCode = detail.long_name;
            }
          });
          onAddressDataChange(addressData);
        })
        .catch(error => console.error(error));
    }
  };

  return (
    <div>
      {" "}
      <Controller
        name={name}
        control={control}
        rules={{ required, maxLength: INPUT_MAX_LENGTHS.address }}
        render={({ field }) => {
          return (
            <GooglePlacesAutocomplete
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["za"],
                },
              }}
              key={field.value}
              debounce={700}
              ref={addressRef}
              selectProps={{
                inputValue,
                onInputChange: (value, { action }) => {
                  if (action === "input-change") {
                    setLoading(() => true);
                    setInputValue(value);
                    if (!field.value) {
                      setManualAddress(() => value);
                    }
                  }
                },
                styles: {
                  container: (provided, state) => ({
                    ...provided,
                    width: "100%",
                    borderRadius: "4px",
                    border: state.isFocused
                      ? "1px solid #f6b26b"
                      : "1px solid #D36E28",
                    boxShadow: state.isFocused
                      ? "0 0 0 0.2rem rgba(211, 110, 40, 0.25)"
                      : "none",
                    transition:
                      "#D36E28 .15s ease-in-out,box-shadow .15s ease-in-out",
                  }),

                  option: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? "white" : "balck",
                    margin: "5px",
                    fontSize: "14px",
                    fontWeight: "500",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#D36E28",
                      color: "white",
                    },
                  }),
                  control: provided => ({
                    ...provided,
                    border: "none",
                    boxShadow: "none",
                    fontSize: "14px",
                    fontWeight: "500",
                    "&:hover": {
                      border: "none",
                    },
                  }),
                },
                onBlur: () => {
                  if (manuallyAddress) setInputValue(() => manuallyAddress);
                  setManualAddress("");
                },
                onMenuOpen() {
                  setLoading(() => false);
                },
                menuPortalTarget: document.body,
                noOptionsMessage: () => {
                  setLoading(() => false);
                  return (
                    <>
                      <div className="no-options">
                        <div className="no-options">
                          No address found. Please try again.
                        </div>
                      </div>
                    </>
                  );
                },

                isClearable: field.value ? true : false,
                onChange: address => {
                  const event = {
                    target: {
                      name,
                      value: address ? address?.label : "",
                    },
                  };
                  field.onChange(event);

                  handleOnChange(address);
                  setInputValue(address?.label || "");
                },

                placeholder: "Search for your address",
                defaultValue: { value: field.value ?? "", label: field.value },
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default Address;
