import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";
import { ThemeColors } from "../../../../../styles/theme";

function CheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z"
          fill={ThemeColors.primaryOrange}
        />
        <path
          d="M14.6668 6.5L8.25016 12.9167L5.3335 10"
          stroke={ThemeColors.white}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}

export default CheckedIcon;
