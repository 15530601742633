import { SAGA_ACTIONS } from "../../../_config";
import { useApiCall } from "../common/appApiCallHook";
import {
  ContentReq,
  DetailsReq,
  BlankReq,
  locationReq,
  SearchReq,
  editProfileReq,
  LoginReq,
  LogoutReq,
} from "../../interfaces/ApiReqRes";

interface generateOtpReq {
  requestName: "GenerateOTPReq";
  inputParamters: {
    OTPInformation: {
      Email: string;
      Mobile: string;
    };
  };
}

interface confirmOtpReq {
  requestName: "ConfirmOTPReq";
  inputParamters: {
    OTPInformation: {
      OTPNo: string;
      email: string;
      mobile: string;
    };
  };
}

export function useUserApi() {
  const callApi = useApiCall();

  const login = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  };
  const registerEntity = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.USER.REGISTER_ENTITY, data, onSuccess, onError);
  };
  const registerIndividual = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.USER.REGISTER_INDIVIDUAL, data, onSuccess, onError);
  };
  const registerStudent = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.USER.REGISTER_STUDENT, data, onSuccess, onError);
  };

  const studentSecurityCheck = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.USER.REGISTER_STUDENT, data, onSuccess, onError);
  };

  const logout = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError);
  };

  const editProfile = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.EDIT_PROFILE, data, onSuccess, onError);
  };
  const aboutMe = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ME, data, onSuccess, onError);
  };

  const myOrders = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.MY_ORDERS, data, onSuccess, onError);
  };

  const orderDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.ORDER_DETAILS, data, onSuccess, onError);
  };

  const cancelOrder = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.CANCEL_ORDER, data, onSuccess, onError);
  };

  const resetPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  };
  const forgotPassword = (
    data: any,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  };

  //
  const generateOtp = (
    data: generateOtpReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.USER.GENERATE_OTP, data, onSuccess, onError);
  };
  const confirmOtp = (
    data: confirmOtpReq,
    onSuccess: Function,
    onError: Function
  ) => {
    callApi(SAGA_ACTIONS.USER.CONFIRM_OTP, data, onSuccess, onError);
  };
  const acceptTerms = () => {
    callApi(
      SAGA_ACTIONS.USER.ACCEPT_TERMS,
      null,
      () => {},
      () => {}
    );
  };

  const checkStudent = () => {};

  /* 
    const registration = (data: RegistrationReq, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.USER.REGISTRATION, data, onSuccess, onError);
    }
  
    const emailVerify = (data: EmailVerifyReq, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.USER.VERIFY, data, onSuccess, onError);
    }
  
    const getUser = (data: EmailVerifyReq, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.USER.GET_USER, data, onSuccess, onError);
    }
  
    const submitContact = (data: contactReq, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.USER.CONTACT, data, onSuccess, onError);
    }
  
  
    const validToken = (data: EmailVerifyReq, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.USER.VALID_TOKEN, data, onSuccess, onError);
    }
  
    const changePassword = (data: changePasswordReq, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.USER.CHANGE_PASSWORD, data, onSuccess, onError);
    }
  
    const profileUpdate = (data: profileUpdateReq, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.USER.PROFILE_UPDATE, data, onSuccess, onError);
    }
  
    const updateAvatar = (data: avatar, onSuccess: Function, onError: Function) => {
      callApi(SAGA_ACTIONS.USER.AVATAR, data, onSuccess, onError);
    } */

  return {
    login: login,
    logout: logout,
    // logoutSecurity,
    editProfile: editProfile,
    aboutMe: aboutMe,
    myOrders: myOrders,
    orderDetails: orderDetails,
    resetPassword: resetPassword,
    forgotPassword: forgotPassword,
    cancelOrder: cancelOrder,
    registerEntity: registerEntity,
    registerIndividual: registerIndividual,
    registerStudent: registerStudent,
    generateOtp,
    confirmOtp,
    acceptTerms,
    /* 
    callRegistration: registration,
    callEmailVerify: emailVerify,
    callGetUser: getUser,
    callLogout: logout,
    submitContact: submitContact,
    forgotPassword: forgotPassword,
    validToken: validToken,
    changePassword: changePassword,
    profileUpdate: profileUpdate,
    updateAvatar: updateAvatar */
  };
}
