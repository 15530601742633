import {
  Box,
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Header from "../components/header";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { DevTool } from "@hookform/devtools";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { useParams } from "react-router-dom";
import Breadcrumbs from "./student/components/breadcrumbs";

interface Properties {
  Address: string;
  Email: string;
  FacilityId: string;
  KMToCampus: string;
  Mobile: string;
  Name: string;
  NumberOfBeds: number;
  // FacilityId:string
}

interface CampusDetails {
  Address: null;
  CampusId: string;
  InstitutionId: string;
  InstitutionIdName: string;
  Name: string;
  PostalCode: string;
  Province: string;
  Suburb: string;
}

const CampusDetails = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const commonApi = useCommonApi();
  const [provinces, setProvinces] = useState<any>(null); // state to hold provinces
  const [institutions, setInstitutions] = useState<any>(null);
  const institution = watch("institution");
  const [propertyDetails, setPropertyDetails] = useState<Properties[]>([]);
  const [campusDetails, setCampusDetails] = useState<CampusDetails[]>([]);
  const { showLoader, hideLoader } = useAppLoader();
  const user: any = useUserSelector();
  const { id } = useParams<{ id: string }>();

  const getProvinces = () => {
    commonApi.post(
      {
        entityName: "Picklist",
        requestName: "RetrieveSelectOptionExecuteRequest",
        inputParamters: {
          SelectOption: {
            OptionText: "Province",
          },
        },
      },
      (message: string, resp: any) => {
        if (resp.outputParameters.Count > 0) {
          // Filter provinces here to exclude 'Limpopo'
          const filteredProvinces = resp.outputParameters.data.filter(
            (data: any) => data.optionText !== ""
          );

          setProvinces(
            filteredProvinces.map((data: any) => ({
              label: data.optionText,
              value: data.optionValue,
            }))
          );
        }
      },
      (message: string) => {}
    );
  };

  const getInstitutions = () => {
    commonApi.retreiveInstitution(
      {},
      (message: string, resp: any) => {
        const options: any = [];
        resp.map((ins: any) => {
          if (ins.recordId.search("000000") !== 0) {
            ins.value = ins.recordId;
            ins.label = ins.mainText;
            options.push(ins);
          }
        });
        setInstitutions(options);
      },
      (message: string) => {
        // console.log(message);
      }
    );
  };

  useEffect(() => {
    getProvinces();
    getInstitutions();
    getCampusDetails();
    getCampusProperties();
  }, []);

  const getCampusDetails = () => {
    commonApi.getCampusDetails(
      {
        CampusId: id,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setCampusDetails(resp);
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };
  const getCampusProperties = () => {
    commonApi.getCampusProperties(
      {
        CampusId: id,
      },
      (message: string, resp: any) => {
        if (resp.length > 0) {
          setPropertyDetails(resp);
        }
      },
      (message: string) => {
        console.log(message);
      }
    );
  };

  return (
    <>
      <Box>
        <Header />
      </Box>
      <Box sx={{ padding: "2rem" }}>
        <Box sx={{ marginTop: "4rem", marginBottom: "2rem" }}>
          <Breadcrumbs
            data={[
              { label: "Home", path: "/" },
              { label: "Dashboard", path: "/institution-dashboard" },
              { label: "Campus Details" },
            ]}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "7rem",
          }}
        >
          <Typography
            sx={{
              color: "#101828",
              fontFamily: "Inter",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "38px",
              marginBottom: "20px",
            }}
          >
            Campus Details
          </Typography>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden", padding: "3rem" }}>
          {campusDetails.map((campusDetail: CampusDetails) => (
            <form action="">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="E.g Barberton Tvet"
                    style={{ width: "60%" }}
                    {...register("name")}
                    // defaultValue={studentDetail.Name}
                    value={campusDetail.Name}
                    disabled={true}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    Institution
                  </label>
                  <div className="" style={{ width: "60%" }}>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1a"
                      placeholder="E.g Barberton Tvet"
                      style={{ width: "100%" }}
                      {...register("institution")}
                      // defaultValue={studentDetail.Name}
                      value={campusDetail.InstitutionIdName}
                      disabled={true}
                    />
                    {/* <Controller
                          name={"institution"}
                          control={control}
                          // rules={{ required: true }}
                          render={({ field: { value, onChange, onBlur } }) => {
                            return (
                              <Select
                                className={
                                  "form-control p-0" +
                                  (errors?.institution ? " is-invalid" : "")
                                }
                                options={institutions}
                                placeholder={"Choose Institution"}
                                value={campusDetail.InstitutionIdName}

                                // defaultValue={campusDetail.InstitutionIdName}
                                defaultInputValue={campusDetail.InstitutionIdName}
                                onChange={onChange}
                                isDisabled={true}
                                // {...register('institution', { required: true } )}
                              />
                            );
                          }}
                        /> */}
                  </div>
                </div>
              </Box>
              {/*  */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Province
                  </label>
                  <div className="" style={{ width: "60%" }}>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1a"
                      placeholder="E.g Barberton Tvet"
                      style={{ width: "100%" }}
                      {...register("province")}
                      // defaultValue={studentDetail.Name}
                      value={campusDetail.Province}
                      disabled={true}
                    />
                    {/* <Controller
                            name={"Province"}
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange, onBlur } }) => {
                                return (<Select
                                  className={"form-control p-0" + (errors?.Province ? ' is-invalid' : '')}
                                  options={provinces}
                                  
                                  placeholder={"Select a Province"}
                                  onChange={onChange}
                                  value={value}
                                  defaultValue={value}
                                  isDisabled={true}

                              />)
                            }}
                        /> */}
                  </div>
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="E.g 12 Hellen Road"
                    style={{ width: "60%", textAlign: "left" }}
                    {...register("address")}
                    value={
                      campusDetail.Address === null
                        ? "No Address"
                        : campusDetail.Address
                    }
                    disabled={true}
                  />
                </div>
              </Box>
              {/* ******************** */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2rem",
                  flexDirection: { xs: "column", md: "row" },
                  width: { xs: "50%", md: "100%" },
                  marginLeft: { xs: "auto" },
                  marginRight: { xs: "auto" },
                }}
              >
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                  >
                    Suburb
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="E.g Middlestown"
                    style={{ width: "60%" }}
                    {...register("surburb")}
                    defaultValue={campusDetail.Suburb}
                    value={
                      campusDetail.Suburb ? campusDetail.Suburb : "No Surburb"
                    }
                    disabled={true}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "1rem",
                    marginTop: "1rem",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    htmlFor="exampleFormControlInput1a"
                    className={"form-label "}
                    style={{ textAlign: "left" }}
                  >
                    Postal Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1a"
                    placeholder="E.g 2309"
                    style={{ width: "60%", textAlign: "left" }}
                    {...register("postalCode")}
                    value={
                      campusDetail.PostalCode
                        ? campusDetail.PostalCode
                        : "No Postal Code"
                    }
                    disabled={true}
                  />
                </div>
              </Box>
            </form>
          ))}
        </Paper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            marginTop: "3rem",
          }}
        >
          <Typography
            sx={{
              // marginTop: "7rem",
              color: "#101828",
              fontFamily: "Inter",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "38px",
              marginBottom: "20px",
            }}
          >
            Properties Under{" "}
            {campusDetails.map((details: CampusDetails) => {
              return details.InstitutionIdName;
            })}
          </Typography>
        </Box>
        <Paper
          sx={{
            width: "100%",
            overflowY: "auto",
            marginTop: "2rem",
            height: 400,
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              "&::-webkit-scrollbar": {
                width: 20,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "red",
              },
              "&::-webkitscrollbar-thumb": {
                backgroundColor: "blue",
                borderRadius: 2,
              },
            }}
          >
            <Table aria-label="sticky table">
              <TableHead
                sx={{
                  backgroundColor: "#F9FAFB",
                }}
              >
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>KM From Campus</TableCell>
                  <TableCell>Number of Beds</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {propertyDetails.map((propDetail: Properties) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      textAlign: "center",
                    }}
                  >
                    <TableCell className="table-data">
                      {propDetail.Name}
                    </TableCell>
                    <TableCell className="table-data">
                      {propDetail.Address}
                    </TableCell>
                    <TableCell className="table-data">
                      {propDetail.Mobile}
                    </TableCell>
                    <TableCell className="table-data">
                      {propDetail.Email}
                    </TableCell>
                    <TableCell className="table-data">
                      {propDetail.KMToCampus}
                    </TableCell>
                    <TableCell className="table-data">
                      {propDetail.NumberOfBeds}
                    </TableCell>
                    <TableCell className="table-data">
                      <Button
                        // onClick={()=>{navigate('/campus-details')}}
                        // href='/student/accomodation-details/1ecad9c9-d76c-4ddf-9a14-25cdcb60466f'
                        href={`/view-properties/${propDetail.FacilityId}`}
                        // sx={{
                        //   border:'1px solid grey',
                        //   cursor:'pointer',
                        // }}
                        sx={{
                          border: "1px solid grey",
                          cursor: "pointer",
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <DevTool control={control} />
      </Box>
    </>
  );
};

export default CampusDetails;
