import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useCommonApi } from "../../../_common/hooks/api/common/commonApiHook";
import { useAppLoader } from "../../../_common/hooks/common/appLoaderHook";
import { useUserSelector } from "../../../_common/hooks/selectors/userSelector";
import { useNavigate, useParams } from "react-router-dom";
import { showErrorAlert } from "../../../components/Notify";
import Select from "../../../ui/v2/components/Select";
import { Button } from "@mui/material";
import MonthPeriodSelect from "../../../ui/v2/components/MonthPeriodSelect";
import useFormFieldErrorProps from "../../../ui/v2/hooks/useFormFieldErrorProps";
import { isBefore } from "date-fns";
import { IApiResponse } from "../../../ui/v2/types/IApiResponse";
import { API } from "../../../ui/v2/services/API";
import { EXECUTE_REQUEST_PATH } from "../../../ui/v2/constants";
import useCurrentUser from "../../../ui/v2/hooks/useCurrentUser";
import { UTILS } from "../../../utils";

type DatesOutputParameters = {
  PlannedMoveOutDate: string[];
};

interface TenantsRoomModalProps {
  handleClose: () => void;
  shouldShow: boolean;
  residence: any;
  planneMoveInDates?: any;
}
type TFormProps = {
  PlannedMoveInDate: string;
  PlannedMoveOutDate: string;
  PreferredRoomTypeId: string;
};
const StudentRoomModal = ({
  shouldShow,
  handleClose,
  residence,
  planneMoveInDates,
}: TenantsRoomModalProps) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm<TFormProps>();
  const { getFieldErrorProps } = useFormFieldErrorProps<TFormProps>(errors);

  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();
  const user: any = useUserSelector();
  const navigate = useNavigate();

  const [studentId, setStudentId] = useState<string>("");
  const { id } = useParams();

  const [roomTypes, setRoomTypes] = useState<any>(null);
  const [moveOutDates, setMoveOutDates] = useState<
    { label: string; value: string }[]
  >([]);
  const [moveInDate, setMoveInDate] = useState<any>(null);

  const currentuser = useCurrentUser();

  const getApplicationRoomTypes = () => {
    if (residence?.rooms.length > 0) {
      const opt: any = [];
      residence.rooms.map((o: any) => {
        opt.push({ label: o.iconName, value: o.capacityId });
      });
      setRoomTypes(opt);
    }
  };

  // useEffect(() => {
  //   getApplicationMoveOutDates;
  // }, [planneMoveInDates]);

  const getApplicationMoveOutDates = async (date: any) => {
    try {
      const response: IApiResponse<DatesOutputParameters> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Employee",
          requestName: "RetrievePlannedMoveOutDatesReq",
          recordId: currentuser?.recordId,
          inputParamters: {
            PlannedMoveInDate: date,
          },
        }
      );

      console.log("RetrievePlannedMoveOutDatesReq", response);

      if (response.isSuccess) {
        // const plannedMoveInDates = UTILS.convertDates(
        //   response.outputParameters.PlannedMoveInDate
        // );

        const plannedMoveOutDates = UTILS.convertDates(
          response.outputParameters.PlannedMoveOutDate
        );

        setMoveOutDates(plannedMoveOutDates);
      } else {
        showErrorAlert(response.clientMessage);
        console.error("isError", response); //TODO handle error state
      }
    } catch (e) {
      console.error("e", e); //TODO handle error state
    }
  };

  const onSubmit = ({
    PlannedMoveInDate,
    PreferredRoomTypeId,
    PlannedMoveOutDate,
  }: TFormProps) => {
    if (PreferredRoomTypeId) {
      showLoader();
      commonApi.post(
        {
          entityName: "AccomodationApplications",
          requestName: "UpsertRecordReq",
          inputParamters: {
            Entity: {
              studentId: studentId,
              facilityId: id,
              capacityId: PreferredRoomTypeId,
              PreferredRoomTypeId: PreferredRoomTypeId,
              PlannedMoveInDate,
              PlannedMoveOutDate,
            },
          },
        },
        (message: string, resp: any) => {
          toast.success(message);
          handleClose();
          hideLoader();
          navigate(`/student/my-applications/success`);
        },
        (message: string) => {
          showErrorAlert(message);
          handleClose();
          hideLoader();
        }
      );
    } else {
      console.log("Please select a room");
    }
  };

  useEffect(() => {
    if (user) {
      setStudentId(user.recordId);
      getApplicationRoomTypes();
    }
  }, [user]);

  useEffect(() => {
    if (residence) {
      getApplicationRoomTypes();
    }
  }, [residence]);

  return (
    <Modal show={shouldShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Apply for Residence</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <MonthPeriodSelect
              mode="start"
              label="Planned Move-in Date"
              applicationDates={planneMoveInDates}
              size="small"
              {...register("PlannedMoveInDate", { required: true })}
              {...getFieldErrorProps("PlannedMoveInDate")}
              onChange={e => {
                const selectedValue = e.target.value; // Capture the selected value
                console.log("seel", selectedValue);
                // setMoveInDate(selectedValue); // Update the form field
                getApplicationMoveOutDates(selectedValue); // Call your existing handler if necessary
              }}
            />
          </div>
          {
            <div>
              <MonthPeriodSelect
                mode="end"
                label="Planned Move-out Date"
                size="small"
                applicationDates={moveOutDates}
                {...register("PlannedMoveOutDate", {
                  required: true,
                  // validate: (value, values) => {
                  //   if (
                  //     isBefore(
                  //       new Date(value),
                  //       new Date(values.PlannedMoveInDate)
                  //     )
                  //   ) {
                  //     return "Move-out date should be after move-in date";
                  //   }
                  //   return true;
                  // },
                })}
                {...getFieldErrorProps("PlannedMoveOutDate")}
              />
            </div>
          }
          <div className="my-2">
            <Select
              {...register("PreferredRoomTypeId", { required: true })}
              label="Preferred Room Type"
              size="small"
              options={roomTypes}
              {...getFieldErrorProps("PreferredRoomTypeId")}
            />
          </div>

          <div>
            <Button fullWidth variant="contained" type="submit">
              Confirm
            </Button>
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} fullWidth>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StudentRoomModal;
