import { type } from "os";
import { ActionExtended } from "../../../_common/interfaces/ActionExtended";
import { User } from "../../../_common/interfaces/models/user";
// import { ACTIONS } from '../../../_config'
import { ACTIONS, STORAGE } from "../../../_config";

export interface UserReducer {
  user: User | null;
  token: string | null;
  isExpired: any;
  isAuthenticated: boolean;
  navigations: any;
  alerts: { message: string; actionLink: string; read?: boolean }[];
}

const initialState: UserReducer = {
  user: null,
  isExpired: false,
  token: null,
  isAuthenticated: false,
  navigations: null,
  alerts: [],
};

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    //
    case ACTIONS.USER.ACCEPT_TERMS:
      const mergedUserData = { ...state.user, tsAndCsAccepted: true };
      sessionStorage.setItem(STORAGE, JSON.stringify(mergedUserData));
      return {
        ...state,
        user: mergedUserData,
      };
    case ACTIONS.USER.LOGIN:
      if (!action.payload.user) {
        throw new Error("An error occurred, please contact system admin");
      }
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
        isExpired: action.payload.user.isExpired,
        navigations: action.payload.navigations,
        alerts: action.payload.alerts,
      };
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
        isExpired: false,
        navigations: null,
      };

    //
    case ACTIONS.USER.GET_NOTIFICATIONS:
      const updated = {
        ...state,
        alerts: action.payload.alerts?.map((alert: any) => {
          const matching =
            state.alerts &&
            state.alerts.find(a => a.actionLink === alert.actionLink);
          return matching || alert;
        }),
      };
      return updated;

    case ACTIONS.USER.READ_NOTIFICATION:
      return {
        ...state,
        alerts: state.alerts?.map(alert => {
          if (alert.actionLink === action.payload) {
            alert.read = true;
          }
          return alert;
        }),
      };
    //

    case ACTIONS.USER.ME:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
