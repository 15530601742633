import { Box, Card, Stack, Typography } from "@mui/material";
import React from "react";
import myImg from "./ic_glass_bag.png";

interface incomingProps {
  cardIcon: string;
  cardDesc: string;
  statDesc: any;
}

const DashboardCard = ({ cardIcon, cardDesc, statDesc }: incomingProps) => {
  return (
    <div style={{ cursor: "pointer" }} className="dashboard-card">
      <Card
        component={Stack}
        spacing={3}
        direction="row"
        sx={{
          px: 8,
          py: 5,
          borderRadius: 3,
          width: "330px",
          height: "160px",
        }}
      >
        <img style={{ width: "50px", height: "50px" }} src={cardIcon} alt="" />

        <Stack spacing={0.5}>
          <Typography
            variant="h4"
            fontWeight={600}
            fontFamily={"Public Sans, sans-serif"}
          >
            {statDesc}
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ color: "text.disabled", maxWidth: "100%" }}
          >
            {cardDesc}
          </Typography>
        </Stack>
      </Card>
    </div>
  );
};

export default DashboardCard;
