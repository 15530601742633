import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { useEffect, useState } from "react";
import TextField from "../../../../components/TextField";
import PageActionsBar from "../../PageActionsBar";
import SectionHeader from "../../SectionHeader";

import { useFormContext, useWatch } from "react-hook-form";
import {
  EXECUTE_REQUEST_PATH,
  PHONE_NUMBER_REGEX,
} from "../../../../constants";
import CircleLoader from "../../../../components/CircleLoader";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../components/Notify";
import { API } from "../../../../services/API";
import { useAppLoader } from "../../../../../../_common/hooks/common/appLoaderHook";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { IApiResponse } from "../../../../types/IApiResponse";
import { TApProfileDetailsResponse } from "../../../../types/TApProfileDetailsResponse";

type TPersonalDetailsForm = {
  Name: string;
  RegistrationName: string;
  registrationNumber: string | null;
  VATNumber: string;
  Mobile: string;
  NextOfKinFullName: string;
  Email: string;
  NextOfKinRelationship: string;
  NextOfKinMobile: string;
  NextOfKinEmail: string;
  IDNumber: string;
};

function ProfileDetails() {
  const { register, handleSubmit, reset, control } =
    useFormContext<TPersonalDetailsForm>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isIndividualAP, setIsIndividualAP] = useState(false);
  const { showLoader, hideLoader } = useAppLoader();
  const currentUser = useCurrentUser();

  // Store initial form values
  const [initialValues, setInitialValues] =
    useState<TPersonalDetailsForm | null>(null);
  const [isEdited, setIsEdited] = useState(false); // Track if form is edited

  // Watch form values for changes
  const formValues = useWatch({ control });

  const getUserDetails = async () => {
    if (!currentUser) {
      return;
    }
    try {
      showLoader();
      const response: IApiResponse<TApProfileDetailsResponse> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Supplier",
          requestName: "RetrieveProfileDetailsReq",
          inputParamters: {
            RecordId: currentUser.supplierId,
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }

      const profileDetails = response.outputParameters.ProfileDetails;

      setIsIndividualAP(
        response.outputParameters.ProfileDetails.details.aPtypeId === 864
      );

      const initialFormValues: TPersonalDetailsForm = {
        Name: profileDetails.details.tradingName,
        RegistrationName: profileDetails.details.registerdName || "",
        VATNumber: profileDetails.details.vatNumber,
        Mobile: profileDetails.contactDetails.mobileNumber,
        Email: profileDetails.contactDetails.email,
        NextOfKinFullName: profileDetails.nextOfKinDetails.fullName,
        NextOfKinRelationship:
          profileDetails.nextOfKinDetails.relationship || "",
        NextOfKinMobile: profileDetails.nextOfKinDetails.mobileNumber || "",
        NextOfKinEmail: profileDetails.nextOfKinDetails.email || "",
        IDNumber: profileDetails.details.idNumber,
        registrationNumber: profileDetails.details.registerdName,
      };

      setInitialValues(initialFormValues); // Save initial values
      reset(initialFormValues); // Reset the form with the fetched values
    } catch (e) {
      showErrorAlert("An error occurred while fetching your details");
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  // Check if the form has been edited
  useEffect(() => {
    if (initialValues) {
      const isFormEdited =
        JSON.stringify(formValues) !== JSON.stringify(initialValues);
      setIsEdited(isFormEdited); // Set form as edited if values have changed
    }
  }, [formValues, initialValues]);

  const onSubmit = async (data: TPersonalDetailsForm) => {
    try {
      if (!currentUser) {
        return;
      }
      setIsSubmitting(true);
      const response = await API.post(EXECUTE_REQUEST_PATH, {
        entityName: "Supplier",
        recordId: currentUser.supplierId,
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: data,
        },
      });
      if (!response.isSuccess) {
        throw new Error("An error occurred while updating your details");
      }

      showSuccessAlert("Profile updated successfully");
      setInitialValues(data); // Reset initial values after successful save
      setIsEdited(false); // Mark form as not edited
    } catch (e) {
      showErrorAlert("An error occurred while updating your details");
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleOnSaveClick = () => handleSubmit(onSubmit)();

  const handleOnCancelClick = () => {
    if (initialValues) {
      reset(initialValues); // Reset form to the initial values
      setIsEdited(false); // Mark form as not edited
    }
  };
  return (
    <>
      <PageActionsBar
        title="Personal profile"
        subtitle="Update your details here."
        onSaveClick={handleOnSaveClick}
        disabled={isSubmitting}
      />
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <SectionHeader
            title={isIndividualAP ? "Profile Details" : "Company Details"}
            subtitle="This will not be displayed on your profile."
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <TextField
            label={isIndividualAP ? "Full Name" : "Trading Name"}
            size="small"
            placeholder="What what properties"
            {...register("Name", { required: true })}
          />
          {isIndividualAP ? null : (
            <TextField
              label="Registered name"
              size="small"
              placeholder="What what properties pty ltd"
              {...register("RegistrationName", { required: true })}
            />
          )}

          <TextField
            label={isIndividualAP ? "ID number" : "Registration number"}
            type="text"
            size="small"
            placeholder="00/0000/00"
            {...register("IDNumber", { required: true, disabled: true })}
          />
          {isIndividualAP ? null : (
            <TextField
              label="VAT Number"
              type="number"
              size="small"
              placeholder="81 454 0865"
              {...register("VATNumber", { required: true })}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <SectionHeader
            title="Contact details"
            subtitle="Your email and number and such like things"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <TextField
            label="Mobile Number"
            size="small"
            type="tel"
            placeholder="81 454 0865"
            {...register("Mobile", {
              required: true,
              pattern: PHONE_NUMBER_REGEX,
            })}
          />
          <TextField
            label="Email"
            size="small"
            type="email"
            placeholder="email@person.com"
            {...register("Email", { required: true, disabled: true })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12} md={4}>
          <SectionHeader
            title="Next of Kin Detail"
            subtitle="Add and manage your next of kin information"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <TextField
            label="Full Name"
            size="small"
            type="tel"
            placeholder=""
            {...register("NextOfKinFullName", { required: true })}
          />
          <TextField
            label="Relationship"
            size="small"
            placeholder="Spouse"
            {...register("NextOfKinRelationship", { required: true })}
          />
          <TextField
            label="Mobile Number"
            size="small"
            type="tel"
            placeholder="81 454 0865"
            {...register("NextOfKinMobile", {
              required: true,
              pattern: PHONE_NUMBER_REGEX,
            })}
          />
          <TextField
            label="Email"
            size="small"
            type="email"
            placeholder="email@person.com"
            {...register("NextOfKinEmail", { required: true })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} py={2}>
        <Divider />
      </Grid>

      <Grid textAlign="right" item xs={12}>
        <Box display="inline-flex" gap={1}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={!isEdited || isSubmitting} // Enable Cancel only if the form is edited
            onClick={handleOnCancelClick}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            type="button"
            color="primary"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircleLoader /> : undefined}
            onClick={handleOnSaveClick}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default ProfileDetails;
