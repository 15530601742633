import { call, put } from "redux-saga/effects";
import { ACTIONS, API_URL, NAVIGATIONS, STORAGE } from "../../_config";
import { CallApi } from "./api/callApi";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* login(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.isSuccess &&
      resp.data.outputParameters
    ) {
      sessionStorage.setItem(
        STORAGE,
        JSON.stringify(resp.data.outputParameters.RequestResults)
      );
      sessionStorage.setItem(
        NAVIGATIONS,
        JSON.stringify(resp.data.outputParameters.Navigation)
      );
      yield put({
        type: ACTIONS.USER.LOGIN,
        payload: {
          alerts: resp.data.outputParameters.Alerts,
          user: resp.data.outputParameters.RequestResults,
          navigations: resp.data.outputParameters.Navigation,
          token: null,
        },
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* post(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(
      CallApi.POST,
      API_BASE_URL + "/ExecuteRequest",
      data
    );
    if (
      resp.status >= 200 &&
      resp.status < 300 &&
      resp.data &&
      resp.data.isSuccess &&
      resp.data.outputParameters
    ) {
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* logout(action: any): any {
  try {
    sessionStorage.removeItem(STORAGE);
    sessionStorage.removeItem(NAVIGATIONS);
    sessionStorage.removeItem("residenceID");
    sessionStorage.removeItem("residenceName");
    sessionStorage.removeItem("detailsImage");
    sessionStorage.removeItem("caseType");
    yield put({
      type: ACTIONS.USER.LOGOUT,
    });
    action && action.callbackSuccess && action.callbackSuccess({});
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}

export function* acceptTerms(action: any): any {
  try {
    yield put({
      type: ACTIONS.USER.ACCEPT_TERMS,
    });
    action && action.callbackSuccess && action.callbackSuccess({});
  } catch (e: any) {
    action &&
      action.callbackError &&
      action.callbackError(e && e.data ? e.data : e);
  }
}
