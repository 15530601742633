import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  Modal,
  NavDropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import {
  useAlertsSelector,
  useUserSelector,
} from "../_common/hooks/selectors/userSelector";
import { ACTIONS, URLS } from "../_config";
import { TermsAndConditions } from "./TermsAndConditions";
import { FaBell, FaInfoCircle } from "react-icons/fa";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
// import file_terms from '../images/file_terms.pdf'
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
// import Button from '@mui/material/Button';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import HouseIcon from "@mui/icons-material/House";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ApartmentIcon from "@mui/icons-material/Apartment";
import nsfasLogo from "../images/nsfas-dark-logo.png";
import Alert from "@mui/material/Alert";
import { Button, Typography } from "@mui/material";
import { CircleOutlined, InfoOutlined } from "@mui/icons-material";

interface incomingProps {}

/**
 *
 * @deprecated use the header in student folder instead. it loads items dynamically from the backend, so it should still work for AP
 */
export default function Header({}: incomingProps) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <img className="" src={nsfasLogo} alt="NSFAS" />
      </Box>
      <List>
        {/* {['My Residence', 'Property Management', 'Offer Letters'].map((text, index) => (
			  <ListItem key={text} disablePadding>
				<ListItemButton>
				  <ListItemIcon>
					{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
				  </ListItemIcon>
				  <ListItemText primary={text} />
				</ListItemButton>
			  </ListItem>
			))} */}
        <ListItem className="drawer-item">
          <HouseIcon />
          <Link className="drawer-icons " to="/">
            Home
          </Link>
        </ListItem>
        <ListItem className="drawer-item">
          <ApartmentIcon />
          <Link className="drawer-icons" to="/dashboard">
            My Residence
          </Link>
        </ListItem>
        <ListItem className="drawer-item">
          <ManageAccountsIcon />
          <Link className="drawer-icons" to="/residences">
            Property Management
          </Link>
        </ListItem>
        <ListItem className="drawer-item">
          <AttachFileIcon />
          <Link className="drawer-icons" to="/offer-letter">
            Offer Letters
          </Link>
        </ListItem>
      </List>
    </Box>
  );

  // ******************End Drawer *******************

  const dispatch = useDispatch();
  const userApi = useUserApi();
  const commonApi = useCommonApi();
  const alerts = useAlertsSelector();
  const user: any = useUserSelector();
  const navigate = useNavigate();
  const [showTermsModal, setShowTermsModal] = useState(false);

  const [userInitials, setUserInitials] = useState<any>();
  const logout = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    userApi.logout(
      {},
      (message: string, resp: any) => {},
      (message: string) => {}
    );
  };

  const updateProfile = () => {
    navigate(URLS.UPDATE_PROFILE);
  };

  const userTermsAccepted = user?.tsAndCsAccepted ?? null;

  useEffect(() => {
    if (userTermsAccepted === false) {
      setShowTermsModal(true);
    }
  }, [userTermsAccepted]);

  const getNotifications = () => {
    if (!user.externalLogonId) {
      return;
    }
    commonApi.getNotifications(user.externalLogonId);
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    getNotifications();
    const initials = user?.name;
    if (initials.split(" ").length > 1) {
      const firstInitials = initials.split(" ")[0];
      const secondInitials = initials.split(" ")[1];
      setUserInitials(firstInitials[0] + secondInitials[0]);
    } else {
      setUserInitials(initials[0]);
    }
  }, []);

  const acceptTerms = () => {
    if (!user.externalLogonId) {
      toast.error("An error occurred");
      return;
    }
    commonApi.post(
      {
        entityName: "ExternalLogOn",
        recordId: user.externalLogonId,
        requestName: "UpsertRecordReq",
        inputParamters: {
          Entity: {
            TsAndCsAccepted: "true",
          },
        },
      },
      (message: string, resp: any) => {
        userApi.acceptTerms();
      },
      (message: string) => {
        toast.error(message);
      }
    );

    setShowTermsModal(false);
  };

  const unreadAlerts = alerts?.filter(alert => alert.read !== true);

  const notificationCount = unreadAlerts?.length || 0;

  const openNotification = (actionLink: string) => () => {
    dispatch({
      type: ACTIONS.USER.READ_NOTIFICATION,
      payload: actionLink,
    });
    navigate(`/${actionLink}`);
  };
  // *********************************************************
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("file_terms.pdf.pdf").then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SamplePDF.pdf";
        alink.click();
      });
    });
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-orange">
        <div className="container-fluid">
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <a className="navbar-brand" href="/">
              <img
                className="header-logo"
                src="/assets/img/logo-white.png"
                alt="NSFAS"
              />
            </a>
            <i
              className="fa fa-bars burger-icon"
              aria-hidden="true"
              onClick={toggleDrawer(true)}
            ></i>

            <ul
              className="navigation-items-menu"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <li
                className="header-items-container"
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Link
                  className="nav-link active header-item"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li
                className="header-items-container"
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Link
                  className="nav-link active header-item"
                  aria-current="page"
                  to="/dashboard"
                >
                  My Residences
                </Link>
              </li>
              <li
                className="header-items-container"
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Link
                  className="nav-link active header-item"
                  aria-current="page"
                  to="/residences"
                >
                  Residence Management
                </Link>
              </li>
              <li
                className="header-items-container"
                style={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Link
                  className="nav-link active header-item"
                  aria-current="page"
                  to="/offer-letter"
                >
                  Offer Letters
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav me-auto ms-auto mb-2 mb-lg-0">
              {/* <li className="nav-item" style={{fontWeight:'600'}}>
							<Link className="nav-link active" aria-current="page" to="/">Home</Link>
						</li> */}
            </ul>

            <div className="d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="mx-2">
                  &nbsp;
                  <FaBell /> ({notificationCount})&nbsp;&nbsp;
                </Dropdown.Toggle>
                {unreadAlerts && (
                  <Dropdown.Menu>
                    {unreadAlerts.map(alert => {
                      return (
                        <Dropdown.Item
                          key={alert.actionLink}
                          onClick={openNotification(alert.actionLink)}
                        >
                          <span className="text-muted">
                            <FaInfoCircle />
                          </span>{" "}
                          &nbsp; {alert.message}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                )}
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="btn btn-outline-success"
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#C7E0F4",
                    borderColor: "#C7E0F4",
                  }}
                >
                  {userInitials && userInitials}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={updateProfile}>
                    Edit Profile
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </nav>
      <div>
        {/* <Button onClick={toggleDrawer(true)}>Open drawer</Button> */}
        <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>
      <Modal
        show={showTermsModal}
        onHide={() => logout()}
        backdrop="static"
        keyboard={false}
        // style={{width:'100%'}}
        fullscreen
      >
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src="assets/img/logo-white.png"
            alt=""
            style={{ width: "60px" }}
          />
          <Modal.Title style={{ marginLeft: "auto" }}>
            <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
              Terms & Conditions
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{}}>
          {/* <TermsAndConditions /> */}
          {/* <iframe src="https://ncert.nic.in/textbook/pdf/keip108.pdf"/> */}
          <iframe
            src="https://assets.ezra360.com/images/images/Terms and Conditions.pdf"
            width={"100%"}
            height={"100%"}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button color="error" onClick={e => logout(e)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              acceptTerms();
            }}
          >
            Accept
          </Button>

          {/* add link */}
          {/* <Button variant="success" onClick={onButtonClick}>
						Download

					</Button>			 */}
        </Modal.Footer>
      </Modal>
      <Box pt={3}>
        {unreadAlerts && unreadAlerts.length > 0 && (
          <Alert
            severity="error"
            icon={<InfoOutlined />}
            action={
              <Button
                size="small"
                variant="text"
                color="inherit"
                sx={{ paddingTop: "0 !important" }}
                onClick={openNotification(unreadAlerts[0].actionLink)}
              >
                Update Now
              </Button>
            }
          >
            <Typography>{unreadAlerts[0].message}</Typography>
          </Alert>
        )}
      </Box>
    </>
  );
}
