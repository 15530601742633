import { useSelector } from "react-redux";
import { User } from "../../interfaces/models/user";
import { StateExtended } from "../../interfaces/StateExtended";

/** @deprecated use the useCurrentUser hook as it has better Typescript support */
export function useUserSelector() {
  const user: User | null = useSelector(
    (state: StateExtended) => state.user.user
  );
  return user;
}

export function useUserTokenSelector() {
  const token: string | null = useSelector(
    (state: StateExtended) => state.user.token
  );
  return token;
}

export function useUserAuthSelector() {
  const isAuth: boolean = useSelector(
    (state: StateExtended) => state.user.isAuthenticated
  );
  return isAuth;
}

export function useNavigationSelector() {
  const navigations: any = useSelector(
    (state: StateExtended) => state.user.navigations
  );
  return navigations;
}

export function useAlertsSelector() {
  const alerts = useSelector((state: StateExtended) => state.user.alerts);
  return alerts;
}

// export function useAlertsSelector() {
//     const alerts = useSelector((state: StateExtended) => state.user.alerts)
//     return alerts;
// }
