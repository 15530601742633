import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";
import FeatureIcon from "../FeatureBlocks";
import FeatureBlocks from "../FeatureBlocks";
import OtpInput from "../OtpInput";
import { IApiResponse } from "../../types/IApiResponse";
import { EXECUTE_REQUEST_PATH } from "../../constants";
import { showErrorAlert } from "../Notify";
import { API } from "../../services/API";
import useCurrentUser from "../../hooks/useCurrentUser";
import AsyncContent from "../AsyncBox";

export default NiceModal.create(
  ({
    title,
    subtitle,
    onOtpConfirm,
    onCancel,
    mode = "email",
  }: {
    title: string;
    subtitle?: string;
    onCancel?: () => void;
    onOtpConfirm: (code: string) => void;
    mode?: "email" | "sms";
  }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const [isLoaded, setIsLoaded] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const currentUser = useCurrentUser();
    const [otp, setOtp] = useState("");

    const handleCancel = async (e: unknown, reason?: string) => {
      if (reason && reason === "backdropClick") {
        return;
      }
      onCancel?.();
      setIsLoaded(false);
      await modal.hide();
      modal.remove();
    };

    const [otpSentMessage, setOtpSentMessage] = useState(
      "We have sent you an OTP to your email address"
    );
    useEffect(() => {
      if (!currentUser || isLoaded || !modal.visible) {
        return;
      }

      (async () => {
        try {
          // Call API to get OTP
          console.log(currentUser);
          const response: IApiResponse<any> = await API.post(
            EXECUTE_REQUEST_PATH,
            {
              entityName: currentUser.relatedObjectIdObjectTypeCode,
              requestName: "GeneratOTPExecuteRequest",
              RecordId: currentUser?.supplierId || currentUser.recordId,
            }
          );
          if (!response.isSuccess) {
            showErrorAlert(response.clientMessage || "Failed to send OTP");
            onCancel?.();
            await modal.hide();
            modal.remove();
            return;
          }
          setOtpSentMessage(response.clientMessage);
        } catch (error) {
          showErrorAlert("An error occurred while requesting OTP");
          handleCancel(null, "exit");
        } finally {
          setIsLoaded(true);
        }
      })();
    }, [currentUser, isLoaded, modal.visible]);

    const handleConfirm = async () => {
      try {
        if (!currentUser) {
          return;
        }
        const response: IApiResponse<any> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: currentUser.relatedObjectIdObjectTypeCode,
            requestName: "ConfirmOTPExecuteRequest",
            recordId: currentUser?.supplierId || currentUser.recordId,
            inputParamters: {
              OTPInformation: {
                OTPNo: otp,
              },
            },
          }
        );
        if (!response.isSuccess) {
          showErrorAlert(response.clientMessage || "Failed to confirm OTP");
          return;
        }
        onOtpConfirm("TEst");
        setIsLoaded(false);
        await modal.hide();
        modal.remove();
      } catch (error) {}
    };
    return (
      <Dialog
        fullScreen={fullScreen}
        open={modal.visible}
        onClose={handleCancel}
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1, textAlign: "center" }}>
            {title}
            <Typography>{subtitle}</Typography>
          </Box>
          <Box>
            <IconButton onClick={handleCancel}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Paper
            sx={{
              width: "100%",
              overflowY: "auto",
              minHeight: 300,
              maxHeight: "80vh",
            }}
            elevation={0}
          >
            <AsyncContent loading={!isLoaded}>
              <Grid item xs={12}>
                <Box display="flex" p={3} justifyContent="center">
                  <FeatureBlocks.Icon icon="mail" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <FeatureBlocks.Text>
                  {`Check your ${mode === "email" ? "email" : "phone"}`}
                </FeatureBlocks.Text>
              </Grid>
              <Grid item xs={12}>
                <FeatureBlocks.SubText>{otpSentMessage}</FeatureBlocks.SubText>
              </Grid>
              <Grid item xs={12}>
                <OtpInput length={4} onChange={_ => setOtp(_)} />
              </Grid>
            </AsyncContent>
          </Paper>
          <Box
            sx={{
              minWidth: { xs: 300, md: 520 },
              maxWidth: { xs: 480, md: undefined },
            }}
          >
            &nbsp;
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", py: 3, px: 2 }}>
          <Button
            onClick={handleCancel}
            sx={{ flex: 1 }}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            disabled={otp.length < 4}
            sx={{ flex: 1 }}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);
