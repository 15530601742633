import React, { useEffect, useMemo, useState } from "react";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { Row, Col } from "react-bootstrap";
import IntroSlider from "../components/introSlider";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
  useWatch,
} from "react-hook-form";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { toast } from "react-hot-toast";
import Select from "react-select";

import * as saIdParser from "south-african-id-parser";
import { FormHelperText } from "@mui/material";
import { EMAIL_VALIDATION_REGEX, PHONE_NUMBER_REGEX } from "../ui/v2/constants";

import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useForgotPasswordMutation } from "@/services/authService";

type FormValues = {
  email: string;
  channel: { value: string; label: string };
};

const options = [
  { value: "RSAID", label: "South African ID" },
  { value: "EMAIL", label: "Email Address" },
  { value: "MOBILE", label: "Mobile" },
];

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useAppLoader();
  const commonApi = useCommonApi();
  const [send, setSend] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [stepIndex, setstepIndex] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<FormValues>({
    mode: "all",
  });
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  console.log("type", type);

  const [error, setError] = useState("");

  console.log("err", error);

  const validateEmail = (email: string) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [
    forgotPassword,
    { isLoading, isSuccess, isError, data, error: logErr },
  ] = useForgotPasswordMutation();

  console.log({ isLoading, isSuccess, isError, data, logErr });

  const onSubmit: SubmitHandler<FormValues> = data => {
    if (type === "s") {
      const { isValid: isIdValid } = saIdParser.parse(idNumber);

      console.log("isVa", isIdValid);
      if (!isIdValid) {
        setError("Please enter a valid id number.");
      } else {
        setError("");
        showLoader();

        //   forgotPassword({
        //     entityName: "ExternalLogon",
        //     requestName: "ForgotPasswordExecuteRequest",
        //     inputParamters: {
        //         ForgotPassword: {
        //             Username: idNumber,
        //             Channel: 'RSAID',
        //         },
        //     },
        // })

        commonApi.post(
          {
            entityName: "ExternalLogon",
            requestName: "ForgotPasswordExecuteRequest",
            inputParamters: {
              ForgotPassword: {
                Username: idNumber,
                // Channel: data.channel.value,
                Channel: "RSAID",
              },
            },
          },
          (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            setstepIndex(1);
          },
          (message: string) => {
            hideLoader();
            toast.error(message);
          }
        );
      }
    }

    if (type === "p") {
      if (!validateEmail(email)) {
        setError("Please enter a valid email address.");
      } else {
        setError("");
        showLoader();

        commonApi.post(
          {
            entityName: "ExternalLogon",
            requestName: "ForgotPasswordExecuteRequest",
            inputParamters: {
              ForgotPassword: {
                Username: email,
                // Channel: data.channel.value,
                Channel: "EMAIL",
              },
            },
          },
          (message: string, resp: any) => {
            hideLoader();
            toast.success(message);
            setstepIndex(1);
          },
          (message: string) => {
            hideLoader();
            toast.error(message);
          }
        );
      }
    }
  };

  const selectedChannel = useWatch({ control, name: "channel" });

  const getUserLabel = () => {
    switch (selectedChannel?.value) {
      case "RSAID":
        return "South African ID";
      case "EMAIL":
        return "Email Address";
      case "MOBILE":
        return "Mobile";
      default:
        return "Username";
    }
  };

  const selectedChannelValue = useMemo(() => {
    return selectedChannel?.value || options[0].value;
  }, [selectedChannel?.value]);

  const validateUser = (value: string) => {
    const { isValid: isIdValid } = saIdParser.parse(value);
    if (selectedChannelValue === "RSAID" && !isIdValid) {
      return "Please enter a valid South African ID.";
    }
    if (
      selectedChannelValue === "EMAIL" &&
      !value.match(EMAIL_VALIDATION_REGEX)
    ) {
      return "Please enter a valid Email Address.";
    }
    if (
      selectedChannelValue === "MOBILE" &&
      value.length > 12 &&
      !value.match(PHONE_NUMBER_REGEX)
    ) {
      return "Please enter a valid Mobile Number.";
    }
    return true;
  };

  const StyledForm = styled("form")(({ theme }) => ({
    width: "100%",
    marginTop: theme.spacing(1),
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#F26721",
    "&:hover": {
      backgroundColor: "#D55A1D",
    },
  }));

  // useEffect(() => {
  //     setValue('email', '', {shouldValidate: true})
  // }, [selectedChannelValue])

  // const location = useLocation();

  // useEffect(() => {
  //   if (location.state && location.state.userLoginDetails) {
  //     console.log("state", location.state.userLoginDetails);
  //     const userLoginDetails = location.state.userLoginDetails;
  //     if (userLoginDetails.type) {
  //         const matchingType = options.find((option) => option.value === userLoginDetails.type);
  //         if(matchingType){
  //             reset({
  //                 channel: matchingType,
  //                 email: userLoginDetails.value
  //             })
  //         }
  //     }

  //   }
  // }, [location.state]);

  switch (stepIndex) {
    case 0:
      return (
        <Container component="main" maxWidth="xs" sx={{ marginTop: 10 }}>
          <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                border: 2,
                borderColor: "#eaeaeb",
                borderRadius: 2,
                width: "fit",
                padding: 1,
              }}
            >
              <LockOpenIcon fontSize="large" />
            </Box>
          </Box>
          <Typography
            component="h1"
            variant="h5"
            textAlign={"center"}
            sx={{ fontWeight: 600 }}
          >
            Forgot password?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ mt: 1, fontWeight: 400, fontSize: "15px" }}
          >
            No worries, we'll send you reset instructions.
          </Typography>
          <StyledForm
            onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
          >
            {type === "s" && (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="idNumber"
                label="Id Number"
                name="idNumber"
                autoComplete="idNumber"
                autoFocus
                value={idNumber}
                onChange={e => setIdNumber(e.target.value)}
                placeholder="Enter your Id Number"
                error={!!error}
                helperText={error}
              />
            )}
            {type === "p" && (
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter your email"
                error={!!error}
                helperText={error}
              />
            )}
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Reset password
            </StyledButton>
          </StyledForm>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ mt: 1, color: "#989a9c" }}
              onClick={() => {
                navigate("/entry-type");
              }}
            >
              <ArrowBackIcon />
              Back to login
            </Button>
          </Box>
        </Container>
      );
      break;
    case 1:
      return (
        <Container component="main" maxWidth="xs" sx={{ marginTop: 10 }}>
          <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                border: 2,
                borderColor: "#eaeaeb",
                borderRadius: 2,
                width: "fit",
                padding: 1,
              }}
            >
              <LockOpenIcon fontSize="large" />
            </Box>
          </Box>
          <Typography
            component="h1"
            variant="h5"
            textAlign={"center"}
            sx={{ fontWeight: 600 }}
          >
            Check your email
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ mt: 1, fontWeight: 400, fontSize: "15px" }}
          >
            We sent a password reset link to{" "}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ mt: 1, fontWeight: 500, fontSize: "15px" }}
          >
            {email}{" "}
          </Typography>
          <StyledForm>
            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.href = `mailto:${email}`;
              }}
            >
              Open Email Client
            </StyledButton>
          </StyledForm>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ mt: 1 }}
            >
              Didn’t receive the email?{" "}
              <Button
                color="primary"
                onClick={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
              >
                Click to resend
              </Button>{" "}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              sx={{ mt: 1, color: "#989a9c" }}
              onClick={() => {
                navigate("/entry-type");
              }}
            >
              <ArrowBackIcon /> Back to login
            </Button>
          </Box>
        </Container>
      );
      break;

    default:
      <div>Error step</div>;
  }

  return <div>Error step</div>;
}
